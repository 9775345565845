<p class="component my-1 ps-2">{{'Dashboard'|translate}}</p>

<div class="container-fluid p-2">
  <div class="row w-100 d-flex mx-0 mb-2">
    <div
      class="card dashboard_headers dashboard-header0 mb-3 cursorPointer"
     
    >
      <div class="card-body cardalign">
        <span class="count" (click)="redirectTrackerUrl('UNASSIGNED')">{{this.totalRecordCount}}</span>
        <span class="card_title1">
          <img
            src="/assets/icons/dash_head.png"
            alt="dash_head"
            class="dash_head"
          />
          <span class="head me-1" (click)="redirectTrackerUrl('UNASSIGNED')">{{'Total' | translate}}</span>
          <div 
          class="dropdown-toggle" (click)="toggleDropdown('total')">
          <!-- <i class="fa-solid " style="color:white;"></i> -->
          <i class="fas fa-chevron-down" style="color: white;"></i>
          </div>
        </span>
      </div>
      <div class="dropdown-content dashboard-header0" [ngClass]="{ 'show': showDropdown }"
      style="z-index: 10;">
      <div class="scrollable-content" [ngStyle]="{ 'overflow-y': totalRecordCountByClient.length > 6 ? 'scroll' : 'hidden' }">
        <ng-container *ngFor="let task of totalRecordCountByClient">
          <div class="dropdown-item"><span class="head me-4">{{task.clientName}}</span> <span class="countC ">{{task.recordCount}}</span></div>
        </ng-container>
        <div *ngIf="totalRecordCountByClient.length == 0" class="p-1 ">
          <span class="head"> No record available</span>
          </div>
      </div>    
      </div>
    </div>
    <div
      class="card dashboard_headers dashboard-header5 mb-3 cursorPointer"
    >
      <div class="card-body cardalign">
        <span class="count"  (click)="redirectTrackerUrl('UNASSIGNED')">{{this.unassignedCount}}</span>
        <span class="card_title1">
          <img
            src="/assets/icons/dash_head.png"
            alt="dash_head"
            class="dash_head4"
          />
          <span class="head"   (click)="redirectTrackerUrl('UNASSIGNED')">{{'Unassigned'|translate}}</span>
          <div 
          class="dropdown-toggle" (click)="toggleDropdown('unassigned')">
          <!-- <i class="fa-solid " style="color:white;"></i> -->
          <i class="fas fa-chevron-down" style="color: white;"></i>
          </div>
        </span>
        
      </div>

      <div class="dropdown-content dashboard-header5" [ngClass]="{ 'show': showUnassignedDropdown }"
      style="z-index: 10;"
      >
      <div class="scrollable-content" [ngStyle]="{ 'overflow-y': unassignedTaskList.length > 6 ? 'scroll' : 'hidden' }">
        <ng-container *ngFor="let task of unassignedTaskList">
          <div class="dropdown-item"><span class="head me-4">{{task.clientName}}</span> <span class="countC ">{{task.recordCount}}</span></div>
        </ng-container>
        <div *ngIf="unassignedTaskList.length == 0" class="p-1 ">
          <span class="head"> No record available</span>
          </div>
      </div>    
      </div>
    </div>
    <div
      class="card dashboard_headers dashboard-header2 mb-3 cursorPointer"   
    >
      <div class="card-body cardalign">
        <span class="count"  (click)="redirectTrackerUrl('ASSIGNED')">{{ this.assignedCount }}</span>
        <span class="card_title1">
          <img
            src="/assets/icons/dash_head.png"
            alt="dash_head"
            class="dash_head2"
          />
          <span class="head"  (click)="redirectTrackerUrl('ASSIGNED')">{{'Assigned'|translate}}</span>
          <div 
          class="dropdown-toggle" (click)="toggleDropdown('assigned')">
          <!-- <i class="fa-solid " style="color:white;"></i> -->
          <i class="fas fa-chevron-down" style="color: white;"></i>
          </div>
        </span>
      </div>
      <div class="dropdown-content dashboard-header2" [ngClass]="{ 'show': showAssignedDropdown }"
      style="z-index: 10;">
      <div class="scrollable-content" [ngStyle]="{ 'overflow-y': assignedTaskList.length > 6 ? 'scroll' : 'hidden' }">
        <ng-container *ngFor="let task of assignedTaskList">
          <div class="dropdown-item"><span class="head me-4">{{task.clientName}}</span> <span class="countC ">{{task.recordCount}}</span></div>
        </ng-container>
        <div *ngIf="assignedTaskList.length == 0" class="p-1 ">
          <span class="head"> No record available</span>
          </div>
      </div>    
      </div>
    </div>
    <div
      class="card dashboard_headers dashboard-header3 mb-3 cursorPointer"
    >
      <div class="card-body cardalign">
        <span class="count" (click)="redirectTrackerUrl('ASSIGNED')">{{ this.acknowledgedCount }}</span>
        <span class="card_title1">
          <img
            src="/assets/icons/dash_head.png"
            alt="dash_head"
            class="dash_head3"
          />
          <span class="head" (click)="redirectTrackerUrl('ASSIGNED')">{{'Acknowledged'|translate}}</span>
          <div 
          class="dropdown-toggle" (click)="toggleDropdown('acknowledged')">
          <!-- <i class="fa-solid " style="color:white;"></i> -->
          <i class="fas fa-chevron-down" style="color: white;"></i>
          </div>
        </span>
      </div>
      <div class="dropdown-content dashboard-header3" [ngClass]="{ 'show': showAcknowledgedDropdown }"
      style="z-index: 10;"
      >
      <div class="scrollable-content" [ngStyle]="{ 'overflow-y': acknowledgedTaskList.length > 6 ? 'scroll' : 'hidden' }">
        <ng-container *ngFor="let task of acknowledgedTaskList">
          <div class="dropdown-item"><span class="head me-4">{{task.clientName}}</span> <span class="countC ">{{task.recordCount}}</span></div>
        </ng-container>
        <div *ngIf="acknowledgedTaskList.length == 0" class="p-1 ">
          <span class="head"> No record available</span>
          </div>
       </div>   
      </div>
    </div>
    <div
      class="card dashboard_headers dashboard-header4 mb-3 cursorPointer"
     
    >
    <div class="card-body cardalign">
        <span class="count"  (click)="redirectTrackerUrl('ASSIGNED')">{{ this.startedCount }}</span>
        <span class="card_title1">
          <img
            src="/assets/icons/dash_head.png"
            alt="dash_head"
            class="dash_head3"
          />
          <span class="head"  (click)="redirectTrackerUrl('ASSIGNED')">{{'Started'|translate}}</span>
          <div 
          class="dropdown-toggle" (click)="toggleDropdown('started')">
          <!-- <i class="fa-solid " style="color:white;"></i> -->
          <i class="fas fa-chevron-down" style="color: white;"></i>
          </div>
        </span>
      </div>
      <div class="dropdown-content dashboard-header4" [ngClass]="{ 'show': showStartedDropdown }"
      style="z-index: 10;"
      >
      <div class="scrollable-content" [ngStyle]="{ 'overflow-y': startedTaskList.length > 6 ? 'scroll' : 'hidden' }">
      <ng-container *ngFor="let task of startedTaskList">
        <div class="dropdown-item"><span class="head me-4">{{task.clientName}}</span> <span class="countC ">{{task.recordCount}}</span></div>
      </ng-container>
      <div *ngIf="startedTaskList.length == 0" class="p-1 ">
        <span class="head"> No record available</span>
        </div>
      </div>
      </div>
    </div>
    <div
      class="card dashboard_headers dashboard-header1 mb-3 cursorPointer"
    >
      <div class="card-body cardalign">
        <span class="count"  (click)="redirectTrackerUrl('COMPLETED')">{{ this.completedCount }}</span>
        <span class="card_title1">
          <img
            src="/assets/icons/dash_head.png"
            alt="dash_head"
            class="dash_head1"
          />
          <span class="head"  (click)="redirectTrackerUrl('COMPLETED')">{{'Completed'|translate}}</span>
          <div 
          class="dropdown-toggle" (click)="toggleDropdown('completed')">
          <!-- <i class="fa-solid " style="color:white;"></i> -->
          <i class="fas fa-chevron-down" style="color: white;"></i>
          </div>
          
        </span>
      </div>
      <div class="dropdown-content dashboard-header1" [ngClass]="{ 'show': showCompletedDropdown }"
      style="z-index: 10;">
      <div class="scrollable-content" [ngStyle]="{ 'overflow-y': completedTaskList.length > 6 ? 'scroll' : 'hidden' }">
      <ng-container *ngFor="let task of completedTaskList">
        <div class="dropdown-item"><span class="head me-4">{{task.clientName}}</span> <span class="countC ">{{task.recordCount}}</span></div>
      </ng-container>
      <div *ngIf="completedTaskList.length == 0" class="p-1 ">
        <span class="head"> No record available</span>
        </div>
        </div>
      </div>
    </div>
    <div
      class="card dashboard_headers dashboard-header6 mb-3 cursorPointer"
      
    >
      <div class="card-body cardalign">
        <span class="count" (click)="redirectTrackerUrl('UNASSIGNED')">{{ this.rejectedCount }}</span>
        <span class="card_title1">
          <img
            src="/assets/icons/dash_head.png"
            alt="dash_head"
            class="dash_head5"
          />
          <span class="head" (click)="redirectTrackerUrl('UNASSIGNED')">{{'CANCELLED'|translate}}</span>
          <div 
          class="dropdown-toggle" (click)="toggleDropdown('cancelled')">
          <!-- <i class="fa-solid " style="color:white;"></i> -->
          <i class="fas fa-chevron-down" style="color: white;"></i>
          </div>
        </span>
      </div>
      <div class="dropdown-content dashboard-header6" [ngClass]="{ 'show': showCancelledDropdown }"
      style="z-index: 10;">
      <div class="scrollable-content" [ngStyle]="{ 'overflow-y': cancelledTaskList.length > 6 ? 'scroll' : 'hidden' }">
      <ng-container *ngFor="let task of cancelledTaskList">
        <div class="dropdown-item"><span class="head me-4">{{task.clientName}}</span> <span class="countC ">{{task.recordCount}}</span></div>
      </ng-container>
      <div *ngIf="cancelledTaskList.length == 0" class="p-1 ">
      <span class="head"> No record available</span>
      </div>
      </div>
      </div>
    </div>
  </div>

  <div class="row m-0 fixheight d-none">
    <!-- CHART 1 -->

    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12 p-0">
      <div class="cardshadow mb-3">
        <div class="card_header1">
          <span class="fuelrate">{{'Monthly Money Spent'|translate}}</span>
          <span class="d-flex flex-direction-row">
            <span
              class="material-icons"
              style="color: #b7b7b7; margin-right: 1vh"
            >
            cached
            </span>
            <span class="material-icons d-block"> close </span>
          </span>
        </div>
        <div id="chart1" style="overflow: auto">
          <div id="chart"></div>
        </div>
      </div>
    </div>

    <!-- FLEET IDLE -->

    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 paddingMobile">
      <div
        class="card mb-3 cardshadow"
        style="max-width: 100%; justify-content: space-between"
      >
        <div class="card-header">
          <span class="fuelrate pt-2">{{'Fleet Idle'|translate}}</span>
          <div
            style="display: flex; flex-direction: row; justify-content: center"
          >
            <div class="customdropdown">
              <select
                class="form-select form-select"
                aria-label=".form-select-lg example"
                style="height: 4.5vh !important"
              >
                <option selected>Custom</option>
                <option value="1">demo</option>
                <option value="2">demo</option>
                <option value="3">demo</option>
              </select>
            </div>
            <span class="material-icons pt-1" style="color: #b7b7b7">
              cached
            </span>
            <span class="material-icons d-block pt-1"> close </span>
          </div>
        </div>
        <div>
          <div class="row m-0">
            <div
              class="col-6 danger_col"
              style="border-right: 2px solid #324151"
            >
              <span>{{'Total Fleet Idle'|translate}}</span>
              <img src="/assets/icons/danger.png" alt="danger" />
              <span>33 Hrs</span>
            </div>
            <div class="col-6 danger_col">
              <span>{{'Approx Fuel Waste'|translate}}</span>
              <img src="/assets/icons/pump.png" alt="danger" />
              <span>petrol: 62 Ltr</span>
            </div>
          </div>
        </div>

        <div class="note">
          <span style="color: #f84a67">{{'Note'|translate}}:</span> Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Pretium placerat ac malesuada scelerisque
          semper. Libero a neque lobortis facilisi vel eget eleifend.
        </div>
      </div>
    </div>

    <!-- SHORTCUT -->

    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12 p-0">
      <div class="card cardshadow">
        <div class="card_header1">
          <span class="fuelrate">{{'Shortcut'|translate}}</span>
          <span>
            <span class="material-icons d-block"> close </span>
          </span>
        </div>
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <span>{{'Add New Machine'|translate}}</span>
              <img src="/assets/icons/play.png" alt="play" />
            </li>
            <li class="list-group-item">
              <span>{{'Machine List'|translate}}</span>
              <img src="/assets/icons/play.png" alt="play" />
            </li>
            <li class="list-group-item">
              <span>{{'Order List'|translate}}</span>
              <img src="/assets/icons/play.png" alt="play" />
            </li>
            <li class="list-group-item">
              <span>{{'Add New Leads'|translate}}</span>
              <img src="/assets/icons/play.png" alt="play" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0 w-100 expenserow">
    <!-- CHART 2 -->
    <!-- <div class="row mb-1 m-0 p-0">
      <div class="col-12">
        <app-search-device class="w-100"></app-search-device>
      </div>
    </div> -->
    <div class="flowchart">
      <div class="card_header1">
        <span class="fuelrate">{{'Last 7 Days Tasks'|translate}}</span>
        <span class="d-flex flex-direction-row" style="width: 10vh">
          <span
            class="material-icons"
            style="color: #b7b7b7; margin-right: 1vh"
          >
            cached
          </span>
          <span class="material-icons d-block"> close </span>
        </span>
      </div>
      <div id="chart2"></div>
    </div>
    <div class="flowchart">
      <div class="card_header1">
        <span class="fuelrate">{{'Top 10 Employees'|translate}}</span>
        <span class="d-flex flex-direction-row" style="width: 10vh">
          <span
            class="material-icons"
            style="color: #b7b7b7; margin-right: 1vh"
          >
            cached
          </span>
          <span class="material-icons d-block"> close </span>
        </span>
      </div>
      <div id="chartOdometer"></div>
    </div>
    <!-- FUEL RATE -->

    <div class="fuel_rate_section">
      <!-- Past 7 DAYS -->

      <div class="card">
        <div class="card_header1">
          <span class="fuelrate">{{'Employees Status'|translate}}</span>
          <span class="d-flex flex-direction-row">
            <span
              class="material-icons"
              style="color: #b7b7b7; margin-right: 1vh"
            >
              cached
            </span>
            <span class="material-icons d-block"> close </span>
          </span>
        </div>
        <div class="fuel_pump">
          <div class="owl-carousel" id="eventCarousel">
            <div class="item d-flex align-items-center justify-content-center flex-column" *ngFor="let e of eventStatusCount.slice().reverse()">
              <div class="event__count cursorPointer" (click)="redirectDriverUrl(e.status)">{{e.recordCount}}</div>
              <div class="font-size-12">{{e.status}}</div>
            </div>
          </div>         
        </div>
      </div>

      <!-- TODAY'S FUEL RATE -->

      <div class="card" style="margin-top: 3vh">
        <div class="card_header1">
          <span class="fuelrate">{{"Today's Fuel Rate"|translate}}</span>
          <span class="d-flex flex-direction-row">
            <span
              class="material-icons"
              style="color: #b7b7b7; margin-right: 1vh"
            >
              cached
            </span>
            <span class="material-icons d-block"> close </span>
          </span>
        </div>

        <div class="state_dropdown">
          <select
            class="form-select scroller mb-3"
            [(ngModel)]="state"
            name="state"
            (change)="getFuelPrice_state()"
            aria-label=".form-select-lg example"
          >
            <option value="" disabled>{{'State'|translate}}</option>
            <option
              value="{{ state }}"
              *ngFor="let state of states; let i = index"
            >
              {{ state }}
            </option>
          </select>
        </div>

        <div class="row m-0 pb-3">
          <div class="col-6 colfirst" style="border-right: 2px solid #324151">
            <span class="fleettext">{{'Diesel'|translate}}</span>
            <img src="/assets/icons/petrol_can.png" alt="danger" />
            <span class="currency1">{{'INR'|translate}} {{ dieselPrice }}</span>
          </div>
          <div class="col-6 colfirst">
            <span class="fleettext"> {{'Petrol'|translate}}</span>
            <img src="/assets/icons/pumpbox.png" alt="danger" />
            <span class="currency1">{{'INR'|translate}} {{ petrolPrice }}</span>
          </div>
        </div>
      </div>
    </div>

   
  </div>
 <div class="row w-100 mx-0 ">
  <div class=" col-12 col-md-12 p-0">

    <div class="flowchart1">
      <div class="card_header1">
        <div class="col-3 col-md-3">
          <select
          class="form-select me-2  taskspanel"
          id="escort1"
          style="height: 6.2vh;"
          [(ngModel)]="selectedOption"
          (change)="onChange()"
        >
        <option value="0" class="select_options" >{{"Today's Tasks"|translate}}</option>
        <option value="1" class="select_options">{{'Last 1 month Tasks'|translate}}</option>    
        </select> 
        </div>

   
        <span class="d-flex flex-direction-row" style="width: 10vh">
          <span
            class="material-icons"
            style="color: #b7b7b7; margin-right: 1vh"
          >
            cached
          </span>
          <span class="material-icons d-block"> close </span>
        </span>
      </div>
      <div id="chart3"></div>
    </div>
  </div>
 </div>

</div>
