
import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-new-filter-modal',
  templateUrl: './new-filter-modal.component.html',
  styleUrls: ['./new-filter-modal.component.css']
})
export class NewFilterModalComponent implements OnInit {
  @Output() filterDataEvent = new EventEmitter<any>();
  formattedFromDate: any = '';
  formattedToDate: any = '';
  todateP: any;
  userdata: any;
  orgId: any;
  taskListData: any[] = [];
  customerList: any[] = [];
  inputFromDate: any = '';
  inputToDate: any= '';
  clientId: any= '';
  isSupervisor: boolean =false;

  toDate: any = '';
  fromDate: any = '';
  searchClients: any = '';
  constructor(public dialogRef: MatDialogRef<NewFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private commonService:CommonService, private ngZone: NgZone,
    private datePipe: DatePipe,
    private zone: NgZone
    ) {
    
     }


   
     // isClient: boolean = false;
     ngOnInit(): void {
       let userDataObj = localStorage.getItem('userData');
       if (userDataObj) {
         try {
           this.userdata = JSON.parse(userDataObj);
           console.log('Org Id =>', this.userdata.id);
           if (this.userdata.attributes.role === 'admin') {
             this.orgId = this.userdata.id;   
           }
           else if(this.userdata.attributes.role === 'sv'){  
             this.isSupervisor=true;
             this.orgId = this.userdata.orgId;
            
           }
           else{
             this.orgId = this.userdata.orgId;
           }
   
           this.getAllCustomers();
         } catch (error) {}
       }
       this.searchClients= -1;
     }
     getAllCustomers() {
       if(this.isSupervisor){
         const uniqueClientNames = new Set();
         const today = new Date();
         const formattedToday = today.toISOString().split('T')[0] + 'T00:00:00Z';
         const formattedToday1 = today.toISOString().split('T')[0] + 'T23:59:59Z';
         this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','','','','','')
         .subscribe((response) => {
          console.log("task list for supervisor=>",response)
         const uniqueClientData = new Set();
        response.forEach(item => {
       const clientName = item.attributes.clientName;
       const clientId = item.clientid;
       const clientDataString = `${clientName}_${clientId}`;
       uniqueClientData.add(clientDataString);
   });
   
   
   const uniqueClientDataArray = Array.from(uniqueClientData).map(string => {
       const [clientName, clientId] =  (string as string).split('_');
       return { clientName, clientId };
   });
   this.customerList=uniqueClientDataArray
   
         
         }, (error) => {
         
           console.error(error);
         });
       }
       else{
         this.commonService
         .getRequest(`users?orgId=${this.orgId}`, [])
         .then((resp: any) => {
           console.log('userslist',resp)
           if (resp?.status) {
             this.customerList = resp.data;
             this.customerList = this.customerList.filter(
               (customer) => customer.attributes?.role == 'clients'
             );
           }
         });
       }
     }
     
     convertedDates() {
      const assignedTo: any = '';
      const supervisorId: any = '';
    const clientId: any = this.searchClients ? this.searchClients : '';
     if(this.fromDate != '' && this.toDate!= ''){
      this.inputFromDate = moment(this.fromDate);
      this.inputToDate = moment(this.toDate);
    this.formattedFromDate = this.inputFromDate;
    this.formattedToDate = this.inputToDate;
     }
     this.closeDialogWithData();

     
     }
     sendDataToParent(data: any) {
       // Process your data as needed
       this.filterDataEvent.emit(data);
       // this.closeForm();
     }
  
      closeForm() {
        this.dialogRef.close();
        this.commonService.commonModel.next(false);
      }
   
     filteredData() {
       this.formattedFromDate = this.fromDate ? `${this.fromDate}T00:00:00Z` : '';
       this.formattedToDate = this.toDate ? `${this.toDate}T00:00:00Z` : '';
       console.log(this.formattedFromDate);
       console.log(this.formattedToDate);
       const assignedTo: any = '';
       const supervisorId: any = '';
       const clientId: any =this.searchClients ? this.searchClients : '';
      if(this.isSupervisor){
     this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,this.formattedFromDate,this.formattedToDate,assignedTo,clientId,'','','','')
     .subscribe((response) => {
   
     }, (error) => {
       console.error(error);
     });
   }
   else{
     this.commonService
     .getTasks(
       `traccartasks/orgId/${this.orgId}`,
       assignedTo,
       supervisorId,
       this.formattedFromDate,
       this.formattedToDate,
       clientId,
       ''
     )
     .subscribe(
       (response) => {
         this.taskListData = response;
         // Handle the API response here
         console.log(response);
       },
       (error) => {
         // Handle errors here
         console.error(error);
       }
     );
   }
   
      
     }

     closeDialogWithData(): void {
     const data = {
      formattedFromDate: this.formattedFromDate,
      formattedToDate: this.formattedToDate,
      searchClients: this.searchClients ? (this.searchClients == -1 ? '':this.searchClients):''
    };
    this.dialogRef.close(data);
    }
}
