import { Component, Inject, Input, OnInit ,AfterViewInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { map as rxMap, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Toast } from 'ngx-toastr';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

declare var google:any;
declare var document:any;
declare var map:any;
@Component({
  selector: 'app-edit-stop-of-task',
  templateUrl: './edit-stop-of-task.component.html',
  styleUrls: ['./edit-stop-of-task.component.css']
})
export class EditStopOfTaskComponent implements OnInit,AfterViewInit {
  @ViewChild('inputField') inputField!: ElementRef;
  taskDetails: any;
  pickLocation:any;
  Location:any;
  isPickup:boolean=false;
  contact:any;
  date:any;
  client:any;
  description:any;
  taskid:any
  shiftControl = new FormControl();
  filteredShifts: Observable<string[]>;
  @Input() placeholder = '';
  autocomplete: google.maps.places.Autocomplete;
  disabled: boolean = true;
  lat:any=0;
 long:any=0;
   map:any=null
  selectedValue: string;
  driverForm: FormGroup;
  formattedDate: string;
  isDrop: boolean;
  status: any;
  fromDateHours: number;
  fromDateMinutes: number;
  selectedTaskStatus: any='';
  isStarted: boolean = false;
  position_lat:any=0;
  position_lon:any=0;
  orgId: any;
  driversList: any;
  myDriverControl=new FormControl();
  filteredOptionsDriver: Observable<any[]>;
  selectedDriverId:any=''
  userData: any;
  assignDriver: boolean;
  isAssignDriver: boolean;
  // isPickUpAdd:boolean=false;
  // isDropAdd:boolean= false

  constructor(
    public dialogRef: MatDialogRef<EditStopOfTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private commonService:CommonService,
    private fb:FormBuilder,private datePipe:DatePipe
  ) { 
    this.taskDetails = data;
  }


  ngOnInit(): void {
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userData = JSON.parse(userDataObj);
       
        console.log('Org Id =>',this.userData.id)
        if(this.userData.attributes.role === 'admin'){
       this.orgId=this.userData.id;
      }
      else{
        this.orgId = this.userData.orgId;
      }
      } catch (error) {}
  }
    this.getDriversList();
console.log('remarks=>',this.taskDetails.attributes.remarks)  
this.selectTaskStatus(this.taskDetails.status.charAt(0).toUpperCase() +  this.taskDetails.status.slice(1))
  if(this.taskDetails.type == 'pickup' )
  { 
   this.isPickup = true;
   this.Location = this.taskDetails.pickuplocation;
  }
  else{
    this.isDrop = true;
    this.Location = this.taskDetails.droplocation;
  }
  this.client = this.taskDetails.clientid;
  this.contact = this.taskDetails.customerphone;
  this.date = this.taskDetails.expectedstarttime;
  this.description = this.taskDetails.description;
  this.status=this.taskDetails.status;
  const dateObj = new Date( this.date);
  const shiftTiming = this.taskDetails.attributes.Shift_Timing;

// Splitting the time string into hours and minutes
  const [hours, minutes] = shiftTiming.split(':');
 this.fromDateHours = hours; 
 this.fromDateMinutes = minutes;

console.log('timings=>',this.fromDateHours,this.fromDateMinutes)
  

const inputDate = this.taskDetails.expectedstarttime;
  // console.log("taskdate",this.taskDetails.date)
  this.date = this.formatCustomDate(inputDate);
//  console.log('date',this.date)
  const dateString =this.taskDetails.expectedstarttime;
 const formattedDate = this.datePipe.transform(new Date(dateString), 'dd/MM/yyyy');
    this.driverForm = this.fb.group({
      taskid:this.taskDetails.id,
      name: this.client,
      pickupLocation: this.Location,
      contact:[this.contact?this.contact:''],
      date: [formattedDate ? formattedDate: ''],
      status: [this.status ? this.status : ''],
      description:[this.description? this.description:''],
      fromDateHours:[this.fromDateHours?this.fromDateHours : '00'],
      fromDateMinutes:[this.fromDateMinutes ? this.fromDateMinutes : '00'],
      remarks:[this.taskDetails.attributes.remarks? this.taskDetails.attributes.remarks :0]
      // shift_time:[this.taskDetails.attributes.Shift_Timing? this.taskDetails.attributes.Shift_Timing : '0']
    });

    this.taskid=this.taskDetails.id;
    console.log(this.taskid)
const dateObject: Date = new Date(this.taskDetails.date);

 this.formattedDate =
  `${(dateObject.getUTCMonth() + 1).toString().padStart(2, '0')}/` +
  `${dateObject.getUTCDate().toString().padStart(2, '0')}/` +
  `${dateObject.getUTCFullYear()}`;

console.log(this.formattedDate);

this.filteredShifts = this.shiftControl.valueChanges.pipe(
  startWith(''),
  rxMap(value => this._filter(value))
);
this.shiftControl.valueChanges.subscribe(shift => {
  this.shifts=shift;
});
this.filteredOptionsDriver = this.myDriverControl.valueChanges.pipe( 
  startWith(''),
  rxMap(value => {
    const name = typeof value === 'string' ? value : value?.name;
    return name ? this._filterDriver(name as string) : this.driversList.slice();
  }),
);
  
  }
  ngAfterViewInit() {
    console.log('Input Field:', this.inputField.nativeElement);
    this.autocomplete = new google.maps.places.Autocomplete(this.inputField.nativeElement);
    this.autocomplete.addListener('place_changed', () => {
      const place: any = this.autocomplete.getPlace();
      if (place && place.geometry && place.geometry.location) {
        this.lat = place.geometry.location.lat();
        this.long = place.geometry.location.lng();
        this.Location = place.formatted_address;

        if (!this.map) {
          this.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: this.lat, lng: this.long },
            zoom: 15
          });
        }
        const myLatlng = new google.maps.LatLng(this.lat, this.long);

        this.map.setCenter(myLatlng);
      }
    });
}
getDriversList() {
  this.commonService.getRequest(`users?orgId=${this.orgId}`, []).then((resp: any) => {
    if (resp?.status) {
        this.driversList = resp.data.filter(x => x.attributes.role == 'driver');
    }
    console.log('driverList',this.driversList)
  });
}
shifts = [
  "00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45",
  "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45",
  "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45",
  "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45",
  "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45",
  "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45",
  "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45",
  "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45",
  "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45",
  "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45",
  "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45",
  "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"
];
filteredOptionDriver() {
 
  this.filteredOptionsDriver = this.myDriverControl.valueChanges.pipe(
    startWith(''),
    rxMap(value => this._filterDriver(value))
  );
}
driverSelected(driver) {

  //this.selectedClientId=dealer && dealer.email ? dealer.email : ''; 
  this.selectedDriverId=driver && driver.id ?driver.id : ''; 
}
onDriverAutocompleteClosed(){
  if (!this.myDriverControl.value) {
    this.driverSelected('');
  }
}	
displayFnDriver(driver: any): string{
    if (driver && driver.attributes && driver.attributes.cabno && driver.name) {
      return `${driver.attributes.cabno} ${driver.name}`;
  } else {
      return '';
  }
      return driver && (driver.attributes.cabno ? driver.attributes.cabno : '') +' '+(driver.name ? driver.name : ''); 
} 

private _filterDriver(value: string): any[] {
  const filterValue = value.toLowerCase();

  return this.driversList.filter(option => {
    const cabnoString = option.attributes?.cabno?.toString().toLowerCase();
    const name = option.name?.toLowerCase();

    // Check if the cab number or name includes the filter value
    return (cabnoString && cabnoString.includes(filterValue)) ||
           (name && name.includes(filterValue));
  });
}
private _filter(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.shifts.filter(shift => shift.toLowerCase().includes(filterValue));
}
  formatCustomDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

update(){
this.fromDateHours=this.driverForm.value.fromDateHours;
this.fromDateMinutes=this.driverForm.value.fromDateMinutes;
  const parsedDate = moment(this.driverForm.value.date, 'DD/MM/YYYY');
  const isoDate = new Date(
    parsedDate.year(),
    parsedDate.month(),
    parsedDate.date(),
    this.fromDateHours,
    this.fromDateMinutes
  );
  const today=new Date();
  const startDate = today.toISOString();
  this.date=this.driverForm.value.date;

  
if(this.taskDetails.type == 'pickup' && this.driverForm.value.status === 'started'){
  // if(this.lat && this.long ){
  //   this.taskDetails.startlongitudelocation=this.long;
  //   this.taskDetails.startlatitudelocation=this.lat;
  //   this.taskDetails.pickuplocation=this.driverForm.value.pickupLocation;
  // }

  if(this.position_lat && this.position_lon){
    this.taskDetails.attributes["trip_startlocation"]=`{${this.position_lat},${this.position_lon}}`
  }
  if(this.isStarted){
    this.taskDetails.actualstarttime=startDate;
    this.taskDetails.attributes.tripstartime=`${startDate}`;
  }
 
}
else if(this.taskDetails.type == 'pickup' && this.driverForm.value.status=== 'acknowledged'){
  this.taskDetails.attributes.actualaccepttime=`${startDate}`;
  if(this.lat && this.long ){
    this.taskDetails.attributes.acceptlatitudelocation=`${this.lat}`;
    this.taskDetails.attributes.acceptlongitudelocation=`${this.long}`;
    // this.taskDetails.startlongitudelocation=this.long;
    // this.taskDetails.startlatitudelocation=this.lat;
    // this.taskDetails.pickupLocation=this.driverForm.value.pickupLocation
  }
  if(this.position_lat && this.position_lon){
    this.taskDetails.attributes.acceptlatitudelocation=`${this.position_lat}`
    this.taskDetails.attributes.acceptlongitudelocation=`${this.position_lon}`
  }
}
else if(this.taskDetails.type == 'pickup' && this.driverForm.value.status === 'completed' && !(this.taskDetails.attributes.remarks === 'separate')){

// if(this.lat && this.long ){
//     this.taskDetails.endlongitudelocation=this.long;
//     this.taskDetails.endlatitudelocation=this.lat;
//     this.taskDetails.droplocation=this.driverForm.value.pickupLocation
//   }

  if(this.position_lat && this.position_lon){
    this.taskDetails.attributes["trip endlocation"]=`{${this.position_lat},${this.position_lon}}`
  }

  this.taskDetails.actualendtime=startDate;
  this.taskDetails.attributes.tripendtime=`${startDate}`;
}
else if(this.taskDetails.type === 'pickup' && this.driverForm.value.status=== 'completed' && this.taskDetails.attributes.remarks === 'separate'){
  if(this.lat && this.long ){
    // this.taskDetails.endlongitudelocation=this.long;
    // this.taskDetails.endlatitudelocation=this.lat;
    // this.taskDetails.droplocation=this.driverForm.value.pickupLocation
    this.taskDetails.startlongitudelocation=this.long;
    this.taskDetails.startlatitudelocation=this.lat;
    this.taskDetails.pickuplocation=this.driverForm.value.pickupLocation;
  }
  if(this.position_lat && this.position_lon){
    this.taskDetails.attributes["trip endlocation"]=`{${this.position_lat},${this.position_lon}}`
  }
  this.taskDetails.actualendtime=startDate;
  this.taskDetails.attributes.tripendtime=`${startDate}`;
}
else if(this.taskDetails.type === 'drop' && this.driverForm.value.status=== 'started' ){

  // if(this.lat && this.long ){
  // this.taskDetails.endlongitudelocation=this.long;
  // this.taskDetails.endtlatitudelocation=this.lat;
  // this.taskDetails.droplocation=this.driverForm.value.pickupLocation;
  // }
  if(this.position_lat && this.position_lon){
    this.taskDetails.attributes["trip_startlocation"]=`{${this.position_lat},${this.position_lon}}`
  }
  if(this.isStarted){
    this.taskDetails.actualstarttime=startDate;
    this.taskDetails.attributes.tripstartime=`${startDate}`
  }
}
else if(this.taskDetails.type == 'drop' && this.driverForm.value.status=== 'acknowledged'){

  this.taskDetails.attributes.actualaccepttime=`${startDate}`;
  // if(this.lat && this.long ){
  //   this.taskDetails.endlongitudelocation=this.long;
  //   this.taskDetails.endtlatitudelocation=this.lat;
  //   this.taskDetails.droplocation=this.driverForm.value.pickupLocation;
  // }
   if(this.position_lat && this.position_lon){
    this.taskDetails.attributes.acceptlatitudelocation=`${this.position_lat}`
    this.taskDetails.attributes.acceptlongitudelocation=`${this.position_lon}`
  }
}
else if(this.taskDetails.type == 'drop' && this.driverForm.value.status=== 'completed' && !(this.taskDetails.attributes.remarks === 'separate')){
  // if(this.lat && this.long ){
  //   this.taskDetails.endlongitudelocation=this.long;
  //   this.taskDetails.endlatitudelocation=this.lat;
  //   this.taskDetails.droplocation=this.driverForm.value.pickupLocation;
  // }
  if(this.position_lat && this.position_lon){
    this.taskDetails.attributes["trip endlocation"]=`{${this.position_lat},${this.position_lon}}`
  }
  this.taskDetails.actualendtime=startDate;
  this.taskDetails.attributes.tripendtime=`${startDate}`
}
else if(this.taskDetails.type == 'drop' && this.driverForm.value.status=== 'completed' && this.taskDetails.attributes.remarks === 'separate'){
  // if(this.lat && this.long ){
  //   this.taskDetails.endlongitudelocation=this.long;
  //   this.taskDetails.endlatitudelocation=this.lat;
  //   this.taskDetails.droplocation=this.driverForm.value.pickupLocation
  // }
  if(this.position_lat && this.position_lon){
    this.taskDetails.attributes["trip endlocation"]=`{${this.position_lat},${this.position_lon}}`
  }
  this.taskDetails.actualendtime=startDate;
  this.taskDetails.attributes.tripendtime=`${startDate}`
}
else if(this.taskDetails.type == 'drop' && this.driverForm.value.status=== 'assigned'){
  // if(this.lat && this.long ){
  //   this.taskDetails.endlongitudelocation=this.long;
  //   this.taskDetails.endlatitudelocation=this.lat;
  //   this.taskDetails.droplocation=this.driverForm.value.pickupLocation
  // }
  if(this.position_lat && this.position_lon){
    this.taskDetails.attributes.latitude=`${this.position_lat}`
    this.taskDetails.attributes.longitude=`${this.position_lon}`
  }
}
else if(this.taskDetails.type == 'pickup' && this.driverForm.value.status=== 'assigned'){
  // if(this.lat && this.long ){
  //   this.taskDetails.startlongitudelocation=this.long;
  //   this.taskDetails.startlatitudelocation=this.lat;
  //   this.taskDetails.pickuplocation=this.driverForm.value.pickupLocation
  // }
  if(this.position_lat && this.position_lon){
    this.taskDetails.attributes.latitude=`${this.position_lat}`
    this.taskDetails.attributes.longitude=`${this.position_lon}` 
  }
}
else if(this.driverForm.value.status=== 'dropped' && this.taskDetails.attributes.remarks === 'separate'){
  if(this.position_lat && this.position_lon){
  this.taskDetails.attributes["trip_droplocation"]=`{${this.position_lat},${this.position_lon}}`;
  }
  // if(this.lat && this.long ){
  //   this.taskDetails.endlongitudelocation=this.long;
  //   this.taskDetails.endlatitudelocation=this.lat;
  //   this.taskDetails.droplocation=this.driverForm.value.pickupLocation
  // }
  this.taskDetails.attributes.drop_time = `${startDate}`
}
else if(this.driverForm.value.status=== 'pickedup' && this.taskDetails.attributes.remarks === 'separate'){
  if(this.position_lat && this.position_lon){
  this.taskDetails.attributes["trippickup_location"]=`{${this.position_lat},${this.position_lon}}`;
  }

  this.taskDetails.attributes.pickup_time = `${startDate}`
}
if(this.driverForm.value.status=== 'unassigned'){
  this.taskDetails.attributes.driverName='';
  this.taskDetails.attributes.did='';
  this.taskDetails.attributes.cabno='';
  this.taskDetails.attributes.vehicleno='';

  // }
}
if(this.selectedTaskStatus === 'assigned'){
  if(this.selectedDriverId == ''){
    Swal.fire({
      icon: "error",
      title: `Please select driver`,
      showConfirmButton: false,
      timer: 1500,
      toast:true,
      position:'top-end'
    });  
    return
  }
  else if(this.driverForm.value.remarks == '' || this.driverForm.value.remarks == 0 ){
    Swal.fire({
      icon: "error",
      title: `Please select remark`,
      showConfirmButton: false,
      timer: 1500,
      toast:true,
      position:'top-end'
      
    });  
    return
  }
this.taskDetails.assignedto = this.selectedDriverId ? this.selectedDriverId : '';
this.taskDetails.attributes.remarks=this.driverForm.value.remarks;
}

if(this.lat && this.long && this.taskDetails.type === 'pickup' ){
  this.taskDetails.startlongitudelocation=this.long;
  this.taskDetails.startlatitudelocation=this.lat;
  this.taskDetails.pickuplocation=this.driverForm.value.pickupLocation;
}
else if(this.lat && this.long && this.taskDetails.type === 'drop'){
  this.taskDetails.endlongitudelocation=this.long;
  this.taskDetails.endlatitudelocation=this.lat;
  this.taskDetails.droplocation=this.driverForm.value.pickupLocation
}
// this.taskDetails.expectedstarttime=startDate;
this.taskDetails.status = this.driverForm.value.status;
// this.taskDetails.customerphone = this.driverForm.value.contact;
// this.taskDetails.description = this.driverForm.value.description;
if (this.taskDetails.attributes.planned_emp !== null) {
  this.taskDetails.attributes.planned_emp = this.taskDetails.attributes.planned_emp.toString();
}



if (this.taskDetails.attributes.actual_emp !== null) {
  this.taskDetails.attributes.actual_emp = this.taskDetails.attributes.actual_emp.toString();
}
debugger
if(this.selectedTaskStatus === 'completed' && this.taskDetails.actualstarttime==undefined){
    this.taskDetails.actualstarttime=startDate;
    this.taskDetails.attributes.tripstartime=`${startDate}`
  }
this.commonService.putRequestForTask(`traccartasks/${this.taskid}`, this.taskDetails)
  .then((resp: any) => {
    console.log('resp=>', resp);
    if (resp?.status) {
      this.commonService.commonModel.next(false);
      Swal.fire({
        icon: 'success',
        title: `Task ${this.driverForm.value.taskid} updated`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  })
  .catch((err) => {
    let errorMessage = 'An unexpected error occurred.';
    if (this.taskDetails.status === 'started') {
      this.taskDetails.status='acknowledged'
      // Custom error message based on specific conditions
      errorMessage = 'Driver already has one running task.';
    }
    else 
    if (typeof err.data === 'string') {
      // Direct string error message
      errorMessage = err.data;
    } else if (err.data && typeof err.data.message === 'string') {
      // Use error message from the response
      errorMessage = err.data.message;
    }

    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: errorMessage,
      showConfirmButton: false,
      timer: 3000,
    });
  })
  .finally(() => {
    this.lat = 0;
    this.long = 0;
    this.isAssignDriver = false;
    this.closeModal();
  });
  }
  onHourChange(selectedHour: string) {
    console.log('Selected hour:', selectedHour);
  }

  onMinuteChange(selectedMinute: string) {
    console.log('Selected minute:', selectedMinute);
  }  
  selectTaskStatus(event: any): void {
    this.selectedTaskStatus = event;
    
  }

  handleStatusChange(event:any) {
    this.selectedTaskStatus = event.target.value;
    if(this.selectedTaskStatus === 'assigned'){
      this.isAssignDriver = true;
    }
    else{
      this.isAssignDriver=false;
    }
    if(this.selectedTaskStatus === 'started'){
      this.isStarted=true;
    }
    else{
      this.isStarted=false;
    }
    console.log("Selected value:", this.selectedTaskStatus);

    if (navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position) => {
        this.position_lat = position.coords.latitude;
        this.position_lon = position.coords.longitude;
    })
  } 
    // You can perform any other actions you want with the selected value here
}
}
