import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';   

@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html',
  styleUrls: ['./assign-driver.component.css']
})
export class AssignDriverComponent implements OnInit {
  driverForm: FormGroup;
  selectedValue:string=""
  driversList: any[] = [];
  driversList1: any[] = [];
  @Input()
  tasks: any = {};
  assignedtoControl = new FormControl();
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  @Input() driverList:any[] = [];
  // filteredOptions: Observable<string[]>;
  // filteredOptions: Observable<{ display: string; id: string; }[]>;
  filteredOptions: Observable<any[]>;  
  recordCount$: Observable<number>;
  @Output()
  driverAssignedEvent:EventEmitter<any> = new EventEmitter<any>();
  userData: any;
  orgId: any;
  reassignDriver:boolean=false;
  assignDriver:boolean=false;
  filteredDrivers: any[] = [];
  driverID: any;
  selectedDriverId:any=''
  driverPosition: { lat: number, lng: number } = { lat: 0, lng: 0 };
  myDriverControl=new FormControl();
  filteredOptionsDriver: Observable<any[]>;
  driversList2: any;
  constructor(private commonService: CommonService,
    private fb: FormBuilder) { 
      let userDataObj = localStorage.getItem('userData');
      if (userDataObj) {
        try {
          this.userData = JSON.parse(userDataObj);
         
          console.log('Org Id =>',this.userData.id)
          if(this.userData.attributes.role === 'admin'){
         this.orgId=this.userData.id;
        }
        else{
          this.orgId = this.userData.orgId;
        }
        } catch (error) {}
      }
      // this.getDriversList();

   
    }

  ngOnInit(): void {
    this.driverForm = this.fb.group({
      assignedto: [0],
      assignedto1:[0],//custome vivek code
      status: ['assigned']
    });
    console.log('Driver List in AssignDriverComponent:', this.driverList);
    console.log('task details1=>',this.tasks);
    if(this.tasks.attributes.escort === "0"){
      console.log('escort=>',this.tasks.attributes.escort)
      this.selectedValue="false"
    }
    else{
      console.log('escort=>',this.tasks.attributes.escort)
      this.selectedValue="true"
    }
    if(this.tasks.assignedto == 0){
       this.assignDriver = true;
    }
    else{
      this.reassignDriver =true;
    }
    setTimeout(()=>{
      this.getDriversList();
    },100)
   
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterforDrivers(value))
    );  
    this.filteredOptionsDriver = this.myDriverControl.valueChanges.pipe( 
      startWith(''),
      map(value => {
    
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterDriver(name as string) : this.driversList.slice();
      }),
    );
 
   
  }
  get assignformControl() {
    return this.driverForm.controls;
  }

  private _filterDriver(value: string): any[] {
    const filterValue = value.toLowerCase();
  
    return this.driversList.filter(option => {
      const cabnoString = option.attributes?.cabno?.toString().toLowerCase();
      const name = option.name?.toLowerCase();
  
      // Check if the cab number or name includes the filter value
      return (cabnoString && cabnoString.includes(filterValue)) ||
             (name && name.includes(filterValue));
    });
  }
  filteredOptionDriver() {
 
    this.filteredOptionsDriver = this.myDriverControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterDriver(value))
    );
  }
  filteredOption1() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  onDriverSelectionChange() {
    console.log('Selected Driver ID:', this.selectedDriverId.id);
  }
  onDriverAutocompleteClosed(){
    if (!this.myDriverControl.value) {
      this.driverSelected('');
    }
  }	
  driverSelected(driver) {

    //this.selectedClientId=dealer && dealer.email ? dealer.email : ''; 
    this.selectedDriverId=driver && driver.id ?driver.id : ''; 
     }	

     displayFnDriver(driver: any): string{
      if (driver && driver.attributes && driver.attributes.cabno && driver.name) {
        return `${driver.attributes.cabno} ${driver.name}`;
    } else {
        return '';
    }
        return driver && (driver.attributes.cabno ? driver.attributes.cabno : '') +' '+(driver.name ? driver.name : ''); 
    }   
  private _filter(value: string): { display: string; id: string }[] {
    let filteredList: { display: string; id: string }[] = [];
    if (value && typeof value === 'string') {
      const filterValue = value.toLowerCase();
      filteredList = this.driversList
        .filter(driver => 
          driver.attributes?.cabno.toLowerCase().includes(filterValue) || 
          driver?.name.toLowerCase().includes(filterValue)
        )
        .map(driver => ({
          display: driver.attributes?.cabno +' '+ driver.name,
          id: driver.id
        }));
    } else {
      filteredList = this.driversList.map(driver => ({
        display: driver.attributes?.cabno +' '+ driver.name,
        id: driver.id   
      }));
    }
    console.log('filteredList', filteredList);
    return filteredList;
  }

  displayFn(option: { display: string, id: string }): string {
    return option ? option.display : '';
  }
//custom vivek code
getDriversList() {

  this.commonService.getRequest(`users?orgId=${this.orgId}`, []).then((resp: any) => {
    debugger
    if (resp?.status) {
        this.driversList = resp.data.filter(x => x.attributes.role == 'driver');
        if (this.driversList.length > 0) {
          this.driverForm.patchValue({
            assignedto: this.driversList[0].id,
            assignedto1:this.driversList[0].name,
          })
        }
    }
    console.log('driverList',this.driversList)
  });
}
  submitFormData() {
    if (navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position) => {
        // Update driverPosition with the obtained latitude and longitude coordinates
        this.driverPosition.lat = position.coords.latitude;
        this.driverPosition.lng = position.coords.longitude;
        console.log(this.driverPosition)
     
        this.tasks.status = this.driverForm.value.status;
        this.tasks.assignedto = this.selectedDriverId ? this.selectedDriverId : '';
        this.tasks.attributes.lattitude= this.driverPosition.lat,
        this.tasks.attributes.longitude=this.driverPosition.lng
  
   
        if(this.driverForm.value.status === 'started'){
          this.tasks.actualstarttime = new Date().toISOString();
          this.tasks.attributes["trip_startlocation"]=`{${this.driverPosition.lat},${this.driverPosition.lng}}`
    
        }
        else if(this.driverForm.value.status === 'completed'){
          this.tasks.actualendtime = new Date().toUTCString();
          this.tasks.attributes["trip endlocation"]=`{${this.driverPosition.lat},${this.driverPosition.lng}}`
          
        }
        else if(this.driverForm.value.status === 'acknowledged'){
          this.tasks.attributes.actualaccepttime = new Date().toISOString();
          this.tasks.attributes.acceptlatitudelocation=this.driverPosition.lat;
          this.tasks.attributes.acceptlongitudelocation=this.driverPosition.lng;
        }
        this.commonService
        .putRequest(`traccartasks/${this.tasks.id}`, this.tasks)
        .then((resp: any) => {
          if (resp?.status) {
            this.driverAssignedEvent.emit(resp);
            this.commonService.commonModel.next(false);
            Swal.fire({
              icon: "success",
              title: `Driver ${this.driverForm.value.status} successfull`,
              showConfirmButton: false,
              timer: 1500,
            });        
          }      
        }, err => {
          Swal.fire({
            icon: "error",
            title: "Something Went Wrong",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      });
    }
    else{
      return
    }
   
    
  
  }

  private _filterforDrivers(value: string): any[] {
    debugger
    const filterValue = value.toLowerCase().trim(); // Convert input value to lowercase and trim whitespace

  return this.driverList
    .filter(option => {
      const cabnoString = option?.name?.toString()?.toLowerCase(); // Convert cabno to lowercase
      if (!cabnoString) {
        return false; // Skip if cabnoString is null or undefined
      }

      // Check if cabnoString contains the filterValue (case insensitive)
      return cabnoString.includes(filterValue);
    })
    .map(option => ({ 
      id: option.id,
      display: option?.name?.toString() }));

  }
//   driverSelected(driver){
// console.log("selected driver = > ", driver)
// this.selectedDriverData=driver;
//     }
  //added by vivek
  onValueChange() {
    console.log('Selected value: ', this.selectedValue);
  }
  closeForm() {
    this.commonService.commonModel.next(false);
  }

}
