
<div [formGroup]="driverForm">
  <div class="p-0" >
  
    <div class="row">
      <div class="col-md-11">
        <h3>Edit Stop</h3>
      </div>
      <div class="col-md-1">
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
   
    </div>

    <div  style="width: 100%;border-top: 5px solid #dee2e6 !important;" class="mt-2 mb-3"></div>
    <div class="row mt-2 mb-2">
    <div class="col-md-6">
      <div class="form-group">
        <label for="name" class="pb-1"  *ngIf="isPickup">{{'Pickup'|translate}}</label> 
        <label for="name" class="pb-1" *ngIf="isDrop">{{'Drop'|translate}}</label>
        <input
          type="text"
          class="form-control inputfields"
          id="name"
          aria-describedby="name"
          formControlName="taskid"
          [(ngModel)]="this.taskid"
          readonly
     
        />
        <!-- <span class="text-danger validation"
        *ngIf="(taskformControl.plannedemp.touched || submitted)&& taskformControl.plannedemp.errors?.required">
        *{{'Planned employees required'|translate}} 
      </span> -->
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="escort1" class="pb-1">{{'Task Status '|translate}}</label>
        <select
          class="form-select me-2  taskspanel"
          id="escort1"
          style="height: 6.2vh;"
          formControlName="status"
          (change)="handleStatusChange($event)"   
        >
        <option value="unassigned">Unassigned</option>                      
        <option value="assigned">Assigned</option>
        <option value="acknowledged">Acknowledged</option>
        <option value="started">Started</option>
        <option *ngIf="this.taskDetails.attributes.remarks=== 'separate' && this.taskDetails.type === 'drop' " value="dropped">Dropped</option>
        <option *ngIf="this.taskDetails.attributes.remarks=== 'separate' && this.taskDetails.type === 'pickup' " value="pickedup">Picked Up</option>
        <option value="completed">Completed</option>      
        </select> 
      </div>  
  </div>
    </div>
    <div *ngIf="isAssignDriver" class="row mb-2">
      <div class="col-md-6 col-12">
        <div class="form-group">
        <label for="driver">Select Driver</label>
        <mat-form-field  class="example-full-width" appearance="fill">
          <input  type="text" placeholder="Select Driver"  matInput 
          [formControl]="myDriverControl"
          [matAutocomplete]="autoDriver" 
          (click)="filteredOptionDriver()"
          id="driver"
    
           />
           <mat-autocomplete [displayWith]="displayFnDriver" #autoDriver="matAutocomplete"
          (optionSelected)="driverSelected($event.option.value)"
          (closed)="onDriverAutocompleteClosed()"
          >
           <mat-option *ngFor="let option of filteredOptionsDriver | async" [value]="option">
       {{ (option.attributes.cabno ? option.attributes.cabno : '') + ' ' + (option.name ? option.name : '') }}
           </mat-option>
           </mat-autocomplete>
        </mat-form-field>
          </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group">
        <label for="remarks">Remarks</label>
        <select class="form-select taskspanel" formControlName="remarks" id="remarks">
            <option value="0" selected>Select</option>
            <option value="cabin">Cab In</option>                      
            <option value="cabout">Cab Out</option>
            <option value="separate">Separate</option>
            <option value="backtoback">Back to Back</option> 
            <option value="garage">Garage</option>                     
        </select>
      </div>
      </div>
    </div>
</div>
<div class="modal-body p-0">
<div class="row mb-2">
    <div class="col-6 col-md-6">
      <div class="form-group">
        <label for="name" class="pb-1" >{{'Company Name'|translate}}</label> 
 
        <input
          type="text"
          class="form-control inputfields"
          id="name"
          aria-describedby="name"
          formControlName="name"
          [(ngModel)]="this.client"
          readonly
     
        />
        <!-- <span class="text-danger validation"
        *ngIf="(taskformControl.plannedemp.touched || submitted)&& taskformControl.plannedemp.errors?.required">
        *{{'Planned employees required'|translate}} 
      </span> -->
      </div>
    </div>
    <!-- <div class="col-6">
      <div class="form-group pb-2">
        <label for="name" class="pb-2">{{'Contact Number'|translate}}</label> 

        <input
          type="text"
          class="form-control inputfields"
          id="name"
          aria-describedby="name"
          formControlName="contact"
        />
        <span class="text-danger validation"
        *ngIf="(taskformControl.plannedemp.touched || submitted)&& taskformControl.plannedemp.errors?.required">
        *{{'Planned employees required'|translate}} 
      </span>
      </div>
    </div> -->
    <div class="col-6 col-md-6">
      <div class="form-group">
        <label for="date" class="pb-1">{{ 'Date and Shift Time ' | translate }}</label>
    
        <div class="d-flex align-items-center">
            <input
                type="text"
                id="date"
                style="height: 5.5vh; border-radius: 5px; width: 121px;"
                placeholder=" dd/mm/yyyy"
                formControlName="date"   
                readonly
            />
            <!-- <select class="form-select" style="width: 12vh;height: 7vh;"
            formControlName="fromDateHours"
            (change)="onHourChange($event.target.value)"
            readonly
            > -->
            <input type="text"
            formControlName="fromDateHours"
            style="width: 12vh;height: 5.5vh;"
            readonly
            >
            <input type="text"
            formControlName="fromDateMinutes"
            style="width: 12vh;height: 5.5vh;"
            readonly
            >
            <!-- <select class="form-select" style="width: 12vh;height: 7vh;"
            formControlName="fromDateHours"
            [disabled]="true"
            >
                <option value="00">00</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
              </select> -->
              <!-- <select class="form-select" style="width: 12vh;height: 7vh;"
              formControlName="fromDateMinutes"
              (change)="onMinuteChange($event.target.value)"
              [disabled]="true"
              >
                <option value="00">00</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
                <option value="41">41</option>
                <option value="42">42</option>
                <option value="43">43</option>
                <option value="44">44</option>
                <option value="45">45</option>
                <option value="46">46</option>
                <option value="47">47</option>
                <option value="48">48</option>
                <option value="49">49</option>
                <option value="50">50</option>
                <option value="51">51</option>
                <option value="52">52</option>
                <option value="53">53</option>
                <option value="54">54</option>
                <option value="55">55</option>
                <option value="56">56</option>
                <option value="57">57</option>
                <option value="58">58</option>
                <option value="59">59</option>
              </select> -->
              <!-- <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <input type="text"   matInput
                  placeholder="Select Shift" aria-label="Number"
                  [formControl]="shiftControl" 
                  [matAutocomplete]="auto" 
                  style="width: 18vh;">
                 <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" style="width: 18vh;">
                <mat-option *ngFor="let shift of filteredShifts | async" [value]="shift" style="width: 18vh;">
                {{ shift }}
                </mat-option>
                </mat-autocomplete>
                </mat-form-field>
              </div> -->
        <!-- <span class="text-danger validation"
        *ngIf="(taskformControl.plannedemp.touched || submitted)&& taskformControl.plannedemp.errors?.required">
        *{{'Planned employees required'|translate}} 
      </span> -->
      </div>
        
  
  </div>
 
  </div>

  </div>
  <div class="row mb-4">
    <div class="col-12">
      <div class="form-group">
        <label for="name" class="pb-1"  *ngIf="isPickup">{{'Pickup Address'|translate}}</label> 
        <label for="name" class="pb-1" *ngIf="isDrop">{{'Drop Address'|translate}}</label>
        <input
        #inputField
          type="text"
          class="form-control inputfields"
          id="name"
          aria-describedby="name"
          formControlName="pickupLocation"
          [(ngModel)]="this.Location"
        />
          </div>   
      </div>
  </div> 

<div class="row mb-3" *ngIf="false">
    <div class="col-12">
        <mat-form-field
        appearance="outline"
        class="w-100"
        floatLabel="always"
       
      >
        <mat-label class="custom-label">Description</mat-label>
        <input
          formControlName="description"
          matInput
          
        />
      </mat-form-field>
    </div>
</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="this.closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="update()">Update Stop</button>
    
</div>
</div>
  
