<app-common-modal-popup [modelSize]="'modal-xs'">
  <div class="" modelheadertext>Task Id: {{ tasks?.id ?? 0 }}</div>
  <div class="container-fluid" modeldata>
    <div class="row mt-2" [formGroup]="driverForm">
      <div class="form-floating  mb-2">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Select Employee</mat-label>
          <input
            type="text"
            placeholder="Select one"
            aria-label="Number"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions | async"
              [value]="option.display"
              (onSelectionChange)="driverSelected(option)"
            >
              {{ option.display }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="col-12 mt-2 mb-2">
        <div class="form-floating">
          <select
            class="form-select"
            formControlName="status"
            aria-label="Floating label select example"
          >
            <option value="unassigned">Unassigned</option>
            <option value="assigned">Assigned</option>
            <option value="acknowledged">Acknowledged</option>
            <option value="started">Started</option>
            <option value="completed">Completed</option>
          </select>
          <label for="floatingSelect">Task Status</label>
        </div>
      </div>
      <!-- <div class="col-12 mt-2">
        <div class="form-floating">
          <select
            class="form-select"
            formControlName="remarks"
            aria-label="Floating label select example"
          >
            <option value="0" selected>Select</option>
            <option value="cabin">Cab In</option>
            <option value="cabout">Cab Out</option>
            <option value="separate">Separate</option>
            <option value="backtoback">Back to Back</option>
            <option value="garage">Garage</option>
            <option value="normal">Normal</option>
          </select>
          <span
            class="text-danger validation"
            *ngIf="assignformControl.remarks.value === '0'"
          >
            *{{ "Please select a valid remark" | translate }}
          </span>
          <label for="floatingSelect">Remarks</label>
        </div>
      </div> -->
   
      <div class="col-12 mt-4 mb-3">
        <button
          type="button"
          class="btn btn-primary me-3"
          (click)="submitFormData()"
        >
          Submit
        </button>
        <button type="button" class="btn btn-secondary" (click)="closeForm()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</app-common-modal-popup>
