<!-- <p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ Device Model</span></p> -->
<app-common-modal-popup>
  <div class="" modelheadertext *ngIf="gridMode">{{'Expense'|translate}}</div>
  <div class="" modelheadertext *ngIf="taskList">{{'Tasks History'|translate}}</div>
  <div class="" modelheadertext *ngIf="addMode">Add Expense</div> 
  <div class="container-fluid " modeldata *ngIf="gridMode">
    <div class="row p-0">
      <div class="card p-0 headcard">
  
        <!-- HEADER WITH ADD POI BTNS -->
        <div class="bg-light d-flex align-items-center p-2">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="row w-100">
          
              <div class="col-3">
                <form>
                    <div class="input-group">
                   
                      <mat-form-field appearance="outline" floatLabel="always" class="single-select">
                        <mat-label>Select Employee</mat-label>
                        <input type="text"
                               placeholder="Select one"
                               aria-label="Number"
                               class="single-autocomplete"
                               matInput
                               [formControl]="myDriverControl"
                               [matAutocomplete]="autoDriver">
                        <mat-autocomplete autoActiveFirstOption #autoDriver="matAutocomplete"
                        >
                          <mat-option *ngFor="let option of filteredOptionsDriver | async" [value]="option.display" (onSelectionChange)="driverSelected(option)">
                            {{option.display}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field> 
                    
                    
                  </div>
                </form>
              </div>
              <div class="col-4 d-flex align-items-center">
                  <span class="to p-2 ms-2">{{'From'|translate}}</span>
                  <input            
                      type="date"            
                      [(ngModel)]="fromdate"
                      style="height: 5.2vh;width:24vh;border-radius: 5px;border:none !important;"                       
                  />
                  <span class="to p-2">{{'To'|translate}}</span>
                  <input 
                      type="date"            
                      [(ngModel)]="todate"
                      style="height: 5.2vh;width:24vh;border-radius: 5px;border:none !important;"                       
                  />
              </div>
              <div class="col-3"></div>
              <div class="col-2 d-flex justify-content-end">
                <button matTooltip="Search" (click)="getAllGroupData()" class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                  <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                </button>
                  <button class="d-flex align-items-center p-2 bg-white" title="{{' Add Group'|translate}}" (click)="onAddModelMaster()">
                      <span class="material-icons" style="font-size: 2.1vh;">
                        add
                      </span>
                    </button>
              </div>
            </div>              
            
          </div>           
        </div>
        <!-- DATE DOWNLOAD HEADER -->
        <div class="card-header flex_row_center_spacebetween">
          <div class="flex_row_center_spacebetween">
            <select *ngIf="!chartsView" class="form-select selectimportant" [(ngModel)]="pageOptionItems" name="pageOption" (change)="getAllGroupData()">
              <option value="10" class="select_options">10</option>
              <option value="20" class="select_options">20</option>
              <option value="30" class="select_options">30</option>
              <option value="40" class="select_options">40</option>
              <option value="50" class="select_options">50</option>
              <option value="60" class="select_options">60</option>
              <option value="99999999" class="select_options">All</option>
            </select>
          </div>
          <div style="display: flex;flex-direction: row;align-items: center;cursor: pointer;" *ngIf="chartsView" (click)="toggleListChart()">
            <span class="btn" matTooltip="List"> <i class="bi bi-list"></i> </span>
        </div>
          <div style="display: flex;flex-direction: row;align-items: center;cursor: pointer;" *ngIf="!chartsView" (click)="toggleListChart()">
              <span class="btn" matTooltip="Charts"> <i class="bi bi-graph-up-arrow"></i>                </span>
            <app-export-data [selectedPage]="'Expense'" [dataList]="groupListData"></app-export-data>
          </div>
        </div>

        <div class="card-body common_scroller fixed_height p-0" *ngIf="!chartsView">
          <table class="table table-borderless m-0">
  
            <thead class="bg-light">
              <tr>
                <th class="table_head_text text-left">{{'Driver'|translate}}
                </th>
                <th class="table_head_text text-left" (click)="sorting('date')">{{'Date'|translate}}
                  <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'date'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'date'"></i>
                </th>
          
                <th class="table_head_text text-left">{{'Quantity'|translate}}
              
                <th class="table_head_text text-left">{{'Amount'|translate}}
                </th>
                <th class="table_head_text text-left">{{'Average'|translate}}
                </th>
          
                <th class="table_head_text text-left">{{'Description'|translate}}
                </th>
                
                <th class="table_head_text text-left">{{'Image'|translate}}
                </th> 
                <th class="table_head_text text-left">{{'Status'|translate}}
                </th>
                  
                  
                  
                  
                  
                
              </tr>
            </thead>
  
            <tbody *ngIf="groupListData.length">
              <tr *ngFor="let model of groupListData  | searchPipe : form.value.searchValue |  paginate : {
                  id: 'dexpp1',
                  itemsPerPage: pageOptionItems,
                  currentPage: page,
                  totalItems: (groupListData  | searchPipe : form.value.searchValue).length
                };let i=index">
                <td class="table_data text-left">{{model?.devicename}}</td> 
                <td class="table_data text-left">{{model.createdtime| date: 'dd/MM/yyyy hh:mm a'}}</td>   
           
                <td class="table_data text-left">{{model.quantity}}</td>  
           
                <td class="table_data text-left">{{model.amount}}</td> 
                <td class="table_data text-left" *ngIf="model?.average">{{model?.average|number:'1.0-0'}}</td>                                    
                <td class="table_data text-left" *ngIf="!model?.average">0</td>                                    
       
                <td class="table_data text-left">{{model.description}}</td>
                
           
                <td class="table_data text-left d-flex align-items-center" *ngIf="model?.billfilepath">
                  <a *ngFor="let x of model.billfilepath.split(','); let i = index;" href="https://{{environmentbaseurl}}:8082/api/file/downloadfile/{{x}}" class="ms-3" target="_blank">Image {{i+1}}</a>
                </td>
                <td class="table_data text-left d-flex align-items-center" *ngIf="!model?.billfilepath">
              
                </td>
                <td class="table_data text-left">
                  <span *ngIf="model ?.status === 'approved'"  class=" cursorPointer badge bg-success custom-badge " (click)="openTaskList(model)">{{model ?.status | titlecase}}</span>
                  <span *ngIf="model ?.status === 'pending' || model ?.status === 'Pending'"class=" cursorPointer badge bg-warning text-dark custom-badge" (click)="openTaskList(model)">{{model ?.status | titlecase}}</span>
                  <span *ngIf="model ?.status === 'rejected'"class=" cursorPointer badge bg-danger  custom-badge" (click)="openTaskList(model)">{{model ?.status | titlecase}}</span>
                  <span *ngIf="model ?.status === 'completed'"class=" cursorPointer badge bg-purple  custom-badge" (click)="openTaskList(model)">{{model ?.status | titlecase}}</span>
                
                </td> 
                <td class="table_data text-center" style="cursor: pointer;" *ngIf="false">
                  <div
                  class="btn btn-primary btn-sm mx-1"
                  (click)="onEditModelMaster(model)"                  
                >
                  <i class="fa fa-edit"></i>
                </div>
                <div 
                  class="btn btn-danger btn-sm mx-1"
                  (click)="onDeleteModelMasterExpense(model.id)"
                >
                  <i class="fa fa-trash"></i>
                </div>
                <div
                class="btn btn-primary btn-sm mx-1"
                (click)="assignDevice(model, staticBackdrop6)"
                matTooltip="Assign Device" 
              >
                <i class="fa fa-car"></i>
              </div>
              </td>
              </tr>
            </tbody>
  
            <tbody *ngIf="!groupListData.length">
              <tr>
                <td colspan="9">
                  <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                    <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                  </div>
                </td>
              </tr>
            </tbody>
  
          </table>
        </div>
        <div class="card-body common_scroller fixed_height p-0 mt-5" *ngIf="chartsView">
          <div class="row">
            <div class="col-6">
              <div id="Linechart"></div>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <div id="Piechart"></div>
            </div>
          </div>
          
        </div>
        <!-- PAGINATION -->
        <div *ngIf="groupListData.length && !chartsView" class="flex_row_center_spacebetween"
          style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
          <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(groupListData  | searchPipe : form.value.searchValue).length/10 | number: '1.0-0'}} {{'of'|translate}}
            {{(groupListData  | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
          <pagination-controls (pageChange)="page = $event" id="dexpp1"></pagination-controls>
        </div>
  
      </div>
    </div>
  </div>
  <div class="container-fluid " modeldata *ngIf="addMode">
    <form [formGroup]="addGroupForm" (ngSubmit)="onModelMasterSubmit()">

      <div class="row">
        <div class="col-4">
          <form action="">
            <div class="pb-2">
           <label for="driverSearch" class="addlabels pb-1">{{'Employees'|translate}}</label>
            
           <mat-form-field appearance="outline" floatLabel="always" class="single-select">
            <mat-label>Select Employee</mat-label>
            <input type="text"
                   placeholder="Select one"
                   aria-label="Number"
                   class="single-autocomplete"
                   matInput
                   [formControl]="myDriverControl"
                   [matAutocomplete]="autoDriver1">
            <mat-autocomplete autoActiveFirstOption #autoDriver1="matAutocomplete"
            >
              <mat-option *ngFor="let option of filteredOptionsDriver | async" [value]="option.display" (onSelectionChange)="driverSelected(option)">
                {{option.display}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> 
              
              </div>
          </form>
        
        </div>
        <div class="col-4">
          <div class="form-group pb-2">
            <label for="manufacturer" class="addlabels pb-2">Type</label>
            <select class="form-select selectimportantlabel w-100" formControlName="type" (change)="addGridDataFilter()">
              <option value="food" class="select_options">Food</option>
              <option value="fuel" class="select_options">Fuel</option>
              <option value="service" class="select_options">Service</option>
              <option value="tools" class="select_options">Tools</option>
              <option value="parts" class="select_options">Parts</option>
              <option value="hotel" class="select_options">Hotel</option>
              <option value="salary" class="select_options">Salary</option>
              <option value="tyre" class="select_options">Tyre</option>
              <option value="toll" class="select_options">Toll</option>
              <option value="other" class="select_options">Other</option>
            </select>              
            <span class="text-danger validation"
              *ngIf="(AddModelMasterControl.type.touched || submitted) && AddModelMasterControl.type.errors?.required">
              *Type is required.
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group pb-2">
            <label for="description" class="addlabels pb-2">{{'Description'|translate}}</label>
            <input type="text" formControlName="description" class="form-control inputfields"
              style="background: #FFF;box-shadow:none;color:#000;">              
          </div>
        </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group pb-2">
                <label for="date" class="addlabels pb-2">{{'Date'|translate}}</label>
                <input type="date" formControlName="date" class="form-control inputfields w-100"
                [max]="maxDateValidation"
                  style="background: #FFF;box-shadow:none;color:#000;border:1px solid #ced4da !important;height:6.2vh;">              
              </div>
          </div>
          <div class="col-4">
            <div class="form-group pb-2">
                <label for="amount" class="addlabels pb-2">{{'Amount'|translate}}</label>
                <input type="text" formControlName="amount" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;">              
              </div>
          </div>
          <div class="col-4">
            <div class="form-group pb-2">
                <label for="quantity" class="addlabels pb-2">{{'Quantity'|translate}}</label>
                <input type="text" formControlName="quantity" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" (blur)="calculateAverage()">              
              </div>
          </div>
        </div>
      
        <!-- <div class="col-4">
          <div class="form-group pb-2">
              <label for="expense" class="addlabels pb-2">{{'Expense'|translate}}</label>
              <input type="text" formControlName="expense" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;">              
            </div>
        </div> -->
        <div class="row">

          <div class="col-4">
            <div class="form-group pb-2">
                <label for="files" class="addlabels pb-2">{{'File path'|translate}}</label>
                <input type="file" formControlName="files" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" (change)="onFileUpload($event)"  >              
              </div>
          </div>
          <div class="col-4">
            <div class="form-group pb-2">
                <label for="modeofpayment" class="addlabels pb-2">{{'Mode of payment'|translate}}</label>
                <!-- <input type="text" formControlName="modeofpayment" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;">               -->
                  <select class="form-select selectimportantlabel w-100" formControlName="modeofpayment">
                    <option value="cash" class="select_options">Cash</option>
                    <option value="online" class="select_options">Online</option>
                    <option value="netbanking" class="select_options">Netbanking</option>
                    <option value="chaque" class="select_options">Chaque</option>
                    <option value="dd" class="select_options">DD</option>
                    <option value="credit" class="select_options">Credit</option>
                    <option value="other" class="select_options">Other</option>
                  </select> 
              </div>
          </div>
        </div>
      <div class="row" *ngIf="false">
        <div class="col-12">
        
          <div class="card-body common_scroller p-0">
            <table class="table table-borderless m-0">
    
              <thead class="bg-light">
                <tr>
                  <th class="table_head_text text-left">{{'Vehicle'|translate}}
                  </th>
                  <th class="table_head_text text-left">{{'Odometer (GPS)'|translate}}
                  </th>  
                  <th class="table_head_text text-left">{{'Date'|translate}}
                  </th>
                  <th class="table_head_text text-left">{{'Distance'|translate}}
                  </th> 
                  <th class="table_head_text text-left">{{'Amount'|translate}}
                  </th>
                  <th class="table_head_text text-left">{{'Qty'|translate}}
                  </th>    
                  <th class="table_head_text text-left">{{'Average'|translate}}
                  </th>      
                     
                </tr>
              </thead>      
              <tbody>
                <ng-container *ngFor="let model of deviceDataAddTime;index as i">                    
                  <tr *ngIf="i == 0">
                    <td class="table_data text-left">{{model?.devicename}}</td>  
                    <td class="table_data text-left">{{model?.odometer}}</td>                   
                    <td class="table_data text-left">{{model.date|date:'dd-MM-YYYY hh:mm:ss'}}</td> 
                    <td class="table_data text-left">{{model?.kmtravelled ? (model.kmtravelled|meterTokm) : '0'}}</td>                
                    <td class="table_data text-left">{{model.amount}}</td>                 
                    <td class="table_data text-left">{{model.quantity}}</td>
                    <td class="table_data text-left">{{averageCalc|number:'1.0-2'}}</td>  
                
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center pt-4 pb-2">
        <button mat-raised-button  type="submit"  class="cancelbtn">SUBMIT</button>
        <button mat-raised-button type="button" class="cancelbtn bg-secondary ms-3"
          (click)="onAddCloseModal()">{{'CANCEL'|translate}}</button>
        
       </div>

    </form>
  </div>

  <div class="container-fluid " modeldata *ngIf="taskList">  
    <div class="row p-0">
      <div class="card p-0 mt-1">
        <div class="card-body">
          <div class="row">
            <div class="col-md-2 taskListItem ">
              <div class="form-group pb-2">
                <label for="description" class="addlabels6 pb-2">{{'Driver Name'|translate}}</label>
                <input type="text" [(ngModel)]="this.model.devicename" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;"
                  readonly
                  >              
              </div>

            </div>
            <!-- <div class="col-md-2 taskListItem">
              <div class="form-group pb-2">
                <label for="description" class="addlabels6 pb-2">{{'Odometer'|translate}}</label>
                <input type="text" [(ngModel)]="this.model.odometer" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;"
                  readonly>              
              </div>
            </div> -->
            <div class="col-md-2 taskListItem">
              <div class="form-group pb-2">
                <label for="description" class="addlabels6 pb-2">{{'Quantity'|translate}}</label>
                <input type="text" [(ngModel)]="this.model.quantity" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;"
                  >  
                     
              </div>
            </div>
            <div class="col-md-2 taskListItem">
              <div class="form-group pb-2">
                <label for="description" class="addlabels6 pb-2">{{'Amount'|translate}}</label>
                <input type="text" [(ngModel)]="this.model.amount" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;"
                  readonly
                  > 
                        
              </div>
            </div>
            <div class="col-md-2 taskListItem">   
              <div class="form-group pb-2">
                <label for="description" class="addlabels6 pb-2">{{ 'Image' | translate }}</label>
                <div class="input-container">
                    <input type="text" class="form-control inputfields" readonly
                           style="background: #FFF; box-shadow: none; color: #000;">
                    <a href="https://{{ environmentbaseurl }}:8082/api/file/downloadfile/{{model.billfilepath}}" target="_blank"
                       class="link-inside-input">
                       Image</a>
                </div>
            </div>
            
              <!-- <div class="form-group pb-2">
                <label for="description" class="addlabels pb-2">{{ 'Image' | translate }}</label>
                <div class="input-with-link">
                    <input type="text" class="form-control inputfields" readonly
                           style="background: #FFF; box-shadow: none; color: #000;">
                    <a href="https://{{ environmentbaseurl }}:8082/api/file/downloadfile/{{model.billfilepath}}" target="_blank">
                        Image
                    </a>
                </div>
            </div> -->
            
            </div>
          </div>
       
        </div>
      </div>
    </div>
   
    <div class="row p-0">
      <div class="card p-0 headcard">
          <!-- DATE DOWNLOAD HEADER -->
          <div class="card-header flex_row_center_spacebetween">
              <div class="flex_row_center_spacebetween">
                  <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
                      <option value="10" class="select_options">10</option>
                      <option value="20" class="select_options">20</option>
                      <option value="30" class="select_options">30</option>
                      <option value="40" class="select_options">40</option>
                      <option value="50" class="select_options">50</option>
                      <option value="60" class="select_options">60</option>
                  </select>
              </div>
              <div style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          width: 27vh;
      ">
                  <span class="btn" style="cursor:pointer" (click)="download()"><img src="/assets/icons/report_download_gray.png" alt="download"
                          style="width: 96%" /></span>
              </div>
          </div>
       
          <div class="card-body common_scroller fixed_height p-0" style="overflow-y: auto; max-height: 50vh;">
              <table id="report-table" class="table table-borderless m-0">
                  <thead class="bg-light">
                      <tr>
                          <th class="table_head_text">{{'Task ID'|translate}}</th>
                          <th class="table_head_text">{{'Client Name'|translate}}</th>
                          <th class="table_head_text">{{'Task Type'|translate}}</th>
                          <th class="table_head_text">{{'Address'|translate}}</th>
                          <th class="table_head_text">{{'Status'|translate}}</th>
                          <th class="table_head_text">{{'Driver Name'|translate}}</th>
                          <th class="table_head_text">{{'Created On'|translate}}</th>
                          <th class="table_head_text">{{'Start Time'|translate}}</th>
                          <th class="table_head_text">{{'End Time'|translate}}</th>
                          <th class="table_head_text">{{'Distance'|translate}}</th>
                          <th class="table_head_text">{{'Duration'|translate}}</th>
                          <th class="table_head_text">{{'Shift Timing'|translate}}</th>
                          <th class="table_head_text">{{'Remarks'|translate}}</th>
                      </tr>
                  </thead>

                  <tbody *ngIf="taskListData.length > 0">
                      <tr *ngFor="let customer of taskListData  | searchPipe : form.value.searchValue |  paginate : {
                           itemsPerPage: pageOption,
                            currentPage: page,
                           totalItems: (taskListData  | searchPipe : form.value.searchValue).length
                            };let i=index">
                          <td class="table_data">{{ customer.id ? customer.id : '' }}</td>
                          <td class="table_data">{{customer.clientid  | titlecase}}</td>
                          <td class="table_data">{{customer.type  | titlecase}}</td>
                          <td class="table_data">{{ customer.pickuplocation }}</td>
                          <td class="table_data">{{ customer.status.charAt(0).toUpperCase() + customer.status.slice(1) }}</td>
                          <td class="table_data">{{ customer.attributes.driverName  | titlecase }}</td>
                          <td class="table_data">{{ customer.date | date: 'dd/MM/yyyy hh:mm a' }}</td>
                          <td class="table_data">{{ customer.actualstarttime?(customer.actualstarttime | date: 'dd/MM/yyyy hh:mm a'):'N/A' }}</td>
                          <td class="table_data">{{ customer.actualendtime ? (customer.actualendtime| date: 'dd/MM/yyyy hh:mm a'):'N/A' }}</td>
                          <td class="table_data">{{ customer.gpsDistance ? (customer.gpsDistance | meterTokm) :'0'}}</td>
                          <td class="table_data">{{customer.duration ? customer.duration:'N/A'}}</td>
                          <td class="table_data">{{ customer.attributes.Shift_Timing }}</td>
                          <td class="table_data">{{ customer.attributes.remarks ? (customer.attributes.remarks  | titlecase) : 'N/A'}}</td>
                          
                      </tr>
                      <tr>
                        <td colspan="6"></td> 
                        <td class="table_data fw-bolder">Total Trips</td>
                        <td class="table_data fw-bolder">{{tripsCount}}</td>
                        <td class="table_data fw-bolder">Total Distance</td>
                        <td class="table_data fw-bolder ">{{ (calculateTotalDistance() | meterTokm ) }} kms</td>
                        <td colspan="4"></td> 
                    </tr>
                  </tbody>

                  <tbody *ngIf="!taskListData.length">
                      <tr>
                          <td colspan="7">
                              <div class="d-flex justify-content-center align-items-center" style="height: 48vh">
                                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE" />
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div *ngIf="taskListData.length" class="flex_row_center_spacebetween"
              style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
              <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(taskListData | searchPipe : form.value.searchValue).length/pageOption |
                  number: '1.0-0'}} {{'of'|translate}}
                  {{(taskListData | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
              <pagination-controls (pageChange)="page = $event"></pagination-controls>
          </div>
          <div class="d-flex align-items-center pt-2" *ngIf="isUpdateDone">
            <button mat-raised-button  type="submit" class="cancelbtn" (click)="updateStatus('approved')">APPROVE</button>
            <button mat-raised-button type="button" class="cancelbtn bg-secondary ms-3"
              (click)="onCloseModal()">{{'REJECT'|translate}}</button>
           </div>
      </div>
  </div>
   
  </div>
</app-common-modal-popup>