
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators,FormsModule } from '@angular/forms';
import * as moments from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersettingService } from 'src/app/services/usersetting.service';
import { ValidationService } from 'src/app/services/validation.service';
import { CommonService } from '../../services/common.service';
import * as moment from 'moment-timezone';
import { ExternalLibraryService } from 'src/app/services/payment.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
declare var $: any;
declare let Razorpay: any;
import * as FileSaver from 'file-saver';
import { DatePipe, TitleCasePipe } from '@angular/common';
import jsPDF from 'jspdf';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
import { DurationPipe } from 'src/app/pipe/duration.pipe';



@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css'],
  providers: [DatePipe, MeterTokm,DurationPipe,TitleCasePipe]
})
export class TaskListComponent implements OnInit {
  @ViewChild('pdfTable') pdfTable: ElementRef;
  myControl = new FormControl();
  filteredOptionsClient: Observable<{ display: string; id: string; }[]>;
  form:FormGroup;
  modelForm:FormGroup;
  dealerList1 =[];
  pageOption = 10;
  pageOptionModal = 10;
  page: number = 1;
  itemsPerPage = 18; // Number of items per page
  currentPage = 0; // Current page number
  offset = 0;
  limit:number=10;
  submitted = false;
  myDealerControl=new FormControl();
  myDriverControl=new FormControl();
  userId = localStorage.getItem('userId');
  userData = {};
  userdata:any;
  filteredOptions: Observable<any[]>;
  filteredOptionsDriver: Observable<any[]>;
  licensesList = [];
  addLicensesForm: any;
  editCustomerForm: FormGroup;
  timezoneArray = [];
  showModal = false;
  deviceList: any = [];
  errors: any = {};
  response;
  address:any=''
  superAdmin:boolean = false;
  razorpayResponse;
  modalRef?: BsModalRef;
  selectedClientId='';
  selectedDriverId='';
  selectedSupervisorId='';
  inputFromDate: any = '';
  inputToDate: any= '';
  tableForPdfColumns:any[] = [];
  tableForPdfContent: any[][] = [];
  displayedFrom: number = 0;
  displayedTo: number = 0;
  RAZORPAY_OPTIONS = {
    "key": "rzp_test_BClgEY2hkt8ayQ",
    "amount": "100",
    "name": "",
    "currency":'INR',
    "order_id": "",
    "description": "purchase license",
    "image": `https://${environment.baseUrl}/assets/images/main-logo.png`,
    "prefill": {
      "name": "",
      "email": "test@test.com",
      "contact": "",
      "method": ""
    },
    "modal": {},
    "theme": {
      "color": "#0096C5"
    }
  };
  fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
fileExtension = '.xlsx';
  @Input()
  addMode = false;
  @Input()
  gridMode = false;
  taskListData: any[] = [];
  reportsName= 'Trips';
  clientName:string ='';
  driverName:string ='';
  supervisorName:string='';
  xlsxFileData: any[];
  orgId: any;
  customerList: any;
  clientList: any;
  // driverList: any;
  empList:any;
  managerList:any;
  tmanagerList:any;
  supervisorList: any;
  fromdateP:any='';
  todateP:any='';
  formattedFromDate:any ;
  formattedToDate:any;
   dayType = 'Today';
   status:string='';
  // isClient: boolean = false;
  clientName1: any='';
  htmlString: string='';
  clientList2: any;
  selectedDriverData: any;
  driverList1: any=[];
  isSupervisor: boolean = false;
  tripType: any = '';
  supervisorId: any;
  @Input() driverList:any[]=[]
  @Input() dealerList:any[]=[]
  totalRecords: number=0;
  taskListDataExcel: any[]=[];
  cabNo: any;
  constructor(
    private modalService: BsModalService,
    private user: UsersettingService,
    private fb: FormBuilder,
    private customValidator: ValidationService,
    private commonService: CommonService,
    private razorpayService: ExternalLibraryService, private cd:  ChangeDetectorRef,
    private datePipe: DatePipe,
    private metertokm: MeterTokm,
    private duration:DurationPipe,
    private titlecase:TitleCasePipe
    
  ) {
    this.commonService.pageLoadInModal.subscribe((result: string) => { 
      if (result == "tasklist") {
        this.gridMode = true;
        this.addMode = false;
      }
    });
    let userData = localStorage.getItem('userData');
    this.userData = userData ? JSON.parse(userData) : {};
    // this.myDealerControl = new FormControl();
    // this.myDriverControl=new FormControl()
   
   this.initPaymentForm();
  }
  
  ngOnInit(): void {
    console.log('driver list by parent=>',this.driverList)
    let today = moment();
    this.fromdateP = today.format("DD/MM/YYYY");
    this.todateP = today.format("DD/MM/YYYY");
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userdata = JSON.parse(userDataObj);
       
        console.log('USer data =>',this.userdata)
        if(this.userdata.attributes.role === 'admin'){
       this.orgId=this.userdata.id;
      }
      else if(this.userdata.attributes.role === 'sv'){
        this.isSupervisor=true;
        this.orgId=this.userdata.orgId;
        this.supervisorId=this.userdata.id
      
      }
      else{
        this.orgId = this.userdata.orgId;
      }

      // if(this.userdata.attributes.role == "clients"){
         
      // }
      } catch (error) {}
    }
    if(!this.isSupervisor){
      this.getClientListfordropdown();
    }
    else{
      this.getMappedUsers(this.supervisorId);
    }
    
    this.getDriverListfordropdown();

  
   
    this.filteredOptions = this.myDealerControl.valueChanges.pipe(
      startWith(''),
      map(value => {
      
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.dealerList.slice();
      }),
    );
    this.filteredOptionsDriver = this.myDriverControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterforDrivers(value))
    );
    this.superAdmin = this.userData && this.userData['administrator'];
    //if(this.superAdmin){
      this.getAllCustomers();
    //}
    this.form = new FormGroup({
      searchValue: new FormControl(''),
    });
    
    this.modelForm = new FormGroup({
      searchValue: new FormControl(''),
    });

    if(this.isSupervisor){
      this.filteredData();  
    
    }
    else{
      this.filteredData();
    }
   
  }
  getAllCustomers() {
    this.commonService.getRequest(`users?orgId=${this.orgId}`, []).then((resp: any) => {
      if (resp?.status) {
        this.customerList = resp.data;
          this.clientList = this.customerList.filter(customer=> customer.attributes?.role == 'clients');
          this.supervisorList = this.customerList.filter(customer=> customer.attributes?.role == 'sv');
          this.driverList = this.customerList.filter(customer=> customer.attributes?.role == 'driver');
      }
    });
  }

  onClientSelect() {
    if(this.clientName !== "0"){
      const selectedClient = this.clientList.find(client => client.name === this.clientName);
      this.selectedClientId = selectedClient ? selectedClient.email : '';
       console.log('Client id',this.selectedClientId);
    }
    else{
      this.selectedClientId= '';
    }
  }
  onDriverSelect() {
  if(this.driverName !== "0"){
    const selecteddriver = this.driverList.find(driver => driver.name === this.driverName);
    this.selectedDriverId = selecteddriver ? selecteddriver.id : 0;
     console.log('driverid',this.selectedDriverId);

  }
  else{
    this.selectedDriverId ="";
  }

  }
  onSupervisorSelect() {
  
    // Find the selected client from clientList and extract the ID
    const selectedSupervisor = this.supervisorList.find(client => client.name === this.supervisorName);
    this.selectedSupervisorId = selectedSupervisor ? selectedSupervisor.id : 0;
     console.log('Client id',this.selectedSupervisorId);
    // You can do additional logic here based on the selected client if needed
  }
  onPageChange(newPage: number): void {
    this.page = newPage;
    console.log('Page changed to:', newPage);
    this.offset= this.page * this.limit
    this.filteredData()
    // Additional logic when page changes can be added here
  }
  filteredData(){
if(this.isValidDateFormat(this.fromdateP)){
console.log('valid date',this.isValidDateFormat(this.fromdateP));
const [fromDay, fromMonth, fromYear] = this.fromdateP.split('/');
this.inputFromDate = new Date(Date.UTC(fromYear, fromMonth - 1, fromDay));
}
else{
      this.inputFromDate = new Date(this.fromdateP);
      this.inputFromDate.setMinutes(this.inputFromDate.getMinutes() - this.inputFromDate.getTimezoneOffset());
     
}
    if(this.isValidDateFormat(this.todateP)){
  const [toDay, toMonth, toYear] = this.todateP.split('/');
  this.inputToDate = new Date(Date.UTC(toYear, toMonth - 1, toDay));

    }
    else{
      this.inputToDate = new Date(this.todateP);
   this.inputToDate.setMinutes(this.inputToDate.getMinutes() - this.inputToDate.getTimezoneOffset());
    }
    this.formattedFromDate =moment(this.inputFromDate).startOf('day').toISOString();
    this.formattedToDate =moment(this.inputToDate).endOf('day').toISOString();
    
    const assignedTo:any = this.selectedDriverId ?? '';
    const supervisorId:any = '';
    const clientId:any = this.selectedClientId ?? '';

    if(!this.isSupervisor){
      this.getTasksStatusCount(this.inputFromDate,this.inputToDate,clientId,this.cabNo)
      this.commonService.getTasksforFilter(`traccartasks/orgId/${this.orgId}`,assignedTo, supervisorId, this.formattedFromDate, this.formattedToDate, clientId,this.status,this.tripType,this.offset,this.limit)
      .subscribe((response) => {
        this.taskListData = response;
        this.calculateDisplayedRange();
      }, (error) => {
        console.error(error);
      });
    }
    else{
      this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,this.formattedFromDate,this.formattedToDate,assignedTo,clientId,this.status,'','','')
     .subscribe((response) => {
     this.taskListData = response;
 },
  (error) => {
   console.error(error);
 });
    }
  
  }
  isValidDateFormat(dateStr) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    return dateRegex.test(dateStr);
}
addLimit(){
  this.filteredData();
}

fetchTasks(): Promise<any[]>  {
  return new Promise((resolve, reject) => {
    this.commonService.getTasksforFilter(`traccartasks/orgId/${this.orgId}`,this.selectedDriverId, '', this.formattedFromDate, this.formattedToDate, this.selectedClientId,this.status,this.tripType,'','')
    .subscribe(response => {
        this.taskListDataExcel = response;
        resolve(response);
      }, error => {
        console.error('Error fetching tasks:', error);
        reject(error);
      });
  });

}    
getAllTasksForSupervisor():Promise<any[]> {
return new Promise((resolve, reject) => {
this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,this.formattedFromDate,this.formattedToDate,this.selectedDriverId, this.selectedClientId,this.status,this.offset,this.limit,'')
.subscribe(response => {
  this.taskListDataExcel = response;
  resolve(response);
}, error => {
  console.error('Error fetching tasks:', error);
  reject(error);
});
});

}  
 getTasksStatusCountForSupervisor(fromdate,todate,clientid='',cabno=''){
  this.totalRecords=0
  let fromDate1=moment(fromdate).startOf('day').toISOString();
  let toDate1=moment(todate).endOf('day').toISOString();
    // dashboard/tasks/today/status/supervisor?fromDate=2024-08-28T00:00:00Z&toDate=2024-08-28T23:59:59Z&supervisorId=3498
    this.commonService.getRequest(`dashboard/tasks/today/status/supervisor?fromDate=${fromDate1}&toDate=${toDate1}&supervisorId=${this.supervisorId}&clientid=${clientid}&cabNo=${cabno}`, []).
    then((resp: any) => {
      resp.data.forEach(entry => {
        switch (entry.status.toLowerCase()) {
          case 'acknowledged':
            this.totalRecords += entry.recordCount;
            break;
          case 'assigned':
            this.totalRecords = entry.recordCount;
            break;
          case 'inprogress':
            this.totalRecords += entry.recordCount;
            break;
          case 'unassigned':
            this.totalRecords += entry.recordCount;
              break;
          case 'rejected':
            this.totalRecords += entry.recordCount;
                break;
          case 'failed':
            this.totalRecords += entry.recordCount;
                  break; 
          case 'completed':
            this.totalRecords += entry.recordCount;   
             break;
          case 'started':
            this.totalRecords += entry.recordCount;
            break;
          case 'dropped':
            this.totalRecords += entry.recordCount;
            break;
          case 'pickedup':
            this.totalRecords += entry.recordCount;
              break;
        }
      });
      this.calculateDisplayedRange();
  })
  
  
  }
  getTasksStatusCount(fromdate,todate,clientid='',cabNo=''){
    this.totalRecords=0
      let fromDate1=moment(fromdate).startOf('day').toISOString();
      let toDate1=moment(todate).endOf('day').toISOString();
        this.commonService.getRequest(`dashboard/tasks/today/status?fromDate=${fromDate1}&toDate=${toDate1}&clientid=${clientid}&cabNo=${cabNo}`, []).
        then((resp: any) => {
    
          resp.data.forEach(entry => {
            switch (entry.status.toLowerCase()) {
              case 'acknowledged':
                this.totalRecords += entry.recordCount;
                break;
              case 'assigned':
                this.totalRecords = entry.recordCount;
                break;
              case 'inprogress':
                this.totalRecords += entry.recordCount;
                break;
              case 'unassigned':
                this.totalRecords += entry.recordCount;
                  break;
              case 'rejected':
                this.totalRecords += entry.recordCount;
                    break;
              case 'failed':
                this.totalRecords += entry.recordCount;
                      break; 
              case 'completed':
                this.totalRecords += entry.recordCount;   
                 break;
              case 'started':
                this.totalRecords += entry.recordCount;
                break;
              case 'dropped':
                this.totalRecords += entry.recordCount;
                break;
              case 'pickedup':
                this.totalRecords += entry.recordCount;
                  break;
            }
         
          });
          console.log("totalRecords=>",this.totalRecords);
          this.calculateDisplayedRange();
          // this.fetchTasks()
      })
      
      
      }
calculateDisplayedRange() {
    this.displayedFrom = (this.page - 1) * this.limit + 1;
    this.displayedTo = Math.min(this.page * this.limit, this.totalRecords);
  }
  dealerChange() {
    console.log(this.addLicensesForm.userId);
  }
  initPaymentForm(){   
  let userDataAttr = this.userData['attributes'] && this.userData['attributes'].unitrate ? this.userData['attributes'] :{
    "unitrate": "100",
    "currency": "INR"
  }
   this.addLicensesForm = {
    amount: parseInt(userDataAttr.unitrate),
    currency: userDataAttr.currency,
    devicecount: 0,
    total:0,
    tax:18,
    userId:0,
    status:'Completed',
  };
}

getAllTaskData() {
 

  this.commonService.getRequest(`traccartasks/orgId/${this.orgId}`, []).then((resp: any) => {
 
    if (resp?.status) {
        this.taskListData = resp.data;
    }
  });
 
}
  searchData() {
    
  }
    getAllLicenses() {
    
    this.commonService.getRequest( this.superAdmin ? 'razorpay/listAllLicenses' : 'razorpay', [],false,false).then((resp: any) => {
      if (resp?.status) {
        this.licensesList = resp.data;
       
      }
    });
  }

  onOrderPlace() {
    this.errors = {};
    let error = false;
    if(!this.addLicensesForm.total) {
      this.errors.total = 'Amount is required field';
      error = true;
    } 
    if(!this.addLicensesForm.devicecount) {
      this.errors.devicecount = 'Count is required field';
      error = true;
    } 
    if(!this.addLicensesForm.currency) {
      this.errors.currency = 'Currency is required field';
      error = true;
    } 
    if( !this.addLicensesForm.status && this.superAdmin) {
      this.errors.status = 'Status is required field';
      error = true;
    } 
    if(!(this.addLicensesForm.userId  && this.myDealerControl.value) && this.superAdmin) {
      this.errors.userId = 'Dealer is required field';
      error = true;
    } 
    if(!error) {
      if(this.superAdmin) {
        this.manualLicence();
      } else {
        this.addLicense();
      }
    }
    
  }
  addLicense(){
    const body = new HttpParams()
    .set('amount', this.addLicensesForm.total)
    .set('currency', this.addLicensesForm.currency)
    .set('devicecount', this.addLicensesForm.devicecount);
   // $('#staticBackdrop').modal('show');
    this.commonService
    .postFormRequest('razorpay/order', body,true)
    .then((resp: any) => {
      console.log(resp);
      if (resp?.status && resp?.data?.orderId) {
        //$('#staticBackdrop').modal('hide');
        this.gridMode = true;
        this.addMode = false;
        this.addLicensesForm.orderId = resp.data.orderId;
        this.proceed(resp.data.orderId)
      }
    });
  }
  manualLicence() {
 
    let bodyObj = {
      "userId": this.addLicensesForm.userId,
      "units": this.addLicensesForm.devicecount,
      "amount": this.addLicensesForm.total,
      "currency": this.addLicensesForm.currency,
      "status": this.addLicensesForm.status,
      "recepitNo": "",
      "orderId": "",
      
      "transactiondate": (new Date()).toISOString()
  }
    //$('#staticBackdrop').modal('show');
    this.commonService
    .postRequest('razorpay/manualAddLicense', bodyObj)
    .then((resp: any) => {
        //$('#staticBackdrop').modal('hide');
        this.gridMode = true;
        this.addMode = false;
        this.getAllLicenses();
    });
  }
  
  dealerSelected(dealer) {
    this.addLicensesForm.userId = dealer && dealer.id? dealer.id : '';  
  }
  clientSelected(dealer) {

 this.selectedClientId=dealer && dealer.email ? dealer.email : '';  
  }
  onInputChange(value: string) {
    if (!value.trim()) {
      this.clientSelected('');
    }
  }

  onAutocompleteOpened() {
    if (!this.myDealerControl.value) {
      this.clientSelected('');
    }
  }
  
  onAutocompleteClosed() {
    if (!this.myDealerControl.value) {
      this.clientSelected('');
    }
  }
  onDriverAutocompleteClosed(){
    if (!this.myDriverControl.value) {
      this.driverSelected('');
    }
  }
  driverSelected(driver) {
    debugger
 this.selectedDriverId=driver && driver.id ?driver.id : ''; 
 this.cabNo=driver && driver?.cabno ? driver?.cabno : '';
  }
  displayFn(dealer: any): string {
    return dealer && dealer.name ? dealer.name : '';
  }
  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.dealerList1.filter(option => option.name.toLowerCase().includes(filterValue));
  }
 
  private _filterDriver(value: string): any[] {
    const filterValue = value.toLowerCase();
  
    return this.driverList1.filter(option => {
      const cabnoString = option.attributes?.cabno?.toString();
      const name = option.name?.toLowerCase();
  
      return (cabnoString && cabnoString.includes(filterValue)) ||
             (name && name.includes(filterValue));
    });
  }

  
  onChangeCount() {
    let totalPrice  = this.addLicensesForm.amount * this.addLicensesForm.devicecount;
    this.addLicensesForm.total =totalPrice + totalPrice*this.addLicensesForm.tax/100;
  }

  purchaseLicense() {
    this.gridMode = false;
    this.addMode = true;
  }
  initPaymentFlow() {
    this.razorpayService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
    .subscribe();
  }
  public proceed(orderId) {
    this.RAZORPAY_OPTIONS.amount = (this.addLicensesForm.total).toString();
    this.RAZORPAY_OPTIONS.order_id = orderId

    this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);


   let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
    razorpay.open();
  }

  public razorPaySuccessHandler(response) {
    console.log(response);
   
    this.cd.detectChanges()
   this.verifyOrder(response.razorpay_payment_id);
  }
  verifyOrder(paymentId,orderStatus="success",details='') {
    let postData = {
      "orderId":this.addLicensesForm.orderId,
      "paymentId":paymentId,
      "failureDetails":details,
      "razorPayPaymentStatus":orderStatus,
    }
    
    this.commonService
      .postRequest('razorpay/verifyPayment', postData)
      .then((resp: any) => {
        console.log(resp);
        this.gridMode = true;
        this.addMode = false;
        this.getAllLicenses();
      });
  }
  addLicenseButton() {
    this.gridMode = false;
    this.addMode = true;
  }
  onCancelClick() {
    this.gridMode = true;
    this.addMode = false;
  }
  exporttoExcel() {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.taskListData);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'task_data.xlsx');
  }

  download(){
    let tableList = [];
    tableList = [
      {
        titleName: 'Task ID',
        type: '',
        filter: '',
        hirarchy: [],
        isHirarchy: false,
        colName: 'id',
      },
      {
        titleName: 'Type',
        type: '',
        filter: '',
        hirarchy: [],
        isHirarchy: false,
        colName: 'type',
      },
      {
        titleName: 'Description',
        type: '',
        filter: '',
        hirarchy: [],
        isHirarchy: false,
        colName: 'name',
      },
      {
        titleName: 'Address',
        type: '',
        filter: '',
        hirarchy: [],
        isHirarchy: false,
        colName: 'pickuplocation',
      },
      {
        titleName: 'Created by',
        type: '',
        filter: '',
        hirarchy: [],
        isHirarchy: false,
        colName: 'createdBy',
      },
      {
        titleName: 'Start before',
        type: 'date',
        filter: '',
        format: 'dd/MM/yyyy hh:mm a',
        hirarchy: [],
        isHirarchy: false,
        colName: 'expectedstarttime',
      },
      {
        titleName: 'Status',
        type: '',
        filter: '',
        hirarchy: [],
        isHirarchy: false,
        colName: 'status',
      },
      {
        titleName: 'Pickup/Delivery',
        type: '',
        filter: '',
        hirarchy: [],
        isHirarchy: false,
        colName: 'type',
      }
    ]
    const typeColumn = tableList.find(column => column.titleName === 'Type');
    console.log(typeColumn);

    this.xlsxFileData = [];
        this.exportexcel();

    }
    formatExpectedEndTime(date: string): string {
      if(date=== null){
        return 'N/A';
      }
      const parsedDate = new Date(date);
      return this.datePipe.transform(parsedDate, 'dd/MM/yyyy hh:mm a');
    }


    downloadAsPDF() {
     
      const doc = new jsPDF();
      let fontSizeTable = this.reportsName == 'Trips' || this.reportsName == 'Summary' || this.reportsName == 'Route' ? 8:10;
      // const startdate = new Date(`${this.fromdateP} ${this.fromDateHours}:${this.fromDateMinutes}`);
      // const enddate = new Date(`${this.todateP} ${this.toDateHours}:${this.toDateMinutes}`);
      let title1 = 'MultiTrack';
      let title2 = (this.reportsName == 'Summary' ?'MIS':this.reportsName) + ' Report';
      let deviceName = '';
      // if (this.Device_ID && this.reportsName != 'Summary') {
      //   deviceName = this.DeviceArray.filter(x => x.id == this.Device_ID)[0].name;
      // }
      // if (this.myControl.value && this.myControl.value.length > 0 && this.reportsName == 'Summary') {
      //   this.myControl.value.forEach(element => {
      //     deviceName += element.name + ', ';
      //   });
      //   deviceName = deviceName.substring(0, deviceName.length - 2);
      // }
      const pdfTable = this.pdfTable.nativeElement;
      let html = htmlToPdfmake(pdfTable.innerHTML, {
        tableAutoSize:true
      });
  
      //const documentDefinition = { content: html };
      let contentOfTable:any[] = [];
      this.tableForPdfContent.forEach(ele => {
        contentOfTable.push(ele);
      });
  
      const documentDefinition =
      {
        content: [
          {
            columns: [
              {
                text: 'Roster',
                width: 100,
                margin: [0, 0, 0, 10]         
              },
              {
                width: '*',
                text: title2,
                style: 'Pdf',
                alignment: 'right'
              }
            ]
          },
          {
            columns: [
              {
                text: '',
                // style: 'tableExample',
                // table: {
                //   body: [
                //     [
                //       {
                //         text: `Asset Name: ${deviceName}`,
                //         bold: true,
                //       },
                //       {
                //         text: 'Group: NA',
                //         bold: true
                //       }
                //     ],
                //     [
                //       {
                //         text: [
                //           {text: `Report Period: ` , style: 'fontBold'},
                //           `${moment(startdate).format('DD/MMM/yyyy hh:mm:ss A')} - ${moment(enddate).format('DD/MMM/yyyy hh:mm:ss A')}`,
                //         ],
                //         colSpan: 2
                //       }
                //     ],
                //     [
                //       {
                //         text: [
                //           { text: `Report Run Time: `, style: 'fontBold'},
                //         `${moment().format('DD/MMM/yyyy hh:mm:ss A')}`,
                //         ],
                //         colSpan: 2
                //       }
                //     ]
                //   ]
                // },
                width: 500
              },
              {
                style: 'tableExample',
                table: {
                  body: [
                    [
                      {
                        text: `Print By: ${this.userdata.name}`,
                        bold: true,
                        style: 'printby'
                      }
                    ],
                    [
                      {
                        text: `Remarks: - NA`,
                        bold: true,
                        style: 'remarks'
                      }
                    ]
                  ]
                },
                width: '*'
              }
            ]
          },
          {
            style: 'tablecss',
            table: {
              body: [
                [...this.tableForPdfColumns],
                ...contentOfTable
              ]
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex == 0) ? '#CCCCCC' : null;
              }
            }
          }
        ],
        styles: {
          tablecss: {
            fontSize: fontSizeTable
          },
          tableStyle: {
  
          },
          testing: {
            bold: true,
            fontSize: 40
          },
          title1: {
            margin: [0, 0, 0, 10],
            fontSize: 10
          },
          title2: {
  
          },
          tableExample: {
            border: 0,
            margin: [0, 0, 5, 10],
            fontSize: 10
          },
          printby: {
            fontSize: 10,
          },
          remarks: {
            fontSize: 10,
          },
          fontBold: {
            bold: true
          }
        }
      };
  
      pdfMake.createPdf(documentDefinition).open();
  
  
      // const documentDefinition =
      // {
      //   content: [
      //     { text: 'Heading test', style: 'testing' },
      //     htmlToPdfmake(pdfTable.innerHTML)
      //   ],
      //   styles: {
      //     testing: {
      //       bold: true,
      //       fontSize: 50
      //     }
      //   }
      // };
  
      // this.htmlString = '';
    }
    private _filterforDrivers(value: string): any[] {
   
      const filterValue = value.toLowerCase().trim(); // Convert input value to lowercase and trim whitespace
  
    return this.driverList
      .filter(option => {
        const cabnoString = option?.name?.toString()?.toLowerCase(); // Convert cabno to lowercase
        if (!cabnoString) {
          return false; // Skip if cabnoString is null or undefined
        }
  
        // Check if cabnoString contains the filterValue (case insensitive)
        return cabnoString.includes(filterValue);
      })
      .map(option => ({ 
        id: option.id,
        cabno:option?.cabNo?.toString(),
        display: option?.name?.toString() }));
  
    }	
  
    exportExcel(xlsxFileData: any[], fileName: string) {
     
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsxFileData);
      const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveExcelFile(excelBuffer, fileName);
    }
    exportexcel(): void
    {

      /* pass here the table id */
      let element = document.getElementById('report-table');
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
      /* generate workbook and add the worksheet */

      
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.reportsName +'.xlsx');
  
      // setTimeout(()=>{
      //   this.page = lastPage;
      //   this.pageOption = lastPerPage;
      // },100)
    }
    trip_export_excel(tableList: any[]): void
    {
    
      /* pass here the table id */
      let element = document.getElementById('report-table');
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);    
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.reportsName +'.xlsx');
  
      // setTimeout(()=>{
      //   this.page = lastPage;
      //   this.pageOption = lastPerPage;
      // },100)
    }

    // exportexcel1(): void {
    //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.taskListData.map(task => ({
    //     "Task ID": task.id,
    //     "Client Name": task.attributes.clientName,
    //     "Task Type":this.capitalizeFirstLetter(task.type),
    //     "Address": task.type === 'pickup' ? task.pickuplocation : task.droplocation,
    //     "Status": this.capitalizeFirstLetter(task.status),
    //     "Driver Name": task.attributes.driverName ?task.attributes.driverName : '',
    //     "Created On":task.date ? new Date(task.date).toLocaleString('en-US', { 
    //       day: '2-digit', 
    //       month: '2-digit', 
    //       year: 'numeric', 
    //       hour: '2-digit', 
    //       minute: '2-digit', 
    //       hour12: true 
    //     }):'' ,
    //     "Start Time": task.expectedstarttime? new Date(task.expectedstarttime).toLocaleString('en-US', { 
    //       day: '2-digit', 
    //       month: '2-digit', 
    //       year: 'numeric', 
    //       hour: '2-digit', 
    //       minute: '2-digit', 
    //       hour12: true 
    //     }):'',
    //     "End Time":task.expectedendtime? new Date(task.expectedendtime).toLocaleString('en-US', { 
    //       day: '2-digit', 
    //       month: '2-digit', 
    //       year: 'numeric', 
    //       hour: '2-digit', 
    //       minute: '2-digit', 
    //       hour12: true 
    //     }): '',
    //     "Distance": (task.gpsDistance / 1000).toFixed(2),
    //     "Duration": task.duration,
    //     "Shift Timing": task.attributes.Shift_Timing,
    //     "Remarks": task.attributes.remarks? task.attributes.remarks : ''
    //   })));
    //   XLSX.utils.book_append_sheet(wb, ws, 'Task List');
    //   XLSX.writeFile(wb, 'TaskList.xlsx');
    // }
    capitalizeFirstLetter(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    saveExcelFile(buffer: any, fileName: string): void {
     
      const data: Blob = new Blob([buffer], { type: this.fileType });
      FileSaver.saveAs(data, fileName + this.fileExtension);
    }


    setReportsDate() {
      let now = moment();
      switch (this.dayType) {
          // case 'Today':
          //     this.fromdateP = now.startOf('day').format("yyyy-MM-DD");
          //     this.todateP = now.endOf('day').format("yyyy-MM-DD");
           
          //   break;
          // case 'Yesterday':
          //   this.fromdateP = now.subtract(1, 'day').startOf('day').format("yyyy-MM-DD");
          //   this.todateP = now.endOf('day').format("yyyy-MM-DD");
          
          //   break;
          // case 'Week':
          //   this.fromdateP = now.startOf('week').format("yyyy-MM-DD");
          //   this.todateP = now.endOf('week').format("yyyy-MM-DD");

          //   break;
          // case 'Month':
          //   this.fromdateP = now.startOf('month').format("yyyy-MM-DD");
          
          //   this.todateP = now.endOf('month').format("yyyy-MM-DD");
          
          //   break;
          // case 'Last Month':
          //   this.fromdateP = now.subtract(1, 'month').startOf('month').format("yyyy-MM-DD");
          
          //   this.todateP = now.endOf('month').format("yyyy-MM-DD");
           
          //   break;
          // case 'Custom':
          //   if (!this.fromdateP) {
          //     this.fromdateP = now.startOf('day').format("yyyy-MM-DD");
              
          //     this.todateP = now.endOf('day').format("yyyy-MM-DD");
             
          //   }
          //   break;
          // default:
          //   break;


          case 'Today':
            this.fromdateP = now.startOf('day').format("DD/MM/YYYY");
            this.todateP = now.endOf('day').format("DD/MM/YYYY");
            break;
          case 'Yesterday':
            this.fromdateP = now.subtract(1, 'day').startOf('day').format("DD/MM/YYYY");
            this.todateP = now.endOf('day').format("DD/MM/YYYY");
            break;
          case 'Week':
            this.fromdateP = now.startOf('week').format("DD/MM/YYYY");
            this.todateP = now.endOf('week').format("DD/MM/YYYY");
            break;
          case 'Month':
            this.fromdateP = now.startOf('month').format("DD/MM/YYYY");
            this.todateP = now.endOf('month').format("DD/MM/YYYY");
            break;
          case 'Last Month':
            this.fromdateP = now.subtract(1, 'month').startOf('month').format("DD/MM/YYYY");
            this.todateP = now.endOf('month').format("DD/MM/YYYY");
            break;
          case 'Custom':
            if (!this.fromdateP) {
              this.fromdateP = now.startOf('day').format("DD/MM/YYYY");
              this.todateP = now.endOf('day').format("DD/MM/YYYY");
            }
            break;
          default:
            break;
        
      }
      console.log("from date",this.fromdateP)
      console.log("to date",this.todateP)
    }

    calculateDuration(tripstartime,tripendtime){
      if(tripstartime && tripendtime){
        let tripStartTime: Date = new Date(tripstartime);
        let  tripEndTime: Date = new Date(tripendtime);
        const durationInMillis = tripEndTime.getTime() - tripStartTime.getTime();
        const durationInSeconds = Math.floor(durationInMillis / 1000);
       const hours = Math.floor(durationInSeconds / 3600);
       const minutes = Math.floor((durationInSeconds % 3600) / 60);
         const seconds = durationInSeconds % 60;
         let durationString = '';

         if (hours > 0) {
           durationString += `${hours}hr`;
         }
       
         if (minutes >= 0) {
           if (durationString.length > 0) {
             durationString += ':';
           }
           durationString += `${minutes}min`;
         }
       
         return durationString;
      }
      return 'N/A';
    
     }
  
    //  convertToLocalTime(datetime:any){
    //   if(datetime != 'undefined'){
    //     const inputTime = new Date(datetime);
    
    //     // Convert to local time
    //     const localTime = this.datePipe.transform(inputTime, 'medium', 'local');
    
    //     console.log('Local Time:', localTime);
    //     return localTime;
    //   }
    //   return 'N/A';
     
    //  }

    statusChange(event:any){
     
      console.log(event.target.value)
      if(event.target.value !== '0'){
      this.status=event.target.value
      }
      else{
        this.status='';
      }
    }
    typeChange(event:any){
      console.log(event.target.value)
      if(event.target.value !== '0'){
      this.tripType=event.target.value
      }
      else{
        this.tripType='';
      }
    }


    filteredOption() {
 
      this.filteredOptions = this.myDealerControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    }  
    filteredOptionDriver() {
 
      this.filteredOptionsDriver = this.myDriverControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterDriver(value))
      );
    }
    onDriverSelectionChange() {
      console.log('Selected Driver ID:', this.selectedDriverData.id);
    }
    
    // private _filterforDrivers(value: string): { display: string; id: string }[] {
    //   let filteredList: { display: string; id: string }[] = [];
    
    //   if (value && typeof value === 'string') {
    //     const filterValue = value.toLowerCase();
    
    //     filteredList = this.clientList2
    //       .filter(client => 
    //         client.attributes.did.toLowerCase().includes(filterValue) || 
    //         client.name.toLowerCase().includes(filterValue)
    //       )
    //       .map(client => ({
    //         display:client.email,
    //         id: client.email
    //       }));
    //   } else {
    //     filteredList = this.clientList2.map(client => ({
    //      display:client.email,
    //         id: client.email
    //     }));
    //   }
    
    //   console.log('filteredList', filteredList);
    //   return filteredList;
    // }
  
    displayFnClient(option: { display: string, id: string }): string {
      return option ? option.display : '';
    }
    displayFnDriver(driver: any): string{
      if (driver && driver.attributes && driver.attributes.cabno && driver.name) {
        return `${driver.attributes.cabno} ${driver.name}`;
    } else {
        return '';
    }
        return driver && (driver.attributes.cabno ? driver.attributes.cabno : '') +' '+(driver.name ? driver.name : ''); 
    }
  getClientListfordropdown() {

    this.commonService.getRequest(`users?orgId=${this.orgId}&role=clients`, []).then((resp: any) => {
          this.dealerList1 = resp.data     
  })  
}
  getDriverListfordropdown() {

        this.commonService.getRequest(`users?orgId=${this.orgId}&role=driver`, []).then((resp: any) => {
              this.driverList1 = resp.data
             console.log('driver list',this.driverList1);
      })
   }

   getMappedUsers(dealerId) {

    this.commonService
      .getRequest('users', ['userId=' + dealerId])
      .then((resp: any) => {
        if (resp?.status) {
         this.dealerList1= resp.data.map((d: any) => {
            return d;
          });
          console.log('mapped client',this.clientList)
          // this.usersList.forEach((d: any, k: number) => {
          //   this.usersList[k]['mapDealerId'] = dealerId;
          //   this.usersList[k]['isSelectedUser'] =
          //     mappedId.indexOf(d.id) > -1;
          // });
        }
      });
  }
  exportToExcel(): void {
    // Define custom headers
if(!this.isSupervisor){
  this.fetchTasks().then(() => {
    const headers = [
      'Task Id', 'Company Name', 'Task Type', 'Address', 'Status', 
      'Driver Name', 'Cab No', 'Created On', 'Actual Start Time', 
      'Actual End Time', 'Distance', 'Duration', 'Shift Timing', 
      'Remark', 'Expected Start Time', 'Expected End Time'
    ];

    const mappedData = this.taskListDataExcel.map(item => {
      let address = '';
      if (item.type === 'pickup') {
        address = item.pickuplocation;
      } else if (item.type === 'drop') {
        address = item.droplocation;
      }

      return {
        'Task Id': item.id,
        'Company Name': item.attributes.clientName,
        'Task Type': this.titlecase.transform(item.type),
        'Address': address,
        'Status': this.titlecase.transform(item.status),
        'Driver Name': item.attributes.driverName,
        'Cab No': item.attributes?.cabno,
        'Created On': this.datePipe.transform(item.createdOn, 'dd/MM/yyyy hh:mm a'),
        'Actual Start Time': this.datePipe.transform(item.actualstarttime, 'dd/MM/yyyy hh:mm a'),
        'Actual End Time': this.datePipe.transform(item.actualendtime, 'dd/MM/yyyy hh:mm a'),
        'Distance': `${this.metertokm.transform(item.gpsDistance)} km`,
        'Duration': this.duration.transform(item.duration),
        'Shift Timing': item.attributes.Shift_Timing,
        'Remark': this.titlecase.transform(item.attributes.remarks),
        'Expected Start Time': this.datePipe.transform(item.expectedstarttime, 'dd/MM/yyyy hh:mm a'),
        'Expected End Time': this.datePipe.transform(item.expectedendtime, 'dd/MM/yyyy hh:mm a')
      };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFileDummy(excelBuffer, 'Tasks');
  }).catch(error => {
    console.error('Error during export:', error);
  });
}
else{
this.getAllTasksForSupervisor().then(() => {
  const headers = [
    'Task Id', 'Company Name', 'Task Type', 'Address', 'Status', 
    'Driver Name', 'Cab No', 'Created On', 'Actual Start Time', 
    'Actual End Time', 'Distance', 'Duration', 'Shift Timing', 
    'Remark', 'Expected Start Time', 'Expected End Time'
  ];

  const mappedData = this.taskListDataExcel.map(item => {
    let address = '';
    if (item.type === 'pickup') {
      address = item.pickuplocation;
    } else if (item.type === 'drop') {
      address = item.droplocation;
    }

    return {
      'Task Id': item.id,
      'Company Name': item.attributes.clientName,
      'Task Type': this.titlecase.transform(item.type),
      'Address': address,
      'Status': this.titlecase.transform(item.status),
      'Driver Name': item.attributes.driverName,
      'Cab No': item.attributes?.cabno,
      'Created On': this.datePipe.transform(item.createdOn, 'dd/MM/yyyy hh:mm a'),
      'Actual Start Time': this.datePipe.transform(item.actualstarttime, 'dd/MM/yyyy hh:mm a'),
      'Actual End Time': this.datePipe.transform(item.actualendtime, 'dd/MM/yyyy hh:mm a'),
      'Distance': `${this.metertokm.transform(item.gpsDistance)} km`,
      'Duration': this.duration.transform(item.duration),
      'Shift Timing': item.attributes.Shift_Timing,
      'Remark': this.titlecase.transform(item.attributes.remarks),
      'Expected Start Time': this.datePipe.transform(item.expectedstarttime, 'dd/MM/yyyy hh:mm a'),
      'Expected End Time': this.datePipe.transform(item.expectedendtime, 'dd/MM/yyyy hh:mm a')
    };
  });

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
  const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };

  const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  this.saveExcelFileDummy(excelBuffer, 'Tasks');
}).catch(error => {
  console.error('Error during export:', error);
});
}

  }

  saveExcelFileDummy(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  }
     