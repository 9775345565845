import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { parse } from 'path';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, tap} from 'rxjs/operators';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import { SubjectSubscribeService } from 'src/app/services/subject-subscribe.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonService } from '../../services/common.service';
import { UsersettingService } from 'src/app/services/usersetting.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isCustomer:boolean = false;
  isDealer:boolean = false;
  isLicense: boolean = false;
  isReports: boolean = false;
  isDevices: boolean = false;
  isMasterModel: boolean = false;
  isTaskList: boolean = false;
  isTaskAdd: boolean = false;
  isTaskEdit: boolean = false;
  isClients: boolean = false;
  isUsers: boolean = false;
  editTaskData:any = {};
  isSupervisor:boolean = false;
  isCompliance:boolean = false;
  isAccountant:boolean = false;
  isBackend:boolean = false;
  isSupervisor1:boolean = true;
  isAdmin:boolean=false;
  isClient:boolean= false;
  isCreatetask: boolean = false;
  isExpenses: boolean = false;
  isDieselManager: boolean = false;
  isPasswordChange: boolean =false;
  private inactivityTimeout: any;
  userdata:any
  private readonly INACTIVITY_THRESHOLD = 10 * 60 * 1000; 
  driverList1: any[]=[];
  orgId: any;
  supervisorId: any;
  dealerList: any[]=[];
  constructor(
    private dash_http: DashboardDataService,
    private commonService: CommonService,
    private subjectSubscribeService: SubjectSubscribeService,
    private router: Router,
    private user: UsersettingService
  ) {
    let userData = localStorage.getItem('userData');
    this.userData = userData ? JSON.parse(userData) : {};
       let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userdata = JSON.parse(userDataObj);
       
        console.log('USer data =>',this.userdata)
        if(this.userdata.attributes.role === 'admin'){
       this.orgId=this.userdata.id;
      }
      else if(this.userdata.attributes.role === 'sv'){
        this.isSupervisor=true;
        this.orgId=this.userdata.orgId;
        this.supervisorId=this.userdata.id
      
      }
      else{
        this.orgId = this.userdata.orgId;
      }

      // if(this.userdata.attributes.role == "clients"){
         
      // }
      } catch (error) {}
    }
    this.commonService.headerPopupEvent.subscribe((result: any) => {

      this.editTaskData = {};
      if (result.page == 'taskEdit' && result.value) {   
        this.editTaskData = result.data;     
        this.openModel(result.page);        
      } else if (result.page == 'taskEdit' && !result.value) {
        this.resetModalVariable();
      } 
    })
   

  }
  @ViewChild('searchDevice') searchDevice: ElementRef;
  page=0;
  myControl = new FormControl();
  myInputSearch = new FormControl();
  deviceDetails = [];
  vehicleID = [];
  DeviceID: string;
  isTrackingScreen=false;
  username = '';
  useremail = '';
  userDetails:any;
  notifications = [];
  isBackToAdmin:number = 0;
  filteredOptions: Observable<any[]>;
  dealer = false;
  customer = false;
  private timeout: any;
  private readonly INACTIVITY_TIMEOUT = 10 * 60 * 1000;
  userData: any = {};
  siteDetails :any;
  ngAfterViewInit() {
    
//this.addedSearchEvent();
}
// addedSearchEvent() {
//   fromEvent(this.searchDevice.nativeElement,'keyup')
//     .pipe(
//         filter(Boolean),
//         debounceTime(300),
//         distinctUntilChanged(),
//         tap((text) => {
//           console.log(this.searchDevice.nativeElement.value);
//           this.subjectSubscribeService.searchDevice.next(this.searchDevice.nativeElement.value)
//         })
//     )
//     .subscribe();
// }
  ngOnInit() {
    this.checkSessionValidity();
    this.startInactivityTimer();
    this.resetInactivityTimer(); 
    this.siteDetails = this.commonService.getSiteDetails();
    let tmpAdmId = localStorage.getItem('temp_adm_user_id');
    this.isBackToAdmin = tmpAdmId ? parseInt(tmpAdmId) : 0;
    this.commonService.isAdminLoginForOtherUser.subscribe(resp=>{
      this.router.navigateByUrl('/main/tracking').then(() => {
        let user = localStorage.getItem('userData');
        if (user) {
          this.userDetails = JSON.parse(user);
          this.username = this.userDetails.name;
          this.useremail = this.userDetails.email;
        }
        window.location.reload();
      });
    })
    let userData = localStorage.getItem('userData');
    if (userData) {
      try {
        this.userData = JSON.parse(userData);
        console.log('userData', this.userData);
      } catch (error) {}
    }
   // this.isTrackingScreen =  this.router && this.router.url == '/main/tracking';
    setTimeout(()=>{
      //this.addedSearchEvent();
    },100)

    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) 
    {
      try {
        let userData =  JSON.parse(userDataObj);
        console.log('userDetails=>',userData.attributes.role);
        if(userData.attributes.role === 'sv' || userData.attributes.role === 'complaince' || userData.attributes.role === 'account' ){
          this.isSupervisor1= false;
        
        }
        if(userData.attributes.role === 'backend')
        {
          this.isBackend= true;
        }
        else if(userData.attributes.role === 'sv'){
          this.isSupervisor =  true
        }
        else if(userData.attributes.role === 'account'){
          this.isAccountant = true;
        }
        else if(userData.attributes.role === 'complaince'){
          this.isCompliance =  true;
        }
        else if(userData.attributes.role === 'admin'){
          this.isAdmin = true;
        }
        else if(userData.attributes.role === 'clients'){
          this.isClient= true;
        }
        else if(userData.attributes.role === 'DM'){
          this.isDieselManager =  true;
        }
      
        // this.superAdmin = userData && userData.administrator;

      } catch (error) {}
    }
  //   this.router.events.pipe(
  //     filter(event => event instanceof NavigationEnd)
  // )
  //     .subscribe(event => {
  //       this.isTrackingScreen =  event && event['url'] == '/main/tracking';
  //       if(this.isTrackingScreen) {
  //         this.myInputSearch.setValue('');
  //         setTimeout(()=>{
  //           //this.addedSearchEvent();
  //         },1)
  //       }
  //     });
  //   // this.onShareProfileImage();
  //   this.getDeviceName();

    this.commonService.getUserDetails().subscribe((resp: any) => {
      if (resp) {
        this.userDetails = resp;
        this.username = resp.name;
        this.useremail = resp.email;
      }
    });
    this.commonService.commonModelReset.subscribe((result) => {
    //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['/main/tracking']);
    //   })
    // console.log(result)
      this.resetModalVariable();
    })
    this.getDriverListfordropdown();
    this.getClientListfordropdown();  
    // this.resetInactivityTimer();
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => {
    //     const name = typeof value === 'string' ? value : value?.name;
    //     return name ? this._filter(name as string) : this.deviceDetails.slice();
    //   }),
    // );
  }
  ngOnDestroy(): void {
    // Clear timeout on component destroy
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
  // displayFn(vehicle: any): string {
  //   return vehicle && vehicle.name ? vehicle.name : '';
  // }
  // private _filter(name: string): any[] {
  //   const filterValue = name.toLowerCase();

  //   return this.deviceDetails.filter(option => option.name.toLowerCase().includes(filterValue));
  // }

  private startInactivityTimer() {
    this.timeout = setTimeout(() => {
      this.handleSessionTimeout();
    }, this.INACTIVITY_TIMEOUT);
  }

  private resetInactivityTimer() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.startInactivityTimer();
  }

  private handleSessionTimeout() {
    // Clear user session data
    localStorage.clear(); // or remove specific keys

    // Navigate to login or any other appropriate page
    this.router.navigate(['/login']);
    // alert('Your session has expired. Please log in again.');
    Swal.fire({
      icon: "error",
      title: `Your session has expired. Please log in again.`,
      showConfirmButton: false,
      timer: 1500,
      toast:true,
      position:'top-end'
      
    });  
  }
  private checkSessionValidity() {
    // Check if the session is still valid
    const userData = localStorage.getItem('userData');
    if (!userData) {
      this.handleSessionTimeout();
    }
  }
  @HostListener('window:mousemove') onMouseMove() {
    this.resetInactivityTimer();
  }

  @HostListener('window:keydown') onKeyDown() {
    this.resetInactivityTimer();
  }

  @HostListener('window:scroll') onScroll() {
    this.resetInactivityTimer();
  }
  loadMoreNotification(e) {
   this.loadNotification();
      e.stopPropagation();
    
  }
  loadNotification() {
   
    let userId  = localStorage.getItem("userData");
    if(userId) {
      userId = (JSON.parse(userId)).id;
    } else {
      userId = '';
    }

    this.commonService
        .getRequest('reports/events/'+ userId +'?limit=25&offset='+this.page, [])
       // https://app.multitrack.in:8082/api/reports/events/5?limit=10&offset=0
        .then((resp: any) => {
          this.page++;
          console.log('-----------------', resp);
          if (resp?.status) {
            this.notifications = this.notifications.concat(resp.data);
          }
        });
  }
  // getDeviceName() {

  //   this.commonService.getRequest('devices?all=all', []).then((resp: any) => {

  //     if (resp?.status) {
  //       this.deviceDetails = resp.data;
  //       localStorage.setItem(
  //         'vehicleIDArray',
  //         JSON.stringify(this.deviceDetails)
  //       );
        
  //       // TODO: handle by dashboard url
  //       this.myControl.setValue(this.deviceDetails[0]);
  //       this.getVehicleDeviceID(this.deviceDetails[0]);
  //     }
  //   });
  //   // this.dash_http.searchDeviceName().subscribe((resp) => {
  //   //   console.log(resp);
  //   //   this.deviceDetails = resp.devices;
  //   //   //  this.vehicleID=this.deviceDetails.map((b) => b["Device_ID"]);
  //   //   localStorage.setItem(
  //   //     'vehicleIDArray',
  //   //     JSON.stringify(this.deviceDetails)
  //   //   );
  //   //   this.myControl.setValue(this.deviceDetails[0]['Device_Name']);
  //   //   this.getVehicleDeviceID(this.deviceDetails[0]['Device_Name']);
  //   // });
  // }
  backToAdmin(){
    let isFailed = false;
    const basicToken = localStorage.getItem('socketBasicToken');
    let authData = atob(basicToken);
    if(authData){
      let userDetails = authData.split(':');
      if(userDetails[0] && userDetails[1]) {
        this.adminLogin(userDetails[0],userDetails[1],basicToken)
      } else {
        isFailed =true;
      }
    } else{
      isFailed =true;
    }
    if(isFailed) {
      Swal.fire({
        icon: "error",
        title: "Unauthorized user",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  adminLogin(username,password,basicToken){
    
     const body = new HttpParams()
        .set('email', username)
        .set('password', password);
    this.commonService.postFormRequest('session', body).then((resp: any) => {
      if (resp?.status) {
        localStorage.clear();
        localStorage.setItem('userData', JSON.stringify(resp.data));
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem(
          'socketBasicToken',
          basicToken
        );
        this.router.navigateByUrl('/main/tracking').then(()=>{
          window.location.reload();
        });
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Unauthorized user",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    (err) => {
    });
  }
  // getVehicleDeviceID(value) {
  //   //let filterData = this.deviceDetails.find((x) => x.name === value.name);
  //   this.DeviceID = value ?  value.id : '';
  //   // this.dash_http.DeviceId.next(this.DeviceID);
  //   this.subjectSubscribeService.DeviceId.next(this.DeviceID);
  // }

  // refreshComponent() {
  //   this.dash_http.getDashbaord().subscribe((resp) => {
  //     console.log(resp);
  //     this.dash_http.vehiclesCountComponent.next(true);
  //   });
  // }
  // changeSearchTxt() {
  //   this.subjectSubscribeService.searchDevice.next(this.myInputSearch.value);
  // }
  onLogout() {
    localStorage.clear();
    this.commonService.closeSocket();
    this.router.navigate(['/login']);
  }
  // @HostListener('document:mousemove', ['$event'])
  // @HostListener('document:keypress', ['$event'])
  // onUserActivity(event: MouseEvent | KeyboardEvent) {
  //   this.resetInactivityTimer();
  // }


  onShareProfileImage() {
    this.dash_http.shareProfileImage().subscribe((resp) => {
      console.log(resp);
      this.username = resp.first_name + ' ' + resp.last_name;
      this.useremail = resp.email;
    });
  }
  onUserSetting() {
    this.user.userSetting().subscribe((resp) => {
      //console.log('ok');
      let SuperAdmin = resp.isSuperAdmin;
      let Dealer = resp.isDealer;
      if (SuperAdmin == true) {
        this.dealer = true;
        this.customer = true;
      }
      if (Dealer) {
        this.dealer = false;
        this.customer = true;
      }
    });
  }

  get check() {
    return window.location.href.includes('admin');
  }
  resetModalVariable() {

    this.isCustomer = false;
    this.isDealer = false;
    this.isLicense = false;
    this.isReports = false;
    this.isDevices = false;
    this.isMasterModel = false;
    this.isTaskList = false;
    this.isTaskAdd = false;
    this.isTaskEdit = false;
    this.isClients = false;
    this.isUsers = false;
    this.isClient= false;
    this.isExpenses = false;
    this.isCreatetask=false;
    this.isPasswordChange = false;
  }
  openModel(val: any) {
  
    this.resetModalVariable();
    if (val == 'customer') {
      this.isCustomer = true;
    } else if (val == 'dealer') {
      this.isDealer = true;
    } else if (val == 'clients') {
      this.isClients = true;
    } else if (val == 'license') {
      this.isLicense = true;
    } else if (val == 'reports') {
      this.isReports = true;
    } else if (val == 'devices') {
      this.isDevices = true;
    } else if (val == 'masterModel') {
      this.isMasterModel = true;
    } else if (val == 'tasklist') {
      this.isTaskList = true;
    } else if (val == 'taskadd') {
      this.isTaskAdd = true;
    } else if (val == 'taskEdit') {
      this.isTaskEdit = true;
    }
    else if(val == 'users'){
      this.isUsers = true;
    }
    else if(val == 'createtask'){
      this.isCreatetask = true;
    } else if (val == 'expense') {
      this.isExpenses = true;
    }
    else if (val == 'changepassword') {
      this.isPasswordChange = true;
    }
    if (this.isCustomer || 
      this.isDealer ||
      this.isLicense ||
      this.isReports ||
      this.isDevices ||
      this.isMasterModel ||
      this.isTaskList ||
      this.isTaskAdd ||
      this.isTaskEdit ||
      this.isClients ||
      this.isUsers ||
      this.isCreatetask ||
      this.isExpenses ||
      this.isPasswordChange
      ) {
        setTimeout(() => {
          this.commonService.pageLoadInModal.next(val);
          this.commonService.commonModel.next(true);  
        }, 100);        
      }
  }
  getDriverListfordropdown() {

            this.commonService.getRequest(`users?orgId=${this.orgId}&role=Employee`, []).then((resp: any) => {
                  this.driverList1 = resp.data
                 console.log('driver list in header component',this.driverList1);
          })
       }
  getClientListfordropdown() {

        this.commonService.getRequest(`users?orgId=${this.orgId}&role=clients`, []).then((resp: any) => {
              this.dealerList = resp.data.filter(x => x.attributes.role == 'clients');
             console.log('dealer list',this.dealerList)
              
              
  })  }
}
