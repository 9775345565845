import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import { ReportService } from 'src/app/services/report.service';
import { CommonService } from 'src/app/services/common.service';

declare var $: any;

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
} from 'ng-apexcharts';
import { debounceTime, defaultIfEmpty, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Observable, OperatorFunction } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
import { convertPipe } from 'src/app/pipe/convert.pipe';
import { DurationPipe } from 'src/app/pipe/duration.pipe';

export type ChartOptions = {
  colunmnList:[],
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [DatePipe, MeterTokm, convertPipe,TitleCasePipe,DurationPipe]
})
export class ReportsComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('pdfTable') pdfTable: ElementRef;

  public chartOptions: Partial<ChartOptions>;
  pageOption = 10;
  searchFunction: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.DeviceArray.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );
  page:number=1;
  pageOptionModal = 10;
  todaysDate = '';
  colunmnList=[
    'latitude',
    'longitude',
    'address',
    'speed',
    'fixTime',
    'deviceTime',
    'serverTime'];
  userdata: any;
  orgId: any;
  isSupervisor: any;
    getNameOfCol(value) {
      let fitem = this.masterColumnList.find(mList=>mList.value == value);
      return fitem? fitem.label : value
    }
  masterColumnList=[
    {"value":"id","label":"Identifier"},
    {"value":"latitude","label":"Latitude"},
    {"value":"longitude","label":"Longitude"},
    {"value":"speed","label":"Speed"},
    {"value":"course","label":"Course"},
    {"value":"altitude","label":"Altitude"},
    {"value":"accuracy","label":"Accuracy"},
    {"value":"valid","label":"Valid"},
    {"value":"protocol","label":"Protocol"},
    {"value":"address","label":"Address"},
    {"value":"deviceTime","label":"Device Time"},
    {"value":"fixTime","label":"Fix Time"},
    {"value":"serverTime","label":"Server Time"},
    {"value":"raw","label":"Raw"},
    {"value":"index","label":"Index"},
    {"value":"hdop","label":"HDOP"},
    {"value":"vdop","label":"VDOP"},
    {"value":"pdop","label":"PDOP"},
    {"value":"sat","label":"Satellites"},
    {"value":"satVisible","label":"Visible Satellites"},
    {"value":"rssi","label":"RSSI"},
    {"value":"gps","label":"GPS"},
    {"value":"roaming","label":"Roaming"},
    {"value":"event","label":"Event"},
    {"value":"alarm","label":"Alarm"},
    {"value":"status","label":"Status"},
    {"value":"odometer","label":"Odometer"},
    {"value":"serviceOdometer","label":"Service Odometer"},
    {"value":"tripOdometer","label":"Trip Odometer"},
    {"value":"hours","label":"Hours"},
    {"value":"steps","label":"Steps"},
    {"value":"input","label":"Input"},
    {"value":"in1","label":"Input 1"},
    {"value":"in2","label":"Input 2"},
    {"value":"in3","label":"Input 3"},
    {"value":"in4","label":"Input 4"},
    {"value":"output","label":"Output "},
    {"value":"out1","label":"Output 1"},
    {"value":"out2","label":"Output 2"},
    {"value":"out3","label":"Output 3"},
    {"value":"out4","label":"Output 4"},
    {"value":"power","label":"Power"},
    {"value":"battery","label":"Battery"},
    {"value":"batteryLevel","label":"Battery Level"},
    {"value":"fuel","label":"Fuel"},
    {"value":"fuelConsumption","label":"Fuel Consumption"},
    {"value":"versionFw","label":"Firmware Version"},
    {"value":"versionHw","label":"Hardware Version"},
    {"value":"type","label":"Type"},
    {"value":"ignition","label":"Ignition"},
    {"value":"flags","label":"Flags"},
    {"value":"charge","label":"Charge"},
    {"value":"ip","label":"IP"},
    {"value":"archive","label":"Archive"},
    {"value":"distance","label":"Distance"},
    {"value":"totalDistance","label":"Total Distance"},
    {"value":"rpm","label":"RPM"},
    {"value":"vin","label":"VIN"},
    {"value":"approximate","label":"Approximate"},
    {"value":"throttle","label":"Throttle"},
    {"value":"motion","label":"Motion"},
    {"value":"armed","label":"Armed"},
    {"value":"geofence","label":"Geofence"},
    {"value":"acceleration","label":"Acceleration"},
    {"value":"deviceTemp","label":"Device Temperature"},
    {"value":"temp1","label":"Temperature 1"},
    {"value":"temp2","label":"Temperature 2"},
    {"value":"temp3","label":"Temperature 3"},
    {"value":"temp4","label":"Temperature 4"},
    {"value":"operator","label":"Operator"},
    {"value":"command","label":"Command"},
    {"value":"blocked","label":"Blocked"},
    {"value":"dtcs","label":"DTCs"},
    {"value":"obdSpeed","label":"OBD Speed"},
    {"value":"obdOdometer","label":"OBD Odometer"},
    {"value":"result","label":"Command result"},
    {"value":"driverUniqueId","label":"Driver Unique Id"},
    {"value":"color","label":"Color"},
    {"value":"image","label":"Image"},
    {"value":"video","label":"Video"},
    {"value":"audio","label":"Audio"}
];
  addressBylatLong = {};
  userId = localStorage.getItem('userId');
  group = true;
  filteredOptions: Observable<any[]>;
  filteredUserOptions: Observable<any[]>;
  details = false;
  reportsName = 'Trips';
  DeviceArray = [];
  usersArray = [];
  supervisorArray = [];
  reportsList = [];
  geofenceList = [];
  poiList = [];
  Device_ID = '';
  Device_Name = '';
  dayType = 'Today';
  groupoption = 'Group';
  todate = '';
  fromdate = '';
  todateP = '';
  toDateHours = '';
  toDateMinutes = '';
  fromdateP = '';
  fromDateHours = '';
  fromDateMinutes = '';
  startLocation = '';
  lastLocation = '';
  Device_NameForModal = '';
  DeviceNameList = [];
  _id = '';
  poiId = '';
  routeId = '';
  yesterdayDate = '';
  DeviceDefaultId = '';
  userTrackRoutesList = [];

  reports = [
    // {
    //   img: '/assets/icons/report5.png',
    //   name: 'AC Report',
    // },
    // {
    //   img: '/assets/icons/report5.png',
    //   name: 'Chart',
    // },
    // {
    //   img: '/assets/icons/report5.png',
    //   name: 'Daily Summary',
    // },
    // {
    //   img: '/assets/icons/report2.png',
    //   name: 'Events',
    // },
    // {
    //   img: '/assets/icons/report2.png',
    //   name: 'Geofence',
    // },
    // {
    //   img: '/assets/icons/report5.png',
    //   name: 'Ignition Report',
    // },
    // {
    //   img: '/assets/icons/report1.png',
    //   name: 'Route',
    // },
    // {
    //   img: '/assets/icons/report4.png',
    //   name: 'Stops',
    // },
    {
      img: '/assets/icons/report5.png',
      name: 'Summary',
    },
    {
      img: '/assets/icons/report3.png',
      name: 'Trips',
    },
    {
      img: '/assets/icons/report3.png',
      name: 'Attendance',
    },
    // {
    //   img: '/assets/icons/report6.png',
    //   name: 'Idle Report',
    // },
    // {
    //   img: '/assets/icons/report7.png',
    //   name: 'Ignition Report',
    // },
    // {
    //   img: '/assets/icons/report8.png',
    //   name: 'Loading Unloading Report',
    // },
    // {
    //   img: '/assets/icons/report9.png',
    //   name: 'Over Speed Report',
    // },
    // {
    //   img: '/assets/icons/report10.png',
    //   name: 'POI Report',
    // },
    // {
    //   img: '/assets/icons/report11.png',
    //   name: 'Route Violation Report',
    // },
    // {
    //   img: '/assets/icons/report12.png',
    //   name: 'SOS Report',
    // },
    // {
    //   img: '/assets/icons/report13.png',
    //   name: 'Speed Variation Report',
    // },
    // {
    //   img: '/assets/icons/report14.png',
    //   name: 'Stoppage Report',
    // },
    // {
    //   img: '/assets/icons/report15.png',
    //   name: 'Summary Report',
    // },
    // {
    //   img: '/assets/icons/report16.png',
    //   name: 'Trip Report',
    // },
    // {
    //   img: '/assets/icons/report17.png',
    //   name: 'User Trip Report',
    // },
    // {
    //   img: '/assets/icons/report18.png',
    //   name: 'Value Screen Report',
    // },
    // {
    //   img: '/assets/icons/report19.png',
    //   name: 'Working Hours Report',
    // },
  ];

  selectChartType: string = 'speed';

  xlsxFileData = [];
  fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  myControl = new FormControl();
  htmlString: string = '';
  isShowChart: boolean = false;
  chartData = [];
  IsLoading=false;
  @Input()
  addMode = false;
  @Input()
  gridMode = false;
  userData: any;
  typeFilterData: any[] = [];
  reportTypeFilter = '';
  sortcolumnName = '';
  sortdirection = '';
  isAscending = true;
  tableForPdfColumns:any[] = [];
  tableForPdfContent: any[][] = [];
  minDateForRouteReportFilter = moment().subtract(1, 'day').format("YYYY-MM-DD");
  maxDateForRouteReportFilter = moment().format("YYYY-MM-DD");
  siteDetails: any;
  constructor(
    private report_http: ReportService,
    private commonService: CommonService,
    private datepipe: DatePipe,
    private numberpipe: DecimalPipe,
    private translatepipe: TranslateService,
    private metertokm: MeterTokm,
    private convertpipe: convertPipe,
    private titlecase:TitleCasePipe,
    private datePipe: DatePipe,

    private duration:DurationPipe,
  ) {
    this.commonService.pageLoadInModal.subscribe((result: string) => {
      if (result == "reports") {
        this.gridMode = true;
        this.addMode = false;
      }
    });
    this.todaysDate = new Date().toISOString();
    const yesterday = new Date(this.todaysDate);
    yesterday.setDate(yesterday.getDate() - 1);
    this.yesterdayDate = yesterday.toISOString();
    // this.Device_Name = this.DeviceArray.find((x) => x.Device_ID === this.Device_ID).Device_Name;
    this.siteDetails = this.commonService.getSiteDetails();    
  }
  exportexcel(): void
  {
    // const lastPage = this.page;
    // const lastPerPage = this.pageOption;
    // this.page = 1;
    // this.pageOption = this.reportsList.length;


    /* pass here the table id */
    let element = document.getElementById('report-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.reportsName +'.xlsx');

    // setTimeout(()=>{
    //   this.page = lastPage;
    //   this.pageOption = lastPerPage;
    // },100)
  }
  ngOnInit(): void {
    let userData = localStorage.getItem('userData');
    if (userData) {
      try {
        this.userData = JSON.parse(userData);
        console.log('userData', this.userData);
      } catch (error) {}
    }

    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userdata = JSON.parse(userDataObj);
       
        console.log('Org Id =>',this.userdata.id)
        if(this.userdata.attributes.role === 'admin'){
       this.orgId=this.userdata.id;
      }
      else if(this.userdata.attributes.role === 'sv'){
       this.isSupervisor=true;
       this.orgId = this.userdata.orgId;
      }
      else{
        this.orgId = this.userdata.orgId;
      }
      } catch (error) {}
    }
    let storedArray = localStorage.getItem("vehicleIDArray");
    this.DeviceArray = JSON.parse(storedArray);
    this.commonService.getRequest('devices?all=all', []).then((resp: any) => {
      console.log('devices', resp);
      this.IsLoading = false;
      if (resp?.status) {
        this.DeviceArray = resp.data;
      }
    });
   
    this.commonService.getRequest('users', []).then((resp: any) => {
      //console.log('devices', resp);
      this.IsLoading = false;
      if (resp?.status) {
        this.usersArray = resp.data;
        this.supervisorArray = this.usersArray.filter(customer=> customer.attributes?.role == 'sv');
      }
    });
    this.onClickReports('Trips');
    // if (this.reportsName == 'AC Reports') {
    //   this.onAcCLick();
    // }
    setTimeout(() => {
      this.initiateCarousel();
    }, 500);
    this.filteredOptions = this.myControl.valueChanges.pipe(
    startWith(''),
    map(value => {
      const name = typeof value === 'string' ? value : value?.name;
      return name ? this._filter(name as string) : this.DeviceArray.slice();
    }),
  );
  this.filteredUserOptions = this.myControl.valueChanges.pipe(
    startWith(''),
    map(value => {
      const name = typeof value === 'string' ? value : value?.name;
      return name ? this._filter(name as string) : this.supervisorArray.slice();
    }),
  );
  this.setReportsDate();
  this.getAllTypesForFilter();
  this.getAllGeofenceList();
  }


displayFn(vehicle: any): string {
  return vehicle && vehicle.name ? vehicle.name : '';
}

private _filter(name: string): any[] {
  const filterValue = name.toLowerCase();

  return this.DeviceArray.filter(option => option.name.toLowerCase().includes(filterValue));
}
  downloadData(type) {
debugger
    let tableList = [];
    switch (this.reportsName) {
      case 'Route':
        tableList = [
          {
            titleName: 'Device Name',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'device_Name',
          },
          {
            titleName: 'Latitude',
            type: 'number',
            filter: '',
            format: '1.0-6',
            hirarchy: [],
            isHirarchy: false,
            colName: 'latitude',
          },
          {
            titleName: 'Longitude',
            type: 'number',
            filter: '',
            format: '1.0-6',
            hirarchy: [],
            isHirarchy: false,
            colName: 'longitude',
          },
          {
            titleName: 'Speed',
            type: 'number',
            filter: 'userbased',
            format: '1.0-2',
            hirarchy: [],
            isHirarchy: false,
            colName: 'speed',
          },
          {
            titleName: 'Odometer',
            type: '',
            filter: 'meterTokm',
            format: '',
            isHirarchy: true,
            hirarchy: ['attributes', 'odometer'],
            colName: 'odometer',
          },
          // {
          //   titleName: 'Fix Time',
          //   type: 'date',
          //   filter: '',
          //   format: 'dd/MM/yyyy hh:mm a',
          //   hirarchy: [],
          //   isHirarchy: false,
          //   colName: 'fixTime',
          // },
          // {
          //   titleName: 'Device Time',
          //   type: 'date',
          //   filter: '',
          //   format: 'dd/MM/yyyy hh:mm a',
          //   hirarchy: [],
          //   isHirarchy: false,
          //   colName: 'deviceTime',
          // },
          {
            titleName: 'Address',
            type: 'address',
            filter: '',
            format: '',
            isHirarchy: false,
            hirarchy: [],
            colName: 'address'
          },
          {
            titleName: 'Server Time',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'serverTime',
          }
        ];
        break;
      case 'Events':
        tableList = [
          {
            titleName: 'Device Name',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'deviceName',
          },
          {
            titleName: 'Fixt Time',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'eventTime',
          },
          {
            titleName: 'Address',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['latLng', 'address'],
            isHirarchy: true,
            colName: 'latLng',
          },
          {
            titleName: 'Type',
            type: '',
            filter: '',
            format: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'type',
          },
          {
            titleName: 'Alarm',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['attributes', 'alarm'],
            isHirarchy: true,
            colName: 'alarm',
          },
          {
            titleName: 'Remarks',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['attributes', 'remarks'],
            isHirarchy: true,
            colName: 'remarks',
          },
        ];
        break;
      case 'AC Report':
        tableList = [
          {
            titleName: 'Device Name',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'deviceName',
          },
          {
            titleName: 'Address',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['latLng', 'address'],
            isHirarchy: true,
            colName: 'latLng',
          },
          {
            titleName: 'Fixt Time',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'eventTime',
          },
          {
            titleName: 'Type',
            type: '',
            filter: '',
            format: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'type',
          }
        ];
        break;
      case 'Ignition Report':
        tableList = [
          {
            titleName: 'Device Name',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'deviceName',
          },
          {
            titleName: 'Address',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['latLng', 'address'],
            isHirarchy: true,
            colName: 'latLng',
          },
          {
            titleName: 'Fixt Time',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'eventTime',
          },
          {
            titleName: 'Type',
            type: '',
            filter: '',
            format: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'type',
          }
        ];
        break;
      case 'Geofence':
          tableList = [
            {
              titleName: 'Device Name',
              type: '',
              filter: '',
              hirarchy: [],
              isHirarchy: false,
              colName: 'deviceName',
            },
            {
              titleName: 'Geofence',
              type: '',
              filter: '',
              hirarchy: [],
              isHirarchy: false,
              colName: 'geoName',
            },
            {
              titleName: 'Fixt Time',
              type: 'date',
              filter: '',
              format: 'dd/MM/yyyy hh:mm a',
              hirarchy: [],
              isHirarchy: false,
              colName: 'eventTime',
            },
            {
              titleName: 'Address',
              type: '',
              filter: '',
              format: '',
              hirarchy: ['latLng', 'address'],
              isHirarchy: true,
              colName: 'latLng',
            },
            {
              titleName: 'Type',
              type: '',
              filter: '',
              format: '',
              hirarchy: [],
              isHirarchy: false,
              colName: 'type',
            },
            {
              titleName: 'Alarm',
              type: '',
              filter: '',
              format: '',
              hirarchy: ['attributes', 'alarm'],
              isHirarchy: true,
              colName: 'alarm',
            },
          ];
          break;
      case 'Trips':
        tableList = [
          {
            titleName: 'Task ID',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'id',
          },
          {
            titleName: 'Task Type',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'type',
          },
          {
            titleName: 'Driver Details',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: '',
          },
          {
            titleName: 'Name',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'name',
          },
          {
            titleName: 'Address',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'address',
          },
          {
            titleName: 'Created By',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'createdBy',
          },
          {
            titleName: 'Start Time',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'expectedstarttime',
          },
          {
            titleName: 'End Time',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'expectedendtime',
          },
          {
            titleName: 'Status',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'status',
          },
          {
            titleName: 'Pickup/ Delivery',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'type',
          },
          {
            titleName: 'Escort',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['escort'],
            isHirarchy: true,
            colName: 'attributes',
          }
          // {
          //   titleName: 'Odometer Start',
          //   type: '',
          //   filter: 'meterTokm',
          //   format: '',
          //   hirarchy: ['startOdometer'],
          //   isHirarchy: true,
          //   colName: 'startOdometer',
          // },
          // {
          //   titleName: 'Start Address',
          //   type: '',
          //   filter: 'address',
          //   format: '',
          //   hirarchy: ['startLat', 'startLon'],
          //   isHirarchy: true,
          //   colName: 'addressBylatLong',
          // },
          // {
          //   titleName: 'Odometer End',
          //   type: '',
          //   filter: 'meterTokm',
          //   format: '',
          //   hirarchy: ['endOdometer'],
          //   isHirarchy: true,
          //   colName: 'endOdometer',
          // },
          // {
          //   titleName: 'End Address',
          //   type: '',
          //   filter: 'address',
          //   format: '',
          //   hirarchy: ['endLat', 'endLon'],
          //   isHirarchy: true,
          //   colName: 'addressBylatLong',
          // },
          // {
          //   titleName: 'Distance',
          //   type: '',
          //   filter: 'meterTokm',
          //   format: '',
          //   hirarchy: ['distance'],
          //   isHirarchy: true,
          //   colName: 'distance',
          // }, {
          //   titleName: 'Average Speed',
          //   type: 'number',
          //   filter: '',
          //   format: '1.0-2',
          //   hirarchy: [],
          //   isHirarchy: false,
          //   colName: 'averageSpeed',
          // }, {
          //   titleName: 'Maximum Speed',
          //   type: 'number',
          //   filter: '',
          //   format: '1.0-2',
          //   hirarchy: [],
          //   isHirarchy: false,
          //   colName: 'maxSpeed',
          // },
          // {
          //   titleName: 'Duration',
          //   type: '',
          //   filter: 'convert',
          //   format: 'hh:mm',
          //   hirarchy: [],
          //   isHirarchy: false,
          //   colName: 'duration',
          // }
        ];
        break;
      case 'Stops':
        tableList = [
          {
            titleName: 'Device Name',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'deviceName',
          },
          {
            titleName: 'Start Time',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'startTime',
          },
          {
            titleName: 'Odometer',
            type: '',
            filter: 'meterTokm',
            format: '',
            hirarchy: ['startOdometer'],
            isHirarchy: true,
            colName: 'startOdometer',
          },
          {
            titleName: 'End Time',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'endTime',
          },
          {
            titleName: 'Duration',
            type: '',
            filter: 'convert',
            format: 'hh:mm',
            hirarchy: [],
            isHirarchy: false,
            colName: 'duration',
          },
          {
            titleName: 'Address',
            type: '',
            filter: 'address',
            format: '',
            hirarchy: ['latitude', 'longitude'],
            isHirarchy: true,
            colName: 'addressBylatLong',
          }, {
            titleName: 'Engine Hours',
            type: '',
            filter: 'convert',
            format: 'hh:mm',
            hirarchy: [],
            isHirarchy: false,
            colName: 'engineHours',
          }
        ];
        break;
      case 'Summary':
        tableList = [
          {
            titleName: 'Device Name',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'deviceName',
          },
          {
            titleName: 'Start Date',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'startTime',
          },
          {
            titleName: 'End Date',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'endTime',
          },
          {
            titleName: 'Distance',
            type: '',
            filter: 'meterTokm',
            format: '',
            hirarchy: ['distance'],
            isHirarchy: true,
            colName: 'distance',
          },
          {
            titleName: 'Start Address',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['startPosition', 'address'],
            isHirarchy: true,
            colName: 'startPosition',
          },
          {
            titleName: 'End Address',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['endPosition', 'address'],
            isHirarchy: true,
            colName: 'endPosition',
          },
          {
            titleName: 'Odometer Start',
            type: '',
            filter: 'meterTokm',
            format: '',
            hirarchy: ['startOdometer'],
            isHirarchy: true,
            colName: 'startOdometer',
          },
          // {
          //   titleName: 'Odometer End',
          //   type: '',
          //   filter: 'meterTokm',
          //   format: '',
          //   hirarchy: ['endOdometer'],
          //   isHirarchy: true,
          //   colName: 'endOdometer',
          // },
          {
            titleName: 'Average Speed',
            type: 'number',
            filter: 'userbased',
            format: '1.0-2',
            hirarchy: [],
            isHirarchy: false,
            colName: 'averageSpeed',
          }, {
            titleName: 'Maximum Speed',
            type: 'number',
            filter: 'userbased',
            format: '1.0-2',
            hirarchy: [],
            isHirarchy: false,
            colName: 'maxSpeed',
          },
          // {
          //   titleName: 'Engine Hours',
          //   type: '',
          //   filter: 'convert',
          //   format: 'hh:mm',
          //   hirarchy: [],
          //   isHirarchy: false,
          //   colName: 'engineHours',
          // },
          {
            titleName: 'Idle Hours',
            type: '',
            filter: 'convert',
            format: 'hh:mm',
            hirarchy: [],
            isHirarchy: false,
            colName: 'idleHours',
          },
          {
            titleName: 'Moving Hours',
            type: '',
            filter: 'convert',
            format: 'hh:mm',
            hirarchy: [],
            isHirarchy: false,
            colName: 'movingHours',
          },
          {
            titleName: 'Stop Hours',
            type: '',
            filter: 'convert',
            format: 'hh:mm',
            hirarchy: [],
            isHirarchy: false,
            colName: 'stopHours',
          }
        ];
        break;
      case 'Attendance':
        tableList = [
          {
            titleName: 'Employee Name',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'userName',
          },
          {
            titleName: 'Date',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'datetime',
          },
          {
            titleName: 'Latitude',
            type: 'number',
            filter: '',
            format: '1.0-4',
            hirarchy: [],
            isHirarchy: false,
            colName: 'latitude',
          },
          {
            titleName: 'longitude',
            type: 'number',
            filter: '',
            format: '1.0-4',
            hirarchy: [],
            isHirarchy: false,
            colName: 'longitude',
          },
          {
            titleName: 'Image',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'imagePath',
          },
        ];
      break;
      case 'Daily Summary':
        tableList = [
          {
            titleName: 'Device Name',
            type: '',
            filter: '',
            hirarchy: [],
            isHirarchy: false,
            colName: 'deviceName',
          },
          {
            titleName: 'Start Date',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'startTime',
          },
          {
            titleName: 'End Date',
            type: 'date',
            filter: '',
            format: 'dd/MM/yyyy hh:mm a',
            hirarchy: [],
            isHirarchy: false,
            colName: 'endTime',
          },
          {
            titleName: 'Distance',
            type: '',
            filter: 'meterTokm',
            format: '',
            hirarchy: ['distance'],
            isHirarchy: true,
            colName: 'distance',
          },
          {
            titleName: 'Start Address',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['startPosition', 'address'],
            isHirarchy: true,
            colName: 'startPosition',
          },
          {
            titleName: 'End Address',
            type: '',
            filter: '',
            format: '',
            hirarchy: ['endPosition', 'address'],
            isHirarchy: true,
            colName: 'endPosition',
          },
          {
            titleName: 'Odometer Start',
            type: '',
            filter: 'meterTokm',
            format: '',
            hirarchy: ['startOdometer'],
            isHirarchy: true,
            colName: 'startOdometer',
          },
          // {
          //   titleName: 'Odometer End',
          //   type: '',
          //   filter: 'meterTokm',
          //   format: '',
          //   hirarchy: ['endOdometer'],
          //   isHirarchy: true,
          //   colName: 'endOdometer',
          // },
          {
            titleName: 'Average Speed',
            type: 'number',
            filter: 'userbased',
            format: '1.0-2',
            hirarchy: [],
            isHirarchy: false,
            colName: 'averageSpeed',
          }, {
            titleName: 'Maximum Speed',
            type: 'number',
            filter: 'userbased',
            format: '1.0-2',
            hirarchy: [],
            isHirarchy: false,
            colName: 'maxSpeed',
          }
          // ,
          // {
          //   titleName: 'Engine Hours',
          //   type: '',
          //   filter: 'convert',
          //   format: 'hh:mm',
          //   hirarchy: [],
          //   isHirarchy: false,
          //   colName: 'engineHours',
          // }
        ];
        break;
      case 'Chart':
        tableList = [
          {
            titleName: 'Device Time',
            colName: 'deviceTime',
          },
          {
            titleName:
              this.selectChartType.charAt(0).toUpperCase() +
              this.selectChartType.slice(1),
            colName: this.selectChartType,
          },
        ];
        break;
    }

    this.xlsxFileData = [];
    if (type === 'xlsx') {
      if (this.reportsName === 'Chart') {
        this.chartData.forEach((r: any, i: number) => {
          let dataSet = {};
          tableList.forEach((t: any) => {
            dataSet[t.titleName] = r[t.colName] ? r[t.colName] : '';
          });
          this.xlsxFileData.push(dataSet);
        });
        this.exportExcel(this.xlsxFileData, this.reportsName);
      } else {
        // this.reportsList.forEach((r: any, i: number) => {
        //   let dataSet = {};
        //   tableList.forEach((t: any) => {
        //     dataSet[t.titleName] = r[t.colName] ? r[t.colName] : '';
        //   });
        //   this.xlsxFileData.push(dataSet);
        // });
        // this.exportExcel(this.xlsxFileData, this.reportsName);

        this.exportexcel();
      }
    } else {
      // let htmlheader = `<table class="pdf-container">
      // <tbody><tr><td style="width:70%">INNOVATIVE TELEMATICS SOLUTIONS PVT. LTD.</td><td style="width:30%">${this.reportsName} Report</td>
      // </tbody></table>`;

      // let htmlheader = `<span style="width:70%;">INNOVATIVE TELEMATICS SOLUTIONS PVT. LTD.</span>
      // <span style="width:29%;">${this.reportsName} Reports</span>`;
      //let html = `<style>.pdf-container { font-size:6px;} </style><table class="border pdf-container"><thead><tr>`;
      this.tableForPdfColumns = [];
      this.tableForPdfContent = [];
      tableList.forEach((t) => {
        //html += `<th>${t.titleName}</th>`;
        this.tableForPdfColumns.push(t.titleName);
      });
      //html += `</tr></thead><tbody>`;
      this.reportsList.forEach((r: any, i: number) => {
        //html += `<tr>`;
        this.tableForPdfContent[i] = [];
        tableList.forEach((t: any, j: number) => {
          if (t.type == 'date' && !t.isHirarchy) {
            //html += `<td>${r[t.colName] ? this.datepipe.transform(r[t.colName], t.format)  : ''}</td>`;
            this.tableForPdfContent[i][j] = `${r[t.colName] ? this.datepipe.transform(r[t.colName], t.format)  : ''}`;
          } else if (t.type == 'address' && !t.isHirarchy) {
            this.tableForPdfContent[i][j] = `${this.addressBylatLong[r['latitude']+'_'+r['longitude']] ?? ''}`;
          } else if (t.type == '' && !t.isHirarchy && t.filter == 'convert') {
            //html += `<td>${r[t.colName] ? this.datepipe.transform(r[t.colName], t.format)  : ''}</td>`;
            this.tableForPdfContent[i][j] = `${r[t.colName] ? this.convertpipe.transform(r[t.colName], t.format)  : '00:00'}`;
          } else if (t.type == 'number' && t.filter == '' && !t.isHirarchy) {
            //html += `<td>${r[t.colName] ? this.numberpipe.transform(r[t.colName], t.format)  : ''}</td>`;
            this.tableForPdfContent[i][j] = `${r[t.colName] ? this.numberpipe.transform(r[t.colName], t.format)  : ''}`;
          } else if (t.type == 'number' && t.filter == 'userbased' && !t.isHirarchy && r[t.colName]) {
            //html += `<td>${r[t.colName] ? this.numberpipe.transform(this.onSpeedgenerateBasedOnuserPreference(r[t.colName]), t.format)  : '0'} ${this.translatepipe.instant(this.userData.attributes.speedUnit)}</td>`;
            //this.tableForPdfContent[i][j] = `${r[t.colName] ? this.numberpipe.transform(this.onSpeedgenerateBasedOnuserPreference(r[t.colName]), t.format)  : '0'} ${this.translatepipe.instant(this.userData.attributes.speedUnit)}`;
            this.tableForPdfContent[i][j] = ''; //data conversion issue in roster. so commenting for now temporary.
          } else if (t.type == '' && t.isHirarchy && t.filter == 'meterTokm') {
            if (t.hirarchy.length == 1) {
              this.tableForPdfContent[i][j] = `${r[t.hirarchy[0]] ? this.metertokm.transform(r[t.hirarchy[0]])  : '0'}`;
            } else if (t.hirarchy.length == 2) {
              this.tableForPdfContent[i][j] = `${r[t.hirarchy[0]][t.hirarchy[1]] ? this.metertokm.transform(r[t.hirarchy[0]][t.hirarchy[1]])  : '0'}`;
            }
          } else if (t.type == '' && t.isHirarchy && t.filter == 'address') {
            if (t.hirarchy.length == 2) {
              this.tableForPdfContent[i][j] = `${(r[t.hirarchy[0]]+'_'+r[t.hirarchy[1]]) ? this.addressBylatLong[r[t.hirarchy[0]]+'_'+r[t.hirarchy[1]]]  : 'NA'}`;
            }
          } else if (t.type == '' && t.isHirarchy && t.filter == '') {
            if (t.hirarchy.length == 1) {
              this.tableForPdfContent[i][j] = `${r[t.colName][t.hirarchy[0]] ? r[t.colName][t.hirarchy[0]]  : ''}`;
            }
            if (t.hirarchy.length == 2 && r[t.hirarchy[0]] && r[t.hirarchy[0]][t.hirarchy[1]]) {
              this.tableForPdfContent[i][j] = `${r[t.hirarchy[0]][t.hirarchy[1]] ? r[t.hirarchy[0]][t.hirarchy[1]]  : ''}`;
            } else {
              this.tableForPdfContent[i][j] = ''; /////data conversion issue in roster. so commenting for now temporary. this else is added for roster only
            }
          }
          else {
            //html += `<td>${r[t.colName] ? r[t.colName] : ''}</td>`;
            this.tableForPdfContent[i][j] = `${r[t.colName] ? r[t.colName] : ''}`;
          }
        });
        //html += `</tr>`;
      });
      //html += `</tbody></table>`;

      this.htmlString = '';

      setTimeout(() => {
        const pdfTable = this.pdfTable.nativeElement;
        // this.convetHtmlToPDF(pdfTable.innerHTML);
        this.downloadAsPDF();
      }, 2000);
    }
  }

  downloadAsPDF() {
   
    const doc = new jsPDF();
    let fontSizeTable = this.reportsName == 'Trips' || this.reportsName == 'Summary' || this.reportsName == 'Route' ? 8:10;
    const startdate = new Date(`${this.fromdateP} ${this.fromDateHours}:${this.fromDateMinutes}`);
    const enddate = new Date(`${this.todateP} ${this.toDateHours}:${this.toDateMinutes}`);
    let title1 = 'MultiTrack';
    let title2 = (this.reportsName == 'Summary' ?'MIS':this.reportsName) + ' Report';
    let deviceName = '';
   
    const pdfTable = this.pdfTable.nativeElement;
    let html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize:true
    });

    //const documentDefinition = { content: html };
    let contentOfTable:any[] = [];
    this.tableForPdfContent.forEach(ele => {
      contentOfTable.push(ele);
    });

    const documentDefinition =
    {
      content: [
        {
          columns: [
            {
              text: 'Roster',
              width: 100,
              margin: [0, 0, 0, 10]         
            },
            {
              width: '*',
              text: title2,
              style: 'title2',
              alignment: 'right'
            }
          ]
        },
        {
          columns: [
            {
              text: '',
              // style: 'tableExample',
              // table: {
              //   body: [
              //     [
              //       {
              //         text: `Asset Name: ${deviceName}`,
              //         bold: true,
              //       },
              //       {
              //         text: 'Group: NA',
              //         bold: true
              //       }
              //     ],
              //     [
              //       {
              //         text: [
              //           {text: `Report Period: ` , style: 'fontBold'},
              //           `${moment(startdate).format('DD/MMM/yyyy hh:mm:ss A')} - ${moment(enddate).format('DD/MMM/yyyy hh:mm:ss A')}`,
              //         ],
              //         colSpan: 2
              //       }
              //     ],
              //     [
              //       {
              //         text: [
              //           { text: `Report Run Time: `, style: 'fontBold'},
              //         `${moment().format('DD/MMM/yyyy hh:mm:ss A')}`,
              //         ],
              //         colSpan: 2
              //       }
              //     ]
              //   ]
              // },
              width: 420
            },
            {
              style: 'tableExample',
              table: {
                body: [
                  [
                    {
                      text: `Print By: ${this.userData.name}`,
                      bold: true,
                      style: 'printby'
                    }
                  ],
                  [
                    {
                      text: `Remarks: - NA`,
                      bold: true,
                      style: 'remarks'
                    }
                  ]
                ]
              },
              width: '*'
            }
          ]
        },
        {
          style: 'tablecss',
          table: {
            body: [
              [...this.tableForPdfColumns],
              ...contentOfTable
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex == 0) ? '#CCCCCC' : null;
            }
          }
        }
      ],
      styles: {
        tablecss: {
          fontSize: fontSizeTable
        },
        tableStyle: {

        },
        testing: {
          bold: true,
          fontSize: 40
        },
        title1: {
          margin: [0, 0, 0, 10],
          fontSize: 10
        },
        title2: {

        },
        tableExample: {
          border: 0,
          margin: [0, 0, 5, 10],
          fontSize: 10
        },
        printby: {
          fontSize: 10,
        },
        remarks: {
          fontSize: 10,
        },
        fontBold: {
          bold: true
        }
      }
    };

    pdfMake.createPdf(documentDefinition).open();


    // const documentDefinition =
    // {
    //   content: [
    //     { text: 'Heading test', style: 'testing' },
    //     htmlToPdfmake(pdfTable.innerHTML)
    //   ],
    //   styles: {
    //     testing: {
    //       bold: true,
    //       fontSize: 50
    //     }
    //   }
    // };

    // this.htmlString = '';
  }

//   downloadAsPDFForReference() {
//     const docDefinition = {
//         content: [
//             { text: 'Task Status Report', style: 'header', alignment: 'center' },
//             { text: 'Task Id : 5365', style: 'taskIdLabel', alignment: 'start', margin: [0, 20] },
//             {
//                 table: {
//                     headerRows: 1,
//                     widths: ['*', '*', '*'],
//                     body: [
//                         ['Customer Name', 'Email', 'Phone Number'],
//                         ['TIAA', '-', '+91 77700 12919'],
//                         ['Start Before', 'Completed Before', 'Driver'],
//                         ['08 Feb 2024 14:00', '08 Feb 2024 15:30', 'PRF 534'],
//                         ['Status', 'Address', ''],
//                         ['Completed', 'Tiaa, Tiaa India, Eon Free Zone, Kharadi, Pune, Maharashtra,India,Pune,,,India', ''],
//                     ],
//                     bodyStyles: { noBorders: { border: 'none' } }, // Apply custom style to remove borders
//                     headerStyles: { noBorders: { border: 'none' } }// Remove internal borders
//                 }
//             }
//         ],
//         styles: {
//             header: { fontSize: 20, bold: true, color: '#1592ce', alignment: 'justify' },
//             taskIdLabel: { fontSize: 13, fontWeight: 600, bold: true, color: '#0000', alignment: 'justify' },
//             remarks: {
//               fontSize: 10,
//             },
//         }
//     };

//     pdfMake.createPdf(docDefinition).open();
// }
  convetHtmlToPDF(html) {

    console.log('document.body---', html);
    html2canvas(html).then((canvas) => {
      console.log('canvas---', canvas);
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('new-file.pdf'); // Generated PDF
    });
  }

  exportExcel(xlsxFileData: any[], fileName: string) {
   
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsxFileData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveExcelFile(excelBuffer, fileName);
  }

  saveExcelFile(buffer: any, fileName: string): void {
   
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  onClickReports(reponame) {
    this.fromdate = null;
    this.todate = null;
    this.fromdateP = null;
    this.todateP = null;
    this.reportsName = reponame;
    this.reportsList = [];
    this.page = 1;
    //this.dayType = 'Today';
    this.setReportsDate();
    switch (this.reportsName) {
      case 'Route':
        this.onAcCLick();
        break;
      case 'Events':
        this.onDailyCLick();
        break;
      case 'Geofence':
        this.onDailyCLickForGeofence();
        break;
      case 'Trips':
        this.onDaywiseCLick();
        break;
      case 'Stops':
        this.onDistanceCLick();
        break;
      case 'Summary':
        this.onGeofenceClick(false);
        break;
      case 'Daily Summary':
        this.onGeofenceClick(true);
        break;
      case 'Chart':
        this.showChart();
        break;
      case 'AC Report':
        this.onDailyClickForACReports();
        break;
      case 'Ignition Report':
        this.onDailyClickForIgnitionReports();
        break;
      case 'Idle Report':
        this.onIdleClick();
        break;
      case 'Ignition Report':
        this.onIgnitionClick();
        break;
      case 'Loading Unloading Report':
        this.onLoadingClick();
        break;
      case 'Over Speed Report':
        this.onOverSpeedClick();
        break;
      case 'POI Report':
        this.onPoiClick(false);
        break;
      case 'Route Violation Report':
        this.onRouteviolationClick();
        break;
      case 'SOS Report':
        this.onSosClick();
        break;
      case 'Speed Variation Report':
        this.onSpeedVariationClick();
        break;
      case 'Stoppage Report':
        this.onStoppageClick();
        break;
      case 'Summary Report':
        this.onSummaryClick();
        break;
      case 'Trip Report':
        this.onTripClick();
        break;
      case 'User Trip Report':
        this.onUsertripClick();
        break;
      case 'Value Screen Report':
        this.onValueScreenClick();
        break;
      case 'Working Hours Report':
        this.onWorkingHoursClick();
        break;

    }
  }

  onSelectVehicle(v) {
    console.log(v);
    let fd = moment(new Date(`${this.fromdateP} ${this.fromDateHours}:${this.fromDateMinutes}`));
    let ed = moment(new Date(`${this.todateP} ${this.toDateHours}:${this.toDateMinutes}`));
    console.log(ed.diff(fd, 'hour'));

    if (this.reportsName == 'Route') {
      if (ed.diff(fd, 'hour') > 48) {
        alert("Route report can be taken for 2 days.");
        return;
      }
    }
    if (ed.diff(fd, 'hour') < 0) {
      alert("To date should be greater than from date.");
      return;
    }
    if(v && v.id) {
      this.Device_ID = v.id;
    }
    this.reportsList = [];
    this.page = 1;
    console.log('this.Device_ID', this.Device_ID);
    console.log('this.reportsName', this.reportsName);
    if(this.reportsName && this.fromdate && this.todate) {
      this.IsLoading =true;
    }
    this.fromdate = new Date(`${this.fromdateP} ${this.fromDateHours}:${this.fromDateMinutes}`).toISOString();
    this.todate = new Date(`${this.todateP} ${this.toDateHours}:${this.toDateMinutes}`).toISOString();



    switch (this.reportsName) {
      case 'Route':
        this.onAcCLick();
        break;
      case 'Events':
        this.onDailyCLick();
        break;
      case 'Geofence':
        this.onDailyCLickForGeofence();
        break;
      case 'Trips':
        this.onDaywiseCLick();
        break;
      case 'Stops':
        this.onDistanceCLick();
        break;
      case 'Summary':
        this.onGeofenceClick(false);
        break;
      case 'Daily Summary':
        this.onGeofenceClick(true);
        break;
      case 'AC Report':
        this.onDailyClickForACReports();
        break;
      case 'Ignition Report':
        this.onDailyClickForIgnitionReports();
        break;
      case 'Chart':
        this.showChart();
        break;
      case 'Idle Report':
        this.onIdleClick();
        break;
      // case 'Ignition Report':
      //   this.onIgnitionClick();
      //   break;
      case 'Loading Unloading Report':
        this.onLoadingClick();
        break;
      case 'Over Speed Report':
        this.onOverSpeedClick();
        break;
      case 'POI Report':
        this.onPoiClick(true);
        break;
      case 'Route Violation Report':
        this.onRouteviolationClick();
        break;
      case 'SOS Report':
        this.onSosClick();
        break;
      case 'Speed Variation Report':
        this.onSpeedVariationClick();
        break;
      case 'Stoppage Report':
        this.onStoppageClick();
        break;
      case 'Summary Report':
        this.onSummaryClick();
        break;
      case 'Trip Report':
        this.onTripClick();
        break;
      case 'User Trip Report':
        this.onUsertripClick();
        break;
      case 'Value Screen Report':
        this.onValueScreenClick();
        break;
      case 'Working Hours Report':
        this.onWorkingHoursClick();
        break;
      case 'Attendance':
        this.onAttendanceClick();
        break;
      default:
          this.IsLoading = false;
    }
  }

  onClickGroup() {
    this.details = false;
    this.group = true;
    this.report_http
      .loadingUnloadingGroupReport(this.Device_ID)
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  onClickDetails() {
    this.group = false;
    this.details = true;
    this.Device_ID = this.DeviceArray[0].Device_ID;
    this.report_http
      .loadingTripcountReport(this.Device_ID)
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  initiateCarousel() {
    $('#startCarousel').owlCarousel({
      loop: false,
      margin: 10,
      nav: true,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 7,
        },
      },
    });
  }

  changeChartType(e) {
    console.log('---------e', e);
    if (e?.value) {
      console.log('---------e', e.value);
      setTimeout(() => {
        this.showChart();
      }, 500);
    }
  }

  showChart() {
    if (!this.Device_ID) {
      this.Device_ID =
        this.DeviceArray?.length && this.DeviceArray[0]?.id
          ? this.DeviceArray[0].id
          : null;
    }
    let params = [
      'deviceId=' + this.Device_ID,
      'from=' + this.fromdate + 'T00:00:01.477Z',
      'to=' + this.todate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' + this.pageOption,
    ];
    this.isShowChart = false;
    // if (this.fromdate && this.todate && this.Device_ID) {
    this.commonService
      .getRequest('reports/route?' + params.join('&'), [])
      .then((resp: any) => {
        this.IsLoading = false;
        if (resp?.status) {

          this.chartData = resp.data;
          let categoriesList = [];
          let seriesList = [];
          let dataSet = [];

          resp.data.forEach((d) => {
            d.speed = d.speed ? d.speed.toFixed(2):0;
            let deviceTime = moment(d.deviceTime).format('YYYY-MM-DD HH:mm');
            dataSet.push(d[this.selectChartType]);
            categoriesList.push(deviceTime);
          });
          seriesList.push({
            name:
              this.selectChartType.charAt(0).toUpperCase() +
              this.selectChartType.slice(1),
            data: dataSet,
          });

          this.chartOptions = {
            series: seriesList,
            // [
            //   {
            //     name: 'Session Duration',
            //     data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
            //   },
            //   {
            //     name: 'Page Views',
            //     data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
            //   },
            //   {
            //     name: 'Total Visits',
            //     data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47],
            //   },
            // ],
            chart: {
              height: 350,
              type: 'line',
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              width: 5,
              curve: 'straight',
              dashArray: [0, 8, 5],
            },
            title: {
              text: '',
              align: 'left',
            },
            legend: {
              tooltipHoverFormatter: function (val, opts) {
                return (
                  val +
                  ' - <strong>' +
                  opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                  '</strong>'
                );
              },
            },
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6,
              },
            },
            xaxis: {
              labels: {
                trim: false,
              },
              categories: categoriesList,
              // [
              // '01 Jan',
              // '02 Jan',
              // '03 Jan',
              // '04 Jan',
              // '05 Jan',
              // '06 Jan',
              // '07 Jan',
              // '08 Jan',
              // '09 Jan',
              // '10 Jan',
              // '11 Jan',
              // '12 Jan',
              // ],
            },
            tooltip: {
              y: [
                {
                  title: {
                    formatter: function (val) {
                      return val + ' (mins)';
                    },
                  },
                },
                {
                  title: {
                    formatter: function (val) {
                      return val + ' per session';
                    },
                  },
                },
                {
                  title: {
                    formatter: function (val) {
                      return val;
                    },
                  },
                },
              ],
            },
            grid: {
              borderColor: '#f1f1f1',
            },
          };
        }
        this.isShowChart = true;
      }).catch(e=>{
        this.IsLoading =false;
      });
    // }
  }

  // TABLE REPORT FUNCTIONS

  onAcCLick() {
    // this.reportsName = 'AC Reports';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';
    // this.Device_ID = this.DeviceArray[0].Device_ID;
    // this.report_http.acReports(this.Device_ID).subscribe((resp) => {
    //   console.log(resp);
    //   this.reportsList = resp.data ? resp.data : [];
    // });
    let deviceName = '';
    if (!this.Device_ID) {
      this.Device_ID =
        this.DeviceArray?.length && this.DeviceArray[0]?.id
          ? this.DeviceArray[0].id
          : null;
    }
    if (this.Device_ID) {
      deviceName = this.DeviceArray.filter(x => x.id == this.Device_ID)[0].name;
    }
    let params = [
      'deviceId=' + this.Device_ID,
      'from=' + this.fromdate,
      'to=' + this.todate,
      'page=1',
      'start=0',
      'limit=' + this.pageOption,
    ];

    if (this.fromdate && this.todate && this.Device_ID) {
      this.commonService
        .getRequest('reports/route?' + params.join('&'), [])
        .then((resp: any) => {
           this.IsLoading = false;
          console.log('-----------------', resp);
          this.IsLoading = false;
          if (resp?.status) {
            //this.reportsList = resp.data;
            this.reportsList = resp.data.map(obj => ({ ...obj, device_Name: deviceName }))
          }
        }).catch(e=>{
          this.IsLoading =false;
        });;
    }
  }

  onDailyCLick() {
    // this.reportsName = 'Daily Reports';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';

    if (!this.Device_ID) {
      this.Device_ID =
        this.DeviceArray?.length && this.DeviceArray[0]?.id
          ? this.DeviceArray[0].id
          : null;
    }
    let params = [
      'deviceId=' + this.Device_ID,
      'from=' + this.fromdate ,
      'to=' + this.todate,
      'page=1',
      'start=0',
      'limit=' + this.pageOption,
      'type=' + this.reportTypeFilter
    ];
    console.log('fromdate', this.fromdate);
    console.log('todate', this.todate);
    if (this.fromdate && this.todate && this.Device_ID) {
      this.commonService
        .getRequest('reports/events?' + params.join('&'), [])
        .then((resp: any) => {
          this.IsLoading = false;

          console.log('-----------------', resp);
          if (resp?.status) {
            this.reportsList = resp.data;
          }
        }).catch(e=>{
          this.IsLoading =false;
        });
    }

    // let body = {
    //   draw: 2,
    //   columns: [
    //     {
    //       data: 'Device_Name',
    //     },
    //     {
    //       data: 'distanceVariation',
    //     },
    //     {
    //       data: 'Device_ID',
    //     },
    //     {
    //       data: 'today_odo',
    //     },
    //     {
    //       data: 'today_running',
    //     },
    //     {
    //       data: 'Mileage',
    //     },
    //     {
    //       data: 'today_stopped',
    //     },
    //     {
    //       data: 'today_trips',
    //     },
    //     {
    //       data: 'maxSpeed',
    //     },
    //     {
    //       data: 't_idling',
    //     },
    //     {
    //       data: 't_noGps',
    //     },
    //     {
    //       data: 't_ofr',
    //     },
    //     {
    //       data: 'today_start_location.lat',
    //     },
    //     {
    //       data: 'today_start_location.long',
    //     },
    //     {
    //       data: 'last_location.lat',
    //     },
    //     {
    //       data: 'last_location.long',
    //     },
    //     {
    //       data: null,
    //       defaultContent: '',
    //     },
    //   ],
    //   order: [
    //     {
    //       column: 0,
    //       dir: 'asc',
    //     },
    //   ],
    //   start: 0,
    //   length: 25,
    //   search: {
    //     value: '',
    //     regex: false,
    //   },
    //   op: {},
    //   select: [],
    //   find: {
    //     user_id: this.userId,
    //     devId: {
    //       $in: [this.Device_ID],
    //     },
    //     date: this.todaysDate,
    //   },
    // };
    // this.report_http.dailyReports(body).subscribe((resp) => {
    //   console.log(resp);
    //   this.reportsList = resp.data ? resp.data : [];

    //   for (let i = 0; i < this.reportsList.length; i++) {
    //     if (this.reportsList[i].today_start_location) {
    //       let body = {
    //         lat: this.reportsList[i].today_start_location.lat,
    //         long: this.reportsList[i].today_start_location.long,
    //       };
    //       this.report_http.googleAddress(body).subscribe((resp) => {
    //         this.startLocation = resp.address;
    //         console.log(this.startLocation);
    //         this.reportsList[i].startLocation = this.startLocation;
    //         console.log(this.reportsList);
    //       });
    //     } else {
    //       this.reportsList[i].startLocation = 'NA';
    //     }
    //   }

    //   for (let i = 0; i < this.reportsList.length; i++) {
    //     if (this.reportsList[i]?.last_location) {
    //       let body = {
    //         lat: this.reportsList[i].last_location.lat,
    //         long: this.reportsList[i].last_location.long,
    //       };
    //       this.report_http.googleAddress(body).subscribe((resp) => {
    //         this.lastLocation = resp.address;
    //         console.log(this.lastLocation);
    //         this.reportsList[i].lastLocation = this.lastLocation;
    //         console.log(this.reportsList);
    //       });
    //     } else {
    //       this.reportsList[i].lastLocation = 'NA';
    //     }
    //   }
    // });
  }

  onDailyCLickForGeofence() {


    if (!this.Device_ID) {
      this.Device_ID =
        this.DeviceArray?.length && this.DeviceArray[0]?.id
          ? this.DeviceArray[0].id
          : null;
    }
    let params = [
      'deviceId=' + this.Device_ID,
      'from=' + this.fromdate ,
      'to=' + this.todate,
      'page=1',
      'start=0',
      'limit=' + this.pageOption,
      'type=geofenceEnter',
      'type=geofenceExit'
    ];
    console.log('fromdate', this.fromdate);
    console.log('todate', this.todate);
    if (this.fromdate && this.todate && this.Device_ID) {
      this.commonService
        .getRequest('reports/events?' + params.join('&'), [])
        .then((resp: any) => {
          this.IsLoading = false;

          console.log('-----------------', resp);
          if (resp?.status) {
            //this.reportsList = resp.data;
            this.reportsList = resp.data.map(obj => ({...obj, geoName: this.geofenceList.filter(x => x.id == obj.geofenceId)[0].name}))
          }
        }).catch(e=>{
          this.IsLoading =false;
        });
    }
  }

  onDailyClickForACReports() {
    if (!this.Device_ID) {
      this.Device_ID =
        this.DeviceArray?.length && this.DeviceArray[0]?.id
          ? this.DeviceArray[0].id
          : null;
    }
    let params = [
      'deviceId=' + this.Device_ID,
      'from=' + this.fromdate ,
      'to=' + this.todate,
      'page=1',
      'start=0',
      'limit=' + this.pageOption,
      'type=acOn',
      'type=acOff'
    ];
    // console.log('fromdate', this.fromdate);
    // console.log('todate', this.todate);
    if (this.fromdate && this.todate && this.Device_ID) {
      this.IsLoading = true;
      this.commonService
        .getRequest('reports/events?' + params.join('&'), [])
        .then((resp: any) => {
          this.IsLoading = false;

          console.log('-----------------', resp);
          if (resp?.status) {
            //this.reportsList = resp.data;
            this.reportsList = resp.data;
          }
        }).catch(e=>{
          this.IsLoading =false;
        });
    }
  }

  onDailyClickForIgnitionReports() {
    if (!this.Device_ID) {
      this.Device_ID =
        this.DeviceArray?.length && this.DeviceArray[0]?.id
          ? this.DeviceArray[0].id
          : null;
    }
    let params = [
      'deviceId=' + this.Device_ID,
      'from=' + this.fromdate ,
      'to=' + this.todate,
      'page=1',
      'start=0',
      'limit=' + this.pageOption,
      'type=ignitionOn',
      'type=ignitionOff'
    ];
    // console.log('fromdate', this.fromdate);
    // console.log('todate', this.todate);
    if (this.fromdate && this.todate && this.Device_ID) {
      this.IsLoading = true;
      this.commonService
        .getRequest('reports/events?' + params.join('&'), [])
        .then((resp: any) => {
          this.IsLoading = false;

          console.log('-----------------', resp);
          if (resp?.status) {
            //this.reportsList = resp.data;
            this.reportsList = resp.data;
          }
        }).catch(e=>{
          this.IsLoading =false;
        });
    }
  }

  onDaywiseCLick() {
    const today = new Date();
    const formattedToday = today.toISOString().slice(0, 10) + 'T00:00:00Z';
    const formattedToday1 = today.toISOString().slice(0, 10) + 'T23:59:59Z';
    if(!this.isSupervisor){
       this.commonService.getTasks(`traccartasks/orgId/${this.orgId}`,'', '', formattedToday, formattedToday1, '','')
      .subscribe((response) => {
        this.reportsList = response;
      }, (error) => {    
        console.error(error);
      });
    }
    else{
      this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','','','','','')
      .subscribe((response) => {
       this.reportsList = response;
      }, (error) => {
        console.error(error);
      });
    }
  }

  onDistanceCLick() {
    // this.reportsName = 'Distance Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';

    if (!this.Device_ID) {
      this.Device_ID =
        this.DeviceArray?.length && this.DeviceArray[0]?.id
          ? this.DeviceArray[0].id
          : null;
    }
    let params = [
      'deviceId=' + this.Device_ID,
      'from=' + this.fromdate ,
      'to=' + this.todate,
      'page=1',
      'start=0',
      'limit=' + this.pageOption,
    ];
    console.log('fromdate', this.fromdate);
    console.log('todate', this.todate);
    if (this.fromdate && this.todate && this.Device_ID) {
      this.commonService
        .getRequest('reports/stops?' + params.join('&'), [])
        .then((resp: any) => {
          console.log('-----------------', resp);
          this.IsLoading = false;
          if (resp?.status) {
            this.reportsList = resp.data;
          }
        }).finally(()=>{
          this.IsLoading =false;
        });
    }

    // this.DeviceDefaultId = this.DeviceArray.find(
    //   (x) => x.Device_ID === this.Device_ID
    // )._id;
    // this.report_http.distanceReport(this.DeviceDefaultId).subscribe((resp) => {
    //   console.log(resp);
    //   this.reportsList = resp;

    //   for (let i = 0; i < this.reportsList.length; i++) {
    //     if (this.reportsList[i].startLat) {
    //       let body = {
    //         lat: this.reportsList[i].startLat,
    //         long: this.reportsList[i].startLng,
    //       };
    //       this.report_http.googleAddress(body).subscribe((resp) => {
    //         this.startLocation = resp.address;
    //         this.reportsList[i].startLocation = this.startLocation;
    //         this.DeviceNameList[i] = this.reportsList[i].device.Device_Name;
    //         console.log(this.DeviceNameList);
    //       });
    //     } else {
    //       this.reportsList[i].startLocation = 'NA';
    //     }
    //   }

    //   for (let i = 0; i < this.reportsList.length; i++) {
    //     if (this.reportsList[i]?.endLat) {
    //       let body = {
    //         lat: this.reportsList[i].endLat,
    //         long: this.reportsList[i].endLng,
    //       };
    //       this.report_http.googleAddress(body).subscribe((resp) => {
    //         this.lastLocation = resp.address;
    //         this.reportsList[i].LastLocation = this.lastLocation;
    //       });
    //     } else {
    //       this.reportsList[i].LastLocation = 'NA';
    //     }
    //   }
    // });
  }
  onAttendanceClick() {
    let params = [
      'from=' + this.fromdate ,
      'to=' + this.todate
    ];
    // let params = [
    //   'from=' + this.fromdate ,
    //   'to=' + this.todate ,
    //   'page=1',
    //   'start=0',
    //   'limit=' + this.pageOption
    // ];
    if (this.myControl.value && this.myControl.value.id) {      
        this.Device_ID = this.myControl.value.id;
        params.push('userId=' + this.Device_ID);
    }
    if (this.myControl.value && this.myControl.value.length > 0) {
      this.myControl.value.forEach(element => {
        params.push('userId=' + element.id);
      });
    }
    console.log('fromdate', this.fromdate);
    console.log('todate', this.todate);
    //if (this.fromdate && this.todate && (this.Device_ID || (this.myControl.value && this.myControl.value.length > 0))) {
      if (this.fromdate && this.todate) {
      this.commonService
        .getRequest('attendance?' + params.join('&'), [])
        .then((resp: any) => {
          this.IsLoading = false;
          if (resp?.status) {
            this.reportsList = resp.data;
          }
        });
    }
  }
  onGeofenceClick(daily = false) {
    // this.reportsName = 'Geofence Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    //document.getElementById('nav_pills').style.width = '42%';

    // if (!this.Device_ID) {
    //   this.Device_ID =
    //     this.DeviceArray?.length && this.DeviceArray[0]?.id
    //       ? this.DeviceArray[0].id
    //       : null;
    // }
    let params = [
      'from=' + this.fromdate ,
      'to=' + this.todate ,
      'page=1',
      'start=0',
      'limit=' + this.pageOption,
      'daily=' + daily,
    ];
    if (this.myControl.value && this.myControl.value.id) {
      // this.Device_ID =
      //   this.DeviceArray?.length && this.DeviceArray[0]?.id
      //     ? this.DeviceArray[0].id
      //     : null;
        this.Device_ID = this.myControl.value.id;
        params.push('deviceId=' + this.Device_ID);
    }
    if (this.myControl.value && this.myControl.value.length > 0) {
      this.myControl.value.forEach(element => {
        params.push('deviceId=' + element.id);
      });
    }
    console.log('fromdate', this.fromdate);
    console.log('todate', this.todate);
    if (this.fromdate && this.todate && (this.Device_ID || (this.myControl.value && this.myControl.value.length > 0))) {
      this.commonService
        .getRequest('reports/summary?' + params.join('&'), [])
        .then((resp: any) => {
          this.IsLoading = false;
          if (resp?.status) {
            this.reportsList = resp.data;
          }
        });
    }

    // this.report_http.getgeofence().subscribe((resp) => {
    //   console.log(resp);
    //   this.geofenceList = resp;
    //   this._id = this.geofenceList[0]._id;
    //   this.onGeofenceReport();
    // });
  }

  onGeofenceReport() {
    this.report_http.geofenceReport(this._id).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp;

      for (let i = 0; i < this.reportsList.length; i++) {
        if (this.reportsList[i].start_location) {
          let body = {
            lat: this.reportsList[i].start_location.lat,
            long: this.reportsList[i].start_location.long,
          };
          this.report_http.googleAddress(body).subscribe((resp) => {
            this.startLocation = resp.address;
            console.log(this.startLocation);
            this.reportsList[i].Location = this.startLocation;
            console.log(this.reportsList);
          });
        } else {
          this.reportsList[i].Location = 'NA';
        }
      }
    });
  }

  onSelectgeofence(geoid) {
    this._id = geoid;
    this.onGeofenceReport();
  }

  onIdleClick() {
    this.reportsName = 'Idle Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';
    this.Device_ID = this.DeviceArray[0].Device_ID;
    this.report_http.idleReport(this.Device_ID).subscribe((resp) => {
      console.log(resp);
    });
  }

  onIgnitionClick() {
    this.reportsName = 'Ignition Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';

    this.report_http.ignitionReport(this.Device_ID).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp;
    });
  }

  onLoadingClick() {
    this.reportsName = 'Loading Unloading Report';
    // document.getElementById('refresh_downloadBtn').style.width = '42vh';
    // document.getElementById('nav_pills').style.width = '42%';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'space-between';
    this.report_http
      .loadingUnloadingGroupReport(this.Device_ID)
      .subscribe((resp) => {
        console.log(resp);
        this.reportsList = resp;
      });
  }

  onOverSpeedClick() {
    this.reportsName = 'Over Speed Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';

    this.report_http.overSpeedReport(this.Device_ID).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp;

      for (let i = 0; i < this.reportsList.length; i++) {
        if (this.reportsList[i].lat) {
          let body = {
            lat: this.reportsList[i].lat,
            long: this.reportsList[i].long,
          };
          this.report_http.googleAddress(body).subscribe((resp) => {
            this.startLocation = resp.address;
            console.log(this.startLocation);
            this.reportsList[i].Location = this.startLocation;
            console.log(this.reportsList);
          });
        } else {
          this.reportsList[i].Location = 'NA';
        }
      }
    });
  }

  onPoiClick(devbool) {
    this.reportsName = 'POI Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '55%';
    this.report_http.poiReport().subscribe((resp) => {
      console.log(resp);
      this.poiList = resp;
      this.poiId = this.poiList[0]._id;
      this.getPoiDataTable(devbool);
    });
  }

  onSelectPoi(poiname) {
    this.poiId = poiname;
    this.getPoiDataTable(true);
  }

  getPoiDataTable(device) {
    this.DeviceDefaultId = this.DeviceArray.find(
      (x) => x.Device_ID === this.Device_ID
    )._id;
    let body = {
      draw: 2,
      columns: [
        {
          data: '_id',
        },
        {
          data: 'device.Device_Name',
        },
        {
          data: 'device.Device_ID',
        },
        {
          data: 'address',
        },
        {
          data: 'arrivalTime',
        },
        {
          data: 'departureTime',
        },
        {
          data: 'poi.poi.poiname',
        },
        {
          data: 'poi.poi.address',
        },
      ],
      order: [
        {
          column: 0,
          dir: 'asc',
        },
      ],
      start: 0,
      length: 25,
      search: {
        value: '',
        regex: false,
      },
      op: {},
      select: [],
      find: {
        $or: [
          {
            $and: [
              {
                arrivalTime: {
                  $gte: this.yesterdayDate,
                },
              },
              {
                arrivalTime: {
                  $lte: this.todaysDate,
                },
              },
            ],
          },
          {
            $and: [
              {
                departureTime: {
                  $gte: this.yesterdayDate,
                },
              },
              {
                departureTime: {
                  $lte: this.todaysDate,
                },
              },
            ],
          },
        ],
        device: this.DeviceDefaultId,
        poi: this.poiId,
        user: this.userId,
      },
    };
    if (!device) {
      delete body.find.device;
      delete body.find.poi;
      console.log(body);
    }
    this.report_http.poidatatable(body).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp.data;
    });
  }

  onRouteviolationClick() {
    this.reportsName = 'Route Violation Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';

    this.report_http.trackRouteUser().subscribe((resp) => {
      console.log(resp);
      this.userTrackRoutesList = resp;
      this.routeId = this.userTrackRoutesList[0]._id;
      this.getRouteViolationData();
    });
  }

  onSelectRoute(routeid) {
    this.routeId = routeid;
    this.getRouteViolationData();
  }

  getRouteViolationData() {
    this.report_http.routeviolationReport(this.routeId).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp;

      for (let i = 0; i < this.reportsList.length; i++) {
        if (this.reportsList[i].lat) {
          let body = {
            lat: this.reportsList[i].lat,
            long: this.reportsList[i].long,
          };
          this.report_http.googleAddress(body).subscribe((resp) => {
            this.startLocation = resp.address;
            this.reportsList[i].Location = this.startLocation;
          });
        } else {
          this.reportsList[i].Location = 'NA';
        }
      }
    });
  }

  onSosClick() {
    this.reportsName = 'SOS Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';

    this.report_http.sosReport(this.Device_ID).subscribe((resp) => {
      console.log(resp);
    });
  }

  onSpeedVariationClick() {
    this.reportsName = 'Speed Variation Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';

    this.report_http.speedVariationReport(this.Device_ID).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp;
    });
  }

  onStoppageClick() {
    this.reportsName = 'Stoppage Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';
    this.DeviceDefaultId = this.DeviceArray.find(
      (x) => x.Device_ID === this.Device_ID
    )._id;
    this.report_http.stoppageReport(this.DeviceDefaultId).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp;

      for (let i = 0; i < this.reportsList.length; i++) {
        let arrival_time = new Date(
          this.reportsList[i].arrival_time
        ).toLocaleString();
        let departure_time = new Date(
          this.reportsList[i].departure_time
        ).toLocaleString();
        var fd = new Date(arrival_time).getTime();
        var td = new Date(departure_time).getTime();
        var time_difference = td - fd;
        var total_min = time_difference / 60000;
        var hours = total_min / 60;
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        this.reportsList[i].duration =
          rhours + ' ' + 'hrs' + ' ' + rminutes + 'min';
      }
      console.log(this.reportsList);
    });
  }

  onSummaryClick() {
    this.reportsName = 'Summary Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';

    this.report_http.summaryReport(this.Device_ID).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp;

      for (let i = 0; i < this.reportsList.length; i++) {
        if (this.reportsList[i].start_location) {
          let body = {
            lat: this.reportsList[i].start_location.lat,
            long: this.reportsList[i].start_location.long,
          };
          this.report_http.googleAddress(body).subscribe((resp) => {
            this.startLocation = resp.address;
            console.log(this.startLocation);
            this.reportsList[i].startLocation = this.startLocation;
            console.log(this.reportsList);
          });
        } else {
          this.reportsList[i].startLocation = 'NA';
        }
      }

      for (let i = 0; i < this.reportsList.length; i++) {
        if (this.reportsList[i]?.end_location) {
          let body = {
            lat: this.reportsList[i].end_location.lat,
            long: this.reportsList[i].end_location.long,
          };
          this.report_http.googleAddress(body).subscribe((resp) => {
            this.lastLocation = resp.address;
            console.log(this.lastLocation);
            this.reportsList[i].lastLocation = this.lastLocation;
            console.log(this.reportsList);
          });
        } else {
          this.reportsList[i].lastLocation = 'NA';
        }
      }
    });
  }

  onTripClick() {
    this.reportsName = 'Trip Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';
    this.report_http.tripReport().subscribe((resp) => {
      console.log(resp);
    });
  }

  onUsertripClick() {
    this.reportsName = 'User Trip Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';
    this.DeviceDefaultId = this.DeviceArray.find(
      (x) => x.Device_ID === this.Device_ID
    )._id;
    this.report_http.usertripReport(this.DeviceDefaultId).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp;
    });
  }

  onValueScreenClick() {
    this.reportsName = 'Value Screen Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';
    this.Device_ID = this.DeviceArray[0].Device_ID;
    this.report_http.valueScreenReport(this.Device_ID).subscribe((resp) => {
      console.log(resp);
    });
  }

  onWorkingHoursClick() {
    this.reportsName = 'Working Hours Report';
    // document.getElementById('refresh_downloadBtn').style.justifyContent =
    //   'right';
    // document.getElementById('nav_pills').style.width = '42%';

    this.report_http.workinghoursReport(this.Device_ID).subscribe((resp) => {
      console.log(resp);
      this.reportsList = resp;
    });
  }
  getDeviceAddress(latitude,longitude,data ={},key='') {
    if(this.addressBylatLong[latitude+'_'+longitude]) {
      data[key] = this.addressBylatLong[latitude+'_'+longitude];
    }
    else if(!(latitude && longitude && this.addressBylatLong[latitude+'_'+longitude])){
      let params = [
        'latitude=' + longitude,
        'longitude=' + latitude,
        'maxDistance=1000'
      ];
      if (latitude && longitude) {
        this.commonService
        .getRequest('statistics/getAddress?' + params.join('&'), [])
        .then((resp: any) => {
          if(resp.data && resp.data.address){
            this.addressBylatLong[latitude+'_'+longitude] = resp.data.address;
            data[key] = resp.data.address;

          }
        }).finally(()=>{

        });
    }
    }



  }
  getAndSetAPIData(data){
    data.apiCall = true;
    if(this.reportsName == 'Route' || this.reportsName == 'Stops'){
      this.getDeviceAddress(data.latitude,data.longitude);
    } else  if(this.reportsName == 'Trips'){
      this.getDeviceAddress(data.startLat,data.startLon,data,'startAdd');
      this.getDeviceAddress(data.endLat,data.endLon,data,'endAdd');
    }

  }
  pageChange(page){
    this.page = page;
  }
  onReportTypeChange() {
    this.onClickReports(this.reportsName);
  }
  // END TABLE REPORT FUNCTIONS

  onSpeedgenerateBasedOnuserPreference(speed: any) {
    if (this.userData) {
      let s = 0;
      switch(this.userData.attributes.speedUnit) {
        case "kmh":
        case "kmph":
          s = speed * 1.852;
          break;
        case "kn":
          s = speed;
          break;
        case "mph":
          s = speed * 1.150;
          break;
      }
      return s;
    }
    return speed;
  }
  setReportsDate() {
    let now = moment();
    switch (this.dayType) {
        case 'Today':
            this.fromdateP = now.startOf('day').format("yyyy-MM-DD");
            this.fromDateHours = '00';
            this.fromDateMinutes = '00';
            //alert(this.fromdateP);
            this.todateP = now.endOf('day').format("yyyy-MM-DD");
            this.toDateHours = '23';
            this.toDateMinutes = '59';
          break;
        case 'Yesterday':
          this.fromdateP = now.subtract(1, 'day').startOf('day').format("yyyy-MM-DD");
          this.fromDateHours = '00';
          this.fromDateMinutes = '00';
          this.todateP = now.endOf('day').format("yyyy-MM-DD");
          this.toDateHours = '23';
          this.toDateMinutes = '59';
          break;
        case 'Week':
          this.fromdateP = now.startOf('week').format("yyyy-MM-DD");
          this.fromDateHours = '00';
          this.fromDateMinutes = '00';
          this.todateP = now.endOf('week').format("yyyy-MM-DD");
          this.toDateHours = '23';
          this.toDateMinutes = '59';
          break;
        case 'Month':
          this.fromdateP = now.startOf('month').format("yyyy-MM-DD");
          this.fromDateHours = '00';
          this.fromDateMinutes = '00';
          this.todateP = now.endOf('month').format("yyyy-MM-DD");
          this.toDateHours = '23';
          this.toDateMinutes = '59';
          break;
        case 'Last Month':
          this.fromdateP = now.subtract(1, 'month').startOf('month').format("yyyy-MM-DD");
          this.fromDateHours = '00';
          this.fromDateMinutes = '00';
          this.todateP = now.endOf('month').format("yyyy-MM-DD");
          this.toDateHours = '23';
          this.toDateMinutes = '59';
          break;
        case 'Custom':
          if (!this.fromdateP) {
            this.fromdateP = now.startOf('day').format("yyyy-MM-DD");
            this.fromDateHours = '00';
            this.fromDateMinutes = '00';
            this.todateP = now.endOf('day').format("yyyy-MM-DD");
            this.toDateHours = '23';
            this.toDateMinutes = '59';
          }
          break;
        default:
          break;
    }
  }

  getAllGeofenceList() {
    this.commonService
      .getRequest('geofences?all=true', [])
      .then((resp: any) => {
        if (resp?.status) {
          this.geofenceList = resp.data;
        }
      });
  }

  getAllTypesForFilter() {
    this.commonService.getRequest('notifications/types', []).then((resp: any) => {
      if (resp?.status) {
        this.typeFilterData = resp.data;
      }
    });
  }
  playSummary(val: any) {
    this.commonService.commonModel.next(false);
    // this.commonService.reportsParameterForHistory.next({
    //   fromDate: moment(val.startTime).toDate(),
    //   toDate: moment(val.endTime).toDate(),
    //   dateType: this.getDateTypeNumber(),
    //   deviceId: val.deviceName
    // });
    // this.fromdate = new Date(`${this.fromdateP} ${this.fromDateHours}:${this.fromDateMinutes}`).toISOString();
    // this.todate = new Date(`${this.todateP} ${this.toDateHours}:${this.toDateMinutes}`).toISOString();
    // this.dayType
  }
  getDateTypeNumber() {
    if (this.dayType == "Today") {
      return '0';
    }
    else if (this.dayType == "Yesterday") {
      return '2';
    }
    else if (this.dayType == "Week") {
      return '3';
    }
    else if (this.dayType == "Month") {
      return '5';
    }
    else if (this.dayType == "Last Month") {
      return '6';
    }
    else if (this.dayType == "Custom") {
      return '2';
    }
  }
  sorting(col: any) {
    this.sortcolumnName = col;
    this.isAscending = !this.isAscending;
    this.sortdirection = this.isAscending? "asc":"desc";
    this.sortingFunction(this.sortcolumnName, this.sortdirection);
  }
  sortingFunction(column, direction) {
    if (column) {
      //this.deviceList.forEach(element => {
        this.reportsList.sort((a,b)=>{
          if(a[column] > b[column]){
            return (direction === 'desc') ? 1 : -1;
          }
          if(a[column] < b[column]){
            return (direction === 'desc') ? -1 : 1;
          }
          return 0;
        });
      //})

    } else {
      return this.reportsList;
    }
  }

  calculateDuration(tripstartime,tripendtime){
    if(tripstartime && tripendtime){
      let tripStartTime: Date = new Date(tripstartime);
      let  tripEndTime: Date = new Date(tripendtime);
      const durationInMillis = tripEndTime.getTime() - tripStartTime.getTime();
      const durationInSeconds = Math.floor(durationInMillis / 1000);
     const hours = Math.floor(durationInSeconds / 3600);
     const minutes = Math.floor((durationInSeconds % 3600) / 60);
       const seconds = durationInSeconds % 60;
       let durationString = '';

       if (hours > 0) {
         durationString += `${hours}hr`;
       }
     
       if (minutes >= 0) {
         if (durationString.length > 0) {
           durationString += ':';
         }
         durationString += `${minutes}min`;
       }
     
       return durationString;
    }
    return 'N/A';
  
   }
   exportToExcel(): void {
    // Define custom headers
    const headers = ['Task Id', 'Company Name', 'Task Type', 'Address', 'Status', 'Driver Name', 'Cab No', 'Created On', 'Actual Start Time', 'Actual End Time', 'Distance', 'Duration', 'Shift Timing', 'Remark', 'Expected Start Time', 'Expected End Time'];

    // Map data to match custom headers and format the date
    const mappedData = this.reportsList.map(item => {
      let address = '';
      if (item.type === 'pickup') {
        address = item.pickuplocation;
      } else if (item.type === 'drop') {
        address = item.droplocation;
      }

      return {
        'Task Id': item.id,
        'Company Name': item.attributes.clientName,
        'Task Type': this.titlecase.transform(item.type),
        'Address': address,
        'Status': this.titlecase.transform(item.status),
        'Driver Name': item.attributes.driverName,
        'Cab No': item.attributes?.cabno,
        'Created On': this.datePipe.transform(item.date, 'dd/MM/yyyy hh:mm a'),
        'Actual Start Time': this.datePipe.transform(item.actualstarttime, 'dd/MM/yyyy hh:mm a'),
        'Actual End Time': this.datePipe.transform(item.actualendtime, 'dd/MM/yyyy hh:mm a'),
       'Distance':`${this.metertokm.transform(item.gpsDistance)} km`,
        'Duration':this.duration.transform(item.duration),
        'Shift Timing': item.attributes.Shift_Timing,
        'Remark': item.attributes.remarks? this.titlecase.transform(item.attributes?.remarks) : '',
        'Expected Start Time': this.datePipe.transform(item.expectedstarttime, 'dd/MM/yyyy hh:mm a'),
        'Expected End Time': this.datePipe.transform(item.expectedendtime, 'dd/MM/yyyy hh:mm a')
      };
    });

    // Convert data to Excel format
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    // Generate Excel file and trigger download
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFileDummy(excelBuffer, 'Tasks');
  }

  saveExcelFileDummy(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
