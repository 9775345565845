




<div class="modal-header">
    <h4 class="modal-title">Filter Task</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeForm()"
    ></button>
  </div>
  <div class="modal-body">
      <div class="row">
          <div class="col-md-4">
              <label for="dp">from Date</label>
              <input type="text" class="form-control" id="dp" placeholder="Choose a date" bsDatepicker
              [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
              placeholder="dd/mm/yyyy"
              [(ngModel)]="this.fromDate"
              autocomplete="off">
          </div>
          <div class="col-md-4">
              <label for="td">To Date</label>
              <input type="text" class="form-control"  
              id="td" 
               bsDatepicker
               [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
               placeholder="dd/mm/yyyy"
               [(ngModel)]="this.toDate"
               autocomplete="off">
          </div>
          <div class="col-md-4" *ngIf="!isSupervisor">
              <label for="td">Select Customer</label>
              <div class="input-group">
                  <select class="form-select taskspanel1" [(ngModel)]="searchClients">
                      <option selected value="-1">Select</option>
                      <option value="{{c.email}}" *ngFor="let c of customerList">{{c.name}}</option>
                  </select>
              </div>
          </div>
          <div class="col-md-4" *ngIf="isSupervisor">
              <label for="td">Select Customer</label>
              <div class="input-group">
                  <select class="form-select taskspanel1" [(ngModel)]="searchClients">
                      <option selected value="-1">Select</option>
                      <option value="{{c.clientId}}" *ngFor="let c of customerList">{{c.clientName}}</option>
                  </select>
              </div>
          </div>
      </div>
   
  </div>
  <!-- Modal -->
  <div class="modal-footer">
      <button type="button" class="btn btn-primary me-3" (click)="convertedDates()">Submit</button>
      <button type="button" class="btn btn-secondary" (click)="closeForm()">Cancel</button>
    </div>
