
import { AfterViewInit, Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-show-location-popup',
  templateUrl: './show-location-popup.component.html',
  styleUrls: ['./show-location-popup.component.css']
})
export class ShowLocationPopupComponent implements OnInit {

  injectedData: any;
  startLat:any;
  startLon:any;
drivrLocation:any='';

  private map: google.maps.Map | undefined;
  constructor(public dialogRef: MatDialogRef<ShowLocationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private commonService:CommonService, private ngZone: NgZone) {
      this.injectedData = data;
     }


  ngOnInit(): void {
    if(this.injectedData.status === 'assigned'){
      this.startLat=this.injectedData.attributes.latitude;
    this.startLon=this.injectedData.attributes.longitude;
    }
    else if(this.injectedData.status === 'unassigned'){
      this.startLat=this.injectedData.attributes.latitude;
    this.startLon=this.injectedData.attributes.longitude;
    }
    else if(this.injectedData.status === 'acknowledged'){
      this.startLat=this.injectedData.attributes.acceptlatitudelocation;
    this.startLon=this.injectedData.attributes.acceptlongitudelocation;
    }
    else if(this.injectedData.status === 'started') {
      const startLocationString = this.injectedData.attributes["trip_startlocation"];
      const coordinates = startLocationString.substring(1, startLocationString.length - 1).split(',');
      const latitude = parseFloat(coordinates[0]);
      const longitude = parseFloat(coordinates[1]);
      this.startLat=latitude;
      this.startLon=longitude;
    }
    else if(this.injectedData.status === 'pickedup') {
      const startLocationString = this.injectedData.attributes["trippickup_location"];
      const coordinates = startLocationString.substring(1, startLocationString.length - 1).split(',');

      const latitude = parseFloat(coordinates[0]);
      const longitude = parseFloat(coordinates[1]);
      this.startLat=latitude;
      this.startLon=longitude;
    }
    else if(this.injectedData.status === 'completed'){
      const endLocationString = this.injectedData.attributes["trip endlocation"];
const coordinates = endLocationString.substring(1, endLocationString.length - 1).split(',');
const latitude = parseFloat(coordinates[0]);
const longitude = parseFloat(coordinates[1]);
console.log("end trip location",latitude + ''+longitude )
      this.startLat=latitude
      this.startLon=longitude
    }
    else if(this.injectedData.status === 'dropped'){
      const endLocationString = this.injectedData.attributes["trip_droplocation"];
const coordinates = endLocationString.substring(1, endLocationString.length - 1).split(',');
const latitude = parseFloat(coordinates[0]);
const longitude = parseFloat(coordinates[1]);
      this.startLat=latitude
      this.startLon=longitude
    }
 
  this.getDeviceAddress( this.startLat,this.startLon)

  }


  getDeviceAddress(latitude,longitude) {
    let params = [
           'latitude=' + longitude,
           'longitude=' + latitude,
           'maxDistance=1000'
         ]
           this.commonService.getRequest('statistics/getAddress?' + params.join('&'), [])
           .then((resp: any) => {
             if(resp.data && resp.data.address){
               console.log('address',resp.data.address)
              this.drivrLocation =resp.data.address;
              this.init();
             }
           }).finally(()=>{
            
           });
       }

  closeModal(): void {
    this.dialogRef.close();
  }
init()
{
  const destination = new google.maps.LatLng(this.startLat, this.startLon);
      const map = new google.maps.Map(document.getElementById('map'), {
        center: { lat:this.startLat, lng: this.startLon},
        zoom: 10,
      });
const marker = new google.maps.Marker({
        position: { lat: this.startLat, lng: this.startLon },
        map: map,
        title: this.drivrLocation,  
      });    
}

}
