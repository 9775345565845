





<!-- <p class="component my-1"><button (click)="openModel()">Open Model</button> {{'Dashboard'|translate}} <span class="trackcolor">/ {{'Users'|translate}}/{{currentUrl.name |translate}}</span></p> -->
<app-common-modal-popup>
<div class="" modelheadertext *ngIf="gridMode">{{currentUrl.isDealer ? 'Supervisor':'Employee'|translate}}</div>
<div class="" modelheadertext *ngIf="addMode">{{ addCustomerForm.id ? ("Edit"|translate) : ("Add"|translate) }} {{currentUrl.isDealer ? 'Supervisor':'Employee' |translate}}</div>
<div class="" modelheadertext *ngIf="viewMode">{{"View"|translate}} {{currentUrl.isDealer ? 'Supervisor':'Employee' |translate}}</div>
<div class="container-fluid" modeldata *ngIf="gridMode">
  <div class="row p-0">
    <div class="card p-0 headcard">
      <!-- HEADER WITH ADD POI BTNS -->
      <div class="bg-light d-flex align-items-center p-2">
        <div class="d-flex align-items-center justify-content-between w-100">
         
          <form [formGroup]="form">
          <div class="input-group searchvehicles bg-white">
            
            <input
            (keyup)="page =1"
            formControlName="searchValue"
            name="searchValue"
              type="text"
              class="form-control reportsbox"
              placeholder="{{'Search'|translate}}"
              
              autocomplete="off"
            />
          
            <div class="input-group-append">
              <button
                class="btn"
                type="button"
                style="padding: 1vh 1.4vh 1.1vh 1.4vh"
              >
                <img
                (click)="searchData()"
                  src="/assets/icons/searchicon.png"
                  alt="search"
                  style="width: 2.3vh"
                />
              </button>
            </div>
          </div>
        </form>
        <button
        class="d-flex align-items-center p-2 bg-white"        
        title="{{'Add'|translate}} {{currentUrl.name |translate}}"
        (click)="addUser()"
        *ngIf="this.isCompliance || isAdmin"
      >
        <span class="material-icons" style="font-size: 2.1vh">
          add
        </span>
        <!-- {{'Add'|translate}} {{currentUrl.name |translate}} -->
      </button>
        </div>         
      </div>
      <!-- DATE DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween">
          <select
            class="form-select selectimportant"
            [(ngModel)]="pageOption"
            name="pageOption"
          >
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 27vh;
          "
        >
          <div
            class="d-flex flex-row align-items-center"
            style="padding: 0.4vh 0 0 0"
          >
            <span class="addlabels">{{'Active'|translate}}</span>
            <mat-slide-toggle style="padding-left: 1vh"></mat-slide-toggle>
          </div>
          <!-- <span class="btn"
          ><img
            src="/assets/icons/report_refresh_gray.png"
            alt="refresh"
            style="width: 96%"
        /></span> -->
        <div class="flex_row_center_spacebetween" style="display: flex; align-items: center;">
    
          <app-export-data [selectedPage]="currentUrl.isDealer ? 'dealer':'customer'" [dataList]="customerList"></app-export-data>
          
          <input style="display: none" type="file" (change)="onFileUpload1($event)" #selectFile>
          <span style="font-size: 22px; color: #07c907; margin-top: 3px;"
          (click)="uploadDocuments()"
         *ngIf="!currentUrl.isDealer"
          >
            <i class="fa fa-upload cursorPointer" matTooltip="Bulk Upload"></i>
          </span>
        </div>
       
        </div>

      
      </div>
      <!-- CUSTOMER TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0">
          <thead class="bg-light text-dark">
            <tr>
              <th class="table_head_text" *ngIf="currentUrl.isDealer">{{'Name'|translate}}</th>
              <th class="table_head_text" *ngIf="!currentUrl.isDealer">{{'First Name'|translate}}</th>
              <th class="table_head_text" *ngIf="!currentUrl.isDealer">{{'Last Name'|translate}}</th>
              <th class="table_head_text" >{{'Password'|translate}}</th>
        
              <th class="table_head_text">{{'User ID'|translate}}</th>
              <th class="table_head_text">{{'Mobile'|translate}}</th>
              <!-- <th *ngIf="currentUrl.isDealer" class="table_head_text">{{'Device Limit'|translate}}</th>
              <th *ngIf="currentUrl.isDealer" class="table_head_text">{{'Available Licenses'|translate}}</th>
              <th *ngIf="currentUrl.isDealer" class="table_head_text">{{'User Limit'|translate}}</th> -->
              <th class="table_head_text" *ngIf="!currentUrl.isDealer">{{'Disabled'|translate}}</th>
              <th class="table_head_text" *ngIf="!currentUrl.isDealer">{{'Disabled Remark'|translate}}</th>
              <th class="table_head_text">{{'Actions'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="customerList.length">
            <tr *ngFor="let customer of customerList  | searchPipe : form.value.searchValue |  paginate : {
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: (customerList  | searchPipe : form.value.searchValue).length
            };let i=index">
              <td class="table_data" *ngIf="currentUrl.isDealer">
                {{ customer.name }}
              </td>
              <td class="table_data" *ngIf="!currentUrl.isDealer">
                {{ customer.attributes.fname ? (customer.attributes.fname) : customer.name}}
              </td>
              <td class="table_data" *ngIf="!currentUrl.isDealer">
                {{ customer.attributes.lname ? (customer.attributes.lname): '' }}
              </td>
              <td class="table_data"> {{customer.password}}<a *ngIf="!customer.password"><span (click)="getPassaword(customer)" class="material-icons passicon" style="cursor: pointer;"> {{customer.password ? 'visibility':'visibility_off' }} </span></a>
              </td>
            
              <td class="table_data">{{ customer.email }}</td>
              <td class="table_data">{{ customer.phone }}</td>
              <!-- <td class="table_data">
                {{ customer.administrator ? "Yes" : "No" }}
              </td> -->
              <!-- <td class="table_data">
                {{
                  !customer.administrator && customer.userLimit !== 0
                    ? "Yes"
                    : "No"
                }}
              </td>
              <td class="table_data">
                {{
                  !customer.administrator && customer.userLimit === 0
                    ? "Yes"
                    : "No"
                }}
              </td> -->
              <!-- <td *ngIf="currentUrl.isDealer">
                {{customer.deviceLimit}}
              </td>
              <td *ngIf="currentUrl.isDealer">
                {{customer.availableLicenses}}
              </td>
              <td *ngIf="currentUrl.isDealer">
                {{customer.userLimit}}
              </td> -->
              <td class="table_data" *ngIf="!currentUrl.isDealer">
                <!-- <mat-slide-toggle style="padding-left: 1vh" 
                *ngIf="isAdmin || isCompliance"
                [checked]="customer.disabled"
                (change)="onToggleDisable(customer, $event)"
                ></mat-slide-toggle>
                <span *ngIf="!(isAdmin || isCompliance)">{{ customer.disabled ? "Yes" : "No" }}</span> -->
                  <span >{{ customer.disabled ? "Yes" : "No" }}</span> 
              </td>
              <td class="table_data" *ngIf="!currentUrl.isDealer"><span *ngIf="customer.disabled">{{customer.attributes.disabledRemarks? customer.attributes.disabledRemarks : ''  }}</span></td>
              <td class="table_data d-flex">
                <div *ngIf="superAdmin && false"
                class="btn btn-light btn-sm mx-1"
                (click)="loginToUser(customer)"
                
                >
                <i class="fa fa-sign-out" aria-hidden="true"></i>

            </div>
                <div *ngIf="isAdmin || isCompliance"
                  class="btn btn-primary btn-sm mx-1"
                  (click)="edtUser(customer)"                  
                >
                  <i class="fa fa-edit" matTooltip="Edit"></i>
                </div>
                <div
                  class="btn btn-primary btn-sm mx-1"
                  (click)="viewUser(customer)"                  
                >
                  <i class="bi bi-eye-fill" matTooltip="View"></i>
                </div>
                <div *ngIf="isAdmin || isCompliance"
                  class="btn btn-danger btn-sm mx-1"
                  (click)="deleteUser(customer.id)"
                >
                  <i class="fa fa-trash" matTooltip="Delete"></i>
                </div>
                <div 
                  class="btn btn-primary btn-sm mx-1"
                  (click)="assignDevice(customer, staticBackdrop6)"
                  *ngIf="false"
                >
                  <i class="fa fa-car" matTooltip="Assign Driver"></i>
                </div>
                <div
                class="btn btn-warning btn-sm mx-1"
                (click)="userNotifications(customer, notificationPopup)"
                *ngIf="false"
                >
                <i class="fa fa-bell"></i>
            </div>
            <div *ngIf="(isAdmin || isCompliance) && customer.attributes?.role !== 'driver'"
            class="btn btn-primary btn-sm mx-1"
            (click)="assignUser(customer, userMappingPopup)"            
          >
            <i class="fa fa-user" matTooltip="Assign clients"></i>
          </div>
          <div *ngIf="isAdmin || isCompliance"
          class="btn  btn-sm mx-1 callIcon"
          (click)="callToDriverRequest(customer)"
          style="cursor:pointer;"            
         >
          <i class="fa-solid fa-phone" matTooltip="Call to driver"></i>
        </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!customerList.length">
            <tr>
              <td colspan="7">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 48vh"
                >
                  <img
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="spinner-container" *ngIf="loading">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
      <div *ngIf="customerList.length" class="flex_row_center_spacebetween"
        style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(customerList  | searchPipe : form.value.searchValue).length/pageOption | number: '1.0-0'}} {{'of'|translate}}
          {{(customerList  | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid add-form" modeldata *ngIf="addMode">
  <div class="row">
    <div class="col-12">
      <accordion [isAnimated]="true">
        <accordion-group heading="Required" [isOpen]="true">
          <div class="row">
            <!-- <div class="col-12 col-md-3" *ngIf="!currentUrl.isDealer">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Cab No'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.cabno"
                  class="form-control inputfields"                  
                />
              
              </div>
            </div> -->
            <div class="col-12 col-md-3" *ngIf="!currentUrl.isDealer">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Vehicle No'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.vehicleno"
                  class="form-control inputfields"                  
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.name">
                  {{ errors.name }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-3" *ngIf="!currentUrl.isDealer">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'First Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.name"
                  class="form-control inputfields"
                  id="name"
                  aria-describedby="name"
                />
                <span class="text-danger validation" *ngIf="errors?.name">
                  {{ errors.name }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-3" *ngIf="currentUrl.isDealer">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.name"
                  class="form-control inputfields"
                  id="name"
                  aria-describedby="name"
                />
                <span class="text-danger validation" *ngIf="errors?.name">
                  {{ errors.name }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-3" *ngIf="!currentUrl.isDealer">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Last Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.lname"
                  class="form-control inputfields"
                  id="name"
                  aria-describedby="name"
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.name">
                  {{ errors.name }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-3" *ngIf="!currentUrl.isDealer">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Email'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.email"
                  class="form-control inputfields"
                  id="identifier"
                  autocomplete="new-phone"
                  aria-describedby="identifier"
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.phone">
                  {{ errors.phone }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Mobile'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.phone"
                  class="form-control inputfields"
                  id="identifier"
                  autocomplete="new-phone"
                  aria-describedby="identifier"
                />
                <span class="text-danger validation" *ngIf="errors?.phone">
                  {{ errors.phone }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Login ID'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.email"
                  class="form-control inputfields"                  
                />
                <span class="text-danger validation" *ngIf="errors?.email">
                  {{ errors.email }}
                </span>
              </div>
            </div>
            
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Enter Password'|translate}}</label>
                <input
                  type="password"
                  [(ngModel)]="addCustomerForm.password"
                  class="form-control inputfields"
                  id="identifier"
                  autocomplete="new-password"
                  aria-describedby="identifier"
                />
                <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span>
              </div>
            </div>
            <div class="col-12" [class.col-md-4]="!currentUrl.isDealer" *ngIf="!currentUrl.isDealer && false">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'DID'|translate}}</label>
                <input                  
                  [(ngModel)]="drivId"
                  class="form-control inputfields"                  
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span> -->
              </div>
            </div>
          </div>
          
          
          
          
          
        </accordion-group>
        <accordion-group heading="Vehicle Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Vehicle Type'|translate}}</label>
                <!-- <input
                  type="text"
                  [(ngModel)]="addExtraValues.transporttype"
                  class="form-control inputfields"
                  
                /> -->

                <select [(ngModel)]="addExtraValues.transporttype" class="form-select  taskspanel1 w-100" name="name"
                (change)="getVehicleType($event)"
                >
                <!-- <option value="0" class="select_options">Select</option> -->
                  <option value="Hatchback" class="select_options">Hatchback</option>
                  <option value="Sedan" class="select_options">Sedan</option>
                  <option value="SUV" class="select_options">SUV</option>
                  <option value="WINGER" class="select_options">WINGER</option>
                  <option value="13-Seater-TT" class="select_options">13 Seater - TT</option>
                  <option value="17-Seater-TT" class="select_options">17 Seater - TT</option>
                  <option value="20-Seater-TT" class="select_options">20 Seater - TT</option>
                  <option value="27-Seater-BUS" class="select_options">27 Seater -BUS</option>
                  <option value="32-Seater-BUS" class="select_options">32 Seater -BUS</option>
                  <option value="40-Seater-BUS" class="select_options">40 Seater -BUS</option>
                  <option value="45-Seater-BUS" class="select_options">45 Seater -BUS</option>
                  <option value="50-Seater-BUS" class="select_options">50 Seater -BUS</option>
                </select>
                <!-- <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span> -->
              </div>   
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Vehicle Model'|translate}}</label>
                
                <select [(ngModel)]="addExtraValues.transportdesc" class="form-select  taskspanel1 w-100" name="name"
                (change)="getVehicleType($event)">
                <!-- <option value="0" class="select_options">Select</option> -->
                  <option value="Amaze" class="select_options">Amaze</option>
                  <option value="Aura" class="select_options">Aura</option>
                  <option value="Bolt" class="select_options">Bolt</option>
                  <option value="Celerio" class="select_options">Celerio</option>
                  <option value="Ciaz" class="select_options">Ciaz</option>
                  <option value="Electric Vehicle" class="select_options">Electric Vehicle</option>
                  <option value="Enjoy" class="select_options">Enjoy</option>
                  <option value="Ertiga" class="select_options">Ertiga</option>
                  <option value="Etios" class="select_options">Etios</option>
                  <option value="Glanza" class="select_options">Glanza</option>
                  <option value="Indica V2" class="select_options">Indica V2</option>
                  <option value="Indigo" class="select_options">Indigo</option>
                  <option value="Innova" class="select_options">Innova</option>
                  <option value="Mahindra Kuv100" class="select_options">Mahindra Kuv100</option>
                  <option value="Mahindra Xuv300" class="select_options">Mahindra Xuv300</option>
                  <option value="Swift Dzire" class="select_options">Swift Dzire</option>
                  <option value="Tata Tigor-El" class="select_options">Tata Tigor-El</option>
                  <option value="TT" class="select_options">TT</option>
                  <option value="TT-AC" class="select_options">TT-AC</option>
                  <option value="Wagon-R" class="select_options">Wagon-R</option>
                  <option value="Winger" class="select_options">Winger</option>
                  <option value="Xcent" class="select_options">Xcent</option>
                  <option value="Xylo" class="select_options">Xylo</option>
                  <option value="Zest" class="select_options">Zest</option>
                </select>
                
                
                <!-- <input
                  type="text"
                  [(ngModel)]="addExtraValues.transportdesc"
                  class="form-control inputfields"
                  
                /> -->
                <!-- <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span> -->


              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Color'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.vehiclecolor"
                  class="form-control inputfields"                  
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span> -->
              </div>
            </div>
            <!-- <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'License Plate'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.vehiclelicenseplate"
                  class="form-control inputfields"                  
                />
            
              </div>
            </div> -->
         
        
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'PVC'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.policeverificationproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'policeverificationproof')"  
                  />
                  <a *ngIf="addExtraValues?.policeverificationproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.policeverificationproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.policeverificationproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'RC'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.registrationcertificateproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'registrationcertificateproof')"  
                  />
                  <a *ngIf="addExtraValues?.registrationcertificateproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.registrationcertificateproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.registrationcertificateproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Tax Certificate'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.taxcertificateproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'taxcertificateproof')"  
                  />
                  <a *ngIf="addExtraValues?.taxcertificateproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.taxcertificateproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.taxcertificateproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Permit'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.permitproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'permitproof')"  
                  />
                  <a *ngIf="addExtraValues?.permitproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.permitproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.permitproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Insurance'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.insuranceproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'insuranceproof')"  
                  />
                  <a *ngIf="addExtraValues?.insuranceproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.insuranceproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.insuranceproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Fitness'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.fitnessproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'fitnessproof')"  
                  />
                  <a *ngIf="addExtraValues?.fitnessproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.fitnessproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.fitnessproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'PUC'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.pucproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'pucproof')"  
                  />
                  <a *ngIf="addExtraValues?.pucproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.pucproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.pucproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Permit Auth'|translate}}</label>
                <div class="input-group">
                  <input
                  type="file"
                  [(ngModel)]="addExtraValues.permitAuth"
                  class="form-control inputfields"
                  (change)="onFileUpload($event, 'permitAuth')"  
                />
                <a *ngIf="addExtraValues?.permitAuth?.length > 0" target="_blank"
                  class="d-flex align-items-center ms-2" 
                  matTooltip="{{addExtraValues.permitAuth[0].file}}" 
                  href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.permitAuth[0].id}}">
                  <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                </a>
                </div>
              </div>  
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Tax certificate Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.taxcertificateexpirydt"
                  class="form-control inputfields"
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Permit Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.permitexpirydt"
                  class="form-control inputfields"
                  
                />
               
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Insurance Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.insuranceexpirydt"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Fitness Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.fitnessexpirydt"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'PUC Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.pucexpirydt"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
           
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'PVC Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.policeverificationexpirydt"
                  class="form-control inputfields"
                 
                />
                
              </div>
              
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'RC Date'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.registrationDate"
                  class="form-control inputfields"  
                />
                
              </div>
              
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Permit Auth Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.permitAuthExpriry"
                  class="form-control inputfields"
                />
              </div>
          </div>
          </div>
        </accordion-group>
        <accordion-group heading="Employee Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Employee Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.drivername"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'DOB'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.driverdobdt"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Driving License No'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.drivinglicenseno"
                  class="form-control inputfields"
                 
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Driving License Expiry Date'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.drivinglicenseexpirydt"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Aadharcard No'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.adharcardno"
                  class="form-control inputfields"                  
                />                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Employee Photo'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.driverphotoproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'driverphotoproof')"  
                  />
                  <a *ngIf="addExtraValues?.driverphotoproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.driverphotoproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.driverphotoproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Mobile IMEI'|translate}}</label>
                <div class="input-group">
                  <input
                    type="text"
                    [(ngModel)]="addExtraValues.mobileIMEI"
                    class="form-control inputfields" 
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Address proof'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.addressproof"
                    class="form-control inputfields"  
                    (change)="onFileUpload($event, 'addressproof')"                
                  />
                  
                  <a *ngIf="addExtraValues?.addressproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.addressproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.addressproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Medical'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.medicalproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'medicalproof')"  
                  />
                  <a *ngIf="addExtraValues?.medicalproof?.length > 0" target="_blank"
                      class="d-flex align-items-center ms-2" 
                      matTooltip="{{addExtraValues.medicalproof[0].file}}" 
                      href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.medicalproof[0].id}}">
                      <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                    </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Medical Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.medicalexpirydt"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Additional doc'|translate}}</label>
                <div class="input-group">
                  <input
                    type="file"
                    [(ngModel)]="addExtraValues.extradocsproof"
                    class="form-control inputfields"
                    (change)="onFileUpload($event, 'extradocsproof')"  
                  />
                  <a *ngIf="addExtraValues?.extradocsproof?.length > 0" target="_blank"
                    class="d-flex align-items-center ms-2" 
                    matTooltip="{{addExtraValues.extradocsproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.extradocsproof[0].id}}">
                    <i class="bi bi-file-earmark-fill" style="font-size: 22px;"></i>
                  </a>
                </div>
              </div>              
            </div>

            <!-- <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Mobile IMEI'|translate}}</label>
                <div class="input-group">
                  <input
                    type="text"
                    [(ngModel)]="addExtraValues.mobileIEMI"
                    class="form-control inputfields" 
                  />
                </div>
              </div>
            </div> -->
            <div class="col-12 col-md-3">
              <div class="class form-group pb-2">
                <lable for="name">Disabled</lable>
                <div class="col-md-3 mt-2">
                  <mat-slide-toggle style="padding-left: 1vh" 
                  *ngIf="isAdmin || isCompliance"
                 [(ngModel)]="addCustomerForm.disabled"
                  ></mat-slide-toggle>
               
                  <span *ngIf="!(isAdmin || isCompliance)">{{ addCustomerForm.disabled ? "Yes" : "No" }}</span>
                </div>
             
              </div>

            </div>
            <div class="col-12 col-md-3" *ngIf="(isAdmin || isCompliance) && addCustomerForm.disabled != '' " >
              <div class="class form-group pb-2">
                <lable for="name">Disabled Remark</lable>
                <textarea
                [(ngModel)]="addExtraValues.disabledRemarks"
                class="form-control inputfields"
                rows="4"  
                id="disabledRemark"
              ></textarea>
              </div>

            </div>
            <div class="col-12 col-md-3"  *ngIf="!(isAdmin || isCompliance) && addCustomerForm.disabled != '' ">
              <div class="class form-group pb-2">
                <lable for="name">Disabled Remark</lable>
                <textarea
                [(ngModel)]="addExtraValues.disabledRemarks"
                class="form-control inputfields"
                rows="4"  
                disabled
              ></textarea>          
              </div>

            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Vendor Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Vendor Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.vendorname"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Permission Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Location Restriction'|translate}}</label>
                <select
                      class="form-select taskspanel" 
                      id="cname"  
                      (change)="getpermissionDetails($event)"
                      style="height:6vh"
                      [(ngModel)]="addExtraValues.location_restriction"
                    >
                    <option  value='0'>Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Distance Restriction'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.location_restriction_distance"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
          </div>
        </accordion-group> 
        <accordion-group heading="Package Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Company Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.companyname"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Package Type'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.packagetype"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Total trips/km'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.totaltripkm"
                  class="form-control inputfields"
                  
                />
                
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Location Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <!-- <input placeholder="search for location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #search [formControl]="searchControl"> -->
                <input
                #inputField1
                type="text"
                class="form-control inputfields"
                id="address1"
                [(ngModel)]="address"
                [formControl]="searchControl"
            
              />
              <div id="map1" style="height: 500px; width: 100%;"></div>
              </div>
              <!-- <agm-map [latitude]="latitude" #mapslocation [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom" style="height: 400px;">
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                (dragEnd)="markerDragEnd($event)"></agm-marker>
              </agm-map>               -->
            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Preferences" style="display: none;">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group pb-2">
                <label for="speed-unit inline" class="pb-2">{{'Speed Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.speedUnit" class="inputfields w-100" name="pageOption">
                  <option value="kn" class="select_options">kn</option>
                  <option value="kmh" class="select_options">km/h</option>
                  <option value="mph" class="select_options">mph</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Distance-Unit inline" class="pb-2">{{'Distance Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.distanceUnit" class="inputfields w-100" name="pageOption">
                  <option value="km" class="select_options">km</option>
                  <option value="mi" class="select_options">mi</option>
                  <option value="nmi" class="select_options">nmi</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Altitude-Unit inline" class="pb-2">{{'Altitude Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.altitudeUnit" class="inputfields w-100" name="pageOption">
                  <option value="m" class="select_options">m</option>
                  <option value="ft" class="select_options">ft</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Volume-Unit inline" class="pb-2">{{'Volume Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.volumeUnit" class="inputfields w-100" name="pageOption">
                  <option value="ltr" class="select_options">Liter</option>
                  <option value="usGal" class="select_options">U.S. Gallon</option>
                  <option value="impGal" class="select_options">imp. Gallon</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Time-Zone inline" class="pb-2">{{'Time Zone'|translate}}</label>
                <select  class="inputfields w-100" [(ngModel)]="addCustomerForm.attributes.timezone" name="pageOption">
                  <option value="{{data}}" class="select_options" *ngFor="let data of timeZoneList">{{data}}</option>
                </select>
              </div>
            </div>
           
          </div>
        </accordion-group>
        <accordion-group heading="Permissions" style="display: none;">
          <div class="row">
            <div class="col-12 col-md-6 d-none">
              <div class="form-group pb-2">
                <label for="expiration" class="pb-2">{{'Expiration'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addCustomerForm.expirationTime"
                  class="form-control inputfields w-100"
                  id="expiration"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 pb-2">
              <div class="form-group">
                <label for="limit" class="pb-2">{{'Device Limit'|translate}}</label>
                <input
                  type="number"
                  [(ngModel)]="addCustomerForm.deviceLimit"
                  [min]="-1"
                  class="form-control inputfields"
                  id="limit"
                  aria-describedby="model"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 pb-2">
              <div class="form-group">
                <label for="userLimit" class="pb-2">{{'User Limit'|translate}}</label>
                <input
                  type="number"
                  [(ngModel)]="addCustomerForm.userLimit"
                  [min]="-1"
                  class="form-control inputfields"
                  id="userLimit"
                  aria-describedby="userLimit"
                />
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.disabled"
                    (change)="checkboxEvent($event.target, 'disabled')"
                    id="disabled"
                  />
                  <label class="form-check-label" for="disabled">{{'Disabled'|translate}}</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.readonly"
                    (change)="checkboxEvent($event.target, 'readonly')"
                    id="readonly"
                  />
                  <label class="form-check-label" for="readonly">{{'Readonly'|translate}}</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.deviceReadonly"
                    (change)="checkboxEvent($event.target, 'deviceReadonly')"
                    id="deviceReadonly"
                  />
                  <label class="form-check-label" for="deviceReadonly"
                    >{{'Device Readonly'|translate}}</label
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.limitCommands"
                    (change)="checkboxEvent($event.target, 'limitCommands')"
                    id="limitCommands"
                  />
                  <label class="form-check-label" for="limitCommands"
                    >{{'Limit Commands'|translate}}</label
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.disableReports"
                    (change)="checkboxEvent($event.target, 'disableReports')"
                    id="disableReports"
                  />
                  <label class="form-check-label" for="disableReports"
                    >{{'Disable Reports'|translate}}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Attributes" style="display: none;">
          <ng-container
            *ngFor="let attribute of addCustomerForm.attributesList; index as i"
          >
            <div class="row d-flex align-items-center">
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'notificationTokens'"
              >
                <label>{{'Notification Tokens'|translate}}</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'currency'"
              >
                <label>{{'Currency'|translate}}</label>
                
                <select
                  class="form-select "
                
                  [(ngModel)]="attribute.value"
                >
                  <option value="INR" class="select_options">INR</option>
                  <option value="USD" class="select_options">USD</option>
                 
        </select>
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'unitprice'"
              >
                <label>{{'Unit Price'|translate}}</label>
                <input
                  type="{{attribute.type}}"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'managerEmailAddress'"
              >
                <label>{{'Manager Email Address'|translate}}</label>
                <input
                  type="{{attribute.type}}"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'managerContactNumber'"
              >
                <label>{{'Manager Contact Number'|translate}}</label>
                <input
                  type="{{attribute.type}}"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div class="col-1">
                <label>&nbsp;</label>
                <i
                  class="fa fa-trash text-danger"
                  (click)="deleteAttr(i)"
                ></i>
              </div>
            </div>
          </ng-container>

          <div
            class="btn btn-outline-dark w-100"
            (click)="addAttri(template)"
          >
            <i class="fa fa-plus"></i> {{'ADD'|translate}}
          </div>
        </accordion-group>
      </accordion>

      <div class="d-flex align-items pt-4 pb-2">
        <button
          mat-raised-button
          type="submit"
          class="cancelbtn"
          (click)="onCustomerSubmit()"
        >
        {{'SAVE' | translate}}
        </button>
        <button
          mat-raised-button
          type="button"
          class="cancelbtn bg-secondary ms-3"
          (click)="onCustomerCancel()"
        >
        {{'CANCEL' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid add-form" modeldata *ngIf="viewMode">
  <div class="row">
    <div class="col-12">
      <accordion [isAnimated]="true">
        <accordion-group heading="Required" [isOpen]="true">
          <div class="row">
         /
            <div class="col-12 col-md-3" *ngIf="!currentUrl.isDealer">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Vehicle No'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.vehicleno"
                  class="form-control inputfields" 
                  readonly                 
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.name">
                  {{ errors.name }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{!currentUrl.isDealer?'First Name':'Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.name"
                  class="form-control inputfields"
                  id="name"
                  aria-describedby="name"
                  readonly
                />
                <span class="text-danger validation" *ngIf="errors?.name">
                  {{ errors.name }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-3" *ngIf="!currentUrl.isDealer">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Last Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.lname"
                  class="form-control inputfields"
                  id="name"
                  aria-describedby="name"
                  readonly
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.name">
                  {{ errors.name }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-3" *ngIf="!currentUrl.isDealer">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Email'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.email"
                  class="form-control inputfields"
                  id="identifier"
                  autocomplete="new-phone"
                  aria-describedby="identifier"
                  readonly
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.phone">
                  {{ errors.phone }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Mobile'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.phone"
                  class="form-control inputfields"
                  id="identifier"
                  autocomplete="new-phone"
                  aria-describedby="identifier"
                  readonly
                />
                <span class="text-danger validation" *ngIf="errors?.phone">
                  {{ errors.phone }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Login ID'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.email"
                  class="form-control inputfields"  
                  readonly                
                />
                <span class="text-danger validation" *ngIf="errors?.email">
                  {{ errors.email }}
                </span>
              </div>
            </div>
            
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Enter Password'|translate}}</label>
                <input
                  type="password"
                  [(ngModel)]="addCustomerForm.password"
                  class="form-control inputfields"
                  id="identifier"
                  autocomplete="new-password"
                  aria-describedby="identifier"
                  readonly
                />
                <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span>
              </div>
            </div>
            <div class="col-12" [class.col-md-4]="!currentUrl.isDealer" *ngIf="!currentUrl.isDealer && false">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'DID'|translate}}</label>
                <input                  
                  [(ngModel)]="drivId"
                  class="form-control inputfields" 
                  readonly                 
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span> -->
              </div>
            </div>
          </div>
          
          
          
          
          
        </accordion-group>
        <accordion-group heading="Vehicle Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Vehicle Type'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.transporttype"
                  class="form-control inputfields"
                  readonly
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Vehicle Model'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.transportdesc"
                  class="form-control inputfields"
                  readonly
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Color'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.vehiclecolor"
                  class="form-control inputfields" 
                  readonly                 
                />
                <!-- <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'License Plate'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.vehiclelicenseplate"
                  class="form-control inputfields"  
                  readonly/>
                <!-- <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span> -->
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Police verification'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.policeverificationproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.policeverificationproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.policeverificationproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.policeverificationproof[0].id}}">
                    <div *ngIf="getFileType(addExtraValues.policeverificationproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.policeverificationproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.policeverificationproof[0]) == 'pdf' || getFileType(addExtraValues.policeverificationproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                    
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'RC'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.registrationcertificateproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>                  
                  <a *ngIf="addExtraValues?.registrationcertificateproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.registrationcertificateproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.registrationcertificateproof[0].id}}">
                    <div *ngIf="getFileType(addExtraValues.registrationcertificateproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.registrationcertificateproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.registrationcertificateproof[0]) == 'pdf' || getFileType(addExtraValues.registrationcertificateproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Tax Certificate'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.taxcertificateproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>  
                  <a *ngIf="addExtraValues?.taxcertificateproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.taxcertificateproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.taxcertificateproof[0].id}}">
                    
                    <div *ngIf="getFileType(addExtraValues.taxcertificateproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.taxcertificateproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.taxcertificateproof[0]) == 'pdf' || getFileType(addExtraValues.taxcertificateproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                    
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Permit'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.permitproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.permitproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.permitproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.permitproof[0].id}}">
                    
                    <div *ngIf="getFileType(addExtraValues.permitproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.permitproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.permitproof[0]) == 'pdf' || getFileType(addExtraValues.permitproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                    
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Insurance'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.insuranceproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.insuranceproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.insuranceproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.insuranceproof[0].id}}">
                    <div *ngIf="getFileType(addExtraValues.insuranceproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.insuranceproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.insuranceproof[0]) == 'pdf' || getFileType(addExtraValues.insuranceproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Fitness'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.fitnessproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.fitnessproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.fitnessproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.fitnessproof[0].id}}">
                    <div *ngIf="getFileType(addExtraValues.fitnessproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.fitnessproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.fitnessproof[0]) == 'pdf' || getFileType(addExtraValues.fitnessproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'PUC'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.pucproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.pucproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.pucproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.pucproof[0].id}}">
                    <div *ngIf="getFileType(addExtraValues.pucproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.pucproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.pucproof[0]) == 'pdf' || getFileType(addExtraValues.pucproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                  </a>
                </div>
              </div>
              
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Permit Auth'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.permitAuth" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.permitAuth?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.pucproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.permitAuth[0].id}}">
                    <div *ngIf="getFileType(addExtraValues.permitAuth[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.permitAuth[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.permitAuth[0]) == 'pdf' || getFileType(addExtraValues.permitAuth[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                  </a>
                </div>
              </div>
              
            </div>
       
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Tax certificate Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.taxcertificateexpirydt"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Permit Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.permitexpirydt"
                  class="form-control inputfields"
                  readonly
                />
               
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Insurance Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.insuranceexpirydt"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Fitness Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.fitnessexpirydt"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'PUC Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.pucexpirydt"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
        
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'PVC Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.policeverificationexpirydt"
                  class="form-control inputfields"
                 readonly
                />
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'RC Date'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.registrationDate"
                  class="form-control inputfields"
                  readonly
                />
              </div>
          </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Permit Auth Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.permitAuthExpriry"
                  class="form-control inputfields"
                 readonly
                />
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Employee Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <!-- <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Driver Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.drivername"
                  class="form-control inputfields"
                  readonly
                />
              </div>
            </div> -->
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'DOB'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.driverdobdt"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'DL No'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.drivinglicenseno"
                  class="form-control inputfields"
                 readonly
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'DL Expiry Date'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.drivinglicenseexpirydt"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Employee Photo'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.driverphotoproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.driverphotoproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.driverphotoproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.driverphotoproof[0].id}}">
                    <div *ngIf="getFileType(addExtraValues.driverphotoproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.driverphotoproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.driverphotoproof[0]) == 'pdf' || getFileType(addExtraValues.driverphotoproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Aadharcard No'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.adharcardno"
                  class="form-control inputfields"   
                  readonly               
                />                
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Address proof'|translate}}</label>
                <div class="input-group">
                  <!-- <input
                    type="file"
                    [(ngModel)]="addExtraValues.addressproof"
                    class="form-control inputfields"  
                    (change)="onFileUpload($event, 'addressproof')" 

                  /> -->
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.addressproof">
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.addressproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.addressproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.addressproof[0].id}}">
                    <div *ngIf="getFileType(addExtraValues.addressproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.addressproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.addressproof[0]) == 'pdf' || getFileType(addExtraValues.addressproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div>                    
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Medical'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.medicalproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.medicalproof?.length > 0" target="_blank"
                      class="d-flex align-items-center" 
                      matTooltip="{{addExtraValues.medicalproof[0].file}}" 
                      href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.medicalproof[0].id}}">

                      <div *ngIf="getFileType(addExtraValues.medicalproof[0]) == 'image'">
                        <img src="{{getFileURL(addExtraValues.medicalproof[0])}}" alt="" class="foundimage">
                      </div>
                      <div *ngIf="getFileType(addExtraValues.medicalproof[0]) == 'pdf' || getFileType(addExtraValues.medicalproof[0]) == 'other'">
                        <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                      </div> 
                      
                    </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Medical Expiry'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addExtraValues.medicalexpirydt"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Additional doc'|translate}}</label>
                <div class="input-group">
                  <div class="d-flex align-items-center justify-content-center flex-column notfoundimage" *ngIf="!addExtraValues?.extradocsproof" >
                    <i class="bi bi-camera mb-2"></i>
                    <span style="font-size: 14px;">Image not found</span>
                  </div>
                  <a *ngIf="addExtraValues?.extradocsproof?.length > 0" target="_blank"
                    class="d-flex align-items-center" 
                    matTooltip="{{addExtraValues.extradocsproof[0].file}}" 
                    href="{{envData.apiUrl}}multitrack/downloadfile/{{addExtraValues.extradocsproof[0].id}}">
                    <div *ngIf="getFileType(addExtraValues.extradocsproof[0]) == 'image'">
                      <img src="{{getFileURL(addExtraValues.extradocsproof[0])}}" alt="" class="foundimage">
                    </div>
                    <div *ngIf="getFileType(addExtraValues.extradocsproof[0]) == 'pdf' || getFileType(addExtraValues.extradocsproof[0]) == 'other'">
                      <i class="bi bi-file-earmark" style="font-size: 55px;"></i>                      
                    </div> 
                  </a>
                </div>
              </div>              
            </div>
            <div class="col-12 col-md-3" *ngIf="!(isAdmin || isCompliance)">
                <div class="col-12 col-md-3">
                  <div class="class form-group pb-2">
                    <label for="disabledStatus">Disabled</label>
                    <input
                      type="text"
                      [value]="addCustomerForm.disabled ? 'Yes' : 'No'"
                      class="form-control inputfields"
                      id="disabledStatus"
                      readonly
                    />
                  </div>
                </div>
            </div>
            <div class="col-12 col-md-3"  *ngIf="!(isAdmin || isCompliance) && addCustomerForm.disabled != '' ">
              <div class="class form-group pb-2">
                <lable for="name">Disabled Remark</lable>
                <textarea
                [(ngModel)]="addExtraValues.disabledRemarks"
                class="form-control inputfields"
                rows="4"  
                disabled
              ></textarea>          
              </div>
              </div>
          </div>
        </accordion-group>
        <accordion-group heading="Vendor Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Vendor Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.vendorname"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Package Information" *ngIf="!currentUrl.isDealer">
          <div class="row">
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Company Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.companyname"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Package Type'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.packagetype"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Total trips/km'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addExtraValues.totaltripkm"
                  class="form-control inputfields"
                  readonly
                />
                
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Preferences" style="display: none;">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group pb-2">
                <label for="speed-unit inline" class="pb-2">{{'Speed Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.speedUnit" class="inputfields w-100" name="pageOption">
                  <option value="kn" class="select_options">kn</option>
                  <option value="kmh" class="select_options">km/h</option>
                  <option value="mph" class="select_options">mph</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Distance-Unit inline" class="pb-2">{{'Distance Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.distanceUnit" class="inputfields w-100" name="pageOption">
                  <option value="km" class="select_options">km</option>
                  <option value="mi" class="select_options">mi</option>
                  <option value="nmi" class="select_options">nmi</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Altitude-Unit inline" class="pb-2">{{'Altitude Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.altitudeUnit" class="inputfields w-100" name="pageOption">
                  <option value="m" class="select_options">m</option>
                  <option value="ft" class="select_options">ft</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Volume-Unit inline" class="pb-2">{{'Volume Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.volumeUnit" class="inputfields w-100" name="pageOption">
                  <option value="ltr" class="select_options">Liter</option>
                  <option value="usGal" class="select_options">U.S. Gallon</option>
                  <option value="impGal" class="select_options">imp. Gallon</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Time-Zone inline" class="pb-2">{{'Time Zone'|translate}}</label>
                <select  class="inputfields w-100" [(ngModel)]="addCustomerForm.attributes.timezone" name="pageOption">
                  <option value="{{data}}" class="select_options" *ngFor="let data of timeZoneList">{{data}}</option>
                </select>
              </div>
            </div>
           
          </div>
        </accordion-group>
        <accordion-group heading="Permissions" style="display: none;">
          <div class="row">
            <div class="col-12 col-md-6 d-none">
              <div class="form-group pb-2">
                <label for="expiration" class="pb-2">{{'Expiration'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addCustomerForm.expirationTime"
                  class="form-control inputfields w-100"
                  id="expiration"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 pb-2">
              <div class="form-group">
                <label for="limit" class="pb-2">{{'Device Limit'|translate}}</label>
                <input
                  type="number"
                  [(ngModel)]="addCustomerForm.deviceLimit"
                  [min]="-1"
                  class="form-control inputfields"
                  id="limit"
                  aria-describedby="model"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 pb-2">
              <div class="form-group">
                <label for="userLimit" class="pb-2">{{'User Limit'|translate}}</label>
                <input
                  type="number"
                  [(ngModel)]="addCustomerForm.userLimit"
                  [min]="-1"
                  class="form-control inputfields"
                  id="userLimit"
                  aria-describedby="userLimit"
                />
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.disabled"
                    (change)="checkboxEvent($event.target, 'disabled')"
                    id="disabled"
                  />
                  <label class="form-check-label" for="disabled">{{'Disabled'|translate}}</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.readonly"
                    (change)="checkboxEvent($event.target, 'readonly')"
                    id="readonly"
                  />
                  <label class="form-check-label" for="readonly">{{'Readonly'|translate}}</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.deviceReadonly"
                    (change)="checkboxEvent($event.target, 'deviceReadonly')"
                    id="deviceReadonly"
                  />
                  <label class="form-check-label" for="deviceReadonly"
                    >{{'Device Readonly'|translate}}</label
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.limitCommands"
                    (change)="checkboxEvent($event.target, 'limitCommands')"
                    id="limitCommands"
                  />
                  <label class="form-check-label" for="limitCommands"
                    >{{'Limit Commands'|translate}}</label
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.disableReports"
                    (change)="checkboxEvent($event.target, 'disableReports')"
                    id="disableReports"
                  />
                  <label class="form-check-label" for="disableReports"
                    >{{'Disable Reports'|translate}}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Attributes" style="display: none;">
          <ng-container
            *ngFor="let attribute of addCustomerForm.attributesList; index as i"
          >
            <div class="row d-flex align-items-center">
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'notificationTokens'"
              >
                <label>{{'Notification Tokens'|translate}}</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'currency'"
              >
                <label>{{'Currency'|translate}}</label>
                
                <select
                  class="form-select "
                
                  [(ngModel)]="attribute.value"
                >
                  <option value="INR" class="select_options">INR</option>
                  <option value="USD" class="select_options">USD</option>
                 
        </select>
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'unitprice'"
              >
                <label>{{'Unit Price'|translate}}</label>
                <input
                  type="{{attribute.type}}"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'managerEmailAddress'"
              >
                <label>{{'Manager Email Address'|translate}}</label>
                <input
                  type="{{attribute.type}}"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'managerContactNumber'"
              >
                <label>{{'Manager Contact Number'|translate}}</label>
                <input
                  type="{{attribute.type}}"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div class="col-1">
                <label>&nbsp;</label>
                <i
                  class="fa fa-trash text-danger"
                  (click)="deleteAttr(i)"
                ></i>
              </div>
            </div>
          </ng-container>

          <div
            class="btn btn-outline-dark w-100"
            (click)="addAttri(template)"
          >
            <i class="fa fa-plus"></i> {{'ADD'|translate}}
          </div>
        </accordion-group>
      </accordion>
      <div class="d-flex align-items pt-4 pb-2">
        <!-- <button
          mat-raised-button
          type="submit"
          class="cancelbtn"
          (click)="onCustomerSubmit()"
        >
        {{'SAVE' | translate}}
        </button> -->
        <button
          mat-raised-button
          type="button"
          class="cancelbtn bg-secondary" [class.ms-3]="!viewMode"
          (click)="onCustomerCancel()"
        >
        {{'CANCEL' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
</app-common-modal-popup>
<!-- ADD CUSTOMERS MODAL -->

<!-- <div
  class="modal"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content" style="border: none">
      <div class="modal-header addtriphead">
        <div class="headtext">
          {{ addCustomerForm.id ? ("Edit"|translate) : ("Add"|translate) }} {{currentUrl.name |translate}}
        </div>
        <button
          type="button"
          class="btnclose"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="material-icons"> close </span>
        </button>
      </div>
      <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
        
        <accordion [isAnimated]="true">
          <accordion-group heading="Required" [isOpen]="true">
            <div class="form-group pb-2">
              <label for="name" class="pb-2">{{'Name'|translate}}</label>
              <input
                type="text"
                [(ngModel)]="addCustomerForm.name"
                class="form-control inputfields"
                id="name"
                aria-describedby="name"
              />
              <span class="text-danger validation" *ngIf="errors?.name">
                {{ errors.name }}
              </span>
            </div>
            <div class="form-group pb-2">
              <label for="name" class="pb-2">{{'User ID'|translate}}</label>
              <input
                type="text"
                [(ngModel)]="addCustomerForm.email"
                class="form-control inputfields"
                id="identifier"
                aria-describedby="identifier"
              />
              <span class="text-danger validation" *ngIf="errors?.email">
                {{ errors.email }}
              </span>
            </div>
            <div class="form-group pb-2">
              <label for="name" class="pb-2">{{'Mobile'|translate}}</label>
              <input
                type="text"
                [(ngModel)]="addCustomerForm.phone"
                class="form-control inputfields"
                id="identifier"
                aria-describedby="identifier"
              />
              <span class="text-danger validation" *ngIf="errors?.phone">
                {{ errors.phone }}
              </span>
            </div>
            <div class="form-group pb-2">
              <label for="name" class="pb-2">{{'Enter Password'|translate}}</label>
              <input
                type="password"
                [(ngModel)]="addCustomerForm.password"
                class="form-control inputfields"
                id="identifier"
                aria-describedby="identifier"
              />
              <span class="text-danger validation" *ngIf="errors?.password">
                {{ errors.password }}
              </span>
            </div>
            
          </accordion-group>
          <accordion-group heading="Preferences">            
            <div class="form-group pb-2">
              <label for="speed-unit inline" class="pb-2">{{'Speed Unit'|translate}}</label>
              <select [(ngModel)]="addCustomerForm.attributes.speedUnit" class="inputfields w-100" name="pageOption">
                <option value="kn" class="select_options">kn</option>
                <option value="kmh" class="select_options">km/h</option>
                <option value="mph" class="select_options">mph</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="Distance-Unit inline" class="pb-2">{{'Distance Unit'|translate}}</label>
              <select [(ngModel)]="addCustomerForm.attributes.distanceUnit" class="inputfields w-100" name="pageOption">
                <option value="km" class="select_options">km</option>
                <option value="mi" class="select_options">mi</option>
                <option value="nmi" class="select_options">nmi</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="Altitude-Unit inline" class="pb-2">{{'Altitude Unit'|translate}}</label>
              <select [(ngModel)]="addCustomerForm.attributes.altitudeUnit" class="inputfields w-100" name="pageOption">
                <option value="m" class="select_options">m</option>
                <option value="ft" class="select_options">ft</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="Volume-Unit inline" class="pb-2">{{'Volume Unit'|translate}}</label>
              <select [(ngModel)]="addCustomerForm.attributes.volumeUnit" class="inputfields w-100" name="pageOption">
                <option value="ltr" class="select_options">Liter</option>
                <option value="usGal" class="select_options">U.S. Gallon</option>
                <option value="impGal" class="select_options">imp. Gallon</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="Time-Zone inline" class="pb-2">{{'Time Zone'|translate}}</label>
              <select  class="inputfields w-100" [(ngModel)]="addCustomerForm.attributes.timezone" name="pageOption">
                <option value="{{data.tz}}" class="select_options" *ngFor="let data of TimezoneArray">{{data.tz}}</option>
              </select>
            </div>
          </accordion-group>
          <accordion-group heading="Permissions">
            <div class="form-group pb-2 d-none">
              <label for="expiration" class="pb-2">{{'Expiration'|translate}}</label>
              <input
                type="date"
                [(ngModel)]="addCustomerForm.expirationTime"
                class="form-control inputfields w-100"
                id="expiration"
              />
            </div>
            <div class="form-group pb-2">
              <label for="limit" class="pb-2">{{'Device Limit'|translate}}</label>
              <input
                type="number"
                [(ngModel)]="addCustomerForm.deviceLimit"
                [min]="-1"
                class="form-control inputfields"
                id="limit"
                aria-describedby="model"
              />
            </div>
            <div class="form-group pb-2">
              <label for="userLimit" class="pb-2">{{'User Limit'|translate}}</label>
              <input
                type="number"
                [(ngModel)]="addCustomerForm.userLimit"
                [min]="-1"
                class="form-control inputfields"
                id="userLimit"
                aria-describedby="userLimit"
              />
            </div>
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.disabled"
                  (change)="checkboxEvent($event.target, 'disabled')"
                  id="disabled"
                />
                <label class="form-check-label" for="disabled">{{'Disabled'|translate}}</label>
              </div>
            </div>            
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.readonly"
                  (change)="checkboxEvent($event.target, 'readonly')"
                  id="readonly"
                />
                <label class="form-check-label" for="readonly">{{'Readonly'|translate}}</label>
              </div>
            </div>
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.deviceReadonly"
                  (change)="checkboxEvent($event.target, 'deviceReadonly')"
                  id="deviceReadonly"
                />
                <label class="form-check-label" for="deviceReadonly"
                  >{{'Device Readonly'|translate}}</label
                >
              </div>
            </div>
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.limitCommands"
                  (change)="checkboxEvent($event.target, 'limitCommands')"
                  id="limitCommands"
                />
                <label class="form-check-label" for="limitCommands"
                  >{{'Limit Commands'|translate}}</label
                >
              </div>
            </div>
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.disableReports"
                  (change)="checkboxEvent($event.target, 'disableReports')"
                  id="disableReports"
                />
                <label class="form-check-label" for="disableReports"
                  >{{'Disable Reports'|translate}}</label
                >
              </div>
            </div>
          </accordion-group>
          <accordion-group heading="Attributes">
            <ng-container
              *ngFor="let attribute of addCustomerForm.attributesList; index as i"
            >
              <div class="row">
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'notificationTokens'"
                >
                  <label>{{'Notification Tokens'|translate}}</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'currency'"
                >
                  <label>{{'Currency'|translate}}</label>
                  
                  <select
                    class="form-select "
                  
                    [(ngModel)]="attribute.value"
                  >
                    <option value="INR" class="select_options">INR</option>
                    <option value="USD" class="select_options">USD</option>
                   
          </select>
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'unitprice'"
                >
                  <label>{{'Unit Price'|translate}}</label>
                  <input
                    type="{{attribute.type}}"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'managerEmailAddress'"
                >
                  <label>{{'Manager Email Address'|translate}}</label>
                  <input
                    type="{{attribute.type}}"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'managerContactNumber'"
                >
                  <label>{{'Manager Contact Number'|translate}}</label>
                  <input
                    type="{{attribute.type}}"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div class="col-1">
                  <label>&nbsp;</label>
                  <i
                    class="fa fa-trash text-danger"
                    (click)="deleteAttr(i)"
                  ></i>
                </div>
              </div>
            </ng-container>

            <div
              class="btn btn-outline-dark w-100"
              (click)="addAttri(template)"
            >
              <i class="fa fa-plus"></i> {{'ADD'|translate}}
            </div>
          </accordion-group>
        </accordion>

        <div class="flex_row_center_spacebetween pt-4 pb-2">
          <button
            mat-raised-button
            type="submit"
            class="cancelbtn"
            (click)="onCustomerSubmit()"
          >
          {{'SAVE' | translate}}
          </button>
        </div>
        
      </div>
    </div>
  </div>
</div> -->

<!-- EDIT DEALER MODAL -->

<div
  class="modal fade"
  id="staticBackdrop4"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content content3">
      <div class="modal-header" style="border-bottom: 1px solid #324151">
        <div class="card-title titlehaed1 m-0">{{'Edit Customer'|translate}}</div>
        <button
          type="button"
          class="btnclose"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="material-icons"> close </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="editCustomerForm"
          (ngSubmit)="onEditCustomerSubmit()"
        >
          <div class="form-group pb-2">
            <label for="userids" class="addlabels3 pb-2">{{'User ID'|translate}}</label>
            <input
              type="text"
              class="form-control inputfields"
              formControlName="userids"
              aria-describedby="userids"
            />
          </div>

          <div class="form-group pb-2">
            <label for="first_name" class="addlabels3 pb-2">{{'First Name'|translate}}</label>
            <input
              type="text"
              class="form-control inputfields"
              formControlName="first_name"
              aria-describedby="first_name"
            />
          </div>

          <div class="form-group pb-2">
            <label for="last_name" class="addlabels3 pb-2">{{'Last Name'|translate}}</label>
            <input
              type="text"
              class="form-control inputfields"
              formControlName="last_name"
              aria-describedby="last_name"
            />
          </div>

          <div class="form-group pb-2">
            <label for="emailid" class="addlabels3 pb-2">{{'Email ID'|translate}}</label>
            <input
              type="email"
              class="form-control inputfields"
              formControlName="emailid"
              aria-describedby="emailid"
            />
          </div>

          <div class="form-group pb-2">
            <label for="phonenumber" class="addlabels3 pb-2"
            >{{'Mobile Number'|translate}}</label>
            <input
              type="number"
              class="form-control inputfields"
              formControlName="phonenumber"
              aria-describedby="phonenumber"
            />
          </div>

          <div class="form-group pb-2">
            <label for="expdate" class="addlabels3 pb-2">{{'Expiration Date'|translate}}</label>
            <input
              type="date"
              class="form-control inputfields"
              formControlName="expdate"
              style="
                width: 100% !important;
                height: 5.6vh !important;
                border: 1px solid #324151 !important;
                flex-flow: row !important;
              "
              aria-describedby="expdate"
            />
          </div>

          <div class="form-group pb-3">
            <label for="addresss" class="addlabels3 pb-2">{{'Address'|translate}}</label>
            <input
              type="text"
              class="form-control inputfields"
              formControlName="addresss"
              aria-describedby="addresss"
            />
          </div>

          <div class="flex_row_center_spacebetween">
            <button
              mat-raised-button
              class="cancelbtn"
              style="width: 47%; padding: 0.8vh 0"
              data-bs-dismiss="modal"
            >{{'CANCEL'|translate}}</button>
            <button
              mat-raised-button
              class="cancelbtn"
              style="width: 47%; padding: 0.8vh 0"
            >
              PROCEED
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ng-template #staticBackdrop6>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Devices'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="closeDevicesModal()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form [formGroup]="modelForm" class="bg-light p-2">
      <div class="input-group searchvehicles">
      
        <input
        
        formControlName="searchValue"
        name="searchValue"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      <div
        class="col-12 form-group"
        *ngFor="let device of deviceList | searchPipe : modelForm.value.searchValue; index as i"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'device-' + i"
            [checked]="device.isSelectedDevice"
            (change)="changeEventDevices($event.target, device.id, device.mapUserId)"
          />
          <label class="form-check-label" for="device-{{ i }}">
            {{ device.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>
    

<ng-template #userMappingPopup>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Clients'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="closeUserModal()"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    <form [formGroup]="modelForm" class="bg-light p-2">
      <div class="input-group searchvehicles">
        
        <input
        
        formControlName="searchValue"
        name="searchValue"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      <div
        class="col-12 form-group"
        *ngFor="let device of usersList | searchPipe : modelForm.value.searchValue; index as i"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'device-' + i"
            [checked]="device.isSelectedUser"
            (change)="changeEventUserMapping($event.target,device.mapDealerId, device.id )"
          />
          <label class="form-check-label" for="device-{{ i }}">
            {{ device.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <label>{{'Attribute'|translate}}</label>
        <select
          class="form-control"
          [(ngModel)]="addAttr.attribute"
          (change)="changeAttrEvent($event.target)"
        >
          <option *ngFor="let attr of attrList" [value]="attr.id">
            {{ attr.name }}
          </option>
        </select>
      </div>
      <div class="col-12">
        <label>{{'Type'|translate}}</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="addAttr.type"
          [readonly]="true"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-light btn-sm" (click)="addAttrbute()">{{'ADD'|translate}}</div>
    <div class="btn btn-light btn-sm" (click)="modalRef.hide()">{{'CANCEL'|translate}}</div>
  </div>
</ng-template>

<ng-template #notificationPopup>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Notifications'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="notificationModalClose()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form [formGroup]="modelForm" class="bg-light p-2">
      <div class="input-group searchvehicles">
        
        <input
        
        formControlName="searchValue"
        name="searchValue"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      <div
        class="col-12 form-group"
        *ngFor="let notification of notificationsMasterList | searchPipe : modelForm.value.searchValue; index as i"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'notification-' + i"
            [checked]="notification.isSelectedDevice"
            (change)="
              changeEvent($event.target, notification.id, notification.mapUserId)
            "
          />
          <label class="form-check-label" for="notification-{{ i }}">
            {{ notification.type  }} ({{notification.notificators}})
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>
