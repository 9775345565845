<app-common-modal-popup>
    <div class="" modelheadertext>{{'Task Edit'|translate}}</div>
    <div class="container-fluid" modeldata>
        <form [formGroup]="tasksform" >
            <div class="row">
                <div class="col-6">
                    <div class="mb-3">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control" formControlName="name" placeholder="Name">
                    </div>                      
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" rows="1" formControlName="description"></textarea>
                    </div>
                </div>
            </div> 
            <div class="row">
                <div class="col-4">
                    <label class="form-label">Start before</label>
                    <input type="datetime-local" class="form-control" formControlName="expectedstarttime" placeholder="Start before">
                </div>
                <div class="col-4">
                    <label class="form-label">Complete before</label>
                    <input type="datetime-local" class="form-control" formControlName="expectedendtime" placeholder="Complete before">
                </div>
                <div class="col-4">
                    <label class="form-label">Team</label>
                    <input type="text" class="form-control" formControlName="team" readonly placeholder="Team">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <label class="form-label">Driver</label>
                    <select class="form-select" formControlName="driver">
                        <option value="{{d.id}}" *ngFor="let d of driversList">{{d.attributes?.did}}</option>                      
                    </select>
                </div>
                <div class="col-3">
                    <label class="form-label">Order Id</label>
                    <input type="text" class="form-control" formControlName="orderId" readonly placeholder="Team">
                </div>
                <div class="col-3">
                    <label class="form-label">Type</label>
                    <select class="form-select" formControlName="type">
                        <option value="pickup">Pickup</option>                      
                        <option value="drop">Drop</option>                                             
                    </select>
                </div>
                <div class="col-3">
                    <label class="form-label">Status</label>
                    <select class="form-select" formControlName="status" aria-label="Floating label select example">
                        <option value="unassigned">Unassigned</option>                      
                        <option value="assigned">Assigned</option>
                        <option value="acknowledge">Aknowledge</option>
                        <option value="inprogress">Started</option>
                        <option value="completed">Completed</option>                      
                      </select>
                </div>
            </div> 
            <div class="row mt-3" formGroupName="attributes">                
                <div class="col-3">
                    <label class="form-label">Planned Employees</label>
                    <input type="text" class="form-control" formControlName="planned_emp" placeholder="Planned Employees">
                </div>
                <div class="col-3">
                    <label class="form-label">Actual Employees</label>
                    <input type="text" class="form-control" formControlName="actual_emp" placeholder="Actual Employees">
                </div>
                <div class="col-3">
                    <label class="form-label">Escort</label>
                    <select class="form-select" formControlName="escort" placeholder="Escort">
                        <option value="false">No</option>                      
                        <option value="true">Yes</option>                      
                    </select>
                </div>
            </div>           
            <button type="button" class="btn btn-primary mt-4 mb-3" (click)="editTask()"> {{'Save' |translate}}</button>
            <button type="button" class="btn btn-secondary mt-4 mb-3 ms-3" (click)="onCancelClick()"> Cancel </button>
        </form>
    </div>
</app-common-modal-popup>