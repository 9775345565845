<app-common-modal-popup>
  <div class="" modelheadertext *ngIf="gridMode">
    {{ "Task List" | translate }}
  </div>
  <div class="" modelheadertext *ngIf="addMode">
    {{
      superAdmin
        ? ("Add license" | translate)
        : ("Purchase license" | translate)
    }}
  </div>
  <div class="container-fluid" modeldata *ngIf="gridMode">
    <div class="row p-0">
      <div class="card p-0 headcard">

        <div class="bg-light">
          <div class="card-header flex_row_center_spacebetween">
            <label for="userId">{{ "Customers" | translate }}</label>
            <div class="search-dealer bg-light">
              <mat-form-field class="example-full-width" appearance="fill">
                <input
                  type="text"
                  placeholder="Select Customer"
                  aria-label="Number"
                  matInput
                  [formControl]="myDealerControl"
                  [matAutocomplete]="auto"
                  (input)="onInputChange($event.target.value)"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #auto="matAutocomplete"
        
                >
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option.name"
                    (onSelectionChange)="clientSelected(option)"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="d-flex align-items-center ms-2">
              <label>{{ "Employee" | translate }}</label>
              <div class="search-dealer bg-light">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Select Employee</mat-label>
                  <input
                    type="text"
                    placeholder="Select one"
                    aria-label="Number"
                    matInput
                    [formControl]="myDriverControl"
                    [matAutocomplete]="autoDriver"
                    
                  />
                  <mat-autocomplete
                    autoActiveFirstOption
                    #autoDriver="matAutocomplete"
                  >
                    <mat-option
                      *ngFor="let option of filteredOptionsDriver | async"
                      [value]="option.display"
                      (onSelectionChange)="driverSelected(option)"
                    >
                      {{ option.display }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex align-items-center ms-1">
              <span class="to">Status</span>
              <select
                [ngStyle]="{ width: '120px' }"
                class="form-select taskspanel"
                (change)="statusChange($event)"
              >
                <option value="0" class="select_options">Select</option>
                <option value="unassigned" class="select_options">
                  Unassigned
                </option>
                <option value="assigned" class="select_options">
                  Assigned
                </option>
                <option value="started" class="select_options">Started</option>
                <option value="acknowledged" class="select_options">
                  Acknowledged
                </option>
                <option value="completed" class="select_options">
                  Completed
                </option>
              </select>
            </div>
            <div class="d-flex align-items-center ms-1">
              <span class="to">Type</span>
              <select
                [ngStyle]="{ width: '100%' }"
                class="form-select taskspanel"
                (change)="typeChange($event)"
              >
                <option value="0" class="select_options">Select</option>
                <option value="pickup" class="select_options">Pickup</option>
                <option value="drop" class="select_options">Drop</option>
              </select>
            </div>

            <div class="flex_row_center_spacebetween">
              <span class="to p-2">{{ "from" | translate }}</span>
              <input
                type="text"
                [(ngModel)]="fromdateP"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                style="height: 5.2vh; border-radius: 5px; width: 137px"
                placeholder="dd/mm/yyyy"
              />

              <span class="to p-2">{{ "to" | translate }}</span>
              <input
                type="text"
                [(ngModel)]="todateP"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                style="height: 5.2vh; border-radius: 5px; width: 137px"
                placeholder="dd/mm/yyyy"
              />
            </div>
            <select
              [ngStyle]="{ width: '100px', 'margin-left': '10px' }"
              class="form-select taskspanel"
              [(ngModel)]="dayType"
              name="dayType"
              (change)="setReportsDate()"
            >
              <option value="Today" class="select_options">Today</option>
              <option value="Yesterday" class="select_options">
                Yesterday
              </option>
              <option value="Week" class="select_options">Week</option>
              <option value="Month" class="select_options">Month</option>
              <option value="Last Month" class="select_options">
                Last Month
              </option>
              <option value="Custom" class="select_options">Custom</option>
            </select>
            <select
              style="
                width: 185px !important;
                margin-left: 10px;
                height: 5.2vh !important;
              "
              class="form-select selectimportant"
              [(ngModel)]="reportTypeFilter"
              *ngIf="reportsName == 'Events'"
            >
              <option value=""></option>
              <option value="{{ t.type }}" *ngFor="let t of typeFilterData">
                {{ t.type }}
              </option>
            </select>

            <div *ngIf="reportsName == 'Route'" class="form-control-mt-select">
              <mat-select
                placeholder="Colunmn"
                [(ngModel)]="colunmnList"
                multiple
                class="d-flex align-items-center justify-content-center flex-column"
              >
                <mat-option
                  *ngFor="let item of masterColumnList"
                  [value]="item.value"
                  >{{ item.label }}</mat-option
                >
              </mat-select>
            </div>
            <div
              class="flex-grow-1 p-2"
              id="refresh_downloadBtn"
              style="display: flex; flex-direction: row; justify-content: end"
            >
              <div style="display: flex; flex-direction: row">
                <button
                  type="button"
                  class="btn btn-info me-2"
                  (click)="filteredData()"
                >
                  Search
                </button>
                <div class="dropdown">
                  <button
                    class="btn btn-primary dropdown-toggle"
                    type="button"
                    id="dropdownMenu2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Download
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <li>
                      <button
                        type="button"
                        matTooltip="Excel Download"
                        class="btn btn-success w-100 d-flex align-items-center justify-content-center"
                        (click)="exportToExcel()"
                      >
                        <i
                          class="bi bi-file-earmark-excel"
                          style="
                            color: #fff;
                            font-size: 24px;
                            margin-right: 5px;
                          "
                        ></i>
                        Excel
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>

        <div class="card-header flex_row_center_spacebetween">
          <div class="flex_row_center_spacebetween">
            <select class="form-select selectimportant" [(ngModel)]="limit" name="pageOption" (change)="addLimit()">

              <option value="10" class="select_options">10</option>
              <option value="20" class="select_options">20</option>
              <option value="30" class="select_options">30</option>
              <option value="40" class="select_options">40</option>
              <option value="50" class="select_options">50</option>
              <option value="60" class="select_options">60</option>
            </select>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              width: 27vh;
            "
          >
          
            <span class="btn" style="cursor: pointer" (click)="download()"
              ><img
                src="/assets/icons/report_download_gray.png"
                alt="download"
                style="width: 96%"
            /></span>
          </div>
        </div>

        <div class="card-body common_scroller fixed_height p-0">
          <div class="table-responsive">
            <table id="report-table" class="table table-borderless m-0">
              <thead class="bg-light">
                <tr>
                  <th class="table_head_text">{{ "Task ID" | translate }}</th>
                  <th class="table_head_text">
                    {{ "Customer Name" | translate }}
                  </th>
                  <th class="table_head_text">{{ "Task Type" | translate }}</th>
                  <th class="table_head_text">{{ "Address" | translate }}</th>
                  <th class="table_head_text">{{ "Status" | translate }}</th>
                  <th class="table_head_text">{{ "Name" | translate }}</th>
           
                  <th class="table_head_text">
                    {{ "Created On" | translate }}
                  </th>
                  <th class="table_head_text">
                    {{ "Start Time" | translate }}
                  </th>
                  <th class="table_head_text">{{ "End Time" | translate }}</th>
                  <th class="table_head_text">{{ "Distance" | translate }}</th>
                  <th class="table_head_text">{{ "Duration" | translate }}</th>
                  <th class="table_head_text">
                    {{ "Shift Timing" | translate }}
                  </th>
                  <th class="table_head_text">{{ "Remarks" | translate }}</th>
                </tr>
              </thead>

              <tbody *ngIf="taskListData.length">
                <tr
                  *ngFor="
                    let customer of taskListData
                      | searchPipe : form.value.searchValue
                      | paginate
                        : {
                            itemsPerPage: limit,
                            currentPage: page,
                            totalItems: 
                              totalRecords 
      
                          };
                    let i = index
                  "
                >
                  <td class="table_data">
                    {{ customer.id ? customer.id : "" }}
                  </td>
                  <td class="table_data">
                    {{ customer?.attributes?.clientName }}
                  </td>
                  <td class="table_data">{{ customer.type | titlecase }}</td>
                  <td class="table_data">
                    {{
                      customer?.type === "pickup" || customer.type === "cabout"
                        ? customer.pickuplocation
                        : customer.droplocation
                    }}
                  </td>
                  <td class="table_data">
                    {{
                      customer?.status.charAt(0).toUpperCase() +
                        customer.status.slice(1)
                    }}
                  </td>
                  <td class="table_data">
                    {{ customer.attributes?.driverName | titlecase }}
                  </td>
                  <!-- <td class="table_data">{{ customer.attributes?.cabno }}</td> -->
                  <td class="table_data">
                    {{ customer.createdOn | date : "dd/MM/yyyy hh:mm a" }}
                  </td>
                  <td class="table_data">
                    {{
                      customer?.expectedstarttime
                        ? (customer.expectedstarttime
                          | date : "dd/MM/yyyy hh:mm a")
                        : ""
                    }}
                  </td>
                  <td class="table_data">
                    {{
                      customer?.expectedendtime
                        ? (customer.expectedendtime
                          | date : "dd/MM/yyyy hh:mm a")
                        : ""
                    }}
                  </td>
                  <td class="table_data">
                    {{
                      customer?.gpsDistance
                        ? (customer.gpsDistance | meterTokm)
                        : "0"
                    }}Kms
                  </td>
                  <td class="table_data text-nowrap">
                    {{
                      customer?.duration ? (customer.duration | duration) : "0"
                    }}
                  </td>
                  <td class="table_data">
                    {{ customer.attributes?.Shift_Timing }}
                  </td>
                  <td class="table_data">
                    {{
                      customer.attributes?.remarks
                        ? (customer.attributes?.remarks | titlecase)
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>

              <tbody *ngIf="!taskListData.length">
                <tr>
                  <td colspan="7">
                    <div
                      class="d-flex justify-content-center align-items-center"
                      style="height: 48vh"
                    >
                      <img
                        src="/assets/images/datanotfound.png"
                        alt="NO DATA AVAILABLE IN TABLE"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

     
        <div
          *ngIf="totalRecords > 0"
          class="flex_row_center_spacebetween"
          style="padding: 1.5vh 2vh; border-top: 1px solid #e3e7ec"
        >
         <div>
        {{ "Showing" | translate }} 
  {{ displayedFrom }} 
  {{ "to" | translate }} 
  {{ displayedTo }}
  {{ "of" | translate }} 
  {{ totalRecords }}
  {{ "entries" | translate }}
        </div> 
        <pagination-controls 
        [totalItems]="totalRecords"
        [itemsPerPage]="limit"
        [currentPage]="page"
        (pageChange)="onPageChange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" modeldata *ngIf="addMode">
    <form name="licensesForm">
      <div class="row">
        <div class="col-12 col-md-6" *ngIf="superAdmin">
          <ng-container *ngIf="superAdmin">
            <label for="userId" class="">{{ "Dealer" | translate }}</label>
            <div class="search-dealer bg-light px-1 py-1">
              <mat-form-field class="example-full-width" appearance="fill">
                <input
                  type="text"
                  placeholder="Select Dealer"
                  aria-label="Number"
                  matInput
                  [formControl]="myDealerControl"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  [displayWith]="displayFn"
                  #auto="matAutocomplete"
                  (optionSelected)="dealerSelected($event.option.value)"
                >
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <span class="text-danger validation" *ngIf="errors?.userId">
              {{ errors.userId }}
            </span>
          </ng-container>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group pb-2">
            <label for="name" class="pb-2">{{
              "Single Unit Amount" | translate
            }}</label>
            <input
              disabled
              type="number"
              [value]="addLicensesForm.amount"
              name="amount"
              class="form-control"
              id="amount"
              aria-describedby="amount"
            />
            <span class="text-danger validation" *ngIf="errors?.amount">
              {{ errors.amount }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group pb-2">
            <label for="name" class="pb-2">{{ "Currency" | translate }}</label>
            <input
              name="currency"
              type="text"
              disabled
              [value]="addLicensesForm.currency"
              class="form-control"
              id="identifier"
              aria-describedby="identifier"
            />
            <span class="text-danger validation" *ngIf="errors?.currency">
              {{ errors.currency }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group pb-2">
            <label for="name" class="pb-2">{{ "Count" | translate }}</label>
            <input
              name="devicecount"
              (change)="onChangeCount()"
              type="number"
              min="0"
              max="100"
              [(ngModel)]="addLicensesForm.devicecount"
              class="form-control"
              id="identifier"
              aria-describedby="identifier"
            />
            <span class="text-danger validation" *ngIf="errors?.devicecount">
              {{ errors.devicecount }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group pb-2">
            <label for="name" class="pb-2">{{ "Tax" | translate }} (%)</label>
            <input
              disabled
              type="number"
              min="0"
              [(ngModel)]="addLicensesForm.tax"
              name="tax"
              class="form-control"
              id="identifier"
              aria-describedby="identifier"
            />
            <span class="text-danger validation" *ngIf="errors?.tax">
              {{ errors.tax }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group pb-2">
            <label for="name" class="pb-2">{{ "Total" | translate }}</label>
            <input
              disabled
              type="number"
              min="0"
              [(ngModel)]="addLicensesForm.total"
              name="total"
              class="form-control"
              id="identifier"
              aria-describedby="identifier"
            />
            <span class="text-danger validation" *ngIf="errors?.total">
              {{ errors.total }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="superAdmin">
          <div *ngIf="superAdmin" class="form-group pb-2">
            <label for="name" class="pb-2">{{ "Status" | translate }}</label>
            <select
              name="status"
              class="form-control"
              [(ngModel)]="addLicensesForm.status"
            >
              <option value="" class="select_options">Select</option>
              <option value="Completed" class="select_options">
                Completed
              </option>
              <option value="Pending" class="select_options">Pending</option>
            </select>
            <span class="text-danger validation" *ngIf="errors?.status">
              {{ errors.status }}
            </span>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-primary" (click)="onOrderPlace()">
        {{ superAdmin ? ("Save" | translate) : ("Checkout" | translate) }}
      </button>
      <button
        type="button"
        class="btn btn-secondary ms-3"
        (click)="onCancelClick()"
      >
        Cancel
      </button>
    </form>
  </div>
</app-common-modal-popup>

<div
  id="htmlString-container"
  #pdfTable
  [innerHtml]="htmlString"
  style="display: none"
></div>
