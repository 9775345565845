import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-change-status-of-task',
  templateUrl: './change-status-of-task.component.html',
  styleUrls: ['./change-status-of-task.component.css']
})
export class ChangeStatusOfTaskComponent implements OnInit {
  injectedData: any;
  taskStatusForm: FormGroup;
  someObject: any = {'vivek':'mahajan'};
  selectedTaskStatus: string = '';
  position_lat:any=0;
  position_lon:any=0;

  driversList: any;
  userData: any;
  orgId: any;

  constructor(public dialogRef: MatDialogRef<ChangeStatusOfTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private commonService:CommonService) {
      this.injectedData = data;
     }

  ngOnInit(): void {
    this.selectTaskStatus(this.injectedData.status.charAt(0).toUpperCase() + this.injectedData.status.slice(1))
    if (navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position) => {
        this.position_lat = position.coords.latitude;
        this.position_lon = position.coords.longitude;
    })
  }
  }
  selectTaskStatus(event: any): void {
    this.selectedTaskStatus = event;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  submit(task: any, data: any){

    if(data == 0){
      Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: "Please select status",
        showConfirmButton: false,
        timer: 1500,
      });
      return
    }
    const today=new Date();
    const startDate = today.toISOString();
if(data == 'Started'){     
  task.status = 'started';
  task.actualstarttime=startDate;
  if(this.position_lat && this.position_lon){
    task.attributes["trip_startlocation"]=`{${this.position_lat},${this.position_lon}}` 
  }

}else if(data == 'Assigned'){
  task.status = 'assigned'; 
}
else if(data == 'Completed'){
task.status = 'completed';
task.actualendtime=startDate;
if(this.position_lat && this.position_lon){
  task.attributes["trip endlocation"]=`{${this.position_lat},${this.position_lon}}`
}
}
else if(data == 'Unassigned'){
  task.status = 'unassigned';
  task.attributes.cabno='';
  task.attributes.did='';
  task.attributes.driverName='';
  task.attributes.vehicleno='';
}
else if(data == 'Acknowledged'){
  task.status ='acknowledged';
  task.attributes.actualaccepttime=startDate;
  if(this.position_lat && this.position_lon){
    task.attributes.acceptlatitudelocation=this.position_lat
    task.attributes.acceptlongitudelocation=this.position_lon
  }
}
    
    this.commonService.putRequest(`traccartasks/${task.id}`, task).then((resp: any) => {
      if (resp?.status) {
        // this.driverAssignedEvent.emit(resp);
        // this.commonService.commonModel.next(false);
        Swal.fire({
          icon: "success",
          title: `Task updated successfully`,
          showConfirmButton: false,
          timer: 1500,
        });        
      }      
    }, err => {
      Swal.fire({
        icon: "error",
        title: "Something Went Wrong",
        showConfirmButton: false,
        timer: 1500,
      });
    });
    this.closeModal();
  }
}
