import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonService } from './services/common.service';
import { DashboardDataService } from './services/dashboard-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MultiTrack';
  langSub: Subscription;

  constructor(public _commonService: CommonService,private titleService:Title, private translate: TranslateService,
    private dashBoardService:DashboardDataService) {
    translate.addLangs(['en', 'ar', 'fa', 'pr', 'sp','punjabi']);
    let siteDetails = this._commonService.getSiteDetails();
    this.titleService.setTitle('multiDrive');
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (localStorage.getItem('Language') != undefined) {
      translate.use(localStorage.getItem('Language'));
    } else {
      translate.use('en');
    }
    // translate.use(this.dash.language);
  }

  ngOnInit(): void {
    //this.translate.use(localStorage.getItem('Language')?localStorage.getItem('Language') : 'en');
    this.dashBoardService.Language.subscribe(res => {
      localStorage.setItem('Language',res);
      this.translate.use(res);
    })
  }

}
