<app-common-modal-popup [modelSize]="'modal-lg'" class="">
  <div class="" modelheadertext>{{'Create task'|translate}}</div>
  <div class="container-fluid" modeldata>
    <form action="" [formGroup]="taskform">
          <div class="row mt-1">
            <div class="col-12 col-md-6 mb-3">
              <div class="form-group">
                <label for="cname" class="pb-2">{{ 'Customers' | translate }}</label> <span>*</span>
                <select
                  class="form-select"
                  id="cname"
                  formControlName="clientId"
                  (change)="getClientDetails($event)"
                  style="width: 100%;" 
                >
                  <option value="0">Select</option>
                  <option value="{{ c.email }}" *ngFor="let c of customerList">
                    {{ c.name }}
                  </option>
                </select>
                <span class="text-danger validation"
                  *ngIf="(taskform.get('clientId').touched || submitted) && taskform.get('clientId').value === '0'">
                  *{{ 'Please select customer' | translate }}
                </span>
              </div>
            </div>
      
            <div class="col-12 col-md-6 mb-3">
              <div class="form-group">
                <label for="type" class="pb-2">{{ 'Task Type' | translate }}</label> <span>*</span>
                <select
                  class="form-select"
                  id="type"
                  formControlName="triptype"
                  style="width: 100%;"
                >
                  <option value="0">Select</option>
                  <option value="service">Service</option>
                  <option value="delivery">Delivery</option>
                  <option value="return">Return</option>
                  <option value="meeting">Meeting</option>
                  <option value="office_work">Office Work</option>
                  <option value="sales">Sales</option>
                  <option value="other">Other</option>
                </select>
                <span class="text-danger validation"
                  *ngIf="(taskform.get('triptype').touched || submitted) && taskform.get('triptype').value === '0'">
                  *{{ 'Please select task' | translate }}
                </span>
              </div>
            </div>

          </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <div class="form-group">
            <label for="date" class="pb-2">{{ 'Select Date and Time' | translate }}</label>
            <span>*</span>
            <div class="d-flex align-items-center">
              <input
                type="text"
                id="date"
                bsDatepicker
                class="form-control"
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                placeholder="dd/mm/yyyy"
                formControlName="date"
                style="width: 150px;"
              />
              <select class="form-select ms-2" style="width: 100px;" formControlName="hours">
                <option value="00">00</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
              </select>
              <select class="form-select ms-2" style="width: 100px;" formControlName="minutes">
                <option value="00">00</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
                <option value="32">32</option>
                <option value="33">33</option>
                <option value="34">34</option>
                <option value="35">35</option>
                <option value="36">36</option>
                <option value="37">37</option>
                <option value="38">38</option>
                <option value="39">39</option>
                <option value="40">40</option>
                <option value="41">41</option>
                <option value="42">42</option>
                <option value="43">43</option>
                <option value="44">44</option>
                <option value="45">45</option>
                <option value="46">46</option>
                <option value="47">47</option>
                <option value="48">48</option>
                <option value="49">49</option>
                <option value="50">50</option>
                <option value="51">51</option>
                <option value="52">52</option>
                <option value="53">53</option>
                <option value="54">54</option>
                <option value="55">55</option>
                <option value="56">56</option>
                <option value="57">57</option>
                <option value="58">58</option>
                <option value="59">59</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
        
            <div class="form-group pb-2">
              <label for="description" class="pb-2">{{ 'Description' | translate }}</label>
              <input
              type="text"
              class="form-control"
              id="description"
              aria-describedby="name"
              formControlName="description"
              autocomplete="off"
         
            />
            </div>
          </div>
        </div>
      
        <div class="col-12">
          <div class="form-group pb-2">
            <label for="address1" class="pb-2">{{ 'Address' | translate }}</label> <span>*</span>
            <input
              type="text"
              class="form-control"
              id="address1"
              formControlName="address"
            />
            <span class="text-danger validation"
              *ngIf="(taskform.get('address').touched || submitted) && taskform.get('address').errors?.required">
              *{{ 'Address is required' | translate }}
            </span>
          </div>
        </div>
  
       
  
        <div class="col-12 d-flex justify-content-end pt-4 pb-2">
          <button
            mat-raised-button
            type="submit"
            class="btn btn-primary me-2"
            (click)="submit()"
          >
            {{ 'SAVE' | translate }}
          </button>
          <button
            mat-raised-button
            type="button"
            class="btn btn-secondary"
            (click)="cancel()"
          >
            {{ 'CANCEL' | translate }}
          </button>
        </div>

    </form>
  </div>
  
</app-common-modal-popup>
