
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import * as ApexCharts from 'apexcharts';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare const google: any;
declare var $: any;
import { AgmInfoWindow, MapsAPILoader, MouseEvent } from '@agm/core';
import {
  EventType,
  TravelData,
  TravelMarker,
  TravelMarkerOptions,
} from 'travel-marker';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { SubjectSubscribeService } from '../../services/subject-subscribe.service';

import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

import { WebSocketService } from 'src/app/services/web-socket.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment-timezone';
import { parse } from 'path';
import { GeofenceService } from 'src/app/services/geofence.service';
import { DecimalPipe, DatePipe } from '@angular/common';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import {debounceTime, map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChangeStatusOfTaskComponent } from '../change-status-of-task/change-status-of-task.component';
import { EditStopOfTaskComponent } from '../edit-stop-of-task/edit-stop-of-task.component';
import { RouteModalComponent } from '../route-modal/route-modal.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ShowLocationPopupComponent } from '../show-location-popup/show-location-popup.component';
import { AnyARecord } from 'dns';
import html2canvas from 'html2canvas';
import { combineLatest } from 'rxjs';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
// import { NewFilterModalComponent } from '../new-filter-modal/new-filter-modal.component';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import { NewFilterModalComponent } from '../new-filter-modal/new-filter-modal.component';
// import { thisTypeAnnotation } from 'node_modules_backup/@babel/types/lib/index-legacy';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css'],
  providers:[MeterTokm],

})
export class TrackingComponent implements OnInit, AfterViewInit, OnDestroy,OnChanges  {
  vehicleDetailsReport = {
    alert: [],
    moving: [],
    parked: [],
    summary: {},
  };
  lat2: number = 40.7128; // Replace with your desired latitude
  lng2: number = -74.0060; // Replace with your desired longitude
  zoom1: number = 12;
  mapParkingIcon = {
    url: '/assets/icons/mapparking.png',
  scaledSize: { height: 38, width: 38 },
  size: new google.maps.Size(38, 38),
  scale: 2,}
  blueDotIcon = {
    url: '/assets/icons/bluedot.png',
  scaledSize: { height: 10, width: 10 },
  size: new google.maps.Size(10,10),
  scale: 2,}
  historyPath: any = {};
  statusFilter = 'ALL';
  previousInfoWindow;
  polyLineArray = [];
  loadCountSubscriber: any;
  geofenceList: any = [];
  markerGeofenceList: any = [];
  isGeofenceVisiable = false;
  mapTypeId = 'roadmap';
  page = 1;
  eventPage = 0;  
  devicePage = 0;
  pageOption = 10;
  activeSection = 1;
  allCounts={
    ALL:0,
    RUNNING:0,
    IDLE:0,
    STOPPED:0,
    NOT_REPORTING:0,
    EXPIRED:0,
    NO_DATA:0,
    TOWWED:0,
  };
  devicesList = [];
  allDevicesList=[];
  selectedDevice: any = {};
  options: any = {};
  locations = [];
  origin: any;
  destination: any;
  loaders: any;
  icon = '/assets/icons/greencar.png';
  markers: marker[] = [];
  zoom: number = 16;
  lat: number;
  lng: number;
  fullScreen = false;
  playback = false;
  fulldetails = false;
  vehicle_fullDetail = false;
  vehicle_status = false;
  main_col = true;
  markersList = [];
  history: boolean = false;
  parking: boolean = false;
  event: boolean = false;
  fuelsummary: boolean = true;
  bottomNav: boolean = false;
  allvehicles = true;
  oneVehicle = false;
  gloableSearchText= '';
  fullwidth = true;
  back_button = false;
  numbDelta = 1000;
  selectedDate: any;
  // TRAVEL MARKER VARIABLES
  device_id :any ='';
  to = '';
  from = '';
  todayDate = '';
  speedMultiplier = 2;
  map: any;
  marker: TravelMarker = null;
  origin_lat: any;
  origin_lng: any;
  destination_lat: any;
  destination_lng: any;
  locationData = [];
  playpause = false;
  arrlength: number;
  values: number;
  locationArray = [];
  selcetvehicle = {};
  allLocationTrackData = [];
  fromdate = '';
  todate = '';
  selectedDeviceDistance = 0;
  locationTotalDistance = 0;
  driverDetailsOnSelect:any;
  isMoreInfo:boolean= true;
  isLessInfo:boolean= false;
  remainingTasks:number = 0;
  orgId:any;
  lastLocation : any;
  tasktype:string="unassined";
  totalDistance:any='';
  movingTime:any='';
  stoppedTime:any='';
  travelledDistance:any='';
  dailySummaryForDriver:any='';
  routeCoordinates: { lat: number, lng: number }[] = [];
  @Input() inputData: any[] = [];
  lat1 = 41.85;
  lng1 = -87.65;
  driverPosition1: { lat: number, lng: number } = { lat: 0, lng: 0 };
  origin1: { lat: number, lng: number };
  destination1: { lat: number, lng: number };
  itemsPerPage: number = 10;
  sortby:any='fno'
  waypoints = [
    //  {location: { lat: 39.0921167, lng: -94.8559005 }},
    //  {location: { lat: 41.8339037, lng: -87.8720468 }}
  ];
  start_end_mark = [];
  optionsPractice: string[] = ['One', 'Two', 'Three'];
  filteredOptionsPractice: Observable<string[]>;

  latlng = [
    [
      18.563287,
      73.9179139
    ],
    [
      18.564287,
      73.9189139
    ],
   
  ];
  opts = {
    extraHeaders: {
      Cookie:
        '_ga=GA1.2.17432343994.1475611967; _gat=1; __cfduid=dc232334gwdsd23434542342342342475611928',
    },
  };

  attrList: any = [
    {
      id: 'speedLimit',
      name: 'Speed Limit',
      type: 'number',
    },
    {
      id: 'timezone',
      name: 'Timezone',
      type: 'string',
    },
    {
      id: 'deviceInactivityStart',
      name: 'Device Inactivity Start',
      type: 'number',
    },
    {
      id: 'deviceInactivityPeriod',
      name: 'Device Inactivity Period',
      type: 'number',
    },
  ];
  addAttr: any = {
    attribute: '',
    type: '',
    value: null,
  };
  deviceCategoryList: any = [
    { id: 'default', name: 'Default' },
    { id: 'animal', name: 'Animal' },
    { id: 'bicycle', name: 'Bicycle' },
    { id: 'boat', name: 'Boat' },
    { id: 'bus', name: 'Bus' },
    { id: 'car', name: 'Car' },
    { id: 'crane', name: 'Crane' },
    { id: 'helicopter', name: 'Helicopter' },
    { id: 'motorcycle', name: 'Motorcycle' },
    { id: 'offroad', name: 'Offroad' },
    { id: 'person', name: 'Person' },
    { id: 'pickup', name: 'Pickup' },
    { id: 'plane', name: 'Plane' },
    { id: 'ship', name: 'Ship' },
    { id: 'tractor', name: 'Tractor' },
    { id: 'train', name: 'Train' },
    { id: 'tram', name: 'Tram' },
    { id: 'trolleybus', name: 'Trolleybus' },
    { id: 'truck', name: 'Truck' },
    { id: 'van', name: 'Van' },
    { id: 'scooter', name: 'Scooter' },
  ];
  addDevice: any;
  errors: any = {};

  modalRef?: BsModalRef;
  trafficLayer: any;
  isTrafficEnabled = false;
  isSetFitBound = false;
  typesList: any = [];
  userData: any;
  // socket = io(
  //   'wss://app.multitrack.in:8082/api/session?token=' +
  //     localStorage.getItem('basicToken'),
  //   this.opts
  // );
  sendCommand: any = {};
  selectAllObjects: boolean = false;
  selectAllGeofense: boolean = false;
  isDeviceListVisible:boolean = true;
  isObjectsTab: boolean = false;  
  isEventsTab: boolean = false;
  isHistoryTab: boolean = false;
  isTaskHistoryTab:boolean= false;
  isGeofenceTab: boolean = false;
  isTaskTab: boolean = false;
  isTaskUnassigned: boolean = false;
  isTaskAssigned: boolean = false;
  isTaskCompleted: boolean = false;
  isTaskDetails: boolean = false;
  isTaskDetailDetail: boolean = false;
  isTaskDetailsCustomer: boolean = false;
  isTaskDetailsHistory:boolean = false;
  eventsDataList: any[] = [];
  eventFilterText: any = '';
  objectNameList: any[] = [];
  filteredobjectNameList: Observable<string[]>;
  historyForm:FormGroup;
  historySelectedDevice: any = {};
  rightPanelExpand: boolean = true;
  driverDetails: any[] = [];
  selectedDeviceNetworkData: any = null;
  searchGeo = '';
  polygons = true;
  circular = true;
  maincard = true;
  addpolygon = false;
  addcircular = false;
  checked = false;
  disabled = false;
  polygonGeofence = true;
  circularGeofence = true;
  //fullScreen = false;
  googleMap = true;
  drawPolygonGeofence = false;
  drawCircularGeofence = false;
  submitted = false;
  poiList = [];
  polygonForm: FormGroup;
  circularForm: FormGroup;
  geofenceDataList: any[] = [];
  circleArrayLatLng = [];
  polyArrayLatLng = [];
  circleRadius;
  myControl = new FormControl();
  // filteredOptions: Observable<{ display: string; id: string; }[]>;
  filteredOptions: Observable<any[]>;
  defaultLatitude: number = 18.5204; // Set your default latitude value here
  defaultLongitude: number = 73.8567;
  limit=15;
  offset=0;
  assignedlist_limit=15;
  assignedlist_offset=0;
  completedlist_limit=15;
  completedlist_offset=0;
  isLoading = false;
  activeTab: string = 'unassigned';
  dd: any = [
    {
      geofence: {
        coordinates: [
          [
            [30.340540087432323, 78.04063285692901],
            [30.29608489517005, 78.09075797899932],
            [30.336391789690683, 77.89918388231963],
            [30.405704529085938, 77.88682426317901],
            [30.406888934979417, 78.29057182177276],
            [30.381421042396628, 78.28439201220245],
            [30.340540087432323, 78.04063285692901],
          ],
        ],
        type: 'Polygon',
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      id: '61ae1a0d0584d2483b86d60e',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'new Geofences',
      entering: true,
      exiting: true,
      type: 'Unloading',
      status: false,
      circle_center: [],
      v: 0,
      vehicleGroup: null,
    },
    {
      geofence: {
        coordinates: [
          [
            [30.480710635331903, 78.02569103134559],
            [30.33858686954149, 78.25640392197059],
            [30.234231418847923, 78.06414317978309],
            [30.490178192225784, 77.69610118759559],
            [30.480710635331903, 78.02569103134559],
          ],
        ],
        type: 'Polygon',
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '61c18cb5edaf507ea9d21379',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'pimpari',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
      vehicleGroup: null,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [73.728417, 18.616194],
            [73.731056, 18.61653],
            [73.730037, 18.615782],
            [73.728938, 18.615167],
            [73.728417, 18.616194],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '61c3152fcebac3309f069fdd',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'chinchavad',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
      vehicleGroup: null,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [18.548322, 73.793796],
            [18.609436, 73.847954],
            [18.577417, 73.902886],
            [18.514814, 73.841609],
            [18.548322, 73.793796],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: false,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '628f1759263b9503701ce7c6',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'Farm1',
      entering: true,
      exiting: true,
      status: true,
      circle_center: [],
      v: 0,
      type: 'Unloading',
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [73.904012, 18.594385],
            [73.904173, 18.595056],
            [73.905353, 18.595173],
            [73.905648, 18.594344],
            [73.905187, 18.59376],
            [73.904012, 18.594385],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: false,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62a84609f0d5c03267be935e',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'home',
      entering: true,
      exiting: false,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
    },
    {
      geofence: {
        coordinates: [
          [
            [19.443354490073318, 81.95704227639173],
            [18.97649898052674, 82.38550907326673],
            [18.872574205562298, 81.84717899514173],
            [19.215279630450194, 81.74830204201673],
            [19.443354490073318, 81.95704227639173],
          ],
        ],
        type: 'Polygon',
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62a96ce4008c3c0585c00d9d',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'abnuj second poly 2',
      entering: true,
      exiting: true,
      type: 'Unloading',
      status: true,
      circle_center: [],
      v: 0,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [21.68594279230725, 78.63558466949462],
            [21.205337286988406, 79.06405146636962],
            [21.097752507367517, 78.56966670074462],
            [21.40492956020722, 78.41585810699462],
            [21.68594279230725, 78.63558466949462],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62c56b416f5a8732069e77f6',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'tester',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [28.684576017318168, 77.27052673563996],
            [28.632156091045463, 77.29936584696809],
            [28.63155341081541, 76.86815246806184],
            [28.75984608375382, 76.87501892313996],
            [28.684576017318168, 77.27052673563996],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62c56c1b289c9631eeea8f84',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'tester ',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      _v: 0,
    },
  ];
  polygonList = [];
  isGeofenceList:boolean = true;
  historyActivityList = [];
  historyActivityListTask=[];
  selectedHistoryActivity: any = {};
  isHistorySpeedChart = true;
  isHistoryFuelChart = true;
  isDriverPanelVisible = false;//by default driverpanel will be collapsed
  isDriversTab: boolean = false;
  tasksList: any[] = [];
  assignedTaskslist:any[]=[];
  completedTaskslist:any[]=[];
  duplicateAssignedTaskslist:any[]=[];
  duplicateCompletedTaskslist:any[]=[];
  duplicateTasksList: any[] = [];
  selectedTaskDeails:any[] = [];
  taskHistoryDetails:any[] = [];
  taskHistoryDetails2:any[] = [];
  isDriverFree: boolean = false;
  isDriverBusy: boolean = false;
  isDriverInactive: boolean = false;
  driversList: any[] = [];
  driversInactiveList: any[] = [];
  driversBusyList: any[] = [];
  driversFreeList: any[] = [];
  selectedTaskIdInTaskDetail:any = '';
  selectedDriverIdInTaskDetail: any = '';
  cabno:any=''
  unassignedTaskCount = 0;
  assignedTaskCount = 0;
  completedTaskCount = 0;
  searchDriver: any = '';
  isAssignDriverPopupOpen = false;
  isSendGPRSCommandPopupOpen = false;
  isDriversTaskDetails = false;
  isDriversDetailTaskDetails = false;
  driversTaskDetailList: any[] = [];
  searchTasks: any = '';
  searchClients: any = '';
  tasksFilter: any = 'cabno';
  customerList: any[] = [];
  totalResult:number = 0;
  isBackend: boolean=false;
  isSupervisor: boolean=false;
  supervisorId=''
  isAccountant: boolean=false;
  isAdmin: boolean=false;
  isCompliance: boolean=false;
  hideDriverDetails: boolean;
  isDriverDetails: boolean = false;
  isTaskHistory: boolean =false;
  addressBylatLong:any = {};
  isDriversDetailTodaysTaskDetails: boolean =false;
  formattedFromDate: string;
  fromdateP: any;
  formattedToDate: string;
  todateP: any;
  selectedDriverId: string;
  selectedDriverData:any=''
  taskListData: any;
  eventStatusCount: any[] = []
  filterTask: boolean = false;
  isfilterTask: boolean = false;
  convertedDate: any;
  originalDateString: any;
  driverList: any[];
  driverList1: any[];
  driverID: any;
  driversList2: any[] = [];
  isTaskDetailDetail1: boolean =false;
  // isClient:boolean=false;
  clientId:any='';
  isTaskTimeline: boolean = false;
  timelinetaskList: any[]=[];
driverDid:any='';
  clientData: any;
  clientLocation: any='';
  deviceId: any = '';
  driversData: any;
  isTrackingTrip: boolean= false;
  timelineForTask: any;
  formattedAddress: string;
  startbeforeDate: string;
  completedDate: string;
  tripRoute: google.maps.LatLng[];
  deletedtask: boolean=false;
  isInfoHide: boolean=true;
  selectedRowIndex: number = -1;
  pageno: number= -1;
  acknowledgedCount: any = 0;
  assignedCount: any = 0;
  inprogressCount: any=0;
  unassignedCount: any=0;
  rejectedCount: any=0;
  failedCount: any=0;
  completedCount: any =0;
  totalRecordCount: any =0;
  startedCount: any = 0;
  taskCountByStatus: any;
  clientList: any[]=[];
  formattedFromDate_for_tasks: any='';
  formattedtoDate_for_tasks: any='';
  taskId: any='';
  searchCab: any='';
  cab: any='';
  droppedCount: any=0;
  pickedup: any=0;
  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private track_http: DashboardDataService,
    private fb: FormBuilder,
    private webSocketService: WebSocketService,
    private commonService: CommonService,
    private cookieService: CookieService,
    private subjectSubscribeService: SubjectSubscribeService,
    private mapsAPILoader: MapsAPILoader,
    private geofence: GeofenceService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private meterTokm: MeterTokm,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router:Router,
    private ref: ChangeDetectorRef
  
  ) {

    this.historyPath['poly'];
    this.historyPath['line'];
    this.historyPath['start'];
    this.historyPath['end'];

    this.subjectSubscribeService.searchDevice.subscribe((resp: any) => {
 
      console.log('subjectSubscribe resp=>',resp)
      this.gloableSearchText = resp;
      this.devicePage = 0;
      this.devicesList = [];
      this.deviceFilter(this.statusFilter,true);

    });
    this.start_end_mark.push(this.latlng[0]);
    this.start_end_mark.push(this.latlng[this.latlng.length - 1]);
    this.addDevicepopup();
    this.historyForm = this.fb.group({
      object: [''],
      filter: ['0'],
      timeFrom: [moment().format("yyyy-MM-DDThh:mm:ss").toString()],
      timeTo: [moment().format("yyyy-MM-DDThh:mm:ss").toString()],
      stops: ['1'],
      assignedTo1: [''],

    });
    (this.polygonForm = this.fb.group({
      fencename: ['', Validators.required],
      geoaddress: ['']
    }),
    this.circularForm = this.fb.group({
        circularName: ['', Validators.required],
        circularAddress: ['']        
    }));
    this.commonService.pageLoadInModal.subscribe((result: string) => { 
      
      if (result == "devicemodel") {
        this.isSendGPRSCommandPopupOpen = true;
        this.commonService.commonModel.next(true); 
      } else if (result == 'assigndriver') {
        this.isAssignDriverPopupOpen = true;
        this.commonService.commonModel.next(true); 
      }
      else if (result == 'filterTask'){
        this.isfilterTask = true;
        this.commonService.commonModel.next(true);
      }
    });
    this.commonService.commonModelReset.subscribe((result) => {
      console.log('result=>',result)
      this.resetModalVariable();
    })
    this.setRouteCoordinates();
    // this.getDriversListfordropdown();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterforDrivers(value))
    );
    this.filteredOptionsPractice = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterforPractice(value))
    );
  }

  setRouteCoordinates() {
  
}



  driverPosition={lat:18.563287 ,lon: 73.9179139}
  openedWindow : number = 0; // alternative: array of numbers
  openedWindowDots : string = '';
  openWindow(id) {
      this.openedWindow = id; // alternative: push to array of numbers
  }
  get polygonFormControl() {
    return this.polygonForm.controls;
  }

  get circularFormControl() {
    return this.circularForm.controls;
  }

  isInfoWindowOpen(id) {
      return this.openedWindow == id; // alternative: check if id is in array
  }
  openWindowDots(id) {
    this.openedWindowDots = id; // alternative: push to array of numbers
  }

  isInfoWindowOpenDots(id) {
    return this.openedWindowDots == id; // alternative: check if id is in array
  }
  showSuccess(title, message) {
    this.toastr.info(title, message);
  }
  changeMapTypeId() {
    this.mapTypeId = this.mapTypeId == 'satellite' ? 'roadmap' : 'satellite';
  }
  changeSetToBound() {
    this.isSetFitBound = !this.isSetFitBound;
    this.zoom = this.isSetFitBound ? 13.2 : 9;
  }
  my_string;
  displayODa() {
  
    let dInMeter = this.selectedDevice['attributes']['totalDistance'] ? this.selectedDevice['attributes']['totalDistance'] : 0
    let km = dInMeter/1000
   
    let kmAfterMeter =  km? km.toFixed(0) :0;
    this.my_string = kmAfterMeter.toString().padStart(7,'0');
    // while (this.my_string.length < 7) {
    //   this.my_string = '0' + this.my_string;
    // }
    this.my_string[0]; this.my_string[1]; this.my_string[2]; this.my_string[3]; this.my_string[4]; this.my_string[5]; this.my_string[6];

  }
  getDateIntimes(actions) {
    let now = moment();

    switch (actions) {
      case 'startToday':
        return now.startOf('day').utc().toISOString();
        break;
      case 'endToday':
        return now.endOf('day').utc().toISOString();
        break;
      case 'start24':
        return now.subtract(1, 'day').utc().toISOString();
        break;
      case 'end24':
        return now.utc().toISOString();
        break;
      case 'fromYest':
        return now.subtract(1, 'day').startOf('day').utc().toISOString();
        break;
      case 'toYest':
        return now.subtract(1, 'day').endOf('day').utc().toISOString();
        break;
      case 'weekFrom':
        return now.startOf('week').startOf('day').utc().toISOString();
        break;
      case 'weekTo':
        return now.startOf('week').endOf('day').utc().toISOString();
        break;
    
      default:
        break;
    }
  }
  setHistoryDate(val: any) {
    let now = moment();
    switch (val) {
        case '0':
          //console.log(now.startOf('day').format("yyyy-MM-DDThh:mm:ss A"));
          //setTimeout(() => {
            this.historyForm.patchValue({            
              timeFrom: now.startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
              timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()          
            });            
          //},10);
          break;
        case '1':
          this.historyForm.patchValue({            
            timeFrom: now.format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.subtract(1, 'day').format("yyyy-MM-DD hh:mm:ss A")          
          });
          break;
        case '2':
          this.historyForm.patchValue({            
            timeFrom: now.subtract(1, 'day').startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
            timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()          
          });  
          break;
        case '3':
          this.historyForm.patchValue({            
            timeFrom: now.startOf('week').format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.endOf('week').format("yyyy-MM-DD hh:mm:ss A")          
          });

          break;
        case '4':
          this.historyForm.patchValue({            
            timeFrom: now.subtract(7, 'day').startOf('week').format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.endOf('week').format("yyyy-MM-DD hh:mm:ss A")          
          });
          break;
        case '5':
          this.historyForm.patchValue({            
            timeFrom: now.startOf('month').format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.endOf('month').format("yyyy-MM-DD hh:mm:ss A")          
          });
          break;
        case '6':
          this.historyForm.patchValue({            
            timeFrom: now.subtract(1, 'month').startOf('month').format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.endOf('month').format("yyyy-MM-DD hh:mm:ss A")          
          });
          break;
        default:
          break;
    }
  }
  onPeriodChange(newValue) {
    var day, first, from, to, custom = newValue === '7';
    if (!custom) {
        from = new Date();
        to = new Date();
        switch (newValue) {
            case '0':
                to.setDate(to.getDate() + 1);                              
                break;
            case '1':
              from.setDate(to.getDate() - 1);
              break;
            case '2':
                from.setDate(to.getDate() - 1);
                break;
            case '3':
              from = moment().startOf('week');
              to = moment().endOf('week');

                break;
            case '4':
              from = moment().subtract(1, 'week').startOf('week');
              to = moment().subtract(1, 'week').endOf('week');
                break;
            case '5':
                from.setDate(1);
                to.setDate(1);
                to.setMonth(from.getMonth() + 1);
                break;
            case '6':
                from.setDate(1);
                from.setMonth(from.getMonth() - 1);
                to.setDate(1);
                break;
            default:
                break;
        }
        // if(newValue != '1' && newValue != '3' && newValue != '4') {
        //   from.setHours(0, 1, 0, 0);
        //   to.setHours(23, 59, 0, 0);
        // }
        
       this.from = from.toISOString();
       this.to = to.toISOString();
      //  setTimeout(() => {
      //   this.historyForm.patchValue({
      //     timeTo: moment(this.to).local().format("yyyy-MM-DDThh:mm:ss "),
      //     timeFrom: moment(this.from).local().format("yyyy-MM-DDThh:mm:ss")          
      //   }); 
      //  }, 10);              
    }
}
  setMapPath() {
    //console.log(this.selectedDevice);
    let url =
      'https://www.google.com/maps/dir/?api=1&destination=' +
      this.selectedDevice['latitude'] +
      ',' +
      this.selectedDevice['longitude'] +
      '&travelmode=driving';
    window.open(url, '_blank');
  }
  trafficLayerOnMap() {
    if (this.isTrafficEnabled) {
      this.trafficLayer.setMap(null);
      this.isTrafficEnabled = false;
    } else {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.map);
      this.isTrafficEnabled = true;
    }
  }
  ngOnDestroy() {
    clearInterval(this.loadCountSubscriber);
    this.main_col = true;
  }
  ngOnInit() {
// this.getTasksStatusCount();
// this.getAllCustomers();
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userData = JSON.parse(userDataObj);
        console.log('User data =>',this.userData)
        console.log('Org Id =>',this.userData.id)
        if(this.userData.attributes.role === 'admin'){
       this.orgId=this.userData.id;
      }
      else{
        this.orgId = this.userData.orgId;
      }
          if(this.userData.attributes.role === 'backend')
          {
            this.isBackend= true;
          }
          else if(this.userData.attributes.role === 'sv'){
            this.isSupervisor =  true;
            this.supervisorId=this.userData.id
            this.getMappedUsers(this.userData.id);
          }
          else if(this.userData.attributes.role === 'account'){
            this.isAccountant = true;
          }
          else if(this.userData.attributes.role === 'complaince'){
            this.isCompliance =  true;
          }
          else if(this.userData.attributes.role === 'admin'){
            this.isAdmin = true;
          }
          // else if(this.userData.attributes.role === 'clients'){
          //    this.clientId = this.userData.email;
          //    this.isClient=true;
          // }
        
          // this.superAdmin = userData && userData.administrator;
      
      } catch (error) {}
    }
    this.deviceFilter( 'ALL' );
    this.getConnection();
   
    // this.getAllTasks();
    this.getDriversListfordropdown();
    this.activeSection = 1;
    let selectedDateM = moment();
    this.selectedDate = selectedDateM.format('YYYY-MM-DD');
    this.route.queryParams.subscribe((params :any) => {

      //  this.getAllTasks();
       if(params.type === "UNASSIGNED" || params.type === "ASSIGNED" ||  params.type === "COMPLETED"  ){
        setTimeout(() => {
          this.tasktype= params.type ? params.type : 'unassigned';
          this.deviceFilter( 'ALL' );
        }, 10);
       }else if(params.type === "BUSY" || params.type === "INACTIVE" ||  params.type === "AVAILABLE")
        setTimeout(() => {
          this.expandDriverPanel();
          this.tasktype='unassigned';
          this.driverPanelTabChange(params.type);
          this.deviceFilter( 'ALL' );        
        }, 10);
        else{
          this.tasktype= 'unassigned';
        }
        // this.commonService.commonModelReset.subscribe((result) => {
        //  console.log(result)
        //   this.resetModalVariable();
        // })
   
        this.getAllDevices() 
      // this.getOnlyCounts();
      this.loadCountSubscriber = setInterval(() => {
        if (!this.fulldetails) {
          // this.getOnlyCounts();
        }
      }, 2000);
    });
   
    // this.getAllCustomers();
    this.filteredobjectNameList = this.historyForm.controls.object.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  

    
    console.log('driver names',this.driverList1)
  this.getCountByStatus();
  // this.getAllTasks();
  this.tabChange("Tasks");
  this.getOnlyCounts()
//  this.tasksTabsChange("unassigned")


 }
 getSlicedAddress(address: string): string {
  // Define an array of states to check
  const states = ['Maharashtra', 'Gujarat'];
  
  // Find the index of the first occurring state in the address
  let indexOfState = -1;
  let selectedState = '';

  for (const state of states) {
    const index = address.indexOf(state);
    if (index !== -1 && (indexOfState === -1 || index < indexOfState)) {
      indexOfState = index;
      selectedState = state;
    }
  }

  if (indexOfState !== -1) {
    // Split the address by the identified state
    const parts = address.split(selectedState);
    // Get the last part before the state and split it by spaces
    const lastPartWords = parts[0].trim().split(' ');
    // Get the index of the last word before the state
    const lastIndex = lastPartWords.length - 1;
    // Slice the address before the word before the state
    let slicedAddress = address.slice(0, address.lastIndexOf(lastPartWords[lastIndex])).trim();
    // Remove the last comma if present
    slicedAddress = slicedAddress.replace(/,\s*$/, '');
    return slicedAddress;
  } else {
    return address;
  }
}

  getAllTypes() {
    this.commonService.getRequest('commands/types', []).then((resp: any) => {
      if (resp?.status) {
        this.typesList = resp.data;
        console.log('-------------', resp.data);
      }
    });
  }

  sendCommandDetails() {
    this.sendCommand.attributes = {};
    let object = {
      attributes: {},
      deviceId: this.device_id,
      textChannel: this.sendCommand.textChannel,
      type: this.sendCommand.type,
    };
    this.commonService
      .postRequest('commands/send', object)
      .then((resp: any) => {
        $('#btnclose1').click();
        Swal.fire({
          icon: 'success',
          title: 'Send command has been Submitted Successfully',
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }
 
  // getCurrentLocation(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(
  //       (resp) => {
  //         resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
  //       },
  //       (err) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }
  roatedAllDevice() {
    this.markersList.forEach((mList) => {
      this.roatedDevice(mList, 10);
    });
  }
  roatedDevice(device, refreshTime = 100, isCourse = 0) {

    setTimeout(() => {
      const element = document.querySelector(
        `img[src~='${device.deviceDetails.vehicleMapImg.url}']`
      ) as HTMLImageElement;
      if (element) {
        element.style.transform =
          'rotate(' + (isCourse ? isCourse : device.course) + 'deg)';
      }
    }, refreshTime);
  }
  getVehicleCounts() {
    this.track_http.getDashbaord().subscribe((resp) => {
      console.log(resp);
      // this.running = resp.running_devices;
      // this.Idling = resp['Ideal Devices'];
      // this.Stopped = resp['OFF Devices'];
      // this.outofreach = resp.OutOfReach;
      // this.expired = resp.expire_status;
      // this.nodata = resp.no_data;
      // this.totalVehicles = resp.Total_Vech;
    });
  }

  getConnection() {

    //close sesstion if aleady exits
    try {
      this.commonService.closeSocket();
    } catch (error) {
      // no open connection found
    }
    const basicToken = localStorage.getItem('socketBasicToken');
    this.commonService.socketConnection = new WebSocket(
      `wss://${environment.baseUrl}:8082/api/socket?token=` + basicToken
    );
    if (this.commonService.socketConnection) {
      this.commonService.socketConnection.onmessage = (event) => {
        var msg = JSON.parse(event.data);
        if (msg.devices) {
          msg.devices.forEach((device) => {
            this.allDevicesList.forEach((displayDevice) => {
              if (displayDevice.deviceId == device.id) {
                displayDevice.deviceTime = device.lastUpdate;
                if(device.totalDistance) {
                  //displayDevice.totalDistance = device.totalDistance;
                }
                //displayDevice.deviceTime = new Date( device.lastUpdate);
                //displayDevice.status = device.status;
                //device.speed
                if (
                  this.selectedDevice && this.selectedDevice['deviceId'] && 
                  this.selectedDevice['deviceId'] == device.deviceId
                ) {
                  //this.displayODa();
                }
              }
            });
          });
        } else if (msg.positions) {
          console.log("Positions");
          console.log(msg.positions);
         
          msg.positions.forEach((device) => {
            this.markersList.forEach((displayMarker) => {
              if (displayMarker.deviceId == device.deviceId) {
                if (!device.isHide && !this.oneVehicle) {
                  //console.log('Is animated');
                  this.animatedMove(displayMarker, displayMarker, device);
                  this.roatedDevice(displayMarker, 10, device.course);
                } else {
                  displayMarker.latitude = device.latitude;
                  displayMarker.longitude = device.longitude;
                  displayMarker.serverTime = device.serverTime;
                  displayMarker.valid = device.valid;
                  displayMarker.attributes = device.attributes;
                  displayMarker.status = device.status;
                }
                if (
                  this.selectedDevice &&
                  this.selectedDevice['deviceId'] == device.deviceId
                ) {
                  this.selectedDevice.serverTime = device.serverTime;
                  this.selectedDevice.valid = device.valid;
                  this.selectedDevice.status = device.status;
                  this.selectedDevice.attributes = device.attributes;
                  this.selectedDevice.status = device.status;
                  let odometerValueInArray = this.selectedDevice?.attributes?.totalDistance / 1000;
                  let odometerValueInArrayRound = odometerValueInArray ? Math.round(odometerValueInArray):0;
                  let odometerValueInFinalArray:any[] = odometerValueInArrayRound.toString().split('');
                  for (let i = odometerValueInFinalArray.length; i <= 7; i++) {
                    odometerValueInFinalArray.unshift(0);
                  }                  
                  this.selectedDevice['odometerArray'] = odometerValueInFinalArray;
                  this.getAddress(device);
                  this.selectedDeviceNetworkData = device.network;
                }
                if (
                  this.selectedDevice &&
                  this.selectedDevice['deviceId'] == device.deviceId &&
                  device.address
                ) {
                  this.selectedDevice['displayAddress'] = device.address;
                }
                if (
                  this.selectedDevice &&
                  this.selectedDevice['deviceId'] == device.deviceId &&
                  !this.allLocationTrackData.length
                ) {
                  this.secondLastLat = this.origin_lat;
                  this.secondLastLng = this.origin_lng;
                  //console.log('Is Transition');                  
                  this.transition([device.latitude, device.longitude]);
                  setTimeout(() => {
                    const element = document.querySelector(
                      `img[src~='${this.icon}']`
                    ) as HTMLImageElement;
                    if (element) {
                      element.style.transform =
                        'rotate(' + device.course + 'deg)';
                    }
                  }, 100);
                } else {
                  displayMarker.latitude = device.latitude;
                  displayMarker.longitude = device.longitude;
                }
              }
            });
           
           this.allDevicesList.forEach((displayDevice) => {
              if (displayDevice.deviceId == device.deviceId) {
                if (displayDevice) {
                  displayDevice.lastLatLng = {
                    latitude : device.latitude,
                    longitude : device.longitude}
                  displayDevice.speed = device.speed;
                  displayDevice.serverTime = device.serverTime;
                  displayDevice.attribute = device.attributes;
                  displayDevice.attributes = device.attributes;
                  displayDevice.valid = device.valid;
            
                  if (displayDevice.status != device.status) {
                    displayDevice.status = device.status;
                    displayDevice.deviceDetails =
                      this.getDeviceDetails(displayDevice);
                    if (
                      this.selectedDevice &&
                      this.selectedDevice['deviceId'] == device.deviceId &&
                      !this.allLocationTrackData.length
                    ) {
                      this.updateMap();
                    }
                  }
                }
              }
            });
          });

        } else if (msg.events) {
          msg.events.forEach((device) => {
            //moment(device.eventTime).format("dddd, MMMM Do YYYY, h:mm:ss a")
            this.showSuccess(device.attributes.message, device.type);
            this.commonService.playAudio(1);
          });
        }
        //console.clear();
      };
    
      this.commonService.socketConnection.onopen = function (event) {
        // this.commonService.socketConnection.send(
        //   "Here's some text that the server is urgently awaiting!"
        // );
      };

      this.commonService.socketConnection.onclose = function (event) {
        // By the server/me or both?
        console.log('Connection closed');
      };
    }
  }

  initiateCarousel() {
    $('#startCarousel').owlCarousel({
      loop: false,
      nav: true,
      autoWidth: false,
      responsive: {
        0: {
          items: 3,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    });
  }
  getUserDeviceDetails() {
    this.deviceFilter(this.statusFilter);
  }
  getOnlyCounts() {
 
    this.commonService
      .getRequest('devices/getDeviceStatus', [],true)
      .then((resp: any) => {
        if (resp?.status) {
          this.allCounts.ALL = resp.data.RUNNING + resp.data.IDLE +resp.data.STOPPED +resp.data.EXPIRED+resp.data.NOT_REPORTING + resp.data.NO_DATA + resp.data.TOWWED;
          this.allCounts.RUNNING = resp.data.RUNNING;
          this.allCounts.IDLE = resp.data.IDLE;
          this.allCounts.STOPPED = resp.data.STOPPED;
          this.allCounts.NOT_REPORTING = resp.data.NOT_REPORTING;
          this.allCounts.EXPIRED = resp.data.EXPIRED;
          this.allCounts.NO_DATA = resp.data.NO_DATA;
          this.allCounts.TOWWED = resp.data.TOWWED;
        }
        console.log('device counts=>',this.allCounts);
      });
  }
  loadMore(){
    this.devicePage = this.devicePage + 1;
    this.getUserDeviceDetails();
  }
  // deviceFilter(type = 'ALL',isManualFilter = false) {

  //   if(this.statusFilter != type || isManualFilter) {
  //     this.statusFilter = type;
  //     this.devicePage = 0;
  //     this.devicesList = [];
  //   }
  //   let limit = 20;
  //   let searchParameter = ['status=' + type,`orgId=${this.orgId}`, 'isAddressRequired=false','limit='+limit,'offset='+limit *this.devicePage];
  //   if(this.gloableSearchText) {
  //     searchParameter.push('deviceName='+this.gloableSearchText);
  //     // searchParameter = searchParameter.filter(param => !param.startsWith('limit='));
  //     // searchParameter.push('limit=0');
  //   }
  //   this.commonService
  //     .getRequest('positions', searchParameter)
  //     .then((resp: any) => {
  //       console.log('deviceFilter=>',resp)
  //       if (resp?.status) {    
  //         console.log('before device list=>',this.devicesList)      
  //         resp.data.forEach((r) => {
  //           if (r.status === type || type === 'ALL') {
  //             r.deviceDetails = this.getDeviceDetails(r);
  //             r.isShow = true;
  //             r["isSelected"] = true;
  //             this.selectAllObjects = true;
             
  //             if(!this.devicesList.find(dItem=>dItem.deviceId == r.deviceId)){
  //               //console.log(r);                
  //               this.devicesList.push(r);
  //               //this.markersList.push(r);
  //             }
  //           }

           
  //         });
  //         //this.markersList = this.devicesList;
  //         this.markersList = [];
  //         Object.assign(this.markersList, this.devicesList);
  //         this.adjustMapBounds();
       
  //         setTimeout(() => {
  //           this.roatedAllDevice();
  //         }, 100);
  //         if (this.markersList?.length) {
  //           this.lat = this.markersList[0]['latitude'];
  //           this.lng = this.markersList[0]['longitude'];
  //         }
  //       }
  //     });
  // }
  deviceFilter(type = 'ALL',isManualFilter = false) {

    if(this.statusFilter != type || isManualFilter) {
      this.statusFilter = type;
      this.devicePage = 0;
      this.devicesList = [];
    }
    let limit = 20;
    let searchParameter = ['status=' + type,`orgId=${this.orgId}`, 'isAddressRequired=false','limit='+limit,'offset='+limit *this.devicePage];
    if(this.gloableSearchText) {
      searchParameter.push('deviceName='+this.gloableSearchText);
      // searchParameter = searchParameter.filter(param => !param.startsWith('limit='));
      // searchParameter.push('limit=0');
    }
    this.commonService
      .getRequest('positions', searchParameter)
      .then((resp: any) => {
        console.log('deviceFilter=>',resp)
        if (resp?.status) {    
          console.log('before device list=>',this.devicesList)      
          resp.data.forEach((r) => {
            if (r.status === type || type === 'ALL') {
              r.deviceDetails = this.getDeviceDetails(r);
              r.isShow = true;
              r["isSelected"] = true;
              this.selectAllObjects = true;
             
              if(!this.devicesList.find(dItem=>dItem.deviceId == r.deviceId)){
                //console.log(r);                
                this.devicesList.push(r);
                //this.markersList.push(r);
              }
            }

           
          });
          // this.markersList = this.allDevicesList;
          // this.markersList = [];
          Object.assign(this.markersList, this.allDevicesList);
          this.adjustMapBounds();
       
          setTimeout(() => {
            this.roatedAllDevice();
          }, 100);
          if (this.markersList?.length) {
            this.lat = this.markersList[0]['latitude'];
            this.lng = this.markersList[0]['longitude'];
          }
        }
      });
  }
  getAllDevices(type = 'ALL',isManualFilter = false) {
    if(this.statusFilter != type || isManualFilter) {
      this.statusFilter = type;
      this.devicePage = 0;
      this.devicesList = [];
    }
    let limit = 100000;
    let searchParameter = ['status=' + type,`orgId=${this.orgId}`, 'isAddressRequired=false','limit='+limit,'offset='+limit *this.devicePage];
    if(this.gloableSearchText) {
      searchParameter.push('deviceName='+this.gloableSearchText);
      // searchParameter = searchParameter.filter(param => !param.startsWith('limit='));
      // searchParameter.push('limit=0');
    }
    this.commonService
      .getRequest('positions', searchParameter)
      .then((resp: any) => {
        console.log('deviceFilter=>',resp)
        if (resp?.status) {    
          console.log('before device list=>',this.allDevicesList)      
          resp.data.forEach((r) => {
            if (r.status === type || type === 'ALL') {
              r.deviceDetails = this.getDeviceDetails(r);
              r.isShow = true;
              r["isSelected"] = true;
              this.selectAllObjects = true;
             
              if(!this.allDevicesList.find(dItem=>dItem.deviceId == r.deviceId)){
                //console.log(r);                
                this.allDevicesList.push(r);
                //this.markersList.push(r);
              }
            }

           
          });
          //this.markersList = this.devicesList;
          this.markersList = [];
          Object.assign(this.markersList, this.allDevicesList);
          this.adjustMapBounds();
       
          setTimeout(() => {
            this.roatedAllDevice();
          }, 100);
          if (this.markersList?.length) {
            this.lat = this.markersList[0]['latitude'];
            this.lng = this.markersList[0]['longitude'];
          }
        }
      });
  }

  getDevicePosition(device) {
    this.commonService
      .getRequest(
        'reports/route?userId=' + this.historyForm.value.object + '&id=' + device.positionId + '',
        []
      )
      .then((resp: any) => {
        let devicesData = device;
        if (resp?.status) {
          devicesData['positionConfig'] = resp.data?.length ? resp.data[0] : {};
        }
      
        this.devicesList.push(devicesData);
        this.markersList.push(devicesData);
        console.log('this.devicesList', this.devicesList);
        console.log('this.markersList', this.markersList);
      });
  }

  getFullWidth() {
    if (this.fullScreen) {
      document.getElementById('closeFullMap').style.display = 'flex';
      document.getElementById('myMap').style.width = '64%';
    } else {
      document.getElementById('myMap').style.width = '100%';
      document.getElementById('myMap').style.paddingLeft = '0';
      document.getElementById('closeFullMap').style.display = 'none';
    }
    this.fullScreen = !this.fullScreen;
  }

  async onFullVehicleDetail(device, isInternalCalling) {
    console.log('device=>',device)
    console.log('isInternalCalling=>',isInternalCalling)
      this.main_col = true;      
      this.vehicle_status = false;
      this.playback = true;
      this.fulldetails = false;
      this.vehicle_fullDetail = true;
     
      if(device.lastLatLng && device.lastLatLng.latitude) {
        device.latitude = device.lastLatLng.latitude;
        device.longitude = device.lastLatLng.longitude;
      }
      this.selectedDevice = device;
      let odometerValueInArray = this.selectedDevice?.attributes?.totalDistance / 1000;
      let odometerValueInArrayRound = odometerValueInArray ? Math.round(odometerValueInArray):0;
      let odometerValueInFinalArray:any[] = odometerValueInArrayRound.toString().split('');
      for (let i = odometerValueInFinalArray.length; i <= 7; i++) {
        odometerValueInFinalArray.unshift(0);
      }
      this.selcetvehicle = this.selectedDevice;
      this.selectedDevice['odometerArray'] = odometerValueInFinalArray;
      
      this.device_id = device.deviceId;//this.markersList[0].deviceId;
      this.allvehicles = true;
      this.oneVehicle = false;
      
      this.onDateSelection();
      this.selectedDevice['displayAddress'] = '';
      this.origin_lat = this.selcetvehicle['latitude'];
      this.origin_lng = this.selcetvehicle['longitude'];
      this.selectedDeviceNetworkData = device?.network;
      
      if (isInternalCalling) {
        await this.getDriverDetails();
        // this.rightPanelExpand = true;
      }
      if (!isInternalCalling) {
        // this.rightPanelExpand = false;
      }
      //start change - code to change focus marker from one to another
      setTimeout(() => {
        this.zoom = 9;        
        this.initialise(isInternalCalling);
        //this.roatedAllDevice();
        // this.roatedDevice(this.selectedDevice, 10);
      }, 100);
      if (this.markersList?.length) {
        this.lat = device.latitude;
        this.lng = device.longitude;
      }
      //end change
       setTimeout(() => {
       //  this.initialise();
        //this.updateMap();
         this.roatedDevice(this.selectedDevice, 10);
       }, 10);
      this.displayODa();
  }

  closeHistorytrack() {

    this.stoppagesReports = [];
    let lastSelectedDevice = this.selcetvehicle;
    this.icon = '/assets/icons/greencar.png';
    //document.getElementById('closeFullMap').style.display = 'flex';
    //document.getElementById('myMap').style.width = '66%';
    //document.getElementById('closeFullMap').style.width = '34%';
    this.bottomNav = false;
    this.back_button = false;
    this.oneVehicle = false;
    this.main_col = true;
    this.vehicle_status = false;
    this.fullwidth = true;
    this.playback = true;
    this.fulldetails = false;
    this.vehicle_fullDetail = true;
    this.allvehicles = true;

    this.allLocationTrackData = [];
    if (this.marker && this.marker != null) {
      this.marker.setMap(null);
    }
    if (this.historyPath && this.historyPath.line && this.historyPath.setMap) {
      this.historyPath.line.setMap(null);
    }
    this.secondLastLat = null;
    this.secondLastLng = null;
    this.onBack(true);
    this.historyActivityList = [];
    this.historyActivityListTask=[];
    this.selectedHistoryActivity = {};
    // setTimeout(() => {
    //   this.onFullVehicleDetail(lastSelectedDevice);
    // }, 101);
    setTimeout(()=>{
      this.closeEndMarker();
    },200)
  }

  onClickDetails() {
    this.vehicle_status = false;
    this.vehicle_fullDetail = true;
  }

  onClickStatus() {
    this.vehicle_fullDetail = false;
    this.vehicle_status = true;
  }

  onBack(ovoidExtra = false) {
    Object.assign(this.markersList, this.devicesList);
    this.allLocationTrackData = [];
    this.vehicle_status = false;
    this.playback = false;
    this.fulldetails = false;
    this.vehicle_fullDetail = false;
    this.main_col = true;

    this.allvehicles = true;
    this.oneVehicle = false;
    this.clearHistorySection();
    // if (!ovoidExtra) {
    //   setTimeout(() => {
    //     this.initiateCarousel();
    //   }, 10);
    // }
  }

  onClickHistory() {
    this.parking = false;
    this.event = false;
    this.fuelsummary = false;
    this.history = true;
  }

  onClickParking() {
    this.event = false;
    this.fuelsummary = false;
    this.history = false;
    this.parking = true;
  }

  onClickEvent() {
    this.fuelsummary = false;
    this.history = false;
    this.parking = false;
    this.event = true;
  }

  onClickFuel() {
    this.history = false;
    this.parking = false;
    this.event = false;
    this.fuelsummary = true;
    // setTimeout(() => {
    //   var chart = new ApexCharts(
    //     document.querySelector('#chart'),
    //     this.options
    //   );
    //   chart.render();
    // }, 1000);
  }
  clearHistorySection() {
    this.locationData = [];
    try {
      this.marker.setMap(null);
    } catch (error) {}

    if (this.historyPath) {
      try {
        if (this.historyPath.line) this.historyPath.line.setMap(null);
      } catch (error) {}
      try {
        if (this.historyPath.end) this.historyPath.end.setMap(null);
      } catch (error) {}
      try {
        if (this.historyPath.poly) this.historyPath.poly.setMap(null);
      } catch (error) {}

      try {
        if (this.historyPath.start) this.historyPath.start.setMap(null);
      } catch (error) {}
    }

    try {
      this.mark.setMap(null);
    } catch (error) {}
  }
  zoomSingle = 16;
  onTrackVehicleHistory(data) {
   
    this.zoomSingle = 16;
    this.locationData = [];
    this.clearHistorySection();
    this.onPeriodChange(data.toString());
    // this.marker.reset();
    if (data == 0) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.to = this.getDateIntimes('end24');
      // // const yesterday = new Date(this.to);
      // // yesterday.setDate(yesterday.getDate() - 1);
      // this.from = this.getDateIntimes('start24');

      this.commonService
        .getRequest(
          'reports/route?userId=' +
            this.historyForm.value.object +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            console.log('allLocationData=>',this.allLocationTrackData)
            this.calculateDistance();
            let lengthIndex = Math.trunc(this.arrlength / 4);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude, r.id]);
            });

            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    
    } else if (data == 1) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;
      this.commonService
        .getRequest(
          'reports/route?userId=' +
            this.historyForm.value.object +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            this.calculateDistance();
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude, r.id]);
            });

            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 2) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      //this.getFullWidth();
      //document.getElementById('closeFullMap').style.display = 'none';
      //document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      this.todayDate = new Date().toISOString();
      this.commonService
        .getRequest(
          'reports/route?userId=' +
          this.historyForm.value.object +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 3) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.to = this.getDateIntimes('weekTo');
      // const thisweek = new Date(this.to);
      // thisweek.setDate(thisweek.getDate() - 7);
      // this.from = this.getDateIntimes('weekFrom');

      this.commonService
        .getRequest(
          'reports/route?userID=' +
          this.historyForm.value.object +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 4) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.todayDate = new Date().toISOString();
      // const lastweek = new Date(this.todayDate);
      // lastweek.setDate(lastweek.getDate() - 7);
      // this.to = lastweek.toISOString();
      // const lastweeks = new Date(this.todayDate);
      // lastweeks.setDate(lastweeks.getDate() - 14);
      // this.from = lastweeks.toISOString();

      this.commonService
        .getRequest(
          'reports/route?userId=' +
          this.historyForm.value.object +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 5) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.to = new Date().toISOString();
      // const thismonth = new Date(this.to);
      // thismonth.setDate(thismonth.getDate() - 30);
      // this.from = thismonth.toISOString();

      this.commonService
        .getRequest(
          'reports/route?userId=' +
          this.historyForm.value.object +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 6) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.todayDate = new Date().toISOString();
      // const lastmonth = new Date(this.todayDate);
      // lastmonth.setDate(lastmonth.getDate() - 30);
      // this.to = lastmonth.toISOString();
      // const lastmonths = new Date(this.todayDate);
      // lastmonths.setDate(lastmonths.getDate() - 60);
      // this.from = lastmonths.toISOString();

      this.commonService
        .getRequest(
          'reports/route?userId=' +
          this.historyForm.value.object +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 7) {
      $('#staticBackdrop').modal('show');
    }
    if(data != 7) {
      this.stoppagesReport(this.device_id,this.from,this.to);
    }
  }

  onCustomSubmit(val: any) {
   
 console.log('device id on submit=>',this.device_id)
    console.log('device details=>',val);
//     this.historyForm.patchValue({            
//       timeFrom: now.startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
//       timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()          
//     });            
//   //},10);
//   break;
// case '1':
//   this.historyForm.patchValue({            
//     timeFrom: now.format("yyyy-MM-DD hh:mm:ss A"),
//     timeTo: now.subtract(1, 'day').format("yyyy-MM-DD hh:mm:ss A")          
//   });
//   break;
// case '2':
    let now = moment();
    this.historyForm.patchValue({            
      timeFrom: now.subtract(1, 'day').startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
      timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()
         
    });  
    console.log('dates=>',this.historyForm.value.timeFrom,this.historyForm.value.timeTo)  
    if (this.historyForm.value.timeFrom && this.historyForm.value.timeTo) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;
      this.from = this.historyForm.value.timeFrom;
      this.to = this.historyForm.value.timeTo;
      this.stoppagesReport(this.device_id,new Date(this.from).toISOString(),new Date(this.to).toISOString());//added by
      this.commonService
        .getRequest(
          'reports/route?userId=' +
          this.historyForm.value.object +
            '&from=' +
            new Date(this.from).toISOString() +
            '&to=' +
            new Date(this.to).toISOString(),
          []
        )
        .then((resp: any) => {  
          console.log('oncustom sub=>',resp)
          console.log('oncustom sub=>',resp.data)
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude, r.id]);
            });
            this.calculateDistance();
            this.mockDirections();
            // this.generateChart(resp.data);
          } else {
            alert('No Data Found');
          }
        });
    } else {
      this.toastr.error('Invalid Date Selected', 'error');
    }
  }
  onCustomSubmitHistory(val: any) {

 console.log('device id on submit=>',this.device_id)
    console.log('device details=>',val);
//     this.historyForm.patchValue({            
//       timeFrom: now.startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
//       timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()          
//     });            
//   //},10);
//   break;
// case '1':
//   this.historyForm.patchValue({            
//     timeFrom: now.format("yyyy-MM-DD hh:mm:ss A"),
//     timeTo: now.subtract(1, 'day').format("yyyy-MM-DD hh:mm:ss A")          
//   });
//   break;
// case '2':
    let now = moment();
    // this.historyForm.patchValue({            
    //   timeFrom: now.subtract(1, 'day').startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
    //   timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ssstops?deviceId A").toString()
         
    // });  


      
    console.log('dates=>',this.historyForm.value.timeFrom,this.historyForm.value.timeTo)  
    if (this.historyForm.value.timeFrom && this.historyForm.value.timeTo) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;


//by vivek
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      this.playpause = false;
      this.main_col = true;      
      this.vehicle_status = false;
      this.playback = true;
      this.fulldetails = false;
      this.vehicle_fullDetail = true; 
      this.allvehicles = true;
      this.oneVehicle = false;
      this.bottomNav = true;



      this.from = this.historyForm.value.timeFrom;
      this.to = this.historyForm.value.timeTo;
      this.stoppagesReport(this.device_id,new Date(this.from).toISOString(),new Date(this.to).toISOString());//added by
      this.commonService
        .getRequest(
          'reports/route?userId=' +
          this.selectedDriverData.id +
            '&from=' +
            new Date(this.from).toISOString() +
            '&to=' +
            new Date(this.to).toISOString(),
          []
        )
        .then((resp: any) => {  
          console.log('oncustom sub=>',resp)
          console.log('oncustom sub=>',resp.data)
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude, r.id]);
            });
            this.calculateDistance();
            this.mockDirectionsTask();
            // this.generateChart(resp.data);
          } else {
            alert('No Data Found');
          }
        });
    } else {
      this.toastr.error('Invalid Date Selected', 'error');
    }
  }
  onCustomSubmitHistoryforTask(val: any,driverId:any) {

    console.log('device id on submit=>',this.device_id)
       console.log('device details=>',val);
   //     this.historyForm.patchValue({            
   //       timeFrom: now.startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
   //       timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()          
   //     });            
   //   //},10);
   //   break;
   // case '1':
   //   this.historyForm.patchValue({            
   //     timeFrom: now.format("yyyy-MM-DD hh:mm:ss A"),
   //     timeTo: now.subtract(1, 'day').format("yyyy-MM-DD hh:mm:ss A")          
   //   });
   //   break;
   // case '2':
       let now = moment();
       // this.historyForm.patchValue({            
       //   timeFrom: now.subtract(1, 'day').startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
       //   timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()
            
       // });  
       console.log('dates=>',this.historyForm.value.timeFrom,this.historyForm.value.timeTo)  
       if (this.from && this.to) {
         this.fullwidth = false;
         this.icon = '';
         this.allvehicles = false;
         this.oneVehicle = true;
         this.back_button = false;
         this.bottomNav = true;

         //vivek
         this.back_button = false;
         this.playpause = false;
         this.main_col = true;      
         this.vehicle_status = false;
         this.playback = true;
         this.fulldetails = false;
         this.vehicle_fullDetail = true; 
         this.allvehicles = true;
         this.oneVehicle = false;
       


       
         this.stoppagesReport(this.device_id,new Date(this.from).toISOString(),new Date(this.to).toISOString());//added by
         this.commonService
           .getRequest(
             'reports/route?userId=' +
            val.uniqueId+
               '&from=' +
               new Date(this.from).toISOString() +
               '&to=' +
               new Date(this.to).toISOString(),
             []
           )
           .then((resp: any) => {  
             console.log('oncustom sub=>',resp)
             console.log('oncustom sub=>',resp.data)
             if (resp && resp.data && resp.data.length) {
               this.arrlength = resp.data.length;
               this.allLocationTrackData = resp.data;
               let lengthIndex = Math.trunc(this.arrlength / 2);
               this.lat = resp.data[lengthIndex]?.latitude;
               this.lng = resp.data[lengthIndex]?.longitude;
               this.origin_lat = resp.data[0]?.latitude;
               this.origin_lng = resp.data[0]?.longitude;
               this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
               this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
               this.locationData = [];
               resp.data.map((r) => {
                 this.locationData.push([r.latitude, r.longitude, r.id]);
               });
               this.calculateDistance();
               this.mockDirectionsTask();
               // this.generateChart(resp.data);
             } else {
               alert('No Data Found');
             }
           });
       } else {
         this.toastr.error('Invalid Date Selected', 'error');
       }
     }

  generateChart(val: any[]) {
     // setTimeout(() => {
    //   var chart = new ApexCharts(
    //     document.querySelector('#chart'),
    //     this.options
    //   );
    //   chart.render();
    // }, 1000);
   
      for (let i = 0; i < val.length; i++) {              
        this.options.series[0].data.push(val[i]['speed']);
        this.options.series[1].data.push(val[i]['attributes']['fuel']?val[i]['attributes']['fuel']:0);
        this.options.xaxis.categories.push(moment(val[i]['deviceTime']).toDate().toString());
      }           
    
    var chart = new ApexCharts(
      document.querySelector('#chart'),
      this.options
    );
    chart.render();
    chart.resetSeries();
    //chart.hideSeries('Speed');
  }
  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
    });
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  onMapReady(map: any) {
    this.map = map;
    this.mockDirections();
    //this.polygonDrawingManager(map);
    //this.circularDrawingManager(map);
    // this.adjustMapBounds();
    setTimeout(()=>{
      // 18.5669365,73.8968862,14z
      this.map.setCenter({lat: 18.5669365, lng: 73.896886214});
    },2000)
  }

  circularDrawingManager(map: any) {
    const circularOptions = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['circle'],
      },
      circularOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: 'circle',
    };
    console.log('drawingManager', google);
    const drawingManager = new google.maps.drawing.DrawingManager(
      circularOptions
    );
    drawingManager.setMap(map);
    google.maps.event.addListener(drawingManager, 'circlecomplete', (event) => {
      console.log('event', event);
      var circle;
      circle = event;
      this.circleRadius = circle.getRadius();
      this.circularForm.value.radius = this.circleRadius;
      this.circleArrayLatLng = [
        circle.getCenter().lat(),
        circle.getCenter().lng(),
      ];
      console.log(this.circleArrayLatLng);
    });
  }
  polygonDrawingManager(map: any) {
    const managerOptions = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        fillColor: 'red',
      },
      drawingMode: 'polygon',
    };
    const drawingManager = new google.maps.drawing.DrawingManager(
      managerOptions
    );
    drawingManager.setMap(map);
    google.maps.event.addListener(
      drawingManager,
      'polygoncomplete',
      (polygon) => {
        const len = polygon.getPath().getLength();
        this.polyArrayLatLng = [];

        for (let i = 0; i < len; i++) {
          const vertex = polygon.getPath().getAt(i);
          const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
          this.polyArrayLatLng.push(vertexLatLng);
        }
        this.polyArrayLatLng.push(this.polyArrayLatLng[0]);
        console.log(this.polyArrayLatLng);
      }
    );
  }
  mockDirections() {
    this.zoom = 12.2;//13.2; --change zoom level to display all markers on one go.
    this.locationArray = this.locationData.map(
      (l) => new google.maps.LatLng(l[0], l[1])
    );
    //#1a73e8
    //strokeColor: '#FF0000',
    this.historyPath.line = new google.maps.Polyline({
      strokeOpacity: 0.5,
      strokeWeight: 2.5, 
      //strokeColor: '#7c7d7d',     
      path: [],
      map: this.map,
      icons: [{
        icon: {
          strokeColor: '#056608',
          fillColor: '#056608',
          strokeOpacity: 0.8,
          strokeWeight: parseInt('2px'),
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        },
        offset: '0',
        repeat: '100px'
      }
        // ,
        // {
        //   icon: {
        //     strokeColor: '#FF0000 ',
        //     fillColor: '#FF0000',
        //     strokeOpacity: 0.8,
        //     strokeWeight: parseInt(this.thicknessVal),
        //     path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW
        //   },
        //   offset: '0',
        //   repeat: '100px'
        // }
      ],
    });    
    this.locationArray.forEach((l) => this.historyPath.line.getPath().push(l));
    // if (this.selectedHistoryActivity && this.selectedHistoryActivity.startPositionId) {
    //   this.locationData.forEach((l, i) => {
    //     if (l[2] >= this.selectedHistoryActivity.startPositionId && l[2] <= this.selectedHistoryActivity.endPositionId) {
    //       this.historyPath.line.setOptions({ 
    //         strokeColor: '#0000FF',
    //         strokeOpacity: 1
    //       });
    //       //this.historyPath.line['strokeColor'] = '#1a73e8';
    //       //this.historyPath.line.icons[0].icon.strokeColor = '#1a73e8';
    //       this.historyPath.line.getPath().push(this.locationArray[i]);
    //     } else {
    //       // this.historyPath.line.setOptions({ 
    //       //   strokeColor: '#7c7d7d',
    //       //   strokeOpacity: 0.5
    //       // });
    //       //this.historyPath.line['strokeColor'] = '#7c7d7d';
    //       //this.historyPath.line.icons[0].icon.strokeColor = '#056608';
    //       this.historyPath.line.getPath().push(this.locationArray[i]);
    //     }
    //   });
    // } else {
    //   this.locationArray.forEach((l) => this.historyPath.line.getPath().push(l));
    // }
    this.historyPath.start = new google.maps.LatLng(
      this.origin_lat,
      this.origin_lng
    );
    const end = new google.maps.LatLng(
      this.destination_lat,
      this.destination_lng
    );
    
    this.historyPath.end = new google.maps.Marker({
      position: end,
      map: this.map,
    });

    this.initRoute();
  }
  mockDirectionsTask() {
    this.zoom = 10;//13.2; --change zoom level to display all markers on one go.
    this.locationArray = this.locationData.map(
      (l) => new google.maps.LatLng(l[0], l[1])
    );
    //#1a73e8
    //strokeColor: '#FF0000',
    this.historyPath.line = new google.maps.Polyline({
      strokeOpacity: 0.5,
      strokeWeight: 2.5, 
      //strokeColor: '#7c7d7d',     
      path: [],
      map: this.map,
      icons: [{
        icon: {
          strokeColor: '#056608',
          fillColor: '#056608',
          strokeOpacity: 0.8,
          strokeWeight: parseInt('2px'),
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        },
        offset: '0',
        repeat: '100px'
      }
        // ,
        // {
        //   icon: {
        //     strokeColor: '#FF0000 ',
        //     fillColor: '#FF0000',
        //     strokeOpacity: 0.8,
        //     strokeWeight: parseInt(this.thicknessVal),
        //     path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW
        //   },
        //   offset: '0',
        //   repeat: '100px'
        // }
      ],
    });    
    this.locationArray.forEach((l) => this.historyPath.line.getPath().push(l));
    // if (this.selectedHistoryActivity && this.selectedHistoryActivity.startPositionId) {
    //   this.locationData.forEach((l, i) => {
    //     if (l[2] >= this.selectedHistoryActivity.startPositionId && l[2] <= this.selectedHistoryActivity.endPositionId) {
    //       this.historyPath.line.setOptions({ 
    //         strokeColor: '#0000FF',
    //         strokeOpacity: 1
    //       });
    //       //this.historyPath.line['strokeColor'] = '#1a73e8';
    //       //this.historyPath.line.icons[0].icon.strokeColor = '#1a73e8';
    //       this.historyPath.line.getPath().push(this.locationArray[i]);
    //     } else {
    //       // this.historyPath.line.setOptions({ 
    //       //   strokeColor: '#7c7d7d',
    //       //   strokeOpacity: 0.5
    //       // });
    //       //this.historyPath.line['strokeColor'] = '#7c7d7d';
    //       //this.historyPath.line.icons[0].icon.strokeColor = '#056608';
    //       this.historyPath.line.getPath().push(this.locationArray[i]);
    //     }
    //   });
    // } else {
    //   this.locationArray.forEach((l) => this.historyPath.line.getPath().push(l));
    // }
    this.historyPath.start = new google.maps.LatLng(
      this.origin_lat,
      this.origin_lng
    );
    const end = new google.maps.LatLng(
      this.destination_lat,
      this.destination_lng
    );
    
    this.historyPath.end = new google.maps.Marker({
      position: end,
      map: this.map,
    });

    this.initRoute();
  }
  calculateDistance() {
    this.locationTotalDistance = 0
    this.allLocationTrackData.map(loc=>{
      this.locationTotalDistance =
      this.locationTotalDistance +
      (loc &&
      loc.attributes &&
      loc.attributes.distance
        ? loc.attributes.distance
        : 0)

        loc.displayDistance = this.locationTotalDistance;
    })
  }
  initRoute() {
    try {
      this.marker.setMap(null);
    } catch (error) {}
    try {
      const route = this.historyPath.line.getPath().getArray();
      const options: TravelMarkerOptions = {
        map: this.map,
        speed: 50,
        interval: 10,
        speedMultiplier: this.speedMultiplier,
        markerType: 'overlay',
        overlayOptions: {
          offsetX: 0,
          offsetY: 0,
          offsetAngle: 0,
          imageUrl:
            this.selcetvehicle &&
            this.selectedDevice['deviceDetails'] &&
            this.selectedDevice['deviceDetails'].runningVehicleUrl
              ? this.selectedDevice['deviceDetails'].runningVehicleUrl
              : '/assets/icons/devices/car/running-map.png',
          imageWidth: 20,
          imageHeight: 38,
        },
      };
      this.marker = new TravelMarker(options);
      if (route && route.length) {
        this.marker.addLocation(route);
      }
      let addressLocationIndex = 0;
      this.historyPath['displayAddress'] = '';

      this.marker.event.onEvent((event: EventType, data: TravelData) => {
        console.log('alllocationdata=>',data.index, this.allLocationTrackData[this.values]);
        this.values = data.index;
        if (!this.values) {
          this.selectedDeviceDistance = 0;
        }
        if (data.index == addressLocationIndex) {
          if (this.allLocationTrackData[this.values]) {
            addressLocationIndex += 10;
            this.getDeviceAddress(
              this.allLocationTrackData[this.values].latitude,
              this.allLocationTrackData[this.values].longitude,
              true
            );
          }
        }
        this.selectedDeviceDistance =
          this.selectedDeviceDistance +
          (this.allLocationTrackData[this.values] &&
          this.allLocationTrackData[this.values].attributes &&
          this.allLocationTrackData[this.values].attributes.distance
            ? this.allLocationTrackData[this.values].attributes.distance
            : 0);
      });
      setTimeout(() => this.marker.pause(), 2000);
    } catch (error) {}
    console.log('alllocationdata 2=>',this.allLocationTrackData)
  }
  index = 0;
  transition(result) {
    this.index = 0;
    this.destination_lat = (result[0] - this.origin_lat) / this.numbDelta;
    this.destination_lng = (result[1] - this.origin_lng) / this.numbDelta;

    this.polyLineArray = [{ lat: this.origin_lat, lng: this.origin_lng }];

    // this.polyLineArray.forEach((l) => this.historyPath.poly.getPath().push(l));

    this.moveMarker();
  }
  poly: any;
  moveMarker() {
    this.origin_lat += this.destination_lat;
    this.origin_lng += this.destination_lng;
    
    var latlng = new google.maps.LatLng(this.origin_lat, this.origin_lng);
    this.polyLineArray.push({ lat: this.origin_lat, lng: this.origin_lng });
    this.historyPath.poly = new google.maps.Polyline({
      strokeColor: '#FF0000',
      strokeOpacity: 0.00001,
      strokeWeight: 0,
      path: this.polyLineArray,
      map: this.map,
    });
    this.mark.setPosition(latlng);
    //this.selectedDevice.setPosition(latlng);
    this.historyPath.poly.setMap(this.map);
    try {
      // this.map.setCenter(latlng); //by vivek
    } catch (error) {}
    if (this.index != this.numbDelta) {
      this.index++;
      setTimeout(() => {
        if (!this.allLocationTrackData.length) {
          this.moveMarker();
        }
      }, 10);
    }
  }
  onSlideEvent(e) {
    console.log(e);
    this.values = e.value;
    // this.locationArray = this.locationData[this.values];
    // this.marker.addLocation(this.locationArray);
  }

  speedCount = 1;
  on1xSpeed() {
    this.speedCount++;
    if(this.speedCount > 5) {
      this.speedCount = 1;
    }
    this.speedMultiplier = this.speedCount * 3;
    this.marker.setSpeedMultiplier(this.speedMultiplier);
    console.log('ok');
  }

  onReset() {
    console.log('ok');
    this.marker.reset();
  }

  onPlayPause() {
    if (this.playpause) {
      this.marker.pause();
    } else {
      this.marker.play();
    }
    this.playpause = !this.playpause;
  }
  mark: any;
  secondLastLat: any;
  secondLastLng: any;
  initialise(isCalledFromObjectClick:boolean = false) {
    var latlng = new google.maps.LatLng(this.origin_lat, this.origin_lng);
    var secLast = new google.maps.LatLng(
      this.secondLastLat ? this.secondLastLat : this.origin_lat,
      this.secondLastLng ? this.secondLastLng : this.origin_lng
    );  
   
    if (isCalledFromObjectClick) {
      try {
        this.mark.setMap(null);
      } catch(e: any) {}    
      let markerIndex = this.markersList.findIndex(x => x.deviceId == this.selectedDevice.deviceId);
      this.markersList[markerIndex].isHide = true;
    }
    this.mark = new google.maps.Marker({
      position: latlng,
      map: this.map,
      title: this.selectedDevice['name'],
      label: { fontSize: '10px',color: '#fbfbfc', text: this.selectedDevice['name']},
    });
    if (isCalledFromObjectClick) {
   
      const contentString = this.createInforWindowContent();
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        ariaLabel: "Uluru",
      });
    }
    this.markersList = this.markersList.filter(x => x.deviceId == this.selectedDevice.deviceId);
    
    this.historyPath.poly = new google.maps.Polyline({
      strokeColor: '#FF0000',
      strokeOpacity: 0.00001,
      strokeWeight: 0,
      path: this.polyLineArray,
      map: this.map,
      
    });
    this.icon = this.selectedDevice['deviceDetails'].vehicleMapImg.url;
    this.mark.setIcon({
      url: this.icon,
      scaledSize: { height: 38, width: 20 },
      size: new google.maps.Size(60, 60),
      origin: new google.maps.Point(-30, 0),
      anchor: new google.maps.Point(38, 20),
      scale: 2
    });
    
    const initialiRotation = google.maps.geometry.spherical.computeHeading(
      secLast,
      latlng
    );
    this.zoom = 15.2;
    setTimeout(() => {
      this.mark.setMap(null);
      const element = document.querySelector(
        `img[src~='${this.icon}']`
      ) as HTMLImageElement;
      if (element) {
        element.style.transform = 'rotate(' + initialiRotation + 'deg)';
      }
    }, 2000);

  }
  closeEndMarker() {
    try {
      if(this.historyPath) {
        this.historyPath.end.setMap(null);
      }
    } catch (error) {
      
    }
  }
  createInforWindowContent() {
    let content = `<div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Object')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice.name}</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Address')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice.address ? this.selectedDevice.address : 'NA' }</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Position')} :</div>
    <div class="col-8 ps-0">
      <a href="https://maps.google.com/maps?q=${this.selectedDevice?.latitude},${this.selectedDevice.longitude}&amp;t=m" 
      target="_blank">${this.selectedDevice?.latitude} °, ${this.selectedDevice.longitude} °</a></div>
  </div>
  
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Altitude')} :</div>
    <div class="col-8 ps-0">${ this.selectedDevice?.altitude } m</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Angle')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice?.course} °</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Speed')} :</div>
    <div class="col-8 ps-0">${this.decimalPipe.transform(this.selectedDevice?.speed,'1.0-3')  } ${this.translate.instant('kmph')}</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Time')} :</div>
    <div class="col-8 ps-0">${this.datePipe.transform(this.selectedDevice?.lastUpdate,'yy-mm-dd h:mm:ss')}</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Odometer')} :</div>
    <div class="col-8 ps-0">${this.meterTokm.transform(this.selectedDevice?.attributes?.totalDistance)} ${this.translate.instant('km')}</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Engine hours')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice?.hours?this.selectedDevice?.hours :'0'} h</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Voltage')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice?.attributes?.power} v</div>
  </div>`;
  return content;
  }
  onCustomMarkerClick() {
    // alert('clicked');
    // alert(this.previousInfoWindow);
  }
  updateMap() {
    this.icon = this.selectedDevice['deviceDetails'].vehicleMapImg.url;
    // this.icon['scaledSize'].width = 38;
    // this.mark.setIcon({
    //   url: this.icon,
    //   scaledSize: { height: 38, width: 20 },

    //   size: new google.maps.Size(60, 60),
    //   origin: new google.maps.Point(-10, 0),
    //   anchor: new google.maps.Point(38, 20),
    //   scale: 2,
    // });
    console.log(this.icon);
  }
  // END GOOGLE MAP

  ngAfterViewInit(): void {
    this.options = {
      series: [
        {
          name: 'Speed',
          data: [],
        },
        {
          name: 'Fuel',
          data: [],
        },
      ],
      chart: {
        height: 300,
        type: 'area',
        events: {
          dataPointMouseEnter: (event) => {
            event.path[0].style.cursor = 'pointer';
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [],
      },
      tooltip: {
        theme: 'dark',
        // x: {
        //   format: 'dd/MM/yy HH:mm',
        // },
      },
    };
    //var chart = new ApexCharts(document.querySelector("#chart"), this.options);
    //chart.render();
    
  }

  deviceSubmit() {
    this.errors = {};
    if (this.addDevice.name && this.addDevice.identifier) {
      console.log('this.addDevice', this.addDevice);
      let attributes = {};
      // decoder.timezone: "24"
      // deviceInactivityPeriod: 26
      // deviceInactivityStart: 25
      // speedLimit: 23

      if (this.addDevice?.attributes?.length) {
        this.addDevice.attributes.forEach((a) => {
          switch (a.attribute) {
            case 'speedLimit':
              attributes['speedLimit'] = a.value;
              break;
            case 'timezone':
              attributes['decoder.timezone'] = a.value;
              break;
            case 'deviceInactivityStart':
              attributes['deviceInactivityStart'] = a.value;
              break;
            case 'deviceInactivityPeriod':
              attributes['deviceInactivityPeriod'] = a.value;
              break;
          }
        });
      }

      if (this.addDevice?.id) {
        this.commonService
          .putRequest('devices/' + this.addDevice.id, {
            id: this.addDevice.id,
            name: this.addDevice.name,
            uniqueId: this.addDevice.identifier,
            groupId: this.addDevice.groupId,
            phone: this.addDevice.phone,
            model: this.addDevice.model,
            contact: this.addDevice.contact,
            category: this.addDevice.category,
            disabled: this.addDevice.disabled,
            // attributes: attributes,
          })
          .then(
            (resp: any) => {
              this.getUserDeviceDetails();
              $('#btnclose').click();
              Swal.fire({
                icon: 'success',
                title: 'Your device has been Updated Successfully',
                showConfirmButton: false,
                timer: 1500,
              });
            },
            (err) => {
              Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          );
      } else {
        this.commonService
          .postRequest('devices', {
            name: this.addDevice.name,
            uniqueId: this.addDevice.identifier,
            groupId: this.addDevice.groupId,
            phone: this.addDevice.phone,
            model: this.addDevice.model,
            contact: this.addDevice.contact,
            category: this.addDevice.category,
            disabled: this.addDevice.disabled,
            attributes: attributes,
          })
          .then(
            (resp: any) => {
              this.getUserDeviceDetails();
              $('#btnclose').click();
              Swal.fire({
                icon: 'success',
                title: 'Your device has been Submitted Successfully',
                showConfirmButton: false,
                timer: 1500,
              });
            },
            (err) => {
              console.log(err);
              Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          );
      }
    } else {
      if (!this.addDevice.name) {
        this.errors['Devicename'] = 'This field is required';
      }
      if (!this.addDevice.identifier) {
        this.errors['Deviceidentifier'] = 'This field is required';
      }
    }
  }

  deleteDevice(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this device!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          deleteuser: true,
          userId: id,
        };
        this.commonService
          .deleteRequest('devices/' + id)
          .then((resp) => {
            this.getUserDeviceDetails();
          })
          .catch((e) => {
            this.getUserDeviceDetails();
          });
        Swal.fire('Deleted!', 'Device has been deleted.', 'success');
      }
    });
  }

  addDevicepopup() {
    this.addDevice = {
      id: null,
      name: '',
      identifier: '',
      groupId: '',
      phone: '',
      model: '',
      contact: '',
      category: 'Default',
      disabled: false,
      attributes: [],
    };
  }

  editDevice(device) {
    console.log('device', device);
    let attributes = [];

    let attrLength = Object.keys(device.attributes);
    if (attrLength?.length) {
      attrLength.forEach((a) => {
        let dataSet = {
          attribute: '',
          type: '',
          value: null,
        };
        switch (a) {
          case 'speedLimit':
            if (device.attributes[a]) {
              dataSet.attribute = 'speedLimit';
              dataSet.type = 'number';
              dataSet.value = device.attributes[a];
            }
            break;
          case 'decoder.timezone':
            dataSet.attribute = 'timezone';
            dataSet.type = 'string';
            dataSet.value = device.attributes[a];
            break;
          case 'deviceInactivityStart':
            dataSet.attribute = 'deviceInactivityStart';
            dataSet.type = 'number';
            dataSet.value = device.attributes[a];
            break;
          case 'deviceInactivityPeriod':
            dataSet.attribute = 'deviceInactivityPeriod';
            dataSet.type = 'number';
            dataSet.value = device.attributes[a];
            break;
        }
        console.log('dataSet', dataSet);
        if (dataSet.attribute) {
          attributes.push(dataSet);
        }
      });
    }

    this.addDevice = {
      id: device.deviceId,
      name: device.name,
      identifier: device.uniqueId,
      groupId: device.groupId,
      phone: device.phone,
      model: device.model,
      contact: device.contact,
      category: device.category,
      disabled: device.disabled,
      attributes: attributes,
    };
  }

  checkboxEvent(e: any) {
    this.addDevice.disabled = e.checked;
  }

  addAttri(template: TemplateRef<any>) {
    this.addAttr = {
      attribute: '',
      type: '',
      value: null,
    };
    this.modalRef = this.modalService.show(template);
  }

  deleteAttr(key) {
    this.addDevice.attributes = this.addDevice.attributes.filter(
      (a, k) => k !== key
    );
  }

  changeAttrEvent(e) {
    let findAttrName = this.attrList.find((a) => a.id === e.value);
    if (findAttrName) {
      this.addAttr.type = findAttrName.type;
    } else {
      this.addAttr.type = '';
    }
  }

  addAttrbute() {
    if (this.addAttr.attribute) {
      let find = this.addDevice.attributes.find(
        (a) => a.attribute === this.addAttr.attribute
      );
      if (!find) {
        this.addDevice.attributes.push(this.addAttr);
      }
      this.modalRef?.hide();
    }
  }

  getDeviceDetails(device: any) {
    let details: any = {
      vehicleImg: '/assets/icons/devices/car/no-data.png',
      vehicleMapImg: '/assets/icons/devices/car/no-data-map.png',
      dotImg: '/assets/icons/no_data.png',
      msg: 'No Data',
      color: '#646464',
    };

    let folderName = 'devices/car';
    if (device.category === 'boat') {
      folderName = 'devices/boat';
    } else if (device.category === 'bus') {
      folderName = 'devices/bus';
    } else if (device.category === 'motorcycle') {
      folderName = 'devices/motorcycle';
    } else if (device.category === 'tractor') {
      folderName = 'devices/tractor';
    } else if (device.category === 'truck') {
      folderName = 'devices/truck';
    } else if (device.category === 'scooter') {
      folderName = 'devices/scooter';
    }

    switch (device.status) {
      case 'STOPPED':
        details.vehicleImg = '/assets/icons/' + folderName + '/stop.png';
        details.vehicleMapImg = '/assets/icons/' + folderName + '/stop-map.png';
        details.dotImg = '/assets/icons/stoped.png';
        details.msg = 'Stopped';
        details.color = '#fd1b41';
        break;
      case 'TOWWED':
        details.vehicleImg = '/assets/icons/' + folderName + '/stop.png';
        details.vehicleMapImg = '/assets/icons/' + folderName + '/stop-map.png';
        details.dotImg = '/assets/icons/stoped.png';
        details.msg = 'Towwed';
        details.color = '#fd1b41';
        break;
      case 'RUNNING':
        details.vehicleImg = '/assets/icons/' + folderName + '/running.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/running-map.png';
        details.dotImg = '/assets/icons/dotgreen.png';
        details.msg = 'Running';
        details.color = '#17A573';
        break;
      case 'IDLE':
        details.vehicleImg = '/assets/icons/' + folderName + '/idle.png';
        details.vehicleMapImg = '/assets/icons/' + folderName + '/idle-map.png';
        details.dotImg = '/assets/icons/idle_dot.png';
        details.msg = 'Idle';
        details.color = '#E8AD21';
        break;
      case 'NOT_REPORTING':
        details.vehicleImg =
          '/assets/icons/' + folderName + '/not-reporting.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/not-reporting-map.png';
        details.dotImg = '/assets/icons/not_reporting_dot.png';
        details.msg = 'No Reporting';
        details.color = '#529BA8';
        break;
      case 'EXPIRED':
        details.vehicleImg = '/assets/icons/' + folderName + '/expired.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/expired-map.png';
        details.dotImg = '/assets/icons/expired_dot.png';
        details.msg = 'Expired';
        details.color = '#EB563E';
        break;
      default:
        details.vehicleImg = '/assets/icons/' + folderName + '/no-data.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/no-data-map.png';
        details.dotImg = '/assets/icons/no_data.png';
        details.msg = 'No Data';
        details.color = '#646464';
        break;
    }
    details.runningVehicleUrl =
      '/assets/icons/' + folderName + '/running-map.png';
    details.vehicleMapImg = {
      url: details.vehicleMapImg + '#' + device.deviceId,
      scaledSize: { height: 38, width: 20 },
      size: new google.maps.Size(60, 60),
      origin: new google.maps.Point(-30, 0),
      anchor: new google.maps.Point(38, 20),
      scale: 2,
    };
    return details;
  }
  movingReport(Device_ID, fromdate, todate) {
    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate + 'T00:00:01.477Z',
      'to=' + todate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' + 50,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest('reports/trips?' + params.join('&'), [])
        .then((resp: any) => {
          console.log('-----------------', resp);

          if (resp?.status) {
            this.vehicleDetailsReport.moving = resp.data;
          }
        })
        .catch((e) => {});
    }
  }
  onDistanceCLick(Device_ID, fromdate, todate) {
    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate + 'T00:00:01.477Z',
      'to=' + todate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' + 50,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest('reports/stops?' + params.join('&'), [])
        .then((resp: any) => {
          console.log('-----------------', resp);

          if (resp?.status) {
            this.vehicleDetailsReport.parked = resp.data;
          }
        })
        .finally(() => {});
    }
  }
  getAddress(data) {
    this.getDeviceAddress(data.latitude,data.longitude,false,true,data)
  }
  getDeviceAddress(longitude, latitude, isHistory = false,updateToObj=false,data={}) {
 
    let params = [
      'latitude=' + latitude,
      'longitude=' + longitude,
      'maxDistance=1000',
    ];
    if (latitude && longitude) {
      this.commonService
        .getRequest('statistics/getAddress?' + params.join('&'), [])
        .then((resp: any) => { 
          if(updateToObj){
            if (resp.data && resp.data.address) { 

              data['address'] = resp.data.address;
            }
          } else {
            if (resp.data && resp.data.address) {
              this.selectedDevice['displayAddress'] = resp.data.address;
            }
            if (isHistory) {
              this.historyPath['displayAddress'] = resp.data.address;
            }
          }
          
        })
        .finally(() => {});
    }
  }
  stoppagesReports  = [];
  stoppagesReport(Device_ID, fromdate, todate) {
    this.stoppagesReports  = [];
    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate,
      'to=' + todate,
      'page=1',
      'start=0',
      'limit=' + 50,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest('reports/stops?' + params.join('&'), [])
        .then((resp: any) => {
          console.log('-----------------', resp);

          if (resp?.status) {
            this.stoppagesReports = resp.data;
            this.stoppagesReports.forEach(data=>{
              this.getDeviceAddress(data.latitude,data.longitude,false,true,data)
            })
          }
        })
        .finally(() => {});
    }
  }
  onDailyCLick(Device_ID, fromdate, todate) {
    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate + 'T00:00:01.477Z',
      'to=' + todate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' + 50,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest('reports/events?' + params.join('&'), [])
        .then((resp: any) => {
          console.log('-----------------', resp);
          if (resp?.status) {
            this.vehicleDetailsReport.alert = resp.data;
          }
        })
        .catch((e) => {});
    }
  }
  onDateSelection() {
    let selectedDate = this.selectedDate;
    this.vehicleDetailsReport = {
      alert: [],
      moving: [],
      parked: [],
      summary: {},
    };
    this.movingReport(
      this.selectedDevice['deviceId'],
      selectedDate,
      selectedDate
    );
    this.onDistanceCLick(
      this.selectedDevice['deviceId'],
      selectedDate,
      selectedDate
    );
    this.onDailyCLick(
      this.selectedDevice['deviceId'],
      selectedDate,
      selectedDate
    );
    this.dailySumary(
      this.selectedDevice['deviceId'],
      selectedDate,
      selectedDate
    );
  }
  dailySumary(Device_ID, fromdate, todate) {

    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate + 'T00:00:01.477Z',
      'to=' + todate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' + 10,
      'daily=' + false,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest(`reports/summary?userId=${this.selectedDriverData.id}&` + params.join('&'), [])
        .then((resp: any) => {
          if (resp?.status) {
            this.vehicleDetailsReport.summary =
              resp.data && resp.data.length ? resp.data[0] : {};
            let timeDiff =
              new Date(this.vehicleDetailsReport.summary['endTime']).getTime() -
              new Date(
                this.vehicleDetailsReport.summary['startTime']
              ).getTime();
            // console.log('timeDiff', timeDiff);
            // console.log(
            //   timeDiff - this.vehicleDetailsReport.summary['engineHours']
            // );
            this.vehicleDetailsReport.summary['parkedTime'] =
              timeDiff - this.vehicleDetailsReport.summary['engineHours'];
          } else {
            this.vehicleDetailsReport.summary['parkedTime'] = 0;
            this.vehicleDetailsReport.summary['distance'] = 0;
            this.vehicleDetailsReport.summary['engineHours'] = 0;
          }
        });
    }
  }
  dailySumaryForDriverData(Device_ID, fromdate, todate) {

    // Device_ID=56;
    // fromdate='2024-01-15T09:36:10.477Z';
    // todate='2024-01-15T09:56:54.477Z';
    // let params = [
    //   'deviceId=' + Device_ID,
    //   'from=' + fromdate ,
    //   'to=' + todate ,
    //   'page=1',
    //   'start=0',
    //   'limit=' + 10,
    //   'daily=' + false,
    // ];
    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate + 'T00:00:01.477Z',
      'to=' + todate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' + 10,
      'daily=' + false,
    ];

    if (fromdate && todate && Device_ID) {

      this.commonService
        .getRequest(`reports/summary?userId=${this.selectedDriverData.id}&` + params.join('&'), [])
        .then((resp: any) => {
          if (resp?.status) {
            this.dailySummaryForDriver =
              resp.data && resp.data.length ? resp.data[0] : {};
              this.travelledDistance= this.dailySummaryForDriver.distance
            this.movingTime =
              new Date(this.dailySummaryForDriver['endTime']).getTime() -
              new Date(
                this.dailySummaryForDriver['startTime']
              ).getTime();
            this.stoppedTime =
            this.movingTime - this.dailySummaryForDriver['engineHours'];
          } else {
            this.movingTime= 0;
            this.stoppedTime = 0;
            this.travelledDistance = 0;
          }
        });
    }
  }
  dailySumaryForTask(Device_ID, fromdate, todate,val) {
  const updatedFromdate=fromdate.slice(0, -9) +'477Z'
  const updatedTodate=todate.slice(0, -9) +'477Z'
   
    let params = [
      'deviceId=' + Device_ID,
      'from=' + updatedFromdate ,
      'to=' + updatedTodate ,
      'page=1',
      'start=0',
      'limit=' + 10,
      'daily=' + false,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest(`reports/summary?userId=${val?.assignedto}&` + params.join('&'), [])
        .then((resp: any) => {
          if (resp?.status) {
            this.vehicleDetailsReport.summary =
              resp.data && resp.data.length ? resp.data[0] : {};
            let timeDiff =
              new Date(this.vehicleDetailsReport.summary['endTime']).getTime() -
              new Date(
                this.vehicleDetailsReport.summary['startTime']
              ).getTime();
            // console.log('timeDiff', timeDiff);
            // console.log(
            //   timeDiff - this.vehicleDetailsReport.summary['engineHours']
            // );
            this.vehicleDetailsReport.summary['parkedTime'] =
              timeDiff - this.vehicleDetailsReport.summary['engineHours'];
          } else {
            this.vehicleDetailsReport.summary['parkedTime'] = 0;
            this.vehicleDetailsReport.summary['distance'] = 0;
            this.vehicleDetailsReport.summary['engineHours'] = 0;
          }
        });
    }
  }
  sumaryForDriverHistory(Device_ID, fromdate, todate) {
    const fromDate = moment(fromdate, "YYYY-MM-DD hh:mm:ss A");
    const fromDateString = fromDate.toISOString();//"2024-01-20T18:30:00.000Z"
    const toDate = moment(todate, "YYYY-MM-DD hh:mm:ss A");
    const toDateString = toDate.toISOString();

    const updatedFromdate=fromDateString.slice(0, -13) +'00:00:00.477Z'
    const updatedTodate=toDateString.slice(0, -13) +'23:59:59.477Z'
     
      let params = [    
        'deviceId=' + Device_ID,
        'from=' + updatedFromdate ,
        'to=' + updatedTodate ,
        'page=1',
        'start=0',
        'limit=' + 10,
        'daily=' + false,   
      ];
  
      if (fromdate && todate && Device_ID) {
        this.commonService
          .getRequest(`reports/summary?userId=${this.selectedDriverData.id}&` + params.join('&'), [])
          .then((resp: any) => {
            if (resp?.status) {
              this.vehicleDetailsReport.summary =
                resp.data && resp.data.length ? resp.data[0] : {};
              let timeDiff =
                new Date(this.vehicleDetailsReport.summary['endTime']).getTime() -
                new Date(
                  this.vehicleDetailsReport.summary['startTime']
                ).getTime();
              // console.log('timeDiff', timeDiff);
              // console.log(
              //   timeDiff - this.vehicleDetailsReport.summary['engineHours']
              // );
              this.vehicleDetailsReport.summary['parkedTime'] =
                timeDiff - this.vehicleDetailsReport.summary['engineHours'];
            } else {
              this.vehicleDetailsReport.summary['parkedTime'] = 0;
              this.vehicleDetailsReport.summary['distance'] = 0;
              this.vehicleDetailsReport.summary['engineHours'] = 0;
            }
          });
      }
    }
  geofenceToggle() {
    this.isGeofenceVisiable = !this.isGeofenceVisiable;
    if (this.isGeofenceVisiable) {
      this.zoom = 8;
      this.lat = 0;
      this.lng = 0;
      const geo = this.geofenceList.length ? this.geofenceList[0] : false;
      if (geo && geo.geofence) {
        if (geo.geofence.type === 'Feature') {
          this.lat = geo.geofence.geometry.coordinates[0].lat;
          this.lng = geo.geofence.geometry.coordinates[0].lng;
        } else {
          this.lat = geo.geofence.coordinates[0];
          this.lng = geo.geofence.coordinates[1];
        }
      }
    }
  }
  getAllGeofence() {
    this.circular = true;
    this.polygons = true;
    this.zoom = 6;
    this.lat = 20.5937;
    this.lng = 78.9629;

    // this.commonService
    //   .getRequest('geofences?all=true', [])
    //   .then((resp: any) => {
    //     if (resp?.status) {
          
    //       this.geofenceList = [];

    //       this.polygonList = [];
    //       resp.data.forEach(async (r, k) => {
    //         this.geofenceList.push(r);
    //         await this.parseGeoJson(r, k);
    //         let arr = [];

    //         if (this.geofenceList[k]?.geofence?.coordinates?.length) {
    //           for (
    //             let j = 0;
    //             j < this.geofenceList[k].geofence.coordinates.length;
    //             j++
    //           ) {
    //             arr.push({
    //               lat: this.geofenceList[k].geofence.coordinates[j].lat,
    //               lng: this.geofenceList[k].geofence.coordinates[j].long,
    //             });
    //           }
    //         }
    //         let data = {
    //           coordinates: arr,
    //           type: r?.geofence?.type,
    //           show: true,
    //         };
    //         this.polygonList.push(data);

    //       });
    //       setTimeout(() => {
    //         console.log('-----------', this.geofenceList);
    //       }, 2000);
    //       console.log(this.polygonList);
          
    //     }
    //   });




    this.commonService
      .getRequest('geofences?all=true', [])
      .then((resp: any) => {
        if (resp?.status) {
          this.geofenceList = [];

          this.polygonList = [];
          resp.data.forEach(async (r, k) => {
            r['isSelected'] = true;
            this.selectAllGeofense = true;
            this.geofenceList.push(r);
            await this.parseGeoJson(r, k);
            let arr = [];

            if (this.geofenceList[k]?.geofence?.coordinates?.length) {
              for (
                let j = 0;
                j < this.geofenceList[k].geofence.coordinates.length;
                j++
              ) {
                arr.push({
                  lat: this.geofenceList[k].geofence.coordinates[j].lat,
                  lng: this.geofenceList[k].geofence.coordinates[j].long,
                });
              }
            }
            let data = {
              coordinates: arr,
              type: r?.geofence?.type,
              show: true,
            };
            this.polygonList.push(data);
          });

          setTimeout(() => {            
            this.markerGeofenceList = [];
            Object.assign(this.markerGeofenceList, this.geofenceList);
          }, 2000);
          console.log(this.polygonList);
        }
      });
  }

  parseGeoJson(geofence, key) {
    return new Promise((resolve) => {
      if (geofence.area.indexOf('CIRCLE') > -1) {
        const coordinates = geofence.area
          .replace(/CIRCLE|\(|\)|,/g, ' ')
          .trim()
          .split(/ +/);

        this.geofenceList[key]['geofence'] = {
          type: 'Point',
          coordinates: [parseFloat(coordinates[0]), parseFloat(coordinates[1])],
          radius: parseFloat(coordinates[2]),
        };
        this.geofenceList[key]['show'] = true;
        resolve(true);
      } else if (geofence.area.indexOf('POLYGON') > -1) {
        const coordinates = geofence.area
          .replace(/POLYGON|\((|\))|,/g, ' ')
          .trim()
          .split(/ +/);

        let dataSet = [];
        let arr = [];
        coordinates.forEach((c, k) => {
          if (k % 2 === 1) {
            dataSet.push({ lat: parseFloat(arr[0]), lng: parseFloat(c) });
            arr = [];
          } else {
            arr.push(c);
          }
        });

        this.geofenceList[key]['geofence'] = {
          id: geofence.id,
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: dataSet,
          },
          properties: { name: geofence.name },
        };
        this.geofenceList[key]['show'] = true;

        resolve(true);
      } else {
        resolve(true);
      }
    });
  }
  // getAllGeofence() {
  //   this.isGeofenceVisiable = !this.isGeofenceVisiable;
  //   if(this.isGeofenceVisiable && !this.geofenceList.length) {
  //     this.commonService
  //     .getRequest('geofences?all=true', [])
  //     .then((resp: any) => {
  //       if (resp?.status) {
  //         this.geofenceList = [];
  //         resp.data.forEach(async (r, k) => {
  //           this.geofenceList.push(r);
  //           await this.parseGeoJson(r, k);
  //           let arr = [];
  //           if (this.geofenceList[k]?.geofence?.coordinates?.length) {
  //             for (
  //               let j = 0;
  //               j < this.geofenceList[k].geofence.coordinates.length;
  //               j++
  //             ) {
  //               arr.push({
  //                 lat: this.geofenceList[k].geofence.coordinates[j].lat,
  //                 lng: this.geofenceList[k].geofence.coordinates[j].long,
  //               });
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }

  // }

  // parseGeoJson(geofence, key) {
  //   return new Promise((resolve) => {
  //     if (geofence.area.indexOf('CIRCLE') > -1) {
  //       const coordinates = geofence.area
  //         .replace(/CIRCLE|\(|\)|,/g, ' ')
  //         .trim()
  //         .split(/ +/);

  //       this.geofenceList[key]['geofence'] = {
  //         type: 'Point',
  //         coordinates: [parseFloat(coordinates[0]), parseFloat(coordinates[1])],
  //         radius: parseFloat(coordinates[2]),
  //       };
  //       this.geofenceList[key]['show'] = true;
  //       resolve(true);
  //     } else if (geofence.area.indexOf('POLYGON') > -1) {
  //       const coordinates = geofence.area
  //         .replace(/POLYGON|\((|\))|,/g, ' ')
  //         .trim()
  //         .split(/ +/);

  //       let dataSet = [];
  //       let arr = [];
  //       coordinates.forEach((c, k) => {
  //         if (k % 2 === 1) {
  //           dataSet.push({ lat: parseFloat(arr[0]), lng: parseFloat(c) });
  //           arr = [];
  //         } else {
  //           arr.push(c);
  //         }
  //       });

  //       this.geofenceList[key]['geofence'] = {
  //         id: geofence.id,
  //         type: 'Feature',
  //         geometry: {
  //           type: 'Polygon',
  //           coordinates: dataSet,
  //         },
  //         properties: { name: geofence.name },
  //       };
  //       this.geofenceList[key]['show'] = true;

  //       resolve(true);
  //     } else {
  //       resolve(true);
  //     }
  //   });
  // }
  clickedMarker(infowindow) {
    //console.log('marker clicked');
    this.previousInfoWindow = infowindow;
    // if (this.previousInfoWindow) {
    //     this.previousInfoWindow.close();
    // }
    // this.previousInfoWindow = infowindow;
  }
  animatedMove(marker, current, moveto) {
    let deltalat = (moveto.latitude - current.latitude) / 500;
    let deltalng = (moveto.longitude - current.longitude) / 500;
    let i = 0;
    function moveItSelf(){
      setTimeout( ()=> {
        let lat = marker.latitude;
        let lng = marker.longitude;
        lat += deltalat;
        lng += deltalng;
        //let latlng = new google.maps.LatLng(lat, lng);
        marker.latitude = lat;
        marker.longitude = lng;
        if(i<500 && this && !this.oneVehicle) {
          i++;
          moveItSelf();
        } else {
          marker.latitude = moveto.latitude;
          marker.longitude = moveto.longitude;
        }
      }, 10);
    }
    moveItSelf();
    
  }
  isDataPointsVisiable = false;
  dataPointsToggle() {
    this.isDataPointsVisiable = !this.isDataPointsVisiable;
  }
  onScroll(event: any) {
    console.log(event.target.offsetHeight + event.target.scrollTop,event.target.scrollHeight - 1)
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 3) {
        this.loadMore();
    }
  }
  onHistoryActivityScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 3) {
      //this.loadMore();
    }
  }
  selectAllGeofenseChange() {
    this.selectDeselectGeofense(this.selectAllGeofense);
    this.circularGeofence = this.selectAllGeofense;
    this.polygonGeofence = this.selectAllGeofense;
  }
  selectAllObjectChange() {

    if (this.mark && this.mark.setMap) {
      try {
        this.mark.setMap(null);
      } catch(e: any) {}
    }
    this.selectDeselectDevices(this.selectAllObjects);
    if (this.selectAllObjects) {
      //this.markersList = this.devicesList;
      Object.assign(this.markersList, this.devicesList);
    } else {
      this.markersList = [];
    }
    
  }
  selectDeselectDevices(val: boolean) {
    if (this.mark && this.mark.setMap) {
      try {
        this.mark.setMap(null);
      } catch(e: any) {}
    }
    this.devicesList.forEach(ele => {
      ele.isSelected = val;
    })
  }
  selectDeselectGeofense(val: boolean) {
    this.geofenceList.forEach(ele => {
      ele.isSelected = val;
    })
  }
  onObjectSelectionChange(val: any, event: any) {

    event.stopPropagation();
    if (val.isSelected) {
      this.markersList.push(val);
    } else {
      this.markersList=this.allDevicesList;
      let objIndex = this.markersList.findIndex(x => x.deviceId == val.deviceId);
      if (objIndex >= 0) {
        this.markersList.splice(objIndex, 1);
      }
    }
    
  }
  // changeMapZoom(e: any) {
  //   this.zoom = e;
  // }
  collapseDeviceList() {
    this.isDeviceListVisible = false;
  }
  expandDeviceList() {
    this.isDeviceListVisible = true;
  }
  collapseTaskList(){
    this.main_col=false;
  }
  expandTaskPanel(){
    this.main_col=true;
  }
  collapseDriverPanel() {
    this.isDriverPanelVisible = false;
  }
  expandDriverPanel() {
    this.isDriverPanelVisible = true;

  }
  tabChange(val: any) {
    this.isObjectsTab = false;
    this.isEventsTab = false;
    this.isHistoryTab = false;
    this.isGeofenceTab = false;
    this.isTaskTab = false;
    this.isTrackingTrip =false;
    if (val == 'object') {
    
      this.isObjectsTab = true;
      this.hideTracking({});
    } else if (val == 'event') {
      this.isEventsTab = true;
      this.hideTracking({});
      this.loadNotification();
    } else if (val == 'history') {

      this.isHistoryTab = true;
      this.hideTracking({});
      this.selectedDevice = {};
      this.rightPanelExpand = false;
      this.getAllObjectNames();
      this.setHistoryDate("0");
    } else if (val == 'Geofence') {
      this.isGeofenceTab = true;
      this.hideTracking({});
      this.rightPanelExpand = false;
      this.selectedDevice = {};      
      //this.onFullVehicleDetail(this.selectedDevice, false);
    } else if (val === 'Tasks') {
      this.isTaskTab = true;
      this.hideTracking({});
      this.rightPanelExpand = false;
      this.selectedDevice = {};
      this.getTasksPagination();
     setTimeout(()=>{
      this.tasksTabsChange("unassigned")
     },500) 
      
    }
  }
  handleButtonClick() {
    this.expandDriverPanel();
    this.driverPanelTabChange('driver');
  }
  onEventsScroll(event: any) {
    this.loadMoreNotification(event);
  }
  loadMoreNotification(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 3) {
      this.loadNotification();
    }
    event.stopPropagation();
  }
  loadNotification() {
    let userId = '';
    if(this.userData) {
      userId = this.userData.id;
    } else {
      userId = '';
    }
 
    this.commonService
    .getRequest('reports/events/'+ userId +'?limit=25&offset='+this.eventPage, [])
  // https://app.multitrack.in:8082/api/reports/events/5?limit=10&offset=0
    .then((resp: any) => {
      this.eventPage++;
      console.log('-----------------', resp);
      if (resp?.status) {
        this.eventsDataList = this.eventsDataList.concat(resp.data);
        console.log(this.eventsDataList);
      }
    });
  }
  eventSearch() {   
  }
  getAllObjectNames() {
    
    this.commonService
      .getRequest('devices', [],true)
      .then((resp: any) => {
        console.log(resp);
        if (resp?.status) {
          this.objectNameList = resp.data;
          
          setTimeout(() => {
            this.historyForm.patchValue({
              object: this.objectNameList[0].name
            });
          }, 10);
          
        }
      });
  }
  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.objectNameList.filter(street => this._normalizeValue(street.name).includes(filterValue));
  }

  private _normalizeValue(value: any): string {
    return value && value != "" ? value.toLowerCase().replace(/\s/g, ''): "";
  }

  async showTracking(val: any) {

    console.log('showtracking=>',this.historySelectedDevice);
    if (this.historySelectedDevice) {
      this.hideTracking({});
    }

    
    // await this.closeHistorytrack();
    // this.device_id = this.historyForm.value.object;
    // this.selectedDevice = this.objectNameList.filter(x => x.id == this.historyForm.value.object)[0];
    // this.selectedDevice['deviceId'] = this.selectedDevice.id;
    this.getSingleDeviceInformation1(this.selectedDriverData.id);    
    this.onFullVehicleDetail(this.historySelectedDevice, false);
    // this.getHistoryEventformation();
    this.playpause = false;
    setTimeout(() => {
      this.onClickHistory();
    }, 1000);
    // this.onTrackVehicleHistory(val);

    await this.getDeviceInfo(this.selectedDriverData.id);
    
    // setTimeout(() => {
    //   this.onClickFuel();  
    // }, 1000);    
  }
  async getDeviceInfo(val: any) {

    console.log('getDeviceInfo=>',val)
    await this.commonService
      .getRequest('devices?userId='+val, [])//val.assignedto
      .then(async(resp: any) => {
        if (resp?.status && resp.data.length > 0) { 
          console.log('device info=>',resp) 
          console.log('device info=>',resp.data[0])         
          // this.onCustomSubmit(resp.data[0]);  
          // this.onCustomSubmitHistory(resp.data[0]) ;  //commented by vivek28/01/2024 
        }
      });
  }
  async getDeviceInfoForTask(val: any) {
    
    console.log('getDeviceInfo=>',val)
    await this.commonService
      .getRequest('devices?userId='+val.assignedto, [])//val.assignedto
      .then(async(resp: any) => {
        if (resp?.status && resp.data.length > 0) { 
          console.log('device info=>',resp) 
          console.log('device info=>',resp.data[0])         
          // this.onCustomSubmit(resp.data[0]);  
          //this.onCustomSubmitHistoryforTask(resp.data[0],val.assignedto) ;   
        }
      });
  }
  async getSingleDeviceInformation() {   

    let searchParameter = ['status=ALL','isAddressRequired=true','limit=10','offset=0'];

    searchParameter.push('deviceName='+this.historyForm.value.object);
    
    await this.commonService
      .getRequest('positions', searchParameter)
      .then(async(resp: any) => {
        console.log('single device info=>',resp.data[0].deviceId)
          this.device_id=resp.data[0].deviceId;
        if (resp?.status) {          
          resp.data.forEach((r) => {
            //if (r.status) {
              r.deviceDetails = this.getDeviceDetails(r);
              r.isShow = true;
              r["isSelected"] = true;

            //}
            this.historySelectedDevice = r;
          });          
        }
      });
  }
  getHistoryEventformation() {
      this.from = this.historyForm.value.timeFrom;
      this.to = this.historyForm.value.timeTo;    
      this.commonService
        .getRequest(
          'reports/tripsandstops?deviceId=' +
            this.device_id +
            '&from=' +
            new Date(this.from).toISOString() +
            '&to=' +
            new Date(this.to).toISOString(),
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            console.log('history list=>',resp.data)
            this.historyActivityList = resp.data;  
             
          }
        });
  }
  getHistoryEventformation1() {
    
    this.commonService
      .getRequest(
        'reports/tripsandstops?deviceId=' +
          this.device_id +
          '&from=' +
          new Date(this.from).toISOString() +
          '&to=' +
          new Date(this.to).toISOString(),
        []
      )
      .then((resp: any) => {
        if (resp && resp.data && resp.data.length) {
          console.log('history list=>',resp.data)
          this.historyActivityListTask = resp.data; 
          console.log( 'history of activity task',this.historyActivityListTask)
          // this.historyActivityList = resp.data;   
        }
      });
}
  calculateTripTime(startTime, endTime) {
    let startdate = moment(startTime);
    let enddate = moment(endTime);
    return enddate.diff(startdate);
  }
  async hideTracking(val: any) {
    this.closeHistorytrack();
  }
  async getDriverDetails() {
    let searchParameter = [];    
    searchParameter.push('deviceId='+this.selectedDevice.deviceId);
    
    await this.commonService
      .getRequest('drivers', searchParameter)
      .then(async(resp: any) => {
        if (resp.length > 0) {                 
          this.driverDetails = resp;
        }
      });
  }
  onRigthSidePanelScroll(event: any) {}
  collapseRightPanel() {
    this.rightPanelExpand = false;
  }
  expandRightPanel() {
    this.rightPanelExpand = true;
  }
  searchGeoEvent(event: any) {
    if (this.polygons) {
      if (event.target.value) {
        this.geofenceList = this.geofenceList.filter((s) =>
          s.name.includes(event.target.value)
        );
        console.log(this.geofenceList);
      } else {
        this.geofence.allGeofence().subscribe((resp) => {
          this.geofenceList = resp.data;
        });
      }
    } else if (this.circular) {
      if (event.target.value) {
        this.poiList = this.poiList.filter((s) =>
          s.poi.poiname.includes(event.target.value)
        );
        console.log(this.poiList);
      } else {
        this.geofence.allPoiList().subscribe((resp) => {
          this.poiList = resp.data;
        });
      }
    }
  }
  onclickAddPolygon() {
    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addcircular = false;
    this.googleMap = false;
    this.drawCircularGeofence = false;
    this.drawPolygonGeofence = true;
    this.addpolygon = true;
    this.allvehicles = false;
    this.isGeofenceList = false;
    //this.initialise();
  }

  onclickAddCircular() {
    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addpolygon = false;
    this.googleMap = false;
    this.drawPolygonGeofence = false;
    this.drawCircularGeofence = true;
    this.addcircular = true;
    this.allvehicles = false;
    this.isGeofenceList = false;
    console.log('-----------', this.lat, this.lng, this.zoom);
  }

  onCancelPolygon() {
    this.drawPolygonGeofence = false;
    this.addpolygon = false;
    this.circularGeofence = true;
    this.polygonGeofence = true;
    this.googleMap = true;
    this.maincard = true;
    this.allvehicles = true;
    this.isGeofenceList = true;
    this.getAllGeofence();
  }

  onCancelCircular() {
    this.drawCircularGeofence = false;
    this.addcircular = false;
    this.polygonGeofence = true;
    this.circularGeofence = true;
    this.googleMap = true;
    this.maincard = true;
    this.allvehicles = true;
    this.isGeofenceList = true;
    this.getAllGeofence();
  }

  onEditPolygon(geo) {
    // this.maincard = false;
    // this.googleMap = false;
    // this.drawPolygonGeofence = true;
    // this.addpolygon = true;
    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addcircular = false;
    this.googleMap = false;
    this.drawCircularGeofence = false;
    this.drawPolygonGeofence = true;
    this.addpolygon = true;
    this.allvehicles = false;
    this.isGeofenceList = false;
    this.markerGeofenceList = [];
    this.markerGeofenceList.push(this.geofenceList.filter(x => x.id == geo.id)[0]);
    this.polygonForm.patchValue({fencename: geo.name});
    // this.polygonForm.value.type = geo.type;
    // this.polygonForm.value.enableEntering = geo.entering;
    // this.polygonForm.value.enableExiting = geo.exiting;
  }

  onEditPoi(geo: any) {
    // this.maincard = false;
    // this.googleMap = false;
    // this.drawCircularGeofence = true;
    // this.addcircular = true;

    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addpolygon = false;
    this.googleMap = false;
    this.drawPolygonGeofence = false;
    this.drawCircularGeofence = true;
    this.addcircular = true;
    this.allvehicles = false;
    this.isGeofenceList = false;
    this.markerGeofenceList = [];
    this.markerGeofenceList.push(this.geofenceList.filter(x => x.id == geo.id)[0]);
    this.circularForm.patchValue({circularName: geo.name});
  }

  onDeletePolygon(geoid) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this geofence!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        // this.geofence.deletingGeofence(geoid).subscribe((resp) => {
        //   console.log(resp);
        // });
        this.commonService
          .deleteRequest('geofences/' + geoid)
          .then((resp: any) => {
            console.log('-------', resp);
            this.onshowPolygonList();
            Swal.fire('Deleted!', 'Your geofence has been deleted.', 'success');
            this.getAllGeofence();
          }, error => {
            Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
          });        
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
      }
    });
  }

  onDeletePoi(poiId) {
    console.log('poiId', poiId);
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this geofence!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        this.commonService
        .deleteRequest('geofences/' + poiId)
        .then((resp: any) => {
          console.log('-------', resp);
          this.onshowCircularList();
          Swal.fire('Deleted!', 'Your geofence has been deleted.', 'success');
          this.getAllGeofence();
        }, error => {
          Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
        });       
        
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
      }
    });
  }

  onPolygonSubmit() {
    console.log('this.polygonForm', this.polygonForm);
    if (this.polygonForm.invalid) {
      this.polygonForm.controls.fencename.markAsTouched();
      return;
    }
    
    if (this.polyArrayLatLng?.length) {
      let latLng = [];
      this.polyArrayLatLng.forEach((p) => {
        console.log('------------------p', p);
        latLng.push(p.lat + ' ' + p.lng);
      });
      let dataSet = {
        id: -2,
        name: this.polygonForm.value.fencename,
        description: this.polygonForm.value.fencename,
        area: 'POLYGON((' + latLng.join(', ') + '))',
        calendarId: 0,
        attributes: {},
      };
      console.log('this.polyArrayLatLng', dataSet);

      this.commonService.postRequest('geofences', dataSet).then((resp: any) => {
        if (resp) {
          this.polygonForm.reset();          
          this.circularGeofence = true;
          this.polygonGeofence = true;
          this.drawPolygonGeofence = false;
          this.addpolygon = false;
          this.onshowPolygonList();
          this.maincard = true;
          this.googleMap = true;
          this.maincard = true;
          this.allvehicles = true;
          this.isGeofenceList = true;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Geofence Added Successfully!',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.getAllGeofence();
      });      
    } else {
      alert('Pease Create Polygon Geofence.');
    }
  }

  onCircularSubmit() {
    let error = false;

    console.log('dataSet---------', this.circularForm);
    if (this.circularForm.invalid) {
      this.circularForm.controls.circularName.markAsTouched();
      return;
    }
    console.log('dataSet---------', this.circleArrayLatLng);
    if (this.circleArrayLatLng?.length) {
      console.log('dataSet', this.circleArrayLatLng);
      let dataSet = {
        id: -1,
        name: this.circularForm.value.circularName,
        description: this.circularForm.value.circularName,
        area:
          'CIRCLE (' +
          this.circleArrayLatLng[0] +
          ' ' +
          this.circleArrayLatLng[1] +
          ', ' +
          this.circleRadius +
          ')',
        calendarId: 0,
        attributes: {},
      };
      this.commonService.postRequest('geofences', dataSet).then((resp: any) => {
        if (resp) {
          console.log(resp);
          this.circularForm.reset();
          this.circleArrayLatLng = [];

          this.drawCircularGeofence = false;
          this.addcircular = false;
          this.polygonGeofence = true;
          this.circularGeofence = true;
          this.onshowCircularList();
          this.maincard = true;
          this.googleMap = true;
          this.allvehicles = true;
          this.isGeofenceList = true;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Geofence Added Successfully!',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.getAllGeofence();
      });
    } else {
      alert('Pease Create Circular Geofence.');
    }
  }

  onshowPolygonList() {
    this.circularGeofence = true;
    this.polygonGeofence = true;
    this.getAllGeofence();
  }

  onshowCircularList() {
    this.polygonGeofence = true;
    this.circularGeofence = true;
    this.getAllPoiList();
  }
  getAllPoiList() {
    this.polygons = true;
    this.circular = true;
  }
  
  zoomPolygon(geo) {
    this.zoom = 8;
    this.lat = 0;
    this.lng = 0;
    this.lat = geo.geofence.geometry.coordinates[0].lat;
    this.lng = geo.geofence.geometry.coordinates[0].lng;
  }
  zoomCircle(geo, event) {
    //this.circularDrawingManager(event);
    setTimeout(() => {
      this.zoom = 8;
      this.lat = 0;
      this.lng = 0;
      this.lat =  geo.geofence.coordinates[0];
      this.lng =  geo.geofence.coordinates[1];
    }, 100);
    
  }
  onGeofenceScroll(event: any) {}
  onGeofenceSelectionChange(val: any, event: any) {
    //console.log(val);
    event.stopPropagation();
    if (val.isSelected) {
      this.markerGeofenceList.push(val);
    } else {
      let objIndex = this.markerGeofenceList.findIndex(x => x.id == val.id);
      if (objIndex >= 0) {
        this.markerGeofenceList.splice(objIndex, 1);
      }
    }
  }
  getDeviceImage(category: any) {
    //alert(category);
    if (category == 'car') {
      return "car.jpeg";
    } else if (category == 'truck') {
      return "Truck.jpg";
    } else if (category == "motorcycle") {
      return "bike-bnw.PNG";
    } else if (category == "tractor") {
      return "tractor.jpeg";
    } else if (category == "bus") {
      return "bus-bnw.PNG";
    } else if (category == "crane") {
      return "crane.jpeg";
    } else if (category == "rickshaw") {
      return "auto-bnw.PNG";
    } else if (category == "ambulance") {
      return "ambulance-bnw.PNG";
    } else if (category == "animal") {
      return "pet-bnw.PNG";
    } else if (category == "scooter") {
      return "scoot-bnw.PNG";
    } else if (category == "van") {
      return "van.jpeg";
    } else {
      return "Truck.jpg";
    }
  }
  onSpeedgenerateBasedOnuserPreference(speed: any) {
    if (this.userData) {
      let s = 0;
      switch(this.userData.attributes.speedUnit) {
        case "kmh":
          s = speed * 1.852;
          break;
        case "kn":
          s = speed;
          break;
        case "mph":
          s = speed * 1.150;
          break;
      }
      return s; 
    }
    return speed;
  }
  getFuelFill(fuelAmt: any) {
    let fuel = (fuelAmt / 2)/100 > 0.5? 0.5:(fuelAmt / 2)/100;
    return {'transform': `rotate(${fuel}turn)`};
  }
  onPolyClick(evnt: any, polygon: any, infoWinRef: AgmInfoWindow) {
    alert(polygon.name);
  }
  sendGprsCommand(device) {
    this.commonService.pageLoadInModal.next('devicemodel');     
  }
  activityClicked(val: any) {
    if (val.startPositionId && val.endPositionId) {
      console.log(val);
      this.selectedHistoryActivity = val;
    } else {
      this.selectedHistoryActivity = {};
    }
    if (this.marker != null) {
      this.marker.setMap(null);
    }
    this.historyPath.line.setMap(null);       
    setTimeout(()=>{
      //this.closeEndMarker();
      this.mockDirections();
    },200);
    setTimeout(()=>{
      this.hightlightSelectedActivity();
    }, 1200);
  }
  hightlightSelectedActivity() {
    this.zoom = 5;//13.2; --change zoom level to display all markers on one go.
    this.locationArray = this.locationData.map(
      (l) => new google.maps.LatLng(l[0], l[1])
    );    
    this.historyPath.line = new google.maps.Polyline({
      strokeOpacity: 1,
      strokeWeight: 2.5, 
      strokeColor: '#FF0000',     
      path: [],
      map: this.map,      
    }); 
    let last = 0;   
    if (this.selectedHistoryActivity && this.selectedHistoryActivity.startPositionId) {
      let isFirst = true;
      this.locationData.forEach((l, i) => {        
        if (l[2] >= this.selectedHistoryActivity.startPositionId && l[2] <= this.selectedHistoryActivity.endPositionId) {
          if (isFirst) {
            this.origin_lat = l[0]?.latitude;
            this.origin_lng = l[1]?.longitude;
            isFirst = false;
          }                  
          this.historyPath.line.getPath().push(this.locationArray[i]);
          last++;
        }
      });
    }    
    this.destination_lat = this.locationData[last - 1]?.latitude;
    this.destination_lng = this.locationData[last - 1]?.longitude;
    this.historyPath.start = new google.maps.LatLng(
      this.origin_lat,
      this.origin_lng
    );
    const end = new google.maps.LatLng(
      this.destination_lat,
      this.destination_lng
    );
    
    this.historyPath.end = new google.maps.Marker({
      position: end,
      map: this.map,
    });
    // try {
    //   var latlng = new google.maps.LatLng(this.origin_lat, this.origin_lng);
    //   this.historyPath.poly.setMap(this.map);
    //   this.map.setCenter(latlng);
    // } catch (error) {}
    this.initRoute();
  }
  resetHistoryChartSeries() {
    var chart = new ApexCharts(
      document.querySelector('#chart'),
      this.options
    );   
    chart.render();
    chart.resetSeries();
    if (this.isHistorySpeedChart) {
      chart.showSeries('Speed');
    } else {
      chart.hideSeries('Speed');
    }
    if (this.isHistoryFuelChart) {
      chart.showSeries('Fuel');
    } else {
      chart.hideSeries('Fuel');
    }
    
  }
  tasksTabsChange(val: any) {
    debugger
    this.isTaskUnassigned = false;
    this.isTaskAssigned = false;
    this.isTaskCompleted = false; 
    this.activeTab = val;
    if (val == 'unassigned') {
      this.isTaskUnassigned = true;
      this.offset=0;
      this.tasksList = this.duplicateTasksList.filter(x => x.status == 'unassigned');  
      this.hideTracking({});
    
    } else if (val == 'assigned') {

      this.isTaskAssigned = true;
      this.hideTracking({});  
      // this.loadNotification();
      // this.tasksList = this.duplicateTasksList.filter(x => x.status != 'unassigned' && x.status != 'completed');  
      this.assignedTaskslist=this.duplicateAssignedTaskslist;
    } else if (val == 'completed') {
      this.isTaskCompleted = true;
      this.selectedDevice = {};
      this.rightPanelExpand = false;
      this.getAllObjectNames();
      this.setHistoryDate("0");
      // this.tasksList = this.duplicateTasksList.filter(x => x.status == 'completed');  
      this.completedTaskslist=this.duplicateCompletedTaskslist
    }
  }
  tasksTabsChangeForTabOnly(val: any) {
    this.isTaskUnassigned = false;
    this.isTaskAssigned = false;
    this.isTaskCompleted = false; 
    this.activeTab = val;
    if (val == 'unassigned') {
      this.isTaskUnassigned = true;
      this.offset=0;
      this.tasksList = this.duplicateTasksList.filter(x => x.status == 'unassigned');  
      this.hideTracking({});
    
    } else if (val == 'assigned') {
      this.isTaskAssigned = true;
      this.hideTracking({});  
      this.loadNotification();
      // this.tasksList = this.duplicateTasksList.filter(x => x.status != 'unassigned' && x.status != 'completed');  
      this.assignedTaskslist=this.duplicateAssignedTaskslist;
    } else if (val == 'completed') {
      this.isTaskCompleted = true;
      this.selectedDevice = {};
      this.rightPanelExpand = false;
      this.getAllObjectNames();
      this.setHistoryDate("0");
      // this.tasksList = this.duplicateTasksList.filter(x => x.status == 'completed');  
      this.completedTaskslist=this.duplicateCompletedTaskslist
    }
  }
  getAllTasks(clientid = null) {
     const today = new Date();
    const formattedToday = today.toISOString().split('T')[0] + 'T00:00:00Z';
    const formattedToday1 = today.toISOString().split('T')[0] + 'T23:59:59Z';

  this.commonService.getTasks(`traccartasks/orgId/${this.orgId}`,'', '',formattedToday,formattedToday1, '','')
  .subscribe((response) => {
    console.log('response of task list',response)
    // Object.assign(this.duplicateTasksList, response);
    this.duplicateTasksList = response;
    console.log('duplicate task list',this.duplicateTasksList);
  
    this.getTaskCountByStatus();
    // this.getTasksStatusCount();
    if(this.deletedtask){
      console.log('after deletion',this.duplicateTasksList);
      this.closeDetails();
      this.tasksTabsChange(this.tasktype.toLocaleLowerCase());
    }
    else{
      this.tasksTabsChange(this.tasktype.toLocaleLowerCase());
    } 
  }, (error) => {
  
    console.error(error);
  });
   
  }
 

  getAllTasks1(clientid:any) {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0] + 'T00:00:00Z';
    const formattedToday1 = today.toISOString().split('T')[0] + 'T23:59:59Z';
this.commonService.getTasks(`traccartasks/orgId/${this.orgId}`,'', '',formattedToday,formattedToday1,clientid,'')
.subscribe((response) => {
  Object.assign(this.duplicateTasksList, response);
  this.getTaskCountByStatus();
  this.getTasksStatusCount(formattedToday,formattedToday1);
  this.tasksTabsChange('unassigned');
 console.log('tasklist by filter=>',response);
}, (error) => {
 console.error(error);
});
  
   this.duplicateTasksList = [];
   this.tasksList = [];
                 
 }
 getTasksPagination(clientid = null){
debugger

  console.log('cabno=> ',this.cab.toString().toUpperCase())
  let formattedToday;
  let formattedToday1;
  let clientId;
  if(this.formattedFromDate_for_tasks != '' && this.formattedtoDate_for_tasks !=''){
    formattedToday=moment(this.formattedFromDate_for_tasks).startOf('day').toISOString();
    formattedToday1=moment(this.formattedtoDate_for_tasks).endOf('day').toISOString();
  clientId=this.searchClients;
  }
  else{
    const today = moment();
    formattedToday = moment(today).startOf('day').toISOString();
    formattedToday1 = moment(today).endOf('day').toISOString();
    clientId = clientid? (clientid == -1 ? '':clientid):'';
  }


this.clientId=clientId

if(this.cab !== ''){
  this.offset=0;
  let status=["assigned","acknowledged","started","dropped",'pickedup']
  if(!this.isSupervisor){
    this.commonService.getTasksPagination(`traccartasks/orgId/${this.orgId}`, '', '', formattedToday, formattedToday1, this.clientId, status, this.offset, this.limit,this.cab,this.taskId,this.sortby)
    .subscribe(
      (response) => {
        this.duplicateAssignedTaskslist=[];
        this.assignedTaskslist=[];
        this.duplicateTasksList=[];
        this.tasksList=[]
        this.duplicateAssignedTaskslist =  this.duplicateAssignedTaskslist.concat(response);
          this.isLoading = false;
          this.tasksTabsChange('assigned')
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  
  }  
  else if(this.isSupervisor){
    this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','',status,this.offset,this.limit,this.sortby)
    .subscribe(
      (response) => {
        this.duplicateAssignedTaskslist=[];
        this.assignedTaskslist=[];
        this.duplicateTasksList=[];
        this.tasksList=[]
        this.duplicateAssignedTaskslist =  this.duplicateAssignedTaskslist.concat(response); 
          this.isLoading = false;
          this.tasksTabsChange('assigned')
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  } 
  if(!this.isSupervisor){
    this.commonService.getTasksPagination(`traccartasks/orgId/${this.orgId}`,'', '',formattedToday,formattedToday1,clientId,'completed',this.offset,this.limit,this.cab,this.taskId,this.sortby)
    .subscribe((response) => {
      console.log('response of task list',response)
      this.duplicateCompletedTaskslist=[];
      this.completedTaskslist=[];
      this.duplicateCompletedTaskslist = this.duplicateCompletedTaskslist.concat(response);;
    
      console.log('duplicate task list',this.duplicateCompletedTaskslist); 
    }, (error) => {
    
      console.error(error);
    });
  }
  else if(this.isSupervisor){
    this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','','completed',this.offset,this.limit,this.sortby)
    .subscribe((response) => {
      this.duplicateCompletedTaskslist=[];
      this.completedTaskslist=[];
      this.duplicateCompletedTaskslist = this.duplicateCompletedTaskslist.concat(response);;
    

    }, (error) => {
      console.error(error);
      
    });
  } 

}
else if(this.taskId !== ''){
  this.offset=0;
  this.assignedTaskslist=[];
  this.tasksList=[];
  this.completedTaskslist=[];
  this.duplicateAssignedTaskslist=[];
  this.duplicateCompletedTaskslist=[];
  this.duplicateTasksList=[];
  if(!this.isSupervisor){
    this.commonService.getTasksPagination(`traccartasks/orgId/${this.orgId}`,'', '',formattedToday,formattedToday1, clientId,'',this.offset,this.limit,'',this.taskId,this.sortby)
    .subscribe((response) => {
      console.log('response of task list',response)
      if(response[0].status == "assigned" || response[0].status == "acknowledged" || response[0].status == "started" || response[0].status == "pickedup" || response[0].status == "dropped" )
      {
        this.duplicateAssignedTaskslist=response
        this.tasksTabsChange('assigned');
      }
       else if(response[0].status == "unassigned"){
        this.duplicateTasksList=response
        this.tasksTabsChange('unassigned');
       } 
       else if(response[0].status == "completed"){
        this.duplicateCompletedTaskslist=response
        this.tasksTabsChange('completed');
       }
      
    
    }, (error) => {
    
      console.error(error);
    });
  }
  else if(this.isSupervisor){
    this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','','',this.offset,this.limit,this.sortby)
    .pipe(
      debounceTime(500) 
    )
    .subscribe(
      (response) => {
        console.log('response of task list',response)
      this.duplicateTasksList = response;
      if(this.deletedtask){
        this.tasksTabsChange(this.tasktype.toLocaleLowerCase());
      }
      else if(this.cab != ''){
        this.tasksTabsChange('assigned')
      }
      else{
        // this.tasksTabsChange(this.tasktype.toLocaleLowerCase());
      } 
    }, (error) => {
    
      console.error(error);
    });
      
  }
}
else{
    this.getUnassignedTasksList(formattedToday,formattedToday1,this.clientId);
    this.getAssignedTasksList(formattedToday,formattedToday1,this.clientId);
    this.getCompletdTasksList(formattedToday,formattedToday1,this.clientId);
if(this.isTaskAssigned)
  setTimeout(()=>{
    this.tasksTabsChange('unassigned');},200)
 
if(this.isTaskUnassigned)
  setTimeout(()=>{
    this.tasksTabsChange('assigned');;},200)
 

if(this.isTaskCompleted)
  setTimeout(()=>{
    this.tasksTabsChange('unassigned');;},200)
}
if(this.isSupervisor){
  this.getTasksStatusCountForSupervisor(formattedToday,formattedToday1,this.clientId,this.cab);
}else{
  this.getTasksStatusCount(formattedToday,formattedToday1,this.clientId,this.cab);
}
}
getAssignedTasksList(formattedToday,formattedToday1,clientId=null){
  let status=["assigned","acknowledged","started","dropped",'pickedup']
  if(!this.isSupervisor){
    this.commonService.getTasksPagination(`traccartasks/orgId/${this.orgId}`, '', '', formattedToday, formattedToday1,clientId, status, this.offset, this.limit,'','',this.sortby)
    .subscribe(
      (response) => {
     
        this.duplicateAssignedTaskslist =  response;
          this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  
  }  
  else if(this.isSupervisor){
    this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','',status,this.offset,this.limit,this.sortby)
    .subscribe(
      (response) => {
     
        this.duplicateAssignedTaskslist =  response; 
          this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  } 
}
getUnassignedTasksList(formattedToday,formattedToday1,clientId=null){
  if(!this.isSupervisor){
    this.commonService.getTasksPagination(`traccartasks/orgId/${this.orgId}`, '', '', formattedToday, formattedToday1,clientId, 'unassigned', this.offset, this.limit,'','',this.sortby)
    .subscribe(
      (response) => {
        this.duplicateTasksList =  response;
          this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  
  }  
  else if(this.isSupervisor){
    this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','','unassigned',this.offset,this.limit,this.sortby)
    .subscribe(
      (response) => {
    
        this.duplicateTasksList =  response; 
          this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  } 
}
getCompletdTasksList(formattedToday,formattedToday1,clientId=null){

  if(!this.isSupervisor){
    this.commonService.getTasksPagination(`traccartasks/orgId/${this.orgId}`, '', '', formattedToday, formattedToday1,clientId, 'completed', this.offset, this.limit,'','',this.sortby)
    .subscribe(
      (response) => {
      
        this.duplicateCompletedTaskslist =  response;
          this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  
  }  
  else if(this.isSupervisor){
    this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','','completed',this.offset,this.limit,this.sortby)

    .subscribe(
      (response) => {
    
        this.duplicateCompletedTaskslist =  response; 
          this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  } 
}

tabchangewithStatus(status:any){
  if(status == "assigned" ||status == "acknowledged" || status == "started" || status == "pickedup" || status == "dropped" )
    {
    
      this.tasksTabsChange('assigned');
    }
     else if(status == "unassigned"){
    
      this.tasksTabsChange('unassigned');
     } 
     else if(status == "completed"){
     
      this.tasksTabsChange('completed');
     }
}
 onScrollTasks(event: any) {
  let formattedToday;
  let formattedToday1;
  if(this.formattedFromDate_for_tasks != '' && this.formattedtoDate_for_tasks !=''){
    formattedToday=moment(this.formattedFromDate_for_tasks).startOf('day').toISOString();
    formattedToday1=moment(this.formattedtoDate_for_tasks).endOf('day').toISOString();

    }
    else{
      const today = moment();
      formattedToday = moment(today).startOf('day').toISOString();
      formattedToday1 = moment(today).endOf('day').toISOString();
    }
    // console.log(event.target.offsetHeight + event.target.scrollTop,event.target.scrollHeight - 1)
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 3) {
      this.offset += this.limit;

    if(!this.isSupervisor){
      this.commonService
      .getTasksPagination(`traccartasks/orgId/${this.orgId}`, '', '', formattedToday, formattedToday1,this.clientId, 'unassigned', this.offset, this.limit,this.cab,this.taskId,this.sortby)
      .pipe(
        debounceTime(500) // Debounce to avoid multiple requests if scrolling too fast
      )
      .subscribe(
        (response) => {
          this.duplicateTasksList =  this.duplicateTasksList.concat(response);
          if(this.isTaskUnassigned){
            this.tasksList = this.duplicateTasksList.filter(x => x.status == 'unassigned'); 
          }
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    }
    else if(this.isSupervisor){
      this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','','unassigned',this.offset,this.limit,this.sortby)
      .pipe(
        debounceTime(500)
      )
      .subscribe(
        (response) => {
          this.duplicateTasksList =  this.duplicateTasksList.concat(response);
          if(this.isTaskUnassigned){
            this.tasksList = this.duplicateTasksList.filter(x => x.status == 'unassigned'); 
          }
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    }
  
    
    }
}
onScrollAssignedTasks(event: any) {
  let formattedToday;
  let formattedToday1;
  let clientId;
  clientId=this.searchClients?(this.searchClients == -1 ? '':this.searchClients):'';
  if(this.formattedFromDate_for_tasks != '' && this.formattedtoDate_for_tasks !=''){
    formattedToday=moment(this.formattedFromDate_for_tasks).startOf('day').toISOString();
    formattedToday1=moment(this.formattedtoDate_for_tasks).endOf('day').toISOString();

    }
    else{
      const today = moment();
      formattedToday = moment(today).startOf('day').toISOString();
      formattedToday1 = moment(today).endOf('day').toISOString();
    }
  let assignedFetchTasks:any=[]=[]  
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 3) {
      this.assignedlist_offset += this.assignedlist_limit;
    let status=["assigned","acknowledged","started","dropped",'pickedup']
    if(!this.isSupervisor){
      this.commonService.getTasksPagination(`traccartasks/orgId/${this.orgId}`, '', '', formattedToday, formattedToday1, this.clientId, status, this.assignedlist_offset, this.limit,this.cab,this.taskId,this.sortby)
      .pipe(
        debounceTime(500)
      )
      .subscribe(
        (response) => {
          this.duplicateAssignedTaskslist =  this.duplicateAssignedTaskslist.concat(response);
         
            this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    
    }  
    else if(this.isSupervisor){
      this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','',status,this.assignedlist_offset,this.limit,this.sortby)
      .pipe(
        debounceTime(500) 
      )
      .subscribe(
        (response) => {
          this.duplicateAssignedTaskslist =  this.duplicateAssignedTaskslist.concat(response); 
            this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    } 
}
}
onScrollCompletedTasks(event: any) {
  let formattedToday;
  let formattedToday1;
  let clientId;
  clientId=this.searchClients?(this.searchClients == -1 ? '':this.searchClients):'';
  if(this.formattedFromDate_for_tasks != '' && this.formattedtoDate_for_tasks !=''){
    formattedToday=moment(this.formattedFromDate_for_tasks).startOf('day').toISOString();
    formattedToday1=moment(this.formattedtoDate_for_tasks).endOf('day').toISOString();
    }
    else{
      const today = moment();
      formattedToday = moment(today).startOf('day').toISOString();
      formattedToday1 = moment(today).endOf('day').toISOString();
    }
    // console.log(event.target.offsetHeight + event.target.scrollTop,event.target.scrollHeight - 1)
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 3) {
      this.completedlist_offset += this.completedlist_limit;
    if(!this.isSupervisor){
      this.commonService
      .getTasksPagination(`traccartasks/orgId/${this.orgId}`,this.clientId, '', formattedToday, formattedToday1, '', 'completed',this.completedlist_offset,this.completedlist_limit,this.cab,this.taskId,this.sortby)
      .pipe(
        debounceTime(500) // Debounce to avoid multiple requests if scrolling too fast
      )
      .subscribe(
        (response) => {
          this.completedTaskslist =  this.duplicateCompletedTaskslist.concat(response);
            // this.tasksList = this.duplicateTasksList.filter(x => x.status == 'unassigned'); 
            this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    
    }  
    else if(this.isSupervisor){
      this.commonService.getTasksForSupervisor(`traccartasks/supervisor/${this.orgId}`,formattedToday,formattedToday1,'','','completed',this.completedlist_offset,this.completedlist_limit,this.sortby)
      .pipe(
        debounceTime(500) 
      )
      .subscribe(
        (response) => {
          this.completedTaskslist =  this.duplicateCompletedTaskslist.concat(response); 
            this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
    }  
    
    }
}
getTaskCountByStatus() {
    this.unassignedTaskCount = this.duplicateTasksList.length;
    this.assignedTaskCount = this.duplicateAssignedTaskslist.length;
    this.completedTaskCount = this.duplicateCompletedTaskslist.length;
  }

  goToDetails(task: any) {

    this.isTaskDetails = true;
    this.selectedTaskIdInTaskDetail = task.id;
    this.selectedTaskDeails = [];
    this.selectedTaskDeails.push(task);
    console.log('selected task=>',this.selectedTaskDeails[0])
    this.tasksDetailsTabsChange('details');
    this.getClientDetailed(task.clientid)
  }
  goToDetailsforHistory(task: any) {
  
    this.isTaskDetails = true;
    this.selectedTaskIdInTaskDetail = task.id;
    this.selectedTaskDeails = [];
    this.selectedTaskDeails.push(task);
    console.log('selected task=>',this.selectedTaskDeails[0])
    this.tasksDetailsTabsChange('history');
    this.getClientDetailed(task.clientid)
  }
  closeDetails() {
    this.isTaskDetails = false;
    this.hideTracking({});
  }
  tasksDetailsTabsChange(val: any) {

    this.isTaskDetailDetail = false;
    // this.isDriversTaskDetails=false;
    this.isTaskDetailsCustomer = false;
    this.isTaskDetailsHistory = false; 
    this.isTaskDetailDetail = false;   
    // this.isDriversDetailTodaysTaskDetails=false;
    // this.isDriversDetailTaskDetails=false;
    if (val == 'details') {
      this.isTaskDetailDetail = true;
      // this.isDriversDetailTaskDetails =true;
    } else if (val == 'customer') {
      this.isTaskDetailsCustomer = true;
      // this.hideTracking({});
      // this.loadNotification();
    } else if (val == 'history') {
    
      this.isTaskDetailsHistory = true;
      // this.isDriversDetailTaskDetails =false;
      this.getTaskHistoryDetails();
    }
  }

  tasksDetailsTabsChange2(val: any) {

    // this.isTaskDetailDetail = false;
    this.isTaskDetailsCustomer = false;
    // this.isTaskDetailsHistory = false; 
    this.isTaskDetailDetail1 = false;   
    this.isTaskHistory = false;
    this.isTaskTimeline=false;
    this.isDriversDetailTaskDetails = false;
    this.isDriversDetailTodaysTaskDetails=false;
    this.isDriversDetailTaskDetails=false;//1
   
    if (val == 'details') {
      this.isTaskDetailDetail1 = true;
      this.isDriversDetailTaskDetails =true; 
      this.isDriversDetailTodaysTaskDetails=true;
      this.driversTaskDetailsTabsChange("isLessInfo")
    } else if (val == 'history') {
      this.isTaskHistory = true;

    }
    else if(val== 'timeline'){
      this.isTaskTimeline = true;
    
      
    }
  }
  showPathHistory() {
    this.tasksDetailsTabsChange('history');
  }

  getTaskHistoryDetails() {

    if (this.selectedTaskDeails[0] && this.selectedTaskDeails[0].id) {
      this.commonService.getRequest(`traccartasks/timelinetasks/${this.selectedTaskDeails[0].id}`, []).then((resp: any) => {
        if (resp?.status) {
            this.taskHistoryDetails = resp.data;
        }
      });
    }
  }

  driverPanelTabChange(val: any) {

    this.isDriversTab = false;    
    if (val == 'driver') {
      this.isDriversTab = true;  
      this.driverTabChange('free');    
    }
    else if(val== "AVAILABLE"){
      this.isDriversTab = true;  
      this.driverTabChange('free');
    }
    else if(val== "INACTIVE"){
      this.isDriversTab = true;  
      this.driverTabChange('inactive');
    }
    else if(val== "BUSY"){
      this.isDriversTab = true;  
      this.driverTabChange('busy');
    }

  }
  driverTabChange(val: any) {
    this.isDriverFree = false;   
    this.isDriverBusy = false;
    this.isDriverInactive = false;
    if (val == 'free') {
      this.isDriverFree = true;   
      // this.getDriversList();
      this.getDriverListByFreeStatus();   
    } else if (val == 'busy') {
      this.isDriverBusy = true;
      this.getDriverListByBusyStatus();
    } else if (val == 'inactive')  {
      this.isDriverInactive = true;
      this.getDriverListByInactiveStatus();
    }
 
  }

  getDriversList() {
  
    // this.commonService.getRequest('traccartasks/drivertasksforday', []).then((resp: any) => {
      this.commonService.getRequest('traccartasks/drivertasksforday/'+`${this.orgId}`, []).then((resp: any) => {
      // console.log("tasks data");
      console.log('driverList',resp);
      if (resp?.status) {
          this.driversList = resp.data.filter(x => x.userattributes.role == 'Employee');
          console.log( 'driverlist=>',this.driversList)
          let currentDate = moment(new Date());
          this.driversList.forEach(ele => {
            let endDate = ele?.lastupdate ? moment(new Date(ele?.lastupdate)) : 'NA';
            if (endDate == 'NA') {
              ele['hoursAgo'] = endDate + ' hours ago';
            } else {
              ele['hoursAgo'] = currentDate.diff(endDate, "hours") + ' hours ago';
            }
          })
      }
    });
  }
 getDriverListByInactiveStatus(){
    this.commonService.getRequest('dashboard/drivers/list/INACTIVE', []).
    then((resp: any) => {
    
      this.driversInactiveList = resp.data;
      console.log('driverList by Inactive',this.driversInactiveList)

      let currentDate = moment(new Date());
      this.driversList.forEach(ele => {
        let endDate = ele?.lastupdate ? moment(new Date(ele?.lastupdate)) : 'NA';
        if (endDate == 'NA') {
          ele['hoursAgo'] = endDate + ' hours ago';
        } else {
          ele['hoursAgo'] = currentDate.diff(endDate, "hours") + ' hours ago';
        }
      })
  })
 
 }
 getDriverListByBusyStatus(){
  this.commonService.getRequest('dashboard/drivers/list/BUSY', []).
  then((resp: any) => {
  
    this.driversBusyList = resp.data;
    console.log('driverList by Busy',this.driversBusyList)

    let currentDate = moment(new Date());
    this.driversList.forEach(ele => {
      let endDate = ele?.lastupdate ? moment(new Date(ele?.lastupdate)) : 'NA';
      if (endDate == 'NA') {
        ele['hoursAgo'] = endDate + ' hours ago';
      } else {
        ele['hoursAgo'] = currentDate.diff(endDate, "hours") + ' hours ago';
      }
    })
})

}
getDriverDataByBusyForDriverCabSelect(userID:any){

  this.commonService.getRequest('dashboard/drivers/list/BUSY', []).
  then((resp: any) => {
this.driversData = resp.data;
console.log('driverList by Busy',this.driversData);
this.driversList= this.driversData.filter(ele => ele.userid === userID);
console.log( this.driversList);
this.openDriverTaskDetails(this.driversList[0]);
    let currentDate = moment(new Date());
    this.driversList.forEach(ele => {
      let endDate = ele?.lastupdate ? moment(new Date(ele?.lastupdate)) : 'NA';
      if (endDate == 'NA') {
        ele['hoursAgo'] = endDate + ' hours ago';
      } else {
        ele['hoursAgo'] = currentDate.diff(endDate, "hours") + ' hours ago';
      }
    })
})

}

getDriverListByFreeStatus(){
  this.commonService.getRequest('dashboard/drivers/list/AVAILABLE', []).
  then((resp: any) => {
  
    this.driversFreeList = resp.data;
    console.log('driverList by free',this.driversFreeList)

    let currentDate = moment(new Date());
    this.driversList.forEach(ele => {
      let endDate = ele?.lastupdate ? moment(new Date(ele?.lastupdate)) : 'NA';
      if (endDate == 'NA') {
        ele['hoursAgo'] = endDate + ' hours ago';
      } else {
        ele['hoursAgo'] = currentDate.diff(endDate, "hours") + ' hours ago';
      }
    })
})

}
  
  getBgColorBasedOnTaskStatus(status: any) {
    let statusclasses = {
      "task-status-unassigned": status == "unassigned",
      "task-status-assigned": status == "assigned",
      "task-status-acknowledge": status == "acknowledged",//vivek custom code
      "task-status-progress": status == "inprogress",
      "task-status-completed": status == "completed",
      "task-status-started":status == "started",//vivek custom code
      "task-status-dropped":status == "dropped",
      "task-status-pickedup":status == "pickedup"
    };
    return statusclasses;
  }
  getColorBasedOnTaskType(type:any){
    let typeClasses={
      "pickup-icon-color": type == "pickup",
      "drop-icon-color": type == "drop",
    }
    return typeClasses;
  }
  getTextColorBasedOnTaskStatus(status: any) {
    let statusclasses = {
      "task-status-unassigned-text": status == "unassigned",
      "task-status-assigned-text": status == "assigned",
      "task-status-acknowledge-text": status == "acknowledged",//vivek custom code
      "task-status-progress-text": status == "inprogress",
      "task-status-completed-text": status == "completed",
      "task-status-started-text": status == "started",//vivek custom code
      "task-status-dropped-text":status == "dropped",
      "task-status-pickedup-text":status == "pickedup"

    };
    return statusclasses;
  }

  getTasktotalTime(task: any) {
    if (task) {
      return moment(moment(task.actualendtime).diff(task.actualstarttime)).format("hh:mm:ss");
    }
  }
  taskDetailTaskDrdChange() {
  
    this.selectedTaskDeails = [];
    this.selectedTaskDeails.push(this.duplicateTasksList.filter(x => x.id == this.selectedTaskIdInTaskDetail)[0]);
    if (this.isTaskDetailsHistory) {
      this.getTaskHistoryDetails();

    }
  }
assignDriverEvent(task: any,requiredSecondTime = false) {
    this.selectedTaskDeails = [];
    this.selectedTaskDeails.push(task);
    console.log('task details=>',task)
    this.commonService.pageLoadInModal.next("assigndriver");  
    setTimeout(() => {
      if (requiredSecondTime) {
        this.commonService.pageLoadInModal.next("assigndriver"); 
      } 
    }, 100);
    
  }

  taskFilterEvent(requiredSecondTime = false) {
    this.commonService.pageLoadInModal.next("filterTask");  
  }
  resetModalVariable() {
    debugger
    this.offset=0;
    this.getTasksPagination();
    this.isAssignDriverPopupOpen = false;
    this.isSendGPRSCommandPopupOpen = false;
    this.isfilterTask =false;
  }
  driverAssignedEvent(val:any) {

    this.getAllTasks(this.searchClients);
  }
  driverFullName:any=''
  openDriverTaskDetails(driverDetails: any) {

this.driverFullName= (driverDetails.userattributes.fname? driverDetails.userattributes.fname:'') +
' ' +(driverDetails.userattributes.lname ? driverDetails.userattributes.lname : '');
  console.log('driver details=>',driverDetails)
   this.driverDetailsOnSelect = driverDetails;
   console.log('driver details',this.driverDetailsOnSelect)
    this.isDriversTab = false;
    this.isDriversTaskDetails = true;
    this.isDriversDetailTaskDetails = true;  
    this.selectedDriverIdInTaskDetail = driverDetails.userid;
    this.cabno= driverDetails.userattributes.cabno;
    this.isDriversDetailTodaysTaskDetails= true;
    this.getTodaysTaskDetailsbyDriverId(driverDetails.userid);
    this.getDeviceInformationByUserId(driverDetails.userid)
     this.getDriverTimelineTasks(driverDetails);
    // this.driverDetailDrdChange();
    this.tasksDetailsTabsChange2('details');
   this.getDeviceAddress1(this.driverDetailsOnSelect.userattributes.location.lat,this.driverDetailsOnSelect.userattributes.location.lon);
  }
  closeDriverDetails() {
    this.isDriversTab = true;
    this.isDriversTaskDetails = false;
  }
  getDeviceAddress1(latitude,longitude) {

      let params = [
        'latitude=' + longitude,
        'longitude=' + latitude,
        'maxDistance=1000'
      ]
        this.commonService.getRequest('statistics/getAddress?' + params.join('&'), [])
        .then((resp: any) => {
          if(resp.data && resp.data.address){
            console.log('address',resp.data.address)
           this.lastLocation =resp.data.address
          }
        }).finally(()=>{
         
        });
    }

    toggleAddressDisplay(index: number): void {
      this.selectedRowIndex = (this.selectedRowIndex === index) ? -1 : index;
    }
  getDeviceAddressOnRoutes(latitude,longitude,index: number):string {
    const actualIndex = index + ((this.page - 1) * this.itemsPerPage);
    this.selectedRowIndex = index;
    this.pageno=this.page;
let address=''
      let params = [
        'latitude=' + latitude,
        'longitude=' + longitude,
        'maxDistance=1000'
      ]
        this.commonService.getRequest('statistics/getAddress?' + params.join('&'), [])
        .then((resp: any) => {
          this.isInfoHide= false;
          if(resp.data && resp.data.address){
            console.log('address',resp.data.address)
            this.allLocationTrackData[actualIndex].address=resp.data.address
             
          }
        }).finally(()=>{
         
        });

        return this.allLocationTrackData[actualIndex].address;
    }  
 getDeviceAddress2(latitude,longitude) {
 let params = [
        'latitude=' + longitude,
        'longitude=' + latitude,
        'maxDistance=1000'
      ]
        this.commonService.getRequest('statistics/getAddress?' + params.join('&'), [])
        .then((resp: any) => {
          if(resp.data && resp.data.address){
            console.log('address',resp.data.address)
           this.clientLocation =resp.data.address
          }
        }).finally(()=>{
         
        });
    }
 
  driversTaskDetailsTabsChange(val: any) {

    this.isDriversDetailTodaysTaskDetails = false;
    // this.isDriversDetailTaskDetails = true;    
    if (val == 'isMoreInfo') {
      this.isDriversDetailTodaysTaskDetails = false;
      this.driverDetailDrdChange();
      this.isDriverDetails=true;
      this.isMoreInfo = false;
      this.isLessInfo = true;
      
      //this.hideTracking({});

    }
    else if(val == 'isLessInfo'){
      this.isDriversDetailTodaysTaskDetails = true;
      this.isDriversDetailTaskDetails = true;
      this.isLessInfo = false;
      this.isDriverDetails=false;
      this.isMoreInfo = true;
    }
    // if(this.isMoreInfo === true)
    // {
    // this.isMoreInfo = false;
    // this.isLessInfo = true;
    // this.isDriverDetails=true;
    // // this.isDriversDetailTaskDetails= false;

    // }
    // else
    // {
    //   this.isMoreInfo = true;
    //   this.isLessInfo == false;
    //   this.isDriverDetails=false;
    //   this.isDriversDetailTaskDetails =  true;
    // }

  }
  driverDetailDrdChange() {




    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0] + 'T00:00:00Z';
    const formattedToday1 = today.toISOString().split('T')[0] + 'T23:59:59Z';

const assignedTo:any = this.selectedDriverIdInTaskDetail ;
const supervisorId:any =  '';
const clientId:any =  '';

  this.commonService.getTasks(`traccartasks/orgId/${this.orgId}`,'', assignedTo,formattedToday,formattedToday1, '','')
  .subscribe((resp) => {
    if (resp?.status) {
      this.driversTaskDetailList = resp.data;
      console.log('driver tasks=>',this.driversTaskDetailList);
      const filteredData = resp.data.filter(item => ['assigned', 'started','acknowledged','inprogress','completed'].includes(item.status));
      this.totalResult = filteredData.length;
      console.log("Total Result:", this.totalResult);
      const completed= resp.data.filter(item => ['completed'].includes(item.status));
      this.remainingTasks =  this.totalResult - completed.length;


  }
  });
  }


  changeMapZoom(e: any) {
    if (e == 'p') {
      this.zoom += 1;
      if (this.zoom == 22) {
        this.zoom = 22;
      }
    } else if (e == 'm') {
      this.zoom -= 1;
      // if (this.zoom == 0) {
      //   this.zoom = 0;
      // }
    } else {
      this.zoom = e;
    }
  }
  changeMapZoomForHistory(e: any) {
    if (e == 'p') {
      this.zoomSingle += 1;
      if (this.zoomSingle == 22) {
        this.zoomSingle = 22;
      }
    } else if (e == 'm') {
      this.zoomSingle -= 1;
      if (this.zoomSingle == 0) {
        this.zoomSingle = 0;
      }
    } else {
      this.zoomSingle = e;
    }
  }

  updateTasks(task: any, data: any) {
    task.status = data.status;
    this.commonService
    .putRequest(`traccartasks/${task.id}`, task)
    .then((resp: any) => {
      if (resp?.status) {
        // this.driverAssignedEvent.emit(resp);
        // this.commonService.commonModel.next(false);
        Swal.fire({
          icon: "success",
          title: `Task updated successfully`,
          showConfirmButton: false,
          timer: 1500,
        });        
      }      
    }, err => {
      Swal.fire({
        icon: "error",
        title: "Something Went Wrong",
        showConfirmButton: false,
        timer: 1500,
      });
    });
  }
  editTask(task: any) {
    this.commonService.headerPopupEvent.next({page: 'taskEdit', data: task, value: true});
  }
  tasksFilterChange() {
    if (this.tasksFilter == 'client') {
      this.taskId='';
      this
      this.getAllCustomers();
    }
    else if(this.tasksFilter == 'task'){
      this.cab='';
      this.taskId='';
      this.searchCab='';
      this.searchTasks='';
      this.getTasksPagination();
    }
    else if(this.tasksFilter == 'cabno'){
      this.cab='';
      this.taskId='';
      this.searchCab='';
      this.searchTasks='';
      this.getTasksPagination();
    }
    else if(this.tasksFilter == 'sortby'){
      this.cab='';
      this.taskId='';
      this.searchCab='';
      this.searchTasks='';
      this.getTasksPagination();
    }
  }
  tasksFilterByTaskId(){
    this.cab='';
    this.searchCab='';
    this.taskId=this.searchTasks;
    this.getTasksPagination();
  }
  tasksFilterByTaskByCab(){
    this.taskId=''
    this.cab=this.searchCab;
    this.getTasksPagination();
  }
  sortFilterChange(){
    this.cab='';
    this.searchCab='';
    this.taskId='';
    this.clientId=''
    this.getTasksPagination();
  }
  getAllCustomers() {
   
          this.customerList = this.customerList.filter(customer=> customer.attributes?.role == 'clients');
         
          console.log("driver details",this.driverList1)
     
  
  }
  clientFilterChange() {
    // this.getAllTasks1(this.searchClients);
    this.clientId=this.searchClients;
    this.getTasksPagination(this.searchClients);
  }

  getLocationOnMap(){
    console.log('getLocation')
    console.log('selected task details',this.selectedTaskDeails[0].attributes.trip_startlocation)
    console.log('selected task details1',this.selectedTaskDeails[0].attributes.trip_startlocation[1])
  }

  // Add these methods to your component class
  hoverEffect(event: Event) {
    const element = (event.currentTarget as HTMLElement);
    element.classList.add('hovered');
  }
  
  removeHoverEffect(event: Event) {
    const element = (event.currentTarget as HTMLElement);
    element.classList.remove('hovered');
  }

  openChangeStopStatusModal(selectedTaskDetails: any): void {
    const dialogRef = this.dialog.open(ChangeStatusOfTaskComponent, {
      width: '700px', // Set the width as needed
      height: '300px',
      data: selectedTaskDetails
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.getTasksPagination();
      console.log('The dialog was closed');
    });
  }

  openRoutePathModal(selectedTaskDetails: any): void {
 
    const dialogRef = this.dialog.open(RouteModalComponent, {
      width: '700px', // Set the width as needed
      height: '500px',
      data: selectedTaskDetails
    });
  
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  openFilterModal(): void {
    const dialogRef = this.dialog.open(NewFilterModalComponent, {
      width: '500px', // Set the width as needed
      height: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
   
      debugger
      this.formattedFromDate_for_tasks = result.formattedFromDate;
      this.formattedtoDate_for_tasks = result.formattedToDate;
      if(this.formattedFromDate_for_tasks == undefined && this.formattedtoDate_for_tasks== undefined){
        const today = moment();
        this.formattedFromDate_for_tasks = moment(today).startOf('day').toISOString();
        this.formattedtoDate_for_tasks = moment(today).endOf('day').toISOString();
      }
      this.searchClients = result.searchClients;
      this.offset=0;
      // this.clientId=this.searchClients
      this.getTasksPagination(this.searchClients);
      // Use the received data as needed
      console.log("Formatted From Date:", this.formattedFromDate);
      console.log("Formatted To Date:", this.formattedToDate);
      console.log("Search Clients:", this.searchClients);
  
    
      this.tasksTabsChange('unassigned');   
      
    });
  }
  openLocationModal(selectedTaskDetails: any): void {
 
    const dialogRef = this.dialog.open(ShowLocationPopupComponent, {
      width: '700px', // Set the width as needed
      height: '500px',
      data: selectedTaskDetails,
      panelClass: 'my-custom-dialog-class',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.getTasksPagination()
      console.log('The dialog was closed');
    });
  }
  openEditStopStatusModal(selectedTaskDetails: any): void {
    const dialogRef = this.dialog.open(EditStopOfTaskComponent, {
      width: '700px', // Set the width as needed
      height: '500px',
      data: selectedTaskDetails
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.getTasksPagination();
    });
  } 
  deleteTask(task: any) {
    // Show confirmation dialog
    Swal.fire({
      title: 'Are you sure you want to remove?',
      text: 'You will not be able to recover this task!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the delete API
        this.commonService.deleteResource(`traccartasks/${task.id}`).subscribe(
          (resp) => {
            // On success, show success message
            Swal.fire({
              title: 'Deleted!',
              text: 'Task has been deleted.',
              icon: 'success',
              timer: 2000, // 2000 milliseconds = 2 seconds
              showConfirmButton: false
            }).then(() => {
              // Perform any additional actions
              this.deletedtask = true;
              this.tasktype = task.status;
              this.getTasksPagination();
            });
          },
          (error) => {
            // Handle error, if needed
            console.error('Delete failed:', error);
            Swal.fire('Error!', 'There was a problem deleting the task.', 'error');
          }
        );
      }
    });
  }
  getTodaysTaskDetailsbyDriverId(userId :any){

    const today = new Date().toISOString().split('T')[0];
    this.formattedFromDate =  `${today}T00:00:00Z`;
    this.formattedToDate =  `${today}T23:59:59Z`;
  
    const assignedTo:any = userId;
    const supervisorId:any =  '';
    // const fromDate = this.formattedFromDate ?? '';
    // const toDate = this.formattedFromDate ?? '';
    const clientId:any =  '';

    this.commonService.getTasks(`traccartasks/orgId/${this.orgId}`,assignedTo, supervisorId, this.formattedFromDate, this.formattedToDate, clientId,'')
      .subscribe((resp) => {
        this.taskListData = resp
        const filteredData = resp.filter(item => ['assigned', 'started','acknowledged','inprogress','completed'].includes(item.status));
        this.totalResult = filteredData.length;
        console.log("Total Result:", this.totalResult);
        const completed= resp.filter(item => ['completed'].includes(item.status));
        this.remainingTasks =  this.totalResult - completed.length;
      }, (error) => {
        // Handle errors here
        console.error(error);
      });
  }

  getCountByStatus(){
  
    this.commonService.getRequest('dashboard/drivers/status', []).
    then((resp: any) => {
      this.eventStatusCount=resp.data;
      console.log('task count by Status2=>',resp)
      console.log('task count by Status2=>', this.eventStatusCount)
      
  })
  }
  getRecordCount(status: string): number {
    const statusObj = this.eventStatusCount.find(item => item.status === status);
    return statusObj ? statusObj.recordCount : 0;
  }

  handleFilterData(data: any) {
    this.duplicateTasksList = [];
          Object.assign(this.duplicateTasksList,data);
          console.log('filtered data=>',data)
          //this.tasksList = resp.data.filter(x => x.status == 'unassigned');  
          this.getTaskCountByStatus();
          // this.getTasksStatusCount();
          this.tasksTabsChange('unassigned');       
  }

  ngOnChanges(changes: SimpleChanges) {
   
    if (changes.duplicateTasksList) {
      // Handle changes to duplicateTasksList
      const newTasksList = changes.duplicateTasksList.currentValue;
      console.log('Changes detected in duplicateTasksList:', newTasksList);

      // Perform additional actions as needed
    }
  }

 async onCustomSubmitHistory1(val: any) {
  this.isDriverPanelVisible=false;
  console.log('taskdetails',val)

  this.from = val.actualstarttime;
  this.to = val.actualendtime;
   
    console.log('history selected device',this.historySelectedDevice)
    if (this.historySelectedDevice) {
      this.hideTracking({});
    }
     this.getSingleDeviceInformationfortask(val); 
     this.onFullVehicleDetail(this.historySelectedDevice, false);
    //  this.getHistoryEventformation1();
     this.playpause = false;
     setTimeout(() => {
      this.onClickHistory();
    }, 1000);
     await this.getDeviceInfoForTask(val);
 
     }
      getSingleDeviceInformation1(val){
      this.commonService
      .getRequest(
        'devices/userid/' +
        val,
        []
      )
      .then((resp: any) => {  
        console.log('oncustom sub task=>',resp)
        console.log('oncustom sub=>',resp.data)
        this.device_id=resp.data.id;
        this.getHistoryEventformation()
        this.onCustomSubmitHistory(resp.data);
        this.sumaryForDriverHistory(this.device_id,this.from,this.to)
        if (resp?.status) {          
          resp.data.forEach((r) => {
            //if (r.status) {
              r.deviceDetails = this.getDeviceDetails(r);
              r.isShow = true;
              r["isSelected"] = true;
            //}
            this.historySelectedDevice = r;
          });          
        }
      });
}

getDeviceInformationByUserId(val){
 
  this.commonService
  .getRequest(
    'devices/userid/' +
    val,
    []
  )
  .then((resp: any) => {  
 
    this.deviceId=resp.data.id;
   this.dailySumaryForDriverData(resp.data.id,this.selectedDate,this.selectedDate);
  });
}
getDeviceInformationByUserIdForTracking(val){

this.isTrackingTrip =true;
console.log("task=>",val);

const directionsService = new google.maps.DirectionsService();
const directionsRenderer = new google.maps.DirectionsRenderer();
  this.origin1 = { lat:val.startlatitudelocation,lng:val.startlongitudelocation};
  this.destination1={lat:val.endlatitudelocation,lng:val.endlongitudelocation};
  this.db_calculateAndDisplayRoute(directionsService,directionsRenderer,'');
  this.commonService
  .getRequest(
    'devices/userid/' +
    val.assignedto
    ,
    []
  )
  .then((resp: any) => {  
  this.deviceId=resp.data.id;
  this.historySelectedDevice=this.devicesList.filter(item => item.deviceId === this.deviceId);
  this.onFullVehicleDetail(this.historySelectedDevice[0],true);
})
}
getSingleDeviceInformationfortask(val){

  this.commonService
  .getRequest(
    'devices/userid/' +
      val.assignedto,
    []
  )
  .then((resp: any) => {  
    console.log('oncustom sub task=>',resp)
    console.log('oncustom sub=>',resp.data)
    this.device_id=resp.data.id;
    this.getHistoryEventformation1()
    this.onCustomSubmitHistoryforTask(resp.data,this.historyForm.value.object)
    this.dailySumaryForTask(this.device_id,this.from,this.to,val)
    if (resp?.status) {          
      resp.data.forEach((r) => {
        //if (r.status) {
          r.deviceDetails = this.getDeviceDetails(r);
          r.isShow = true;
          r["isSelected"] = true;
        //}
        this.historySelectedDevice = r;
      });          
    }
  });
}
     private formatToLocalISOString(date: Date): string {
      const datePipe = new DatePipe('en-US');
      const localDateTimeStr = datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss.SSSXXX');
      return localDateTimeStr ? localDateTimeStr : '';
    }
    convertDate(): void {
      // Extract date and time components
      const datePart = this.originalDateString.substring(0, 10);
      const timePart = this.originalDateString.substring(11, 19); 
      
      // Adjust time to "09:29:59"
      const adjustedTimePart = "09:29:59";
     
      this.convertedDate = `${datePart}T${adjustedTimePart}Z`;
      console.log("converted date",this.convertedDate)
    }

    filteredOption1() {

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterforDrivers(value))
      );
    }
    private _filterforPractice(value: string): string[] {
      const filterValue = value.toLowerCase();
  
      return this.optionsPractice.filter(option => option.toLowerCase().includes(filterValue));
    }
    onDriverSelectionChange() {
      // Add your logic here to handle the event when the selected option changes
      console.log('Selected Driver ID:', this.selectedDriverData.id);

    }
   
  displayFn(driver): string {
    return driver && driver.name ?   driver.name  : '';
  }

  private _filterforDrivers(value: string): any[] {
    const filterValue = value.toLowerCase().trim(); // Convert input value to lowercase and trim whitespace
  
    return this.driversList2
      .filter(option => {
        const fnameString = option?.attributes?.fname?.toString()?.toLowerCase();
        const lnameString = option?.attributes?.lname?.toString()?.toLowerCase();
  
        if (!fnameString && !lnameString) {
          return false; // Skip if both fname and lname are null or undefined
        }
  
        // Check if fname or lname contains the filterValue
        return (fnameString && fnameString.includes(filterValue)) ||
               (lnameString && lnameString.includes(filterValue));
      })
      .map(option => ({
        id: option.id,  
        display: `${option.attributes?.fname} ${option.attributes?.lname}` // You can return first and last name or customize as needed
      }));
  }
  
  
  onOptionSelected(event: any) {

    console.log('Selected Driver:', event.option);
    // You can perform additional actions here upon selecting an option
  } 

extractAddressUntilCity(fullAddress: string): string {
  const cityIndex = fullAddress.indexOf(', Pune');  // Adjust 'Pune' based on your city name
  const partialAddress = cityIndex !== -1 ? fullAddress.slice(0, cityIndex).trim() : fullAddress.trim();
  return partialAddress;
}

  
    displayFnDriver(driver: any): string{
      if (driver && driver.attributes && driver.attributes.cabno && driver.name) {
        return `${driver.attributes.cabno}`;
    } else {
        return '';
    }
  }
    driverSelected(driver){
console.log("selected driver = > ", driver)
this.selectedDriverData=driver;
    }
  getDriversListfordropdown() {

    this.commonService.getRequest(`users?orgId=${this.orgId}&role=Employee`, []).then((resp: any) => {
      if (resp?.status) {
          this.driversList2 = resp.data
      }   
  })
  this.commonService.getRequest(`users?orgId=${this.orgId}&role=clients`, []).then((resp: any) => {
    if (resp?.status) {
      this.customerList =resp.data   
    }       
})


} 


onOptionSelected1(event:MatAutocompleteSelectedEvent): void {

  const selectedDriverId = event.option.viewValue;


  console.log('Selected Driver ID:', selectedDriverId);

}

onchange(event:any){

console.log(event.value)
}

onOptionSelected2(event:any){
  let id=event.target.value
  console.log("driver id",id)
}
getHistoryDetails(task:any){

  this.tabChange('Tasks');
	if(task.status === 'assigned' ||task.status === 'started' || task.status === 'acknowledged'  ||task.status === 'dropped' || task.status === 'started' || task.status === 'pickedup'){
	this.tasksTabsChange('assigned');
	this.goToDetailsforHistory(task)
	}
	else if(task.status== 'completed'){
	this.tasksTabsChange('completed')
	this.goToDetailsforHistory(task);
	}
 
  
}

getDriverTimelineTasks(driverDetails:any): void {

  let id=driverDetails.userid;
  this.driverDid=driverDetails.userattributes.did
  this.commonService.getTimelineTasks(`traccartasks/timelinetasks/driver/${id}`).subscribe(
    (response) => {
 
      console.log('task timeline Response:', response);
   this.timelinetaskList=response;
    },
    (error) => {
      console.error('API Error:', error);
      // Handle the error as needed
    }
  );
}

getClientDetailed(id:any) {
       this.clientData = this.customerList;
         this.clientData = this.clientData.filter(customer=> customer.email == id);
         console.log( this.clientData)
         this.getDeviceAddress2(this.clientData[0].attributes.location.lat,this.clientData[0].attributes.location.lon)
   
  }
  onDriverCabSelect(cabno:any){
    this.isDriverPanelVisible=true;
    this.driverTabChange('busy');
    this.getDriverDataByBusyForDriverCabSelect(cabno);
    
  }

extractUntilCityFromAddress(fullAddress: string): string {

  
    const stateIndex = fullAddress.indexOf('Maharashtra');
     

  if (stateIndex !== -1) {
    return fullAddress.slice(0, stateIndex).trim();
  }


  return fullAddress.trim();
  }

  markerOptions = {
    origin: {
      icon: 'https://maps.google.com/mapfiles/kml/paddle/P.png'
    },
    destination: {
      icon: 'https://maps.google.com/mapfiles/kml/paddle/D.png'
    },
  
  }

renderOptions = {
  suppressMarkers: true
};



downloadAsPDFForReference(task :any) {
console.log('download task report pdf=>',task)
const parts = task.pickuplocation.split(',');

this.commonService.getRequest(`traccartasks/timelinetasks/${task.id}`, []).then((resp: any) => {
  if (resp?.status) {
      this.timelineForTask = resp.data;
  }
});

this.formattedAddress = parts.slice(0, parts.length - 6).join(',') + ',' + parts[parts.length - 2];

this.startbeforeDate = moment(task.expectedstarttime).format('DD MMM YYYY HH:mm');
this.completedDate = moment(task.expectedendtime).format('DD MMM YYYY HH:mm');

if(task.status === 'assigned'){
this.downloadPdfForAssigned(task);
}
else if(task.status === 'started'){
this.downloadPdfForInprogress(task);
}
else if(task.status === 'acknowledged'){
this.downloadPdfForAknowledged(task);
}
else if(task.status==='completed'){
this.downloadPdfForCompleted(task);
}
}
downloadPdfForAssigned(task:any){
  let assignedDate;
  const addressRowIndex = 2; 
  this.commonService.getRequest(`traccartasks/timelinetasks/${task.id}`, []).then((resp: any) => {
    if (resp?.status) {
        this.timelineForTask = resp.data;
        console.log(this.timelineForTask);
        assignedDate=moment(this.timelineForTask[0].createddate).format('DD MMM YYYY HH:mm');
        const docDefinition = {
          content: [
            { text: 'Task Status Report', style: 'header', alignment: 'center' },
            { text: 'Task Id : 5365', style: 'taskIdLabel', alignment: 'start', margin: [0, 20] },
            
            {
              text: 'Task Details',
                    style: 'details',
                    margin: [0, 10],
                    fillColor: '#1592ce', // Background color
                    color: '#1592ce', // Text color
                    border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
                    borderColor: 'black'
            },
            {
              style: 'tableExample',
              table: {
                widths: ['*', '*', '*'],
                headerRows: 1,
                body: [
                  [{ text: 'Customer Name', style: 'tableHeader' }, { text: 'Trip Type', style: 'tableHeader' }, { text: 'Phone Number', style: 'tableHeader' }],
                  [`${task.attributes.clientName}`, `${task.attributes.remarks.charAt(0).toUpperCase() + task.attributes.remarks.slice(1)}`, '988989 88989'],
                  [{ text: 'Start Before', style: 'tableHeader' }, { text: 'Completed Before', style: 'tableHeader' }, { text: 'Driver', style: 'tableHeader' }],
                  [`${this.startbeforeDate}`,`${this.completedDate}`,`${task.attributes.cabno}`],
                  [{ text: 'Status', style: 'tableHeader' }, { text: 'Address', style: 'tableHeader' }, ''],
                  [`${task.status.charAt(0).toUpperCase() + task.status.slice(1)}`,`${task.pickuplocation}`, ''],
                ],
              },
              layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; },
                rowHeights: function (rowIndex, node) {
             
                  // Specify custom row heights if needed
                  if (rowIndex === addressRowIndex) {
                      return 100; // Increase this value as needed
                  }
                  return node.height || 20; // Default row height
              }
              }
            },
            
           
            {
              text: 'Milestone',
              style: 'milestone',
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'milestoneTable',
              table: {
                widths: ['auto', '*'],
                headerRows: 0,
                body: [
           [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Assign ${task.attributes.cabno} to task - ${task.updatedby}`, style: ['milestoneContent', 'bold'] },
                { text: `\n${assignedDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
                ],
              },
             layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
            
          
            {
              text: 'Path History',
              style: 'milestone',
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'milestoneTable',
              table: {
                widths: ['auto', '*'],
                headerRows: 0,
                body: [
                   [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: 'Total Distance', style: ['milestoneContent', 'bold'] },
                { text: `\n${task.gpsDistance ? task.gpsDistance : '0'}Kms`, style: 'milestoneContent' }
              ] 
            }
          ],
                          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: 'Total Time Taken', style: ['milestoneContent', 'bold'] },
                { text: '\n0 minutes', style: 'milestoneContent' }
              ] 
            }
          ],
                
                ],
              },
             layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
          ],
          styles: {
            header: { fontSize: 20, bold: true, color: '#1592ce', alignment: 'justify' },
            taskIdLabel: { fontSize: 13, fontWeight: 600, bold: true, color: '#0000', alignment: 'justify' },
            remarks: { fontSize: 10 },
            tableExample: { margin: [0, 5, 0, 15] },
            tableHeader: { bold: true, fontSize: 13, color: 'black' },
            details: { bold:true, fontSize: 14, color: 'black' },
            milestone: { bold:true, fontSize: 14, color: 'black' },
            milestoneTable: { margin: [0, 5, 0, 15] },
            circle: { fontSize: 14 },
            milestoneContent: { fontSize: 12 },
            greenBullet: { color: 'green', fontSize: 24 },
            bold:{bold : true}
          },
          defaultStyle: {
            // alignment: 'justify'
          }
        };
      
        pdfMake.createPdf(docDefinition).open();
    }
  });
}


downloadPdfForAknowledged(task:any){
  let assignedDate;
  let acceptDate;
  this.commonService.getRequest(`traccartasks/timelinetasks/${task.id}`, []).then((resp: any) => {
    if (resp?.status) {
        this.timelineForTask = resp.data;
        console.log(this.timelineForTask);
        assignedDate=moment(this.timelineForTask[0].createddate).format('DD MMM YYYY HH:mm');
        acceptDate=moment(this.timelineForTask[1].createddate).format('DD MMM YYYY HH:mm');

        const docDefinition = {
          content: [
            { text: 'Task Status Report', style: 'header', alignment: 'center' },
            { text: 'Task Id : 5365', style: 'taskIdLabel', alignment: 'start', margin: [0, 20] },
            // {
            //   canvas: [
            //     {  
            //       type: 'rect',
            //       x: 0,
            //       y: 0,
            //       w: 110,
            //       h: 23,
            //       r: 3,
            //       lineColor:'#1592ce',
            //       color: '#1592ce',
            //     }
            //   ]
            // },
            {
              text: 'Task Details',
              style: 'details',
              // absolutePosition: { x: 57, y: 122}
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'tableExample',
              table: {
                widths: ['*', '*', '*'],
                headerRows: 1,
                body: [
                  [{ text: 'Customer Name', style: 'tableHeader' }, { text: 'Trip Type', style: 'tableHeader' }, { text: 'Phone Number', style: 'tableHeader' }],
                  [`${task.attributes.clientName}`, `${task.attributes.remarks.charAt(0).toUpperCase() + task.attributes.remarks.slice(1)}`, '988989 88989'],
                  [{ text: 'Start Before', style: 'tableHeader' }, { text: 'Completed Before', style: 'tableHeader' }, { text: 'Driver', style: 'tableHeader' }],
                  [`${this.startbeforeDate}`,`${this.completedDate}`,`${task.attributes.cabno}`],
                  [{ text: 'Status', style: 'tableHeader' }, { text: 'Address', style: 'tableHeader' }, ''],
                  [`${task.status.charAt(0).toUpperCase() + task.status.slice(1)}`,`${task.pickuplocation}`, ''],
                ],
              },
              layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
            
            //  {
            //   canvas: [
            //     {  
            //       type: 'rect',
            //       x: 0,
            //       y: 0,
            //       w: 110,
            //       h: 23,
            //       r: 3,
            //       lineColor:'#1592ce',
            //       color: '#1592ce',
            //     }
            //   ]
            // },
            {
              text: 'Milestone',
              style: 'milestone',
              // absolutePosition: { x: 59, y: 315}
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'milestoneTable',
              table: {
                widths: ['auto', '*'],
                headerRows: 0,
                body: [
           [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Assign ${task.attributes.cabno} to task - ${task.updatedby}`, style: ['milestoneContent', 'bold'] },
                { text: `\n${assignedDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Accepted at`, style: ['milestoneContent', 'bold'] },
                { text: `\n${acceptDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
                ],
              },
             layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
            
            //    {
            //   canvas: [
            //     {  
            //       type: 'rect',
            //       x: 0,
            //       y: 0,
            //       w: 110,
            //       h: 23,
            //       r: 3,
            //       lineColor:'#1592ce',
            //       color: '#1592ce',
            //     }
            //   ]
            // },
            {
              text: 'Path History',
              style: 'milestone',
              // absolutePosition: { x: 59, y: 435}
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'

            },
            {
              style: 'milestoneTable',
              table: {
                widths: ['auto', '*'],
                headerRows: 0,
                body: [
                   [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: 'Total Distance', style: ['milestoneContent', 'bold'] },
                { text: `\n${task.distanceInKm ? task.distanceInKm : '0'}Kms`, style: 'milestoneContent' }
              ] 
            }
          ],
                          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: 'Total Time Taken', style: ['milestoneContent', 'bold'] },
                { text: '\n35 minutes', style: 'milestoneContent' }
              ] 
            }
          ],
                
                ],
              },
             layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
          ],
          styles: {
            header: { fontSize: 20, bold: true, color: '#1592ce', alignment: 'justify' },
            taskIdLabel: { fontSize: 13, fontWeight: 600, bold: true, color: '#0000', alignment: 'justify' },
            remarks: { fontSize: 10 },
            tableExample: { margin: [0, 5, 0, 15] },
            tableHeader: { bold: true, fontSize: 13, color: 'black' },
            details: { bold:true, fontSize: 14, color: 'white' },
            milestone: { bold:true, fontSize: 14, color: 'white' },
            milestoneTable: { margin: [0, 5, 0, 15] },
            circle: { fontSize: 14 },
            milestoneContent: { fontSize: 12 },
            greenBullet: { color: 'green', fontSize: 24 },
            bold:{bold : true}
          },
          defaultStyle: {
            // alignment: 'justify'
          }
        };
      
        pdfMake.createPdf(docDefinition).open();
    }
  });

}
downloadPdfForInprogress(task:any){
  let assignedDate;
  let acceptDate;
  let startedDate;
  this.commonService.getRequest(`traccartasks/timelinetasks/${task.id}`, []).then((resp: any) => {
    if (resp?.status) {
        this.timelineForTask = resp.data;
        console.log(this.timelineForTask);
        assignedDate=moment(this.timelineForTask[0].createddate).format('DD MMM YYYY HH:mm');
        acceptDate=moment(this.timelineForTask[1].createddate).format('DD MMM YYYY HH:mm');
        startedDate=moment(this.timelineForTask[2].createddate).format('DD MMM YYYY HH:mm');
        const docDefinition = {
          content: [
            { text: 'Task Status Report', style: 'header', alignment: 'center' },
            { text: 'Task Id : 5365', style: 'taskIdLabel', alignment: 'start', margin: [0, 20] },
            // {
            //   canvas: [
            //     {  
            //       type: 'rect',
            //       x: 0,
            //       y: 0,
            //       w: 110,
            //       h: 23,
            //       r: 3,
            //       lineColor:'#1592ce',
            //       color: '#1592ce',
            //     }
            //   ]
            // },
            {
              text: 'Task Details',
              style: 'details',
              // absolutePosition: { x: 57, y: 122}
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'tableExample',
              table: {
                widths: ['*', '*', '*'],
                headerRows: 1,
                body: [
                  [{ text: 'Customer Name', style: 'tableHeader' }, { text: 'Trip Type', style: 'tableHeader' }, { text: 'Phone Number', style: 'tableHeader' }],
                  [`${task.attributes.clientName}`, `${task.attributes.remarks.charAt(0).toUpperCase() + task.attributes.remarks.slice(1)}`, '988989 88989'],
                  [{ text: 'Start Before', style: 'tableHeader' }, { text: 'Completed Before', style: 'tableHeader' }, { text: 'Driver', style: 'tableHeader' }],
                  [`${this.startbeforeDate}`,`${this.completedDate}`,`${task.attributes.cabno}`],
                  [{ text: 'Status', style: 'tableHeader' }, { text: 'Address', style: 'tableHeader' }, ''],
                  [`${task.status.charAt(0).toUpperCase() + task.status.slice(1)}`,`${task.pickuplocation}`, ''],
                ],
              },
              layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
            
            //  {
            //   canvas: [
            //     {  
            //       type: 'rect',
            //       x: 0,
            //       y: 0,
            //       w: 110,
            //       h: 23,
            //       r: 3,
            //       lineColor:'#1592ce',
            //       color: '#1592ce',
            //     }
            //   ]
            // },
            {
              text: 'Milestone',
              style: 'milestone',
              // absolutePosition: { x: 59, y: 314}
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'milestoneTable',
              table: {
                widths: ['auto', '*'],
                headerRows: 0,
                body: [
           [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Assign ${task.attributes.cabno} to task - ${task.updatedby}`, style: ['milestoneContent', 'bold'] },
                { text: `\n${assignedDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Accepted at`, style: ['milestoneContent', 'bold'] },
                { text: `\n${acceptDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Started at`, style: ['milestoneContent', 'bold'] },
                { text: `\n${startedDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
                ],
              },
             layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
            
            //    {
            //   canvas: [
            //     {  
            //       type: 'rect',
            //       x: 0,
            //       y: 0,
            //       w: 110,
            //       h: 23,
            //       r: 3,
            //       lineColor:'#1592ce',
            //       color: '#1592ce',
            //     }
            //   ]
            // },
            {
              text: 'Path History',
              style: 'milestone',
              // absolutePosition: { x: 59, y: 474}
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'milestoneTable',
              table: {
                widths: ['auto', '*'],
                headerRows: 0,
                body: [
                   [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: 'Total Distance', style: ['milestoneContent', 'bold'] },
                { text: `\n${task.distanceInKm ? task.distanceInKm : '0'}Kms`, style: 'milestoneContent' }
              ] 
            }
          ],
                          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: 'Total Time Taken', style: ['milestoneContent', 'bold'] },
                { text: '\n35 minutes', style: 'milestoneContent' }
              ] 
            }
          ],
                
                ],
              },
             layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
          ],
          styles: {
            header: { fontSize: 20, bold: true, color: '#1592ce', alignment: 'justify' },
            taskIdLabel: { fontSize: 13, fontWeight: 600, bold: true, color: '#0000', alignment: 'justify' },
            remarks: { fontSize: 10 },
            tableExample: { margin: [0, 5, 0, 15] },
            tableHeader: { bold: true, fontSize: 13, color: 'black' },
            details: { bold:true, fontSize: 14, color: 'white' },
            milestone: { bold:true, fontSize: 14, color: 'white' },
            milestoneTable: { margin: [0, 5, 0, 15] },
            circle: { fontSize: 14 },
            milestoneContent: { fontSize: 12 },
            greenBullet: { color: 'green', fontSize: 24 },
            bold:{bold : true}
          },
          defaultStyle: {
            // alignment: 'justify'
          }
        };
      
        pdfMake.createPdf(docDefinition).open();
    }
  });

}
downloadPdfForCompleted(task:any){
  let assignedDate;
  let acceptDate;
  let startedDate;
  let completeDate;
  this.commonService.getRequest(`traccartasks/timelinetasks/${task.id}`, []).then((resp: any) => {
    if (resp?.status) {
        this.timelineForTask = resp.data;
        console.log(this.timelineForTask);
        assignedDate=moment(this.timelineForTask[0].createddate).format('DD MMM YYYY HH:mm');
        acceptDate=moment(this.timelineForTask[1].createddate).format('DD MMM YYYY HH:mm');
        startedDate=moment(this.timelineForTask[2].createddate).format('DD MMM YYYY HH:mm');
        completeDate=moment(this.timelineForTask[3].createddate).format('DD MMM YYYY HH:mm');
        const docDefinition = {
          content: [
            { text: 'Task Status Report', style: 'header', alignment: 'center' },
            { text: 'Task Id : 5365', style: 'taskIdLabel', alignment: 'start', margin: [0, 20] },
            // {
            //   canvas: [
            //     {  
            //       type: 'rect',
            //       x: 0,
            //       y: 0,
            //       w: 110,
            //       h: 23,
            //       r: 3,
            //       lineColor:'#1592ce',
            //       color: '#1592ce',
            //     }
            //   ]
            // },
            {
              text: 'Task Details',
              style: 'details',
              // absolutePosition: { x: 57, y: 122}
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'tableExample',
              table: {
                widths: ['*', '*', '*'],
                headerRows: 1,
                body: [
                  [{ text: 'Customer Name', style: 'tableHeader' }, { text: 'Trip Type', style: 'tableHeader' }, { text: 'Phone Number', style: 'tableHeader' }],
                  [`${task.attributes.clientName}`,`${task.attributes.remarks.charAt(0).toUpperCase() + task.attributes.remarks.slice(1)}`, '988989 88989'],
                  [{ text: 'Start Before', style: 'tableHeader' }, { text: 'Completed Before', style: 'tableHeader' }, { text: 'Driver', style: 'tableHeader' }],
                  [`${this.startbeforeDate}`,`${this.completedDate}`,`${task.attributes.cabno}`],
                  [{ text: 'Status', style: 'tableHeader' }, { text: 'Address', style: 'tableHeader' }, ''],
                  [`${task.status.charAt(0).toUpperCase() + task.status.slice(1)}`,`${task.pickuplocation}`, ''],
                ],
              },
              layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
            
            //  {
            //   canvas: [
            //     {  
            //       type: 'rect',
            //       x: 0,
            //       y: 0,
            //       w: 110,
            //       h: 23,
            //       r: 3,
            //       lineColor:'#1592ce',
            //       color: '#1592ce',
            //     }
            //   ]
            // },
            {
              text: 'Milestone',
              style: 'milestone',
              // absolutePosition: { x: 59, y: 316}
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'milestoneTable',
              table: {
                widths: ['auto', '*'],
                headerRows: 0,
                body: [
           [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Assign ${task.attributes.cabno} to task - ${task.updatedby}`, style: ['milestoneContent', 'bold'] },
                { text: `\n${assignedDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Accepted at`, style: ['milestoneContent', 'bold'] },
                { text: `\n${acceptDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Started at`, style: ['milestoneContent', 'bold'] },
                { text: `\n${startedDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: `Successful at`, style: ['milestoneContent', 'bold'] },
                { text: `\n${completeDate}`, style: 'milestoneContent' }
              ] 
            }
          ],
                ],
              },
             layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
            
            //    {
            //   canvas: [
            //     {  
            //       type: 'rect',
            //       x: 0,
            //       y: 0,
            //       w: 110,
            //       h: 23,
            //       r: 3,
            //       lineColor:'#1592ce',
            //       color: '#1592ce',
            //     }
            //   ]
            // },
            {
              text: 'Path History',
              style: 'milestone',
              // absolutePosition: { x: 59, y: 513}
              margin: [0, 10],
              fillColor: '#1592ce', // Background color
              color: '#1592ce', // Text color
              border: [true, true, true, true], // Border configuration: [left, top, right, bottom]
              borderColor: 'black'
            },
            {
              style: 'milestoneTable',
              table: {
                widths: ['auto', '*'],
                headerRows: 0,
                body: [
                   [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: 'Total Distance', style: ['milestoneContent', 'bold'] },
                { text: `\n${task.distanceInKm ? task.distanceInKm : '0'}Kms`, style: 'milestoneContent' }
              ] 
            }
          ],
                          [
            { text: '•', style: 'greenBullet' }, 
            { 
              text: [
                { text: 'Total Time Taken', style: ['milestoneContent', 'bold'] },
                { text: '\n0 minutes', style: 'milestoneContent' }
              ] 
            }
          ],
                
                ],
              },
             layout: {
                hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 1 : 0; },
                vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0; },
                hLineColor: function (i, node) { return 'black'; },
                vLineColor: function (i, node) { return 'black'; },
                paddingLeft: function (i, node) { return 5; },
                paddingRight: function (i, node) { return 5; },
                paddingTop: function (i, node) { return 5; },
                paddingBottom: function (i, node) { return 5; }
              }
            },
          ],
          styles: {
            header: { fontSize: 20, bold: true, color: '#1592ce', alignment: 'justify' },
            taskIdLabel: { fontSize: 13, fontWeight: 600, bold: true, color: '#0000', alignment: 'justify' },
            remarks: { fontSize: 10 },
            tableExample: { margin: [0, 5, 0, 15] },
            tableHeader: { bold: true, fontSize: 13, color: 'black' },
            details: { bold:true, fontSize: 14, color: 'white' },
            milestone: { bold:true, fontSize: 14, color: 'black' },
            milestoneTable: { margin: [0, 5, 0, 15] },
            circle: { fontSize: 14 },
            milestoneContent: { fontSize: 12 },
            greenBullet: { color: 'green', fontSize: 24 },
            bold:{bold : true}
          },
          defaultStyle: {
            // alignment: 'justify'
          }
        };
      
        pdfMake.createPdf(docDefinition).open();
    }
  });

}

db_calculateAndDisplayRoute(
  directionsService: any,
  directionsRenderer: any,
  index: any
) {
  var ways = [];
  directionsService
    .route({
      origin: this.origin1,
      destination: this.destination1,
      waypoints: ways,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    })
    .then((response: google.maps.DirectionsResult) => {
   this.tripRoute=response.routes[0].overview_path
   console.log('trip route=>',this.tripRoute)
    })
    .catch((e: any) => console.log('Directions request failed due to ' + e));
}
callToDriverRequest(userData){
  console.log('driver details=>',userData.phone)
  const user_id = '75694186';
  const token = '6TDHMWLh6XbU5kC3THsh';
  const from = this.userData?.phone;
  const to = userData?.phone;
  if(userData.phone == ''){
    if(userData.attributes?.role == 'driver' )
   {
Swal.fire({
  icon: "error",
  title: "Please add driver contact",
  showConfirmButton: false,
  timer: 1500,
  toast:true,
  position: 'top-end',
}); 
  }
  else   if(userData.attributes?.role == 'sv' ){
    Swal.fire({
      icon: "error",
      title: "Please add supervisor contact",
      showConfirmButton: false,
      timer: 1500,
      toast:true,
      position: 'top-end',
    });
  }
    else   if(userData.attributes?.role == 'client' ){
      Swal.fire({
        icon: "error",
        title: "Please add client contact",
        showConfirmButton: false,
        timer: 1500,
        toast:true,
        position: 'top-end',
      });
  }
  
  }
  else{
    this.commonService.makePostRequest(user_id, token, from, to).subscribe(
      (data) => {
        console.log('Response:', data);

        if(userData?.attributes?.role == 'driver'){
          Swal.fire({
            icon: "success",
            title: "Calling to driver",
            showConfirmButton: false,
            timer: 1500,
            toast:true,
            position: 'top-end',
          });
        }
        else if(userData?.attributes?.role == 'sv'){
          Swal.fire({
            icon: "success",
            title: "Calling to Supervisor",
            showConfirmButton: false,
            timer: 1500,
            toast:true,
            position: 'top-end',
          });
        }
        else if(userData?.attributes?.role == 'client'){
          Swal.fire({
            icon: "success",
            title: "Calling to Company",
            showConfirmButton: false,
            timer: 1500,
            toast:true,
            position: 'top-end',
          });
        }
     

    
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
}


setDelay(task:any):boolean{  
console.log('start time',task.actualstarttime)
console.log('end time',task.actualendtime)
console.log('expected start time',task.expectedendtime)
if(task.att === undefined){
 return true;
 }
 else if(task.actualendtime === undefined){
  return true;
 }
 else if(task.actualendtime!= undefined  && task.expectedendtime !=undefined){
  const actualEndTime = new Date(task.actualendtime);
  const expectedEndTime = new Date(task.expectedendtime);
  if(actualEndTime > expectedEndTime){
    return true;
  }
 }
return false;
}
adjustMapBounds(): void {


  const filteredMarkers = this.markersList.filter(device => 
    device.latitude !== 0 && device.longitude !== 0
  );
  if (this.markersList.length === 0) {
    return;
  }
  const positions = filteredMarkers.map(device => ({
    lat: device.latitude,
    lng: device.longitude
  }));
 console.log('positions=>',positions)
    const bounds = new google.maps.LatLngBounds();
    positions.forEach(position => bounds.extend(position));
    this.map.fitBounds(bounds, { top: 50, bottom: 50, left: 50, right: 50 });
      this.map.zoom = 10.5; 

 
}



getTasksStatusCount(fromdate,todate,clientid='',cabNo=''){
debugger
this.acknowledgedCount = 0;
this.assignedCount = 0;
this.inprogressCount = 0;
this.unassignedCount = 0;
this.rejectedCount = 0;
this.failedCount = 0;
this.completedCount = 0;
this.startedCount = 0;
this.droppedCount = 0;
this.pickedup = 0;
this.totalRecordCount=0;
let fromDate1=moment(fromdate).startOf('day').toISOString();
let toDate1=moment(todate).endOf('day').toISOString();
  this.commonService.getRequest(`dashboard/tasks/today/status?fromDate=${fromDate1}&toDate=${toDate1}&clientid=${clientid}&cabNo=${cabNo}`, []).
  then((resp: any) => {
    this.taskCountByStatus = resp.data;
    this.taskCountByStatus.forEach(entry => {
      switch (entry.status.toLowerCase()) {
        case 'acknowledged':
          this.acknowledgedCount = entry.recordCount;
          break;
        case 'assigned':
          this.assignedCount = entry.recordCount;
          break;
        case 'inprogress':
          this.inprogressCount = entry.recordCount;
          break;
        case 'unassigned':
            this.unassignedCount = entry.recordCount;
            break;
        case 'rejected':
              this.rejectedCount = entry.recordCount;
              break;
        case 'failed':
                this.failedCount = entry.recordCount;
                break; 
        case 'completed':
          this.completedCount = entry.recordCount;        
           break;
        case 'started':
          this.startedCount = entry.recordCount;
          break;
        case 'dropped':
          this.droppedCount = entry.recordCount; 
          break;
        case 'pickedup':
            this.pickedup = entry.recordCount; 
            break;
      }
      this.totalRecordCount = this.assignedCount +this.acknowledgedCount + this.startedCount + this.droppedCount + this.pickedup;
    
    });
})


}
getTasksStatusCountForSupervisor(fromdate,todate,clientid='',cabno=''){
  this.acknowledgedCount = 0;
this.assignedCount = 0;
this.inprogressCount = 0;
this.unassignedCount = 0;
this.rejectedCount = 0;
this.failedCount = 0;
this.completedCount = 0;
this.startedCount = 0;
this.droppedCount = 0;
this.pickedup = 0;
let fromDate1=moment(fromdate).startOf('day').toISOString();
let toDate1=moment(todate).endOf('day').toISOString();
  // dashboard/tasks/today/status/supervisor?fromDate=2024-08-28T00:00:00Z&toDate=2024-08-28T23:59:59Z&supervisorId=3498
  this.commonService.getRequest(`dashboard/tasks/today/status/supervisor?fromDate=${fromDate1}&toDate=${toDate1}&supervisorId=${this.supervisorId}&clientid=${clientid}&cabNo=${cabno}`, []).
  then((resp: any) => {
    this.taskCountByStatus = resp.data;
    this.taskCountByStatus.forEach(entry => {
      switch (entry.status.toLowerCase()) {
        case 'acknowledged':
          this.acknowledgedCount = entry.recordCount;
          break;
        case 'assigned':
          this.assignedCount = entry.recordCount;
          break;
        case 'inprogress':
          this.inprogressCount = entry.recordCount;
          break;
        case 'unassigned':
            this.unassignedTaskCount = entry.recordCount;
            break;
        case 'rejected':
              this.rejectedCount = entry.recordCount;
              break;
        case 'failed':
                this.failedCount = entry.recordCount;
                break; 
        case 'completed':
          this.completedTaskCount = entry.recordCount;        
           break;
        case 'started':
          this.startedCount = entry.recordCount;
          break;
        case 'dropped':
          this.droppedCount = entry.recordCount; 
          break;
        case 'pickedup':
            this.pickedup = entry.recordCount; 
            break;
      }
      this.assignedTaskCount = this.assignedCount +this.acknowledgedCount + this.startedCount + this.droppedCount + this.pickedup;
    
    });
})


}
getMappedUsers(dealerId) {

  this.commonService
    .getRequest('users', ['userId=' + dealerId])
    .then((resp: any) => {
      if (resp?.status) {
       this.clientList= resp.data.map((d: any) => {
          return d;
        });
        console.log('mapped client',this.clientList)
        // this.usersList.forEach((d: any, k: number) => {
        //   this.usersList[k]['mapDealerId'] = dealerId;
        //   this.usersList[k]['isSelectedUser'] =
        //     mappedId.indexOf(d.id) > -1;
        // });
      }
    });
}
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
