
<!-- LOGO PAGE -->

<div class="container-fluid" *ngIf="logoShow">
  <div class="row align-items-center section">
    <div class="content1">
      <div class="stage">
        <img src="/assets/images/logo_old.png"  class="box bounce-5" alt="logo">
      </div>
      <!-- <div class="MultiTrack text-black">{{"multiDrive"|translate}}</div> -->
      <div class="MultiTrack text-black">{{"multiDrive"|translate}}</div>
      <!-- <p class="Powered text-black">{{'Powered by'|translate}} @2004-2022 </p> -->
    </div>
  </div>
</div>

<!-- LOGIN PAGE -->

<div class="container-fluid" *ngIf="loginShow">
  <div class="row section backgroundImg">
    <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12 ">

    </div>
    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12 logincol_2"
    style="margin-top:10vh"
    >
      <!-- <img width="300px" src="{{siteDetails.logo}}" class="" alt="logo"> -->
      <div class="card mt-4 ms-4">
        <div class="card-body">
          <div class="accountmanage black mt-1 mx-auto fs-4">{{'Login to manage your account'|translate}}</div>
          <form class="loginform" [formGroup]="reactiveForm" (ngSubmit)="onSubmit()">

            <div class="form__form-group">
              <div class="form__form-group-field">
                <input formControlName="username" type="text" placeholder="Email/Mobile/User ID"
                  class="inputfields black"
                  style="color:black !important;">
              </div>
              <span class="text-danger validation"
                *ngIf="(registerFormControl.username.touched || submitted) && registerFormControl.username.errors?.required">
                *{{'Email/User ID/Phone Number is required'|translate}}</span>
            </div>

            <div class="form__form-group">
              <div class="form__form-group-field position-relative">
                <input type="{{isPasswordVisiable ? 'text':'password' }}" formControlName="password" class="inputfields black" style="color:black" placeholder="Password">
                <span (click)="isPasswordVisiable =  !isPasswordVisiable;" class="material-icons passicon" style="color: #000; cursor: pointer;"> {{isPasswordVisiable ? 'visibility':'visibility_off' }} </span>
              </div>
              <span class="text-danger validation"
                *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required">
                *{{'Password is required'|translate}} 
              </span>
              <span class="text-danger validation"
                *ngIf="registerFormControl.password.touched && registerFormControl.password.errors?.invalidPassword">
                *{{'Password should have minimum 8 characters at least 1 uppercase letter 1 lowercaseletter and 1 number'|translate}}
              </span>
            </div>

            <div class="form__form-group">
              <div class="form__form-group-field checkbtn">
                <mat-checkbox class="example-margin black " formControlName="keepsignedin">{{'Keep me Signed in' |translate}}</mat-checkbox>
              </div>
            </div>

            <div class="account__forgot-password">
              <a routerLink="/forgotpassword" class="forgotpassword black">{{'Forgot Your Password'|translate}}?</a>
            </div>

            <div class="account__btns">
              <button class="btn signin" type="submit">{{'Sign in'|translate}}</button>
            </div>

          </form>

        </div>
      </div>

    </div>
    <!-- <div class="newuser black" >{{'New user'|translate}}? <span class="signup" routerLink="/register">{{'Sign Up'|translate}}</span> </div> -->
  </div>
</div>
