import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonService } from 'src/app/services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
import * as ApexCharts from 'apexcharts';
import { DatePipe } from '@angular/common';
declare var $: any;
declare var _: any;
@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.css'],
  providers: [MeterTokm]
})
export class ExpenseComponent implements OnInit {
  @Input() driverList:any[]=[];
  filter: any = {
    driver: '',
  };
  environmentbaseurl = environment.baseUrl;
  todate = moment().endOf('month').format('YYYY-MM-DD');
  fromdate = moment().startOf('month').format('YYYY-MM-DD');
  maxDateValidation = moment(new Date()).format('YYYY-MM-DD');
  pageOption = 10;
  groupListData = [];
  deviceDataAddTime = [];
  modelId = "";
  form:FormGroup;
  editForm = false;
  userId = localStorage.getItem("userId");
  page: number = 1;
  addGroupForm: FormGroup;
  submitted = false;
  searchMasterModel = "";
  superAdmin :boolean = false;
  @Input()
  addMode = false;
  @Input()
  gridMode = false;
  sortcolumnName = '';
  sortdirection = '';
  isAscending = true;
  modalRef?: BsModalRef;
  deviceList: any[] = [];
  userData:any ;
  modelForm:FormGroup;
  selectedGroup: any = {};
  filteredOptions: Observable<any[]>;
  filteredOptionsDevice: Observable<any[]>;
  filteredOptionsForDriver: Observable<{ display: string; id: string; }[]>;
  myDriverControl=new FormControl();
  filteredOptionsDriver: Observable<any[]>;
  DeviceArray = [];
  selectedFile: File;
  myControl = new FormControl();
  driverFormControl = new FormControl();
  driverSearchFormControl= new FormControl();
  pageOptionItems: any = 10; 
  averageCalc: any = 0; 
  chartsView: any = false;
  expenseByMonth: any[] = [];
  expenseByType: any[] = [];
  taskList:boolean=false;
  taskListData=[];
  orgId: any;
  deviceId: any='';
  model: any;
  isUpdateDone:boolean=false
  tripsCount: any= 0;
  driversList2: any[]=[];
  selectedDriverData: any;
  selectedDriverSeachData:any;
  device_id: any;   
  device_name: any;
  db_driverList: any[]=[];
  driverId: any='';
  constructor(
    private fb: FormBuilder, 
    private customValidator: ValidationService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private metertokm: MeterTokm) {
    this.addGroupForm = this.fb.group(
      {
        id: [0],
        type: [''],
        description: [''],
        deviceid: [0],
        device_name: [''],
        date: [''],
        amount: [0],
        quantity: [0],
        files: [''],
        modeofpayment: ['']       
      });
      this.form = new FormGroup({
        searchValue: new FormControl(''),
      });
      this.modelForm = new FormGroup({
        searchValue: new FormControl(''),
       
      });
  }
  
  ngOnInit(): void {    

  
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userData =  JSON.parse(userDataObj);
        this.superAdmin = this.userData && this.userData.administrator;
        console.log('USer data =>',this.userData)
        if(this.userData.attributes.role === 'admin'){
       this.orgId=this.userData.id;
       
      }

      else{
        this.orgId = this.userData.orgId;
      }
      } catch (error) {}
    }
    this.commonService.pageLoadInModal.subscribe((result: any) => {
      if (result.val == 'expense' && result.from == 'reports') {

        this.gridMode = true;
        this.addMode = false;        
      }
      else if (result == "expense") {
        this.myControl.patchValue({});
        this.gridMode = true;
        this.addMode = false;        
      }
      this.getAllGroupData();
    });
    this.commonService.getRequest('devices?all=all', []).then((resp: any) => {
      console.log('devices', resp);      
      if (resp?.status) {
        this.DeviceArray = resp.data;
      }
    });
    this.db_driverList=this.driverList;
    console.log(' this.db_driverList=>', this.db_driverList)

    this.filteredOptions = this.addGroupForm.controls.deviceid.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.DeviceArray.slice();
      }),);
      this.filteredOptionsDevice = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filter(name as string) : this.DeviceArray.slice();
        }),);
        this.filteredOptionsDriver = this.myDriverControl.valueChanges.pipe(
         startWith(''),
         map(value => this._filterforDrivers(value))
       );
  }

  driverSelected(driver) {
      this.selectedDriverSeachData=driver ? driver.id : ''
      this.getSingleDeviceInformation(driver.id);
  
     }

  get AddModelMasterControl() {
    return this.addGroupForm.controls;
  }
  onSelectVehicle(v) {  

    this.getAllGroupData();
  }
  getAllGroupData() {    
debugger
    console.log("id of driver",this.driverId);
    let param = `?userId=${this.userData.id}&from=${this.fromdate}&to=${this.todate}`;
    if (this.selectedDriverSeachData != '' ) {      
        // this.Device_ID = this.myControl.value.id;
        // params.push('deviceId=' + this.Device_ID);
        param += `&deviceId=${this.device_id}`;
    }
    this.commonService.getRequest("expenses/user"+param, []).then((resp: any) => {
      if (resp?.status) { 
        this.gridMode = true;
        this.groupListData = resp.data;
        this.page = 1;
        this.isAscending = false;
        this.sorting('date');
      }
    })
  }
  onFileUpload(event: any) {    
    if (event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    } else {
      Swal.fire({
        icon: "error",
        title: "Unsupported File Type.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  onModelMasterSubmit() {
      if (!this.addGroupForm.valid) {
        this.addGroupForm.controls.name.markAsTouched();
        return;
      }
      
      if (this.addGroupForm.value.id != null && this.addGroupForm.value.id >= 0) {
        let body = {
          id: this.addGroupForm.value.id,
          name: this.addGroupForm.value.name,
          
          attributes: {
            contact: this.addGroupForm.value.attributes.contact
          },
          groupId: this.addGroupForm.value.groupId
        }
        this.commonService.putRequest(`groups/${this.addGroupForm.value.id}`, body).then(resp => {
          Swal.fire({
            icon: "success",
            title: "Your groups has been Submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          this.addGroupForm.reset();
          this.gridMode = true;
          this.addMode = false;
          this.getAllGroupData();
        });
      } else {  
     
        const formData: FormData = new FormData();
        let dt = isNaN(Date.parse(this.addGroupForm.value.date)) ? new Date().toISOString() : new Date(this.addGroupForm.value.date).toISOString();
        formData.append('type', this.addGroupForm.value.type ? this.addGroupForm.value.type :'');
        formData.append('description', this.addGroupForm.value.description ? this.addGroupForm.value.description:'');
        formData.append('deviceid', this.device_id ? this.device_id:0);
        formData.append('device_name', this.device_name ? this.device_name:0)
        formData.append('date', dt);
        formData.append('amount', this.addGroupForm.value.amount ? this.addGroupForm.value.amount:'' );
        formData.append('quantity', this.addGroupForm.value.quantity);
        formData.append('modeofpayment', this.addGroupForm.value.modeofpayment ? this.addGroupForm.value.modeofpayment :'');
        formData.append('status','pending');
        formData.append('distance', this.deviceDataAddTime.length > 0 && this.deviceDataAddTime[0]?.kmtravelled ? this.metertokm.transform(this.deviceDataAddTime[0].kmtravelled) : 0);
        // formData.append('expense', this.addGroupForm.value.expense);
        formData.append('average', this.averageCalc ? this.averageCalc :0 );
        formData.append('files', this.selectedFile ? this.selectedFile : '');
        this.commonService.postRequestForFile("expenses", formData).then(resp => {
          Swal.fire({
            icon: "success",
            title: "Your expense has been Submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          this.addGroupForm.reset();
          this.gridMode = true;
          this.addMode = false;
          this.getAllGroupData();
        });        
      }    
  }

  onEditModelMaster(model){
    this.gridMode = false;
    this.addMode = true;
    this.addGroupForm.patchValue({
      id: model.id,    
      name: model.name,
      attributes: {
        contact: model.attributes?.contact,
      },      
      groupId: model.groupId
    })
    this.modelId = model.id;
  }

  onDeleteModelMasterExpense(modelId){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this Expense!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.commonService.deleteRequest("expenses/"+modelId).then(resp => {
          console.log(resp);
          this.gridMode = true;
          this.addMode = false;
          this.getAllGroupData();
        });
        Swal.fire(
          'Deleted!',
          'Expense has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Expense is safe :)',
          'error'
        )
      }
    })
  }

  onCloseModal(){
  
    this.updateStatus('rejected');
    this.gridMode = true;
    this.addMode = false;
    this.taskList=false;
    this.addGroupForm.reset();
  }
  onAddCloseModal(){
    this.gridMode = true;
    this.addMode = false;
    this.taskList=false;
    this.addGroupForm.reset();
  }
  onAddModelMaster() {
    this.gridMode = false;
    this.addMode = true;
    this.editForm = false;
    this.deviceDataAddTime = [];
    this.addGroupForm.reset();
  }

  values(event) {
  }

  sorting(col: any) {
    this.sortcolumnName = col;
    this.isAscending = !this.isAscending;
    this.sortdirection = this.isAscending? "asc":"desc";
    this.sortingFunction(this.sortcolumnName, this.sortdirection);
  }
  sortingFunction(column, direction) {
    if (column) {
      //this.deviceList.forEach(element => {
        this.groupListData.sort((a,b)=>{
          if(a[column] > b[column]){
            return (direction === 'desc') ? 1 : -1;
          }
          if(a[column] < b[column]){
            return (direction === 'desc') ? -1 : 1;
          }
          return 0;
        });        
      //})
      
    } else {
      return this.groupListData;
    }
  }

  assignDevice(group, modal) {
    this.modalRef = this.modalService.show(modal, { class : "modal-xl"});
    this.deviceList = [];
    console.log('---------', group);
    this.commonService.getRequest('devices', []).then((resp: any) => {
      if (resp?.status) {
        this.deviceList = resp.data;
        this.getUserDevice(this.userData.id, group);
      }
    });
  }
  getUserDevice(userId, group) {
    this.commonService
      .getRequest('devices', ['userId=' + userId])
      .then((resp: any) => {
        if (resp?.status) {
          // let mappedId = resp.data.map((d: any) => {
          //   return d.id;
          // });
          this.selectedGroup = group;
          this.deviceList.forEach((d: any, k: number) => {
            this.deviceList[k]['mapUserId'] = userId;
            this.deviceList[k]['mapGroupId'] = group.id;
            this.deviceList[k]['isSelectedDevice'] = false;
              //mappedId.indexOf(d.id) > -1;
          });
          this.getMappedGroupsDevices(group.id);
        }
      });
  }
  getMappedGroupsDevices(groupdId) {
    this.commonService
      .getRequest('positions?status=ALL&limit=10&offset=0&groupid='+groupdId, [])
      .then((resp: any) => {
        if (resp?.status) {
          resp.data.forEach((d: any) => {
            let match = this.deviceList.findIndex(x => x.id == d.deviceId);
            if (match >= 0) {
              this.deviceList[match]['isSelectedDevice'] = true;
            }
          });
        }
      });
  }
  changeEventDevices(e: any, device) {
    if (e.checked) {
      device.groupId = this.selectedGroup.id;
      this.commonService
          .putRequest('devices/' + device.id, {
            id: device.id,
            name: device.name,
            uniqueId: device.uniqueId,
            groupId: device.groupId,
            phone: device.phone,
            model: device.model,
            contact: device.contact,
            category: device.category,
            disabled: device.disabled,
            distanceForday: device.distanceForday,
            attributes: device.attributes,
            expirationTime: device.expirationTime
          })
          .then((resp: any) => {
            this.selectedGroup = {};
            // this.gridMode = true;
            // this.addMode = false;
            // this.getAllDevices();
            // $('#btnclose').click();
            // Swal.fire({
            //   icon: 'success',
            //   title: 'Your device has been Updated Successfully',
            //   showConfirmButton: false,
            //   timer: 1500,
            // });
          },(err)=>{
            //console.log(err);
             Swal.fire({
               icon: "error",
               title: "Something Went Wrong",
               showConfirmButton: false,
               timer: 1500,
             });
           });
    }
    else {
      device.groupId = 0;
      this.commonService
            .putRequest('devices/' + device.id, {
              id: device.id,
              name: device.name,
              uniqueId: device.uniqueId,
              groupId: device.groupId,
              phone: device.phone,
              model: device.model,
              contact: device.contact,
              category: device.category,
              disabled: device.disabled,
              distanceForday: device.distanceForday,
              attributes: device.attributes,
              expirationTime: device.expirationTime
            })
            .then((resp: any) => {
              this.selectedGroup = {};
              // this.gridMode = true;
              // this.addMode = false;
              // this.getAllDevices();
              // $('#btnclose').click();
              // Swal.fire({
              //   icon: 'success',
              //   title: 'Your device has been Updated Successfully',
              //   showConfirmButton: false,
              //   timer: 1500,
              // });
            },(err)=>{
              //console.log(err);
               Swal.fire({
                 icon: "error",
                 title: "Something Went Wrong",
                 showConfirmButton: false,
                 timer: 1500,
               });
             });
    }    
  }
  closeDevicesModal() {
    this.modalRef.hide();
  }
  displayFn(vehicle: any): string {
    return vehicle && vehicle.name ? vehicle.name : '';
  }
  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
  
    return this.DeviceArray.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  addGridDataFilter(device: any) {
    let deviceName = this.AddModelMasterControl.deviceid?.value?.name;
    if (device) {
      deviceName = device.name;
    }
    if (deviceName && this.AddModelMasterControl.type && this.AddModelMasterControl.type?.value) {
      let data = this.groupListData.filter(model => model.devicename.toLowerCase() == deviceName.toLowerCase() && model.type.toLowerCase() == this.AddModelMasterControl.type?.value.toLowerCase());
      this.deviceDataAddTime = data;
      this.averageCalc = 0;
      this.calculateAverage();
      if (this.deviceDataAddTime.length > 0) {
        this.sortingListFunction('date', 'asc');
        this.getDeviceSummary();
      }
    }
  }
  deviceOptionChange(value: any) {
    this.addGridDataFilter(value);
    console.log(value);
  }
  sortingListFunction(column, direction) {
    if (column) {
      //this.deviceList.forEach(element => {
        this.deviceDataAddTime.sort((a,b)=>{
          if(a[column] > b[column]){
            return (direction === 'desc') ? 1 : -1;
          }
          if(a[column] < b[column]){
            return (direction === 'desc') ? -1 : 1;
          }
          return 0;
        });        
      //})
      
    } else {
      return this.deviceDataAddTime;
    }
  }
  getDeviceSummary() {
    let pageOption = 10;    
    let params = [
      'from=' + new Date(this.deviceDataAddTime[0].date).toISOString(),
      'to=' + new Date().toISOString(),
      'page=1',
      'start=0',
      'limit=' + pageOption,
      'daily=' + false,
    ];  
    params.push('deviceId=' + this.AddModelMasterControl.deviceid?.value?.id);    
    if (this.deviceDataAddTime.length > 0 && this.deviceDataAddTime[0]) {
      this.commonService
        .getRequest('reports/summary?' + params.join('&'), [])
        .then((resp: any) => {          
          if (resp?.status) {            
            this.deviceDataAddTime[0]['kmtravelled'] = resp.data[0].distance;
          }
        });
    }
  }
  calculateAverage() {
    if (this.deviceDataAddTime && this.deviceDataAddTime.length > 0 && this.AddModelMasterControl.quantity && this.AddModelMasterControl.quantity?.value && this.AddModelMasterControl.type && this.AddModelMasterControl.type?.value) {
      this.averageCalc = (+this.metertokm.transform(this.deviceDataAddTime[0]['kmtravelled']) / +this.AddModelMasterControl.quantity?.value);
    }
  }  
  toggleListChart() {
    this.chartsView = !this.chartsView;
    
    this.getChartsData();
  }
  getChartsData() {
    this.commonService
      .getRequest('expenses/bymonth', [])
      .then((resp: any) => {
        if (resp?.status) {
          this.expenseByMonth = resp.data;
          this.createLineChart();          
        }
      });
      this.commonService
      .getRequest('expenses/user/Fuel', [])
      .then((resp: any) => {
        if (resp?.status) {
          this.expenseByType = resp.data;          
          this.createPieChart();
        }
      });
  }
  createLineChart() {
    var options = {
      series: [{
        name: "Expense",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }],
      chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Expense by Month',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      categories: [],
    }
    };
    setTimeout(() => {
      var chart = new ApexCharts(document.querySelector("#Linechart"), options);
      options.series[0].data = [];
      options.xaxis.categories = [];
      let axisdata = [];
      this.expenseByMonth.forEach((result) => {
        options.series[0].data.push(result.amount);
        let year = result.yearMonth.substr(0,4);
        let month = result.yearMonth.substr(4);
        axisdata.push(moment(year+"-"+month+"-01").format('MMM'));
        //{y:(km? km.toFixed(2) :0),x:moment(dItem.startTime).format('DD-MM-YYYY')});
      });      
      chart.render();

      setTimeout(() => {
        chart.updateOptions({
          xaxis: {
            categories: axisdata,
          },
        });
      }, 500);
      
    }, 100);
    
    //chart.resetSeries();
  }

  createPieChart() {
    var options = {
    series: [],
    labels: [],
    chart: {
      width: 500,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      formatter: function(val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex]
      }
    },
    title: {
      text: 'Expense By Type'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        
        legend: {
          position: 'bottom'
        }
      }
    }]
    };

    setTimeout(() => {
      var chart = new ApexCharts(document.querySelector("#Piechart"), options);
      options.series = [];
      options.labels = [];
      //options.xaxis.categories = [];
      let axisdata = [];
      let labels = [];
      this.expenseByType.forEach((result) => {
        labels.push(result.type);        
        axisdata.push(result.amount);        
      });      
      chart.render();

      // setTimeout(() => {
      //   chart.updateSeries(axisdata);        
      // }, 500);
      setTimeout(() => {
        chart.updateOptions({
          labels: labels,
          series: axisdata
        });
      }, 1000);
    }, 100);
  }

  openTaskList(model){
    this.isUpdateDone= true;
    if(model.status== 'rejected' || model.status == 'approved'){
      this.isUpdateDone=false;
    }
    this.gridMode=false;
    this.taskList=true;
    this.fetchTasks(model);

  }

  fetchTasks(model) {
    this.model=model,
    this.deviceId=model.id
   let originalDate = new Date(model.date);
const toDate=originalDate.toISOString();
let fromDate
this.commonService.getTaskListforExpense(`expenses/recent/user/approved?userId=${this.model.createdby}`).subscribe(
  (response) => {
  fromDate=new Date(response.createdtime).toISOString();
  this.commonService.getTasks(`traccartasks/orgId/${this.orgId}`,this.model.createdby, '',fromDate, toDate, '','')
  .subscribe((response) => {
    // this.taskListData = response.filter(x => x.status === 'completed');
    this.taskListData = response
    this.tripsCount=this.taskListData.length;
    console.log(response);
  }, (error) => {
    console.error(error);
  });
})  
} 
  calculateTotalDistance(): number {
    return this.taskListData.reduce((total, customer) => total + (customer.gpsDistance || 0), 0);
  }
  updateStatus(status:string){
 
    let file=`https://${this.environmentbaseurl}:8082/api/file/downloadfile/${this.model.billfilepath}`
    const formData = new FormData();
    // formData.append('deviceid', this.model.deviceid);
    // formData.append('date', this.model.date);
    // formData.append('files',file);
    formData.append('status',status);
    formData.append('id',this.model.id);
    // formData.append('type',this.model.type);
    // formData.append('amount', this.model.amount);
    // formData.append('modeofpayment',this.model.modeofpayment);
    formData.append('quantity', this.model.quantity);
    // formData.append('distance', this.model.distance);
    // formData.append('description',this.model.description);
    // formData.append('devicename',this.model.devicename);
   this.commonService.updateExpenses('expenses',formData).subscribe(resp =>{
    if(status== 'approved'){
      Swal.fire({
        icon: "success",
        title: "Expense approved successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    else if(status== 'rejected'){
      Swal.fire({
        icon: "success",
        title: "Expense rejected successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    
    this.gridMode = true;
    this.taskList = false;
    this.getAllGroupData();
   })
    // this.commonService.putRequest(`expenses`, body).then(resp => {
    //   Swal.fire({
    //     icon: "success",
    //     title: "Expense approved successfully",
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    //   this.gridMode = true;
    //   this.taskList = false;
    //   this.getAllGroupData();
    // });

  }

  get formattedDate(): string {
    return this.model.date ? this.formatDate(this.model.date) : '';
  }

  private formatDate(date: Date): string {
    const formatted = new DatePipe('en-US').transform(date, 'dd-MM-yyyy HH:mm:ss');
    return formatted ? formatted : '';
  }

  getDriversListfordropdown() {

    this.commonService.getRequest(`users?orgId=${this.orgId}`, []).then((resp: any) => {
      if (resp?.status) {
          this.driversList2 = resp.data.filter(x => x.attributes.role == 'driver');
         console.log(this.driversList2)
          }
          
  })  
  }

  private _filterforDrivers(value: string): any[] {
    debugger
    const filterValue = value.toLowerCase().trim(); // Convert input value to lowercase and trim whitespace
    if(value ==  "")
      {
        this.selectedDriverSeachData=''
      }
  return this.driverList
    .filter(option => {
      const cabnoString = option?.name?.toString()?.toLowerCase(); // Convert cabno to lowercase
      if (!cabnoString) {
        return false; // Skip if cabnoString is null or undefined
      }

      // Check if cabnoString contains the filterValue (case insensitive)
      return cabnoString.includes(filterValue);
    })
    .map(option => ({ 
      id: option.id,
      display: option?.name?.toString() }));

  }	



    displayFnDriver(driver: any): string{
    if (driver && driver.attributes && driver.cabNo && driver.name) {
      return `${driver?.cabNo} ${driver.name}`;
  } else {
      return '';
  }
}

onDriverSelectionChange() {
  console.log('Selected Driver ID:', this.selectedDriverData.id);
  this.getSingleDeviceInformation(this.selectedDriverData.id);

}
onDriverSearchSelectionChange() {
  console.log('Selected Driver ID:', this.selectedDriverSeachData.id);
  if(this.selectedDriverSeachData != undefined){
    this.commonService
    .getRequest(
      'devices/userid/' +
      this.selectedDriverSeachData.id,
      []
    )
    .then((resp: any) => {  
      this.device_id=resp.data.id;
      this.device_name=resp.data.name;
  this.getAllGroupData();   
      })
  }
  else{
    this.getAllGroupData();
  }
}
filteredOption1() {

  this.filteredOptionsForDriver = this.driverFormControl.valueChanges.pipe(
    startWith(''),
    map(value => this._filterforDrivers(value))
  );
}
filteredOption2() {

  this.filteredOptionsForDriver = this.driverSearchFormControl.valueChanges.pipe(
    startWith(''),
    map(value => this._filterforDrivers(value))
  );
}

getSingleDeviceInformation(val){
  debugger
  this.commonService
  .getRequest(
    'devices/userid/' +
    val,
    []
  )
  .then((resp: any) => {  
    this.device_id=resp.data.id;
    this.device_name=resp.data.name;
    console.log('oncustom sub task=>',this.device_id)
    console.log('oncustom sub=>', this.device_name)   
    })
  }
			
  driver_list_change(ev: any, list: any) {
    if (ev.target.value == '') {
      this.db_driverList = JSON.parse(JSON.stringify(list));
    } else {
      this.db_driverList = list.filter((dx: any) => {
        return dx?.cabNo.match(RegExp(ev.target.value, 'gi')) != null;
      });
    }
  }
  
    getTitle(list: any, bookId: any) {
    if (!list) {
      // console.error('List is undefined');
      return ''; // Return default value if list is undefined
    }
    return bookId == ''
      ? ''
      : list.find((dx: any) => {
          return dx.id == bookId;
        }).cabNo ?? '';
  }
    filter_change(id: any, key: any) {
      debugger
  this.selectedDriverSeachData=id.driver
  this.driverId=id.driver
   this.getSingleDeviceInformation(id.driver)

  }
}
